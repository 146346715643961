.c-basic-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;

  &__th,
  &__td {
    border: solid 1px $border-gray3;
    padding: 8px 10px;

    @include media-breakpoint-up(md) {
      border: solid 1px $border-gray3;
      padding: 18px 30px;
    }
  }

  &__th {
    background-color: $bg-light-gray;
    font-weight: normal;
  }

  &__td {
    line-height: 1.7;

    @include media-breakpoint-down(sm) {
      font-size: 1.3rem;

      &.-sp-none {
        display: none !important;
      }

      &.-sp100 {
        width: 100% !important;
        border-left: solid 1px $border-gray3 !important;
      }
    }

    @include media-breakpoint-up(md) {
      line-height: 1.9;
    }
  }

  &:not(.-no-flex) {
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      border-top: solid 1px $border-gray3;
    }

    &__tr {
      @include media-breakpoint-down(sm) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__th,
    &__td {
      @include media-breakpoint-down(sm) {
        border-top: none;
      }
    }

    &__th {
      @include media-breakpoint-down(sm) {
        width: 100%;
        text-align: center;
      }
    }
  }

  &.-visa-howto &__th {
    @include media-breakpoint-up(md) {
      width: 258px;
    }
  }

  &.-visa-howto &__td {

    &:nth-of-type(1) {
      width: 54px;
      text-align: center;
      vertical-align: middle;
      padding: 0;

      @include media-breakpoint-down(sm) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @include media-breakpoint-up(md) {
        width: 127px;
      }
    }

    &:nth-of-type(2) {
      @include media-breakpoint-down(sm) {
        width: calc(100% - 54px);
        border-left: none;
      }
    }
  }

  &__dl {}

  &__dt {
    font-weight: bold;

    &:not(:first-of-type) {
      margin-top: 2px;
    }
  }

  &__dd { }

  &.-bg-white {
    background-color: white;
  }

  &.-bg-white &__th {
    font-weight: bold;
    background-color: white;
  }

  /* stylelint-disable-next-line */
  &.-col2 &__td {

    &:nth-of-type(1) {
      @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 8px 0;
      }
    }
  }

  &.-prix {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 650px;
      margin: 0 auto;
    }
  }

  &.-prix &__th {
    width: 140px;
    background-color: $bg-light-gray;

    @include media-breakpoint-up(md) {
      width: 260px;
    }
  }

  /* stylelint-disable-next-line */
  &.-prix &__td {
    background-color: white;
    text-align: right;
  }
}
