.c-prix-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: white;

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    border-top: solid 1px $border-gray3;
  }

  &__tr {
    @include media-breakpoint-down(sm) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__th,
  &__td {
    border: solid 1px $border-gray3;

    @include media-breakpoint-down(sm) {
      border-top: none;
    }

    @include media-breakpoint-up(md) {
      border: solid 1px $border-gray3;
    }
  }

  &__th {
    width: 100%;
    background-color: $bg-light-gray;
    font-weight: normal;

    @include media-breakpoint-down(sm) {
      text-align: center;
      padding: 7px 0;
    }

    @include media-breakpoint-up(md) {
      width: 210px;
      padding-left: 10px;
    }
  }

  &__td {
    line-height: 1.7;

    @include media-breakpoint-down(sm) {
      font-size: 1.3rem;
      width: 100% !important;
    }

    @include media-breakpoint-up(md) {
      line-height: 1.9;
    }
  }
}
