.c-border-title {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;

  @include media-breakpoint-up(md) {
    font-size: 2.4rem;
  }

  &::after {
    content: "";
    flex-grow: 1;
    height: 2px;
    background-color: $border-gray2;
    margin-left: 14px;
  }
}
