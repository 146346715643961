/* =============================================
 * Header
 * ============================================= */

.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: transparent;

  @include media-breakpoint-down(sm) {
    height: 58px;

    body.is-opened--drawer-menu & {
      height: 100vh;

      &::before,
      &::after {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-up(md) {
    height: 117px;
    padding: 0 20px;

    &::before {
      content: "";
      width: calc(100% - 40px);
      min-width: $min-width;
      max-width: $max-width;
      border-bottom: solid 1px white;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &::before,
  &::after {
    height: 100vh;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s;
    pointer-events: none;

    @include media-breakpoint-up(md) {
      display: none !important;
    }
  }

  &::before {
    background: url("/assets/img/common/kv-sp.jpg") no-repeat center;
    background-size: cover;
  }

  &::after {
    background-color: rgba(255, 255, 255, 0.8);
  }

  &__inner {
    width: 100%;
    position: relative;

    @include media-breakpoint-down(sm) {
      height: 58px;
    }

    @include media-breakpoint-up(md) {
      min-width: $min-width;
      max-width: $max-width;
      transition: all 0.5s;
      margin: 0 auto;
    }
  }

  &__read {
    width: calc(100% - 495px);
    height: 40px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 1.2rem;
    color: white;
    visibility: visible;
    opacity: 1;
    transition: all 0.5s 0s;

    @include media-breakpoint-down(sm) {
      display: none !important;
    }
  }

  &__logo {
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      width: 134px;
      padding: 17px 0 0 19px;
    }

    @include media-breakpoint-up(md) {
      position: absolute;
      left: 2px;
      top: 58px;
      transition: top 0.5s;
    }

    &__link {
      display: block;
    }

    &__icon {
      font-size: 2.26rem;
      color: white;
      transition: all 0.5s;

      body.is-opened--drawer-menu & {
        @include media-breakpoint-down(sm) {
          color: black;
        }
      }

      @include media-breakpoint-up(md) {
        font-size: 3.8rem;
      }
    }
  }

  &__tel {
    position: absolute;
    top: 19px;
    right: 242px;
    visibility: visible;
    opacity: 1;
    transition: all 0.5s 0s;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &__link {
      display: block;
    }

    &__number {
      color: white;
      font-size: 2.4rem;
    }

    &__txt {
      font-size: 1.2rem;
      white-space: nowrap;
      color: white;
    }
  }

  &__helper-list {
    display: flex;
    position: absolute;
    top: 15px;
    right: 63px;
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;

    @include media-breakpoint-up(md) {
      top: 21px;
      right: 0;
    }

    body.is-opened--drawer-menu & {
      visibility: hidden;
      opacity: 0;
    }

    &__item {

      &:first-of-type {
        margin-right: 6px;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }

  /* animations */
  &.-fixed {
    background-color: white;

    @include media-breakpoint-up(md) {
      height: 65px;
    }
  }

  &.-fixed &__inner {
    @include media-breakpoint-up(md) {
      border-color: transparent;
      padding-top: 18px;
    }
  }

  &.-fixed &__read {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
  }

  &.-fixed &__logo {
    @include media-breakpoint-up(md) {
      top: 19px;
    }
  }

  &.-fixed &__logo__icon {
    color: black;

    @include media-breakpoint-up(md) {
      font-size: 2.9rem;
    }
  }

  &.-fixed &__tel {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
  }

  &.-fixed &__helper-list {

    .c-border-link {
      color: black;
      border-color: #ccc;
      background-color: #ccc;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: white;
        border-color: black;
        background-color: black;
      }
    }
  }

  /* lower */
  /* stylelint-disable-next-line */
  body.-lower & {
    background-color: white;
  }

  /* stylelint-disable */
  body.-lower &__inner {
    border: none;
  }

  body.-lower &__read,
  body.-lower &__logo__icon,
  body.-lower &__tel__number,
  body.-lower &__tel__txt,
  body.-lower &__logo__icon {
    color: black;
  }

  body.-lower &__tel__number {
    color: $tricolor-blue-color;
  }

  body.-lower &__helper-list .c-border-link {
    background-color: #ccc;
    border-color: #ccc;
    color: black;

    &:hover {
      background-color: black;
      border-color: black;
      color: white;
    }
  }
}
