.c-dormitory-panel {
  border: solid 1px $tricolor-blue-color;
  border-radius: 10px;
  padding: 17px 20px 6px;

  @include media-breakpoint-up(md) {
    padding: 30px 47px 26px;
  }

  @media screen and (max-width: 320px){
    padding: 17px 15px 6px;
  }

  &__ttl {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: $tricolor-blue-color;
    white-space: nowrap;
    margin-bottom: 11px;

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
      margin-bottom: 8px;
    }

    &__icon {
      font-size: 1.9rem;
      margin-right: 10px;

      @include media-breakpoint-up(md) {
        font-size: 2.3rem;
        margin-right: 5px;
      }
    }
  }

  &__description {
    font-size: 1.3rem;
    line-height: 1.65;
    margin-bottom: 17px;

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
      line-height: 1.9;
      margin-bottom: 10px;
    }
  }

  .c-separate-table {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 700px;
    }

    &__tr {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    &__th {
      width: 365px;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    &__td {
      @include media-breakpoint-down(sm) {
        width: calc(100% / 2);
        min-height: 32px;
        justify-content: center;
      }
    }
  }
}
