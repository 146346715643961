body.-nav-top {

  #QuiHeaderTtl,
  #ServiceHeaderTtl {

    .c-top-sec-header__title__img,
    .c-top-sec-header__title__txt {
      opacity: 0;
      visibility: hidden;
    }

    .c-top-sec-header__title__img {
      transition: all 0.5s ease-in-out;
    }

    .c-top-sec-header__title__txt {
      transition: all 0.5s 0.3s;
    }

    &.-active {

      .c-top-sec-header__title__img,
      .c-top-sec-header__title__txt {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  #QuiHeaderTxt {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;

    &.-active {
      visibility: visible;
      opacity: 1;
    }
  }

  #QuiHeaderLink {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;

    &.-active {
      visibility: visible;
      opacity: 1;
    }
  }

  #QuiImgGrid {
    visibility: hidden;
    opacity: 0;
    transition: all 0.7s;

    &.-active {
      visibility: visible;
      opacity: 1;
    }
  }

  #Visa,
  #Ecole,
  #Traduction {

    .c-service-card__img-container {
      visibility: hidden;
      opacity: 0;
      transition: all 1s cubic-bezier(0.21, 0.61, 0.35, 1);

      @include media-breakpoint-up(md) {
        transform: translateX(-150px);
      }

      &.-ecole {
        @include media-breakpoint-up(md) {
          transform: translateX(150px);
        }
      }
    }

    .c-service-card__title,
    .c-service-card__body__detail {
      visibility: hidden;
      opacity: 0;
    }

    .c-service-card__title {
      transition: all 0.5s ease-in-out 0.2s;

      @include media-breakpoint-up(md) {
        transition: all 0.5s ease-in-out 0.5s;
      }
    }

    .c-service-card__body__detail {
      transition: all 0.5s ease-in-out 0.5s;

      @include media-breakpoint-up(md) {
        transition: all 0.5s ease-in-out 0.9s;
      }
    }

    &.-active {

      .c-service-card__img-container {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
      }

      .c-service-card__title,
      .c-service-card__body__detail {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  #Ecole {

  }

  #Traduction {

  }
}
