.p-ecole-detail {

  &__header {
    margin-bottom: 32px;

    @include media-breakpoint-up(md) {
      margin-bottom: 76px;
    }
  }

  &__lower-header {
    margin: 39px 0 30px;

    @include media-breakpoint-up(md) {
      margin: 88px 0 35px;
    }

    &__txt {
      font-size: 1.1rem;
      font-weight: bold;
      text-align: center;
      margin-top: 7px;

      @include media-breakpoint-down(sm) {
        line-height: 1.5;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
        margin-top: 7px;
      }
    }
  }

  &__catch-copy {
    border-width: 1px 0;
    border-style: solid;
    border-color: black;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding: 7px 0;
    margin-bottom: 31px;

    @include media-breakpoint-up(md) {
      font-size: 2.6rem;
      padding: 13px 0;
      margin-bottom: 68px;
    }
  }

  &__sec {
    @include media-breakpoint-down(sm) {
      padding: 40px 19px 0;
    }

    @include media-breakpoint-up(md) {
      width: 960px;
      padding-top: 70px;
      margin: 0 auto;
    }
  }

  &__detail-ttl {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 4px;

    @include media-breakpoint-up(md) {
      font-size: 2.6rem;
      margin-bottom: 18px;
    }
  }

  &__detail-txt {
    font-size: 1.3rem;

    @include media-breakpoint-down(sm) {
      line-height: 1.6;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
    }
  }

  &__table {

    &__annot {
      text-align: right;
      margin-top: 12px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &__info-sec {
    margin-bottom: 43px;

    @include media-breakpoint-up(md) {
      margin-bottom: 64px;
    }

    .p-ecole-detail__detail-ttl {
      margin-bottom: 11px;

      @include media-breakpoint-up(md) {
        margin-bottom: 17px;
      }
    }

    .p-ecole-detail__col2 {
      display: flex;
      margin-bottom: 16px;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      @include media-breakpoint-up(md) {
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 26px;
      }

      &__left {
        @include media-breakpoint-down(sm) {
          order: 1;
        }

        @include media-breakpoint-up(md) {
          flex-grow: 1;
        }
      }

      &__right {
        @include media-breakpoint-down(sm) {
          order: 0;
          margin-bottom: 26px;
        }

        @include media-breakpoint-up(md) {
          width: 432px;
          padding-top: 70px;
          margin-left: 48px;
        }
      }

      &__img {
        margin-bottom: 3px;

        @include media-breakpoint-up(md) {
          margin-bottom: 10px;
        }
      }
    }

    .c-separate-table {
      @include media-breakpoint-down(sm) {
        margin-bottom: 16px;
      }

      &__th {
        @include media-breakpoint-down(sm) {
          width: 122px;
        }

        @media screen and (max-width: 320px){
          width: 107px;
        }
      }

      &__td {
        @include media-breakpoint-down(sm) {
          width: calc(100% - 137px);
        }

        @media screen and (max-width: 320px){
          width: calc(100% - 117px);
        }
      }
    }

    .c-separate-list {
      margin-top: 16px;

      @include media-breakpoint-up(md) {
        margin-top: 20px;
      }
    }
  }

  &__basic-sec {
    margin-bottom: 46px;

    @include media-breakpoint-up(md) {
      margin-bottom: 46px;
    }

    .c-separate-table {
      margin-top: 15px;

      &__th {
        width: 120px;

        @include media-breakpoint-up(md) {
          width: 250px;
        }

        @media screen and (max-width: 320px){
          width: 105px;
        }
      }

      &__td {
        @include media-breakpoint-down(sm) {
          width: calc(100% - 135px);

          .c-slash-txt {
            padding: 7px 0;
          }
        }

        @media screen and (max-width: 320px){
          width: calc(100% - 115px);
        }
      }
    }
  }

  &__strength-sec {
    margin-bottom: 53px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 70px;
    }

    .c-dormitory-panel {
      margin-top: 25px;

      @include media-breakpoint-up(md) {
        margin-top: 52px;
      }
    }
  }

  &__semester-sec {
    margin-bottom: 18px;

    @include media-breakpoint-up(md) {
      margin-bottom: 66px;
    }

    .p-ecole-detail__detail-ttl {
      margin-bottom: 13px;

      @include media-breakpoint-up(md) {
        margin-bottom: 24px;
      }
    }
  }

  &__accommodation-sec {
    margin-bottom: 47px;

    @include media-breakpoint-up(md) {
      margin-bottom: 63px;
    }

    .p-ecole-detail__detail-ttl {
      @include media-breakpoint-down(sm) {
        margin-bottom: 9px;
      }
    }

    .p-ecole-detail__detail-txt {
      @include media-breakpoint-down(sm) {
        font-size: 1.6rem;
      }
    }
  }

  &__period-sec {

    .p-ecole-detail__detail-ttl {
      @include media-breakpoint-down(sm) {
        margin-bottom: 9px;
      }
    }

    .p-ecole-detail__detail-txt {
      @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
      }
    }

    .c-separate-table {
      margin-top: 16px;

      &__th {
        @include media-breakpoint-down(sm) {
          width: 120px;
          font-size: 1.4rem;
        }
      }

      &__td {
        @include media-breakpoint-down(sm) {
          width: calc(100% - 135px);
          font-size: 1.4rem;
        }
      }
    }
  }

  &__return-link {
    font-size: 1.5rem;
    margin-top: 54px;

    @include media-breakpoint-up(md) {
      width: 270px;
      margin: 62px auto 0;
    }
  }
}
