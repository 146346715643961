.c-board {
  width: 100%;
  padding: 16px 19px;

  &__ttl {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
    }

    &:not(:first-of-type) {
      margin-top: 10px;

      @include media-breakpoint-up(md) {
        margin-top: 30px;
      }
    }
  }

  &__txt {
    line-height: 1.8;

    @include media-breakpoint-up(md) {
      line-height: 1.7;
    }
  }

  &.-gray {
    background-color: $bg-light-gray;
  }

  &.-white {
    background-color: white;
    border: solid 1px $border-gray3;
    padding: 17px 15px;

    .c-board__txt {
      line-height: 1.9;
    }

    @include media-breakpoint-up(md) {
      padding: 27px 35px;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 21px 33px;

    &.-h105 {
      display: flex;
      align-items: center;
      height: 105px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__prix {
    font-size: 1.5rem;
    font-weight: bold;

    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
    }

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
    }

    &__ttl {
      white-space: nowrap;
      margin-right: 16px;
    }

    &__value {
      white-space: nowrap;
    }
  }
}
