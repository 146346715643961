.c-prix-post {
  padding-bottom: 30px;

  @include media-breakpoint-up(md) {
    padding-bottom: 90px;
  }

  &__ttl {
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      margin-bottom: 12px;
    }
  }

  &__paragraph {
    line-height: 1.6;

    @include media-breakpoint-up(md) {
      line-height: 1.9;
    }
  }

  &__board {
    padding: 10px 14px 14px;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      padding: 44px 40px 48px;
      margin-top: 37px;
    }

    .c-board__txt {
      font-size: 1.2rem;
      line-height: 1.7;

      @include media-breakpoint-up(md) {
        font-size: 1.6rem;
        line-height: 1.95;
      }
    }
  }

  .c-helper-btn {
    margin: 22px auto 0;

    @include media-breakpoint-up(md) {
      margin: 80px auto 0;
    }
  }
}
