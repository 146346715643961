/* =============================================
 * Wrapper
 * ============================================= */

.l-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  body:not(.-nav-top) & {
    padding-top: 58px;

    @include media-breakpoint-up(md) {
      padding-top: 117px;
    }
  }
}
