.c-service-card {
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  @include media-breakpoint-up(md) {
  }

  &__title {
    z-index: 1;

    @include media-breakpoint-down(sm) {
      position: absolute;
      top: 0;
      left: 0;
    }

    &.-visa {
      @include media-breakpoint-down(sm) {
        width: 91%;
        padding-top: 12%;
        margin-left: 4.5%;
      }

      @include media-breakpoint-up(md) {
        width: 776px;
        transform: translateX(-316px);
        margin-bottom: 7px;
      }
    }

    &.-ecole {
      @include media-breakpoint-down(sm) {
        width: 25%;
        margin-left: 5%;
        padding-top: 11.8%;
      }

      @include media-breakpoint-up(md) {
        width: 207px;
        margin-bottom: 17px;
      }
    }

    &.-traduction {
      @include media-breakpoint-down(sm) {
        width: 49%;
        margin-left: 5%;
        padding-top: 12%;
      }

      @include media-breakpoint-up(md) {
        width: 412px;
        transform: translateX(-164px);
        margin-bottom: 23px;
      }
    }

    &__img {}
  }

  &__sub-title {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: bold;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      font-size: 2.3rem;
    }

    &.-visa {
      margin-bottom: 8px;

      @include media-breakpoint-up(md) {
        margin-bottom: 17px;
      }
    }

    &.-ecole {
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        margin-bottom: 15px;
      }
    }

    &.-traduction {
      margin-bottom: 11px;

      @include media-breakpoint-up(md) {
        margin-bottom: 14px;
      }
    }

    &__icon {

      &.pi-visa {
        font-size: 3rem;
        margin-right: 8px;

        @include media-breakpoint-up(md) {
          font-size: 4rem;
          margin-right: 8px;
        }
      }

      &.pi-ecole {
        font-size: 3.1rem;
        margin-right: 11px;

        @include media-breakpoint-up(md) {
          font-size: 4.2rem;
          margin-right: 14px;
        }
      }

      &.pi-traduction {
        font-size: 2.7rem;
        margin-right: 14px;

        @include media-breakpoint-up(md) {
          font-size: 3.6rem;
          margin-right: 13px;
        }
      }
    }
  }

  &__img-container {
    @include media-breakpoint-up(md) {
      width: 50%;
      min-width: 50%;
      min-height: 450px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      &::before {
        content: "";
        display: block;
        padding-top: 73%;
      }

      &.-visa {
        background-image: url("/assets/img/top/service-img-visa.jpg");
      }

      &.-ecole {
        background-image: url("/assets/img/top/service-img-ecole.jpg");
      }

      &.-traduction {
        background-image: url("/assets/img/top/service-img-traduction.jpg");
      }
    }
  }

  &__img {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__body {
    width: calc(100% - 38px);

    @include media-breakpoint-down(sm) {
      padding: 12px 0 40px;
      margin: 0 auto;
    }

    @include media-breakpoint-up(md) {
      width: 50%;
      min-width: 50%;
      padding-top: 63px;
    }

    &.-left {
      @include media-breakpoint-down(sm) {
        order: 2;
      }

      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 53px;
      }
    }

    &.-right {
      @include media-breakpoint-up(md) {
        padding-left: 47px;
      }
    }
  }

  &__txt {
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.7;
    margin-bottom: 32px;

    @include media-breakpoint-up(md) {
      width: 450px;
      font-size: 1.7rem;
      line-height: 1.8;
      margin-bottom: 41px;
    }
  }

  &__link {
    @include media-breakpoint-up(md) {
      margin-left: 4px;
    }

    &.-right {
      @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 83px;
      }
    }
  }
}
