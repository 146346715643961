.c-step-title {
  width: 100%;
  min-height: 30px;
  display: flex;
  background-color: white;

  @include media-breakpoint-up(md) {
    height: 56px;
  }

  &__label {
    width: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $tricolor-blue-color;

    @include media-breakpoint-up(md) {
      width: 150px;
    }

    &__img {
      width: 56px;

      @include media-breakpoint-up(md) {
        width: 98px;
      }
    }
  }

  &__txt {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: bold;
    padding: 0 6px;

    @include media-breakpoint-up(md) {
      font-size: 2.6rem;
      padding: 0 20px;
    }
  }
}
