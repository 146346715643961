.c-separate-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
    width: calc((100% - 6px) / 3);

    @include media-breakpoint-up(md) {
      width: calc((100% - 75px) / 6);
    }
  }

  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49px;
    background-color: $bg-table-light-gray;
    margin-bottom: 4px;

    @include media-breakpoint-down(sm) {
      font-size: 1.3rem;
      line-height: 1.3;
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      height: 43px;
      margin-bottom: 10px;
    }
  }

  &__value {
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(sm) {
      padding-bottom: 4px;
    }
  }
}
