.c-hamburger-btn {
  width: 58px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 2px;
  z-index: 1;

  @include media-breakpoint-up(md) {
    display: none;
  }

  &__bar {
    width: calc(100% - 30px);
    height: 3px;
    position: relative;
    background-color: white;
    transition: all 0.3s;

    body.is-opened--drawer-menu & {
      background-color: transparent !important;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      position: absolute;
      background-color: white;
      transition: all 0.3s;

      body.is-opened--drawer-menu & {
        background-color: black;
      }
    }

    /* stylelint-disable-next-line */
    &::before {
      top: -9px;

      body.is-opened--drawer-menu & {
        top: 0;
        transform: rotate(225deg);
      }
    }

    /* stylelint-disable-next-line */
    &::after {
      top: 9px;

      body.is-opened--drawer-menu & {
        top: 0;
        transform: rotate(-225deg);
      }
    }
  }

  body.-lower &__bar,
  .l-header.-fixed &__bar {
    background-color: black;

    &::before,
    &::after {
      background-color: black;
    }
  }
}
