.c-pageup {
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 18px;
  bottom: 12px;
  background-color: #333;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 10;

  @include media-breakpoint-up(md) {
    right: 30px;
    bottom: 20px;

    &:hover {
      opacity: 0.7 !important;
    }
  }

  &.-static {
    position: absolute;
    top: -66px;
    bottom: inherit;

    @include media-breakpoint-up(md) {
      top: -75px;
    }
  }

  body.-visible-pageup & {
    opacity: 1;
    visibility: visible;
  }

  &__img {}
}
