.c-school-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &__item {
    @include media-breakpoint-down(sm) {
      width: calc(100% / 2);
      padding: 0 8px;
      margin-bottom: 17px;
    }

    @include media-breakpoint-up(md) {
      width: 244px;
      padding: 0 8px;
      margin-bottom: 37px;
    }
  }

  &__link {
    display: block;
  }

  &__img {
    margin-bottom: 6px;

    @include media-breakpoint-up(md) {
      margin-bottom: 15px;
    }
  }

  &__ttl {
    font-size: 1.3rem;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
      padding-left: 5px;
    }
  }
}
