.c-more-link-list {
  @include media-breakpoint-down(sm) {
    padding: 0 19px;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
  }

  &__item {

    &:first-of-type {
      @include media-breakpoint-down(sm) {
        margin-bottom: 27px;
      }

      @include media-breakpoint-up(md) {
        margin-right: 50px;
      }
    }
  }
}
