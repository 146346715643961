.c-curtain {
  width: 150%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 9;
  pointer-events: none;
  margin-left: -25%;

  &__item {
    width: 100%;
    height: 200%;
    position: relative;
    top: -50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, hsla(0, 0%, 0%, 0), hsla(0, 0%, 0%, 0.1) 10%, hsla(0, 0%, 0%, 0) 60%, hsla(0, 0%, 0%, 0.1) 80%, hsla(0, 0%, 0%, 0));
    transform-origin: top center;
    //opacity: 0;
    will-change: transform;
  }

  #c-curtain__item--01 {
    animation: curtain01 13s ease-in-out alternate infinite;
  }

  #c-curtain__item--02 {
    animation: curtain02 15s ease-in-out alternate-reverse infinite;
  }

  #c-curtain__item--03 {
    animation: curtain03 12s ease-in-out alternate infinite;
  }

  #c-curtain__item--04 {
    animation: curtain04 14s ease-in-out alternate infinite;
  }

  #c-curtain__item--05 {
    animation: curtain05 12s ease-in-out alternate infinite;
  }
}

@keyframes curtain01 {

  0% {
    transform: matrix3d(0.9, -0.82, 0, 0, 0.82, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  15% {
    transform: matrix3d(1.87, -0.78, 0, 0, 0.78, 1.87, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  27% {
    transform: matrix3d(1.93, -0.89, 0, 0, 0.89, 1.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  40% {
    transform: matrix3d(2.55, -0.35, 0, 0, 0.35, 2.55, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  50% {
    transform: matrix3d(1.48, -0.65, 0, 0, 0.65, 1.48, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  60% {
    transform: matrix3d(1.9, -0.71, 0, 0, 0.71, 1.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  70% {
    transform: matrix3d(2.32, -0.66, 0, 0, 0.66, 2.32, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  85% {
    transform: matrix3d(1.03, -0.59, 0, 0, 0.59, 1.03, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  100% {
    transform: matrix3d(1.79, -0.7, 0, 0, 0.7, 1.79, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes curtain02 {

  0% {
    transform: matrix3d(2.55, -0.35, 0, 0, 0.35, 2.55, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  15% {
    transform: matrix3d(1.48, -0.65, 0, 0, 0.65, 1.48, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  27% {
    transform: matrix3d(1.79, -0.7, 0, 0, 0.7, 1.79, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  40% {
    transform: matrix3d(0.9, -0.82, 0, 0, 0.82, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  50% {
    transform: matrix3d(1.87, -0.78, 0, 0, 0.78, 1.87, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  60% {
    transform: matrix3d(1.9, -0.71, 0, 0, 0.71, 1.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  70% {
    transform: matrix3d(1.03, -0.59, 0, 0, 0.59, 1.03, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  85% {
    transform: matrix3d(1.93, -0.89, 0, 0, 0.89, 1.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  100% {
    transform: matrix3d(2.32, -0.66, 0, 0, 0.66, 2.32, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes curtain03 {

  0% {
    transform: matrix3d(1.79, -0.7, 0, 0, 0.7, 1.79, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  15% {
    transform: matrix3d(1.93, -0.89, 0, 0, 0.89, 1.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  27% {
    transform: matrix3d(0.9, -0.82, 0, 0, 0.82, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  40% {
    transform: matrix3d(1.9, -0.71, 0, 0, 0.71, 1.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  50% {
    transform: matrix3d(1.48, -0.65, 0, 0, 0.65, 1.48, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  60% {
    transform: matrix3d(2.55, -0.35, 0, 0, 0.35, 2.55, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  70% {
    transform: matrix3d(2.32, -0.66, 0, 0, 0.66, 2.32, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  85% {
    transform: matrix3d(1.03, -0.59, 0, 0, 0.59, 1.03, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  100% {
    transform: matrix3d(1.87, -0.78, 0, 0, 0.78, 1.87, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes curtain04 {

  0% {
    transform: matrix3d(1.9, -0.71, 0, 0, 0.71, 1.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  15% {
    transform: matrix3d(1.79, -0.7, 0, 0, 0.7, 1.79, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  27% {
    transform: matrix3d(1.93, -0.89, 0, 0, 0.89, 1.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  40% {
    transform: matrix3d(2.55, -0.35, 0, 0, 0.35, 2.55, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  50% {
    transform: matrix3d(1.48, -0.65, 0, 0, 0.65, 1.48, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  60% {
    transform: matrix3d(0.9, -0.82, 0, 0, 0.82, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  70% {
    transform: matrix3d(2.32, -0.66, 0, 0, 0.66, 2.32, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  85% {
    transform: matrix3d(1.03, -0.59, 0, 0, 0.59, 1.03, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  100% {
    transform: matrix3d(1.87, -0.78, 0, 0, 0.78, 1.87, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes curtain05 {

  0% {
    transform: matrix3d(1.03, -0.59, 0, 0, 0.59, 1.03, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  15% {
    transform: matrix3d(1.93, -0.89, 0, 0, 0.89, 1.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  27% {
    transform: matrix3d(1.87, -0.78, 0, 0, 0.78, 1.87, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  40% {
    transform: matrix3d(1.48, -0.65, 0, 0, 0.65, 1.48, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  50% {
    transform: matrix3d(2.55, -0.35, 0, 0, 0.35, 2.55, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  60% {
    transform: matrix3d(1.9, -0.71, 0, 0, 0.71, 1.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  70% {
    transform: matrix3d(0.9, -0.82, 0, 0, 0.82, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  85% {
    transform: matrix3d(2.32, -0.66, 0, 0, 0.66, 2.32, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  100% {
    transform: matrix3d(1.79, -0.7, 0, 0, 0.7, 1.79, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
