.c-avantage-post {
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__img {
    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-up(md) {
      width: 465px;
    }
  }

  &__body {
    @include media-breakpoint-down(sm) {
      padding: 0 19px;
    }

    @include media-breakpoint-up(md) {
      width: 455px;
    }
  }

  &__ttl {
    font-size: 1.5rem;
    line-height: 1.6;
    font-weight: bold;
    margin-bottom: 12px;

    @include media-breakpoint-up(md) {
      font-size: 2.6rem;
      margin: -6px 0 20px;
    }
  }

  &__paragraph {
    font-size: 1.3rem;

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
      line-height: 1.9;
    }
  }
}
