.p-visa-index {

  &__intro {
    width: 100%;

    @include media-breakpoint-down(sm) {
      padding: 30px 19px 0;
      margin-bottom: 33px;
    }

    @include media-breakpoint-up(md) {
      max-width: $lower-max-width;
      padding-top: 70px;
      margin: 0 auto 70px;
    }

    .c-paragraph {
      letter-spacing: 0.3px;
    }
  }

  &__area {

    &.-france {
      padding: 42px 0 22px;

      @include media-breakpoint-up(md) {
        padding: 94px 0 22px;
      }

      .c-lower-title {
        margin-bottom: 50px;

        @include media-breakpoint-up(md) {
          margin-bottom: 73px;
        }
      }

      .c-sec-visual {
        margin-bottom: 25px;

        &.-pour-etudier {
          margin-bottom: 16px;
        }
      }

      .c-visa-post {
        margin-bottom: 45px;

        @include media-breakpoint-up(md) {
          margin-bottom: 95px;
        }

        &.-etudiant {
          margin-top: 35px;

          @include media-breakpoint-up(md) {
            margin-top: 85px;
          }
        }

        &.-concours,
        &.-visiteur,
        &.-vacances,
        &.-scientifique {
          margin-bottom: 45px;

          @include media-breakpoint-up(md) {
            margin-bottom: 120px;
          }
        }
      }
    }

    &.-monaco {

      .c-lower-title {
        margin-bottom: 50px;

        @include media-breakpoint-up(md) {
          margin-bottom: 73px;
        }
      }

      .c-sec-visual {
        margin-bottom: 25px;
      }
    }
  }

  &__sec {

    &.-categories {
      background-color: $bg-light-gray;
      padding: 63px 19px 36px;

      @include media-breakpoint-up(md) {
        padding: 80px 0 76px;
      }

      .c-lower-title {
        margin-bottom: 50px;

        @include media-breakpoint-up(md) {
          margin-bottom: 60px;
        }
      }

      .c-flag-title {
        margin-bottom: 27px;

        @include media-breakpoint-up(md) {
          margin-bottom: 40px;
        }
      }
    }

    &__inner {
      @include media-breakpoint-up(md) {
        width: 960px;
        margin: 0 auto;
      }
    }
  }

  &__cat-sec {

    &.-fr {
      margin-bottom: 52px;

      @include media-breakpoint-up(md) {
        margin-bottom: 40px;
      }
    }
  }

  &__img-caption {
    margin-bottom: 28px;

    @include media-breakpoint-down(sm) {
      padding: 0 19px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 48px;
    }
  }

  &__about-sec {
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
      margin-bottom: 35px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 19px;
    }

    .c-border-title {
      margin-bottom: 9px;
    }

    .c-paragraph {
      line-height: 1.8;

      @include media-breakpoint-up(md) {
        line-height: 2;
      }
    }
  }
}
