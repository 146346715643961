.p-visa-tarif {

  &__sec {

    &__inner {
      @include media-breakpoint-up(md) {
        width: 960px;
        margin: 0 auto;
      }
    }

    &.-des-prix {
      background-color: $bg-light-gray;
      padding: 65px 19px 54px;

      @include media-breakpoint-up(md) {
        padding: 86px 19px 84px;
      }

      .c-lower-title {
        margin-bottom: 54px;

        @include media-breakpoint-up(md) {
          margin-bottom: 59px;
        }
      }

      .c-prix-table {
        margin-bottom: 30px;
      }
    }

    &.-service-content {
      padding: 65px 0 0;

      @include media-breakpoint-up(md) {
        padding: 86px 19px 0;
      }
    }

    &__service-ttl {
      font-size: 1.8rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 30px;

      @include media-breakpoint-up(md) {
        font-size: 3.6rem;
        margin-bottom: 63px;
      }
    }
  }

  &__service-sec {
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
      margin-bottom: 97px;
    }

    .c-visa-post {
      margin-bottom: 19px;

      @include media-breakpoint-up(md) {
        margin-bottom: 63px;
      }
    }

    .c-accordion {
      @include media-breakpoint-down(sm) {
        padding: 0 19px;
      }
    }
  }

  &__prix-ttl {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 22px;

    @include media-breakpoint-up(md) {
      font-size: 3.6rem;
      margin-bottom: 22px;
    }

    &__sub {
      display: block;
      font-size: 1.4rem;

      @include media-breakpoint-up(md) {
        font-size: 2.6rem;
      }
    }
  }

  &__country-ttl {
    font-size: 1.8rem;
    font-weight: bold;
    white-space: nowrap;
    margin-bottom: 5px;

    @include media-breakpoint-up(md) {
      font-size: 2.6rem;
    }
  }
}
