.c-breadcrumb {
  padding: 0 19px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: $max-width;
      min-width: $min-width;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-right: 5px;

      &::after {
        content: ">";
        font-size: 1.2rem;
        margin-left: 5px;
      }
    }
  }

  &__link {
    font-size: 1.2rem;
    white-space: nowrap;
  }
}
