.c-border-table {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;

  &__tr {
    @include media-breakpoint-down(sm) {
      border-bottom: solid 1px $border-gray3;

      &:first-of-type {
        border-top: solid 1px $border-gray3;
      }
    }

    @include media-breakpoint-up(md) {

      &:not(:last-of-type) {
        border-bottom: solid 1px $border-gray3;
      }
    }
  }

  &__th,
  &__td {
    font-size: 1.5rem;
    padding: 7px 0;

    @include media-breakpoint-down(sm) {
      line-height: 1.7;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      padding: 8px 0;
    }
  }

  &__th {
    width: 131px;
    font-weight: bold;
    vertical-align: top;

    @include media-breakpoint-up(md) {
      width: 158px;
    }
  }

  &__td {}
}
