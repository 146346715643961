.c-paragraph {

  &.-large {
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
    }
  }
}
