.c-helper-btn {
  width: 100%;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1 !important;
  position: relative;
  transition: all 0.3s;

  &::before,
  &::after {
    content: "";
    height: 2px;
    width: 0;
    position: absolute;
    transition: all 0.3s;
  }

  &::before {
    top: 0;
    right: 0;
  }

  &::after {
    bottom: 0;
    left: 0;
  }

  &__icon {
    color: white;
    transition: all 0.3s;

    &.pi-mail {
      font-size: 3.9rem;
      margin-right: 15px;

      @include media-breakpoint-up(md) {
        font-size: 5.6rem;
        margin-right: 22px;
      }
    }

    &.pi-inscription {
      font-size: 4.5rem;
      margin-right: 15px;

      @include media-breakpoint-up(md) {
        font-size: 6.4rem;
        margin-right: 22px;
      }
    }
  }

  &__txt-icon {
    color: white;
    transition: all 0.3s;

    &.pi-txt-contact {
      font-size: 2rem;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        font-size: 2.8rem;
        margin-bottom: 15px;
      }
    }

    &.pi-txt-inscription {
      font-size: 2.35rem;
      margin-bottom: 8px;

      @include media-breakpoint-up(md) {
        font-size: 3.3rem;
        margin-bottom: 10px;
      }
    }
  }

  &__label-container {
    display: flex;
    flex-direction: column;
  }

  &__img-txt {

    &.-contact {
      width: 200px;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        width: 280px;
        margin-bottom: 15px;
      }
    }

    &.-inscription {
      width: 131px;
      margin-bottom: 3px;

      @include media-breakpoint-up(md) {
        width: 187px;
        margin-bottom: 10px;
      }
    }
  }

  &__label {
    font-size: 1.2rem;
    line-height: 1;
    color: white !important;
    white-space: nowrap;
    transition: all 0.3s;

    @include media-breakpoint-up(md) {
      font-size: 1.7rem;
    }
  }

  &.-contact::before,
  &.-contact::after {
    background-color: $tricolor-blue-color;
  }

  &.-inscription::before,
  &.-inscription::after {
    background-color: $tricolor-red-color;
  }

  @include media-breakpoint-up(md) {
    width: 477px;
    height: 134px;
  }

  &.-contact {
    background-color: $tricolor-blue-color;

    &:hover {

      .c-helper-btn__icon,
      .c-helper-btn__label,
      .c-helper-btn__txt-icon {
        color: $tricolor-blue-color !important;
      }
    }
  }

  &.-inscription {
    background-color: $tricolor-red-color;

    &:hover {

      .c-helper-btn__icon,
      .c-helper-btn__label,
      .c-helper-btn__txt-icon {
        color: $tricolor-red-color !important;
      }
    }
  }

  &:hover {
    background-color: transparent !important;
  }

  &:hover::before,
  &:hover::after {
    width: 100%;
    transition: all 0.3s 0.15s;
  }
}
