.c-top-main-nav {
  display: flex;
  width: calc(100% - 40px);
  position: relative;
  z-index: 5;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    margin: 0 auto;
  }

  @include media-breakpoint-up(md) {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__col {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      width: calc(100% / 3);
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: relative;
      opacity: 1;
      transition: opacity 0.3s;

      body.is-opening-ended & {
        cursor: pointer;
      }

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.8;
        transition: all 0.3s;
      }

      body.is-opening-ended &:hover {
        opacity: 1 !important;

        &::before {
          opacity: 0.7;
        }
      }

      body.is-opening-ended &:hover &__scale-container {
        transform: scale(1.15);
      }
    }

    &.-ecole {
      @include media-breakpoint-down(sm) {
        order: 1;
      }

      @include media-breakpoint-up(md) {

        &::before {
          background-color: $tricolor-blue-color;
        }
      }
    }

    &.-visa {
      @include media-breakpoint-down(sm) {
        order: 0;
      }
    }

    &.-traduction {
      @include media-breakpoint-down(sm) {
        order: 2;
      }

      @include media-breakpoint-up(md) {

        &::before {
          background-color: $tricolor-red-color;
        }
      }
    }

    &__inner {
      @include media-breakpoint-up(md) {
        height: 325px;
      }
    }

    &__scale-container {
      transform: scale(1);
      transform-origin: bottom center;
      transition: transform 0.3s;
    }
  }

  &__title {
    position: relative;

    @include media-breakpoint-down(sm) {

      &:not(.-traduction) {
        border-bottom: solid 1px white;
      }
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }

    &__link {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        height: 75px;
      }

      @include media-breakpoint-up(md) {
        flex-direction: column;
      }
    }

    &__icon {
      color: white;

      &.pi-ecole {
        @include media-breakpoint-down(sm) {
          font-size: 4.7rem;
          margin-right: 19px;
        }

        @include media-breakpoint-up(md) {
          font-size: 6.3rem;
          margin-bottom: 15px;
        }
      }

      &.pi-visa {
        @include media-breakpoint-down(sm) {
          font-size: 4.7rem;
          margin-right: 9px;
        }

        @include media-breakpoint-up(md) {
          font-size: 6.1rem;
          margin-bottom: 17px;
        }
      }

      &.pi-traduction {
        @include media-breakpoint-down(sm) {
          font-size: 4.2rem;
          margin-right: 19px;
        }

        @include media-breakpoint-up(md) {
          font-size: 5.5rem;
          margin-bottom: 23px;
        }
      }
    }

    &__txt-container {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        width: 100%;
        align-items: center;
      }
    }

    &__img-txt {

      &.-ecole {
        width: 64px;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
          width: 66px;
          margin-bottom: 10px;
        }
      }

      &.-visa {
        width: 234px;
        margin-bottom: 4px;

        @include media-breakpoint-up(md) {
          width: 252px;
          margin-bottom: 5px;
        }
      }

      &.-traduction {
        width: 123px;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
          width: 132px;
          margin-bottom: 9px;
        }
      }
    }

    &__txt {
      font-size: 1.6rem;
      white-space: nowrap;
      color: white;
      font-weight: bold;

      @include media-breakpoint-down(sm) {
        line-height: 1;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
      }
    }

    &__angle-icon {
      color: white;
      font-size: 1.4rem;
      margin: 0 10px 0 auto;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__list {
    width: 202px;
    position: relative;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__item {
    border-bottom: solid 1px white;

    &:first-of-type {
      border-top: solid 1px white;
    }
  }

  &__link {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: white;
    position: relative;

    &:hover {
      color: white;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    font-size: 10px;
  }

  body.is-opening-ended &:hover {
    @include media-breakpoint-up(md) {

      .c-top-main-nav__col {
        opacity: 0.6;
      }

      .c-top-main-nav__col.-visa::before {
        background-color: black;
        opacity: 0.2;
      }
    }
  }
}
