.l-footer-gnav {
  margin-bottom: 33px;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
  }

  &__col {

    &.-col1 {
      @include media-breakpoint-down(sm) {
        display: flex;
        margin-bottom: 13px;
      }
    }

    &.-col2 {
      @include media-breakpoint-down(sm) {
        margin-bottom: 27px;
      }
    }

    &.-col3 {
      @include media-breakpoint-down(sm) {
        margin-bottom: 28px;
      }
    }
  }

  &__title {
    margin-bottom: 9px;

    &.-faq {
      @include media-breakpoint-down(sm) {
        margin-left: 26px;
      }

      @include media-breakpoint-up(md) {
        margin-top: 19px;
      }
    }

    &__link {
      font-size: 1.6rem;
      font-weight: bold;
      white-space: nowrap;
      color: white;
      padding: 3px 0;

      @include media-breakpoint-down(sm) {
        display: flex;
        align-items: center;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.7rem;
      }

      &:hover {
        color: white;
      }
    }

    &__icon {
      color: white;

      @include media-breakpoint-up(md) {
        display: none;
      }

      &.pi-visa {
        font-size: 3.8rem;
        margin-right: 10px;
      }

      &.pi-ecole {
        font-size: 3.7rem;
        margin-right: 17px;
      }

      &.pi-traduction {
        font-size: 3.3rem;
        margin-right: 17px;
      }
    }
  }

  &__list {
    padding: 0;
    margin: 0;
  }

  &__item {
    @include media-breakpoint-down(sm) {
      border-bottom: solid 1px white;

      &:first-of-type {
        border-top: solid 1px white;
      }
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
    }
  }

  &__link {
    font-size: 1.4rem;
    color: white;
    padding: 3px 0;

    @include media-breakpoint-down(sm) {
      height: 42px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:hover {
      color: white;
    }
  }

  &__angle-icon {
    color: white;
    font-size: 1rem;
    margin-right: 5px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
