.c-anchor-nav {
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
  }

  &__left,
  &__right {
    @include media-breakpoint-up(md) {
      width: calc((100% - 16px) / 2);
    }
  }

  &__left {}

  &__right {}

  &__list {}

  &__item {
    margin-bottom: 22px;

    @include media-breakpoint-up(md) {
      margin-bottom: 25px;
    }
  }

  &.-ecole-list-map {

    .c-anchor-link {
      @include media-breakpoint-down(sm) {
        padding-top: 17px;
        padding-bottom: 17px;
        padding-right: 65px;
      }

      @include media-breakpoint-up(md) {
        display: block;
        height: 168px;
        padding-top: 32px;
      }

      &__ttl {
        margin-bottom: 11px;

        @include media-breakpoint-up(md) {
          margin-bottom: 11px;
        }
      }

      &__txt {
        line-height: 1.6;

        @include media-breakpoint-down(sm) {
          font-size: 1.5rem;
        }

        @include media-breakpoint-up(md) {
          line-height: 1.9;
        }
      }
    }
  }

  &.-ecole-list-map &__list {
    @include media-breakpoint-up(md) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &.-ecole-list-map &__item {
    margin-bottom: 18px;

    @include media-breakpoint-up(md) {
      width: calc((100% - 16px) / 2);
    }
  }
}
