.c-more-link {
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px 0;
  border-color: #b2b2b2;
  position: relative;

  @include media-breakpoint-up(md) {
    width: 364px;
    height: 49px;

    &.-small {
      width: 272px;
    }
  }

  &::before,
  &::after {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    left: 0;
    transition: all 0.3s;
  }

  &::before {
    top: -1px;
    background-color: $tricolor-blue-color;
  }

  &::after {
    bottom: -1px;
    background-color: $tricolor-red-color;
  }

  &.-accordion::before,
  &.-accordion::after,
  &.-reverse::before,
  &.-reverse::after {
    left: inherit;
    right: 0;
    width: 100%;
  }

  &:hover {
    opacity: 0.7 !important;
  }

  &:hover::before,
  &:hover::after {
    width: 100%;
  }

  &.-reverse:hover::before,
  &.-reverse:hover::after {
    width: 0;
  }

  &__img {
    width: 137px;
    margin-right: 10px;

    @include media-breakpoint-up(md) {
      width: 147px;
    }
  }

  &__label {
    font-size: 1.4rem;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }
  }

  &__icon {

    &::after {
      content: "\e900";
      /* stylelint-disable-next-line */
      font-family: Premier;
      font-size: 1rem;
      font-style: normal;
      color: #b2b2b2;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }

  &.-accordion {
    width: 100%;
    height: 50px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      height: 79px;
    }
  }

  &.-accordion &__icon {

    &::after {
      content: "\e908";

      @include media-breakpoint-up(md) {
        font-size: 1.3rem;
        right: 34px;
      }
    }
  }

  &.-accordion &__img {
    width: 108px;
    margin-right: 8px;

    @include media-breakpoint-up(md) {
      width: 220px;
      margin-right: 16px;
    }
  }

  &.-accordion &__label {
    font-size: 1.4rem;

    @include media-breakpoint-up(md) {
      font-size: 2.3rem;
    }
  }
}
