.c-separate-table {
  width: 100%;

  @include media-breakpoint-down(sm) {
    display: block;

    thead,
    tbody {
      display: block;
    }
  }

  @include media-breakpoint-up(md) {
    width: calc(100% + 40px);
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 20px;
    margin-left: -20px;
  }

  &__tr {
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__th,
  &__td {
    min-height: 49px;
    vertical-align: middle;
    font-size: 1.5rem;
    line-height: 1.5;

    @media screen and (max-width: 320px) {
      font-size: 1.3rem;
    }

    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }

    @include media-breakpoint-up(md) {
      height: 56px;
      font-size: 1.6rem;
      line-height: 1.65;

      &:last-child {
        border-right: none;
      }
    }
  }

  &__th {
    background-color: $bg-table-light-gray;
    font-weight: normal;
    text-align: center;

    @include media-breakpoint-down(sm) {
      justify-content: center;
      align-items: center;
    }

    @include media-breakpoint-up(md) {
      width: 228px;
    }
  }

  &__td {
    padding-left: 5px;
  }
}
