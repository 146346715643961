.c-board-link {
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    height: 280px;
  }

  &.-ecole {
    background-color: $tricolor-blue-color;

    @include media-breakpoint-down(sm) {
      padding-top: 10px;
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 10px;
    }
  }

  &.-visa {
    background-color: $dark-gray;

    @include media-breakpoint-down(sm) {
      padding-top: 10px;
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 9px;
    }
  }

  &.-traduction {
    background-color: $tricolor-red-color;

    @include media-breakpoint-down(sm) {
      padding-top: 10px;
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 10px;
    }
  }

  &__icon {
    color: white;

    &.pi-ecole {
      font-size: 6rem;
      margin-bottom: 12px;

      @include media-breakpoint-up(md) {
        font-size: 9.2rem;
        margin-bottom: 27px;
      }
    }

    &.pi-visa {
      font-size: 6rem;
      margin-bottom: 12px;

      @include media-breakpoint-up(md) {
        font-size: 9.2rem;
        margin-bottom: 29px;
      }
    }

    &.pi-traduction {
      font-size: 5.4rem;
      margin-bottom: 17px;

      @include media-breakpoint-up(md) {
        font-size: 8.2rem;
        margin-bottom: 41px;
      }
    }
  }

  &__label-container {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      width: 100%;
      align-items: center;
    }
  }

  &__label-fr {
    @include media-breakpoint-down(sm) {
      display: none !important;
    }

    &.-ecole {
      width: 63px;
      margin-bottom: 27px;
    }

    &.-visa {
      width: 220px;
      margin-bottom: 23px;
    }

    &.-traduction {
      width: 124px;
      margin-bottom: 27px;
    }
  }

  &__label-ja {
    font-size: 1.5rem;
    white-space: nowrap;
    color: white;
    font-weight: bold;
    line-height: 1.4;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
      line-height: 1;
    }
  }
}
