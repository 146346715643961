.c-anchor-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  padding: 15px 20px;
  position: relative;

  @include media-breakpoint-up(md) {
    height: 71px;
    padding: 0 25px;
    opacity: 1 !important;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7 !important;
    }
  }

  &::after {
    /* stylelint-disable-next-line */
    font-family: Premier;
    content: "\e908";
    font-size: 1.3rem;
    color: gray;
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);

    @include media-breakpoint-up(md) {
      right: 26px;
    }
  }

  &.-row2 {
    @include media-breakpoint-up(md) {
      height: 168px;
    }
  }

  &__ttl {
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: bold;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      font-size: 2.2rem;
    }
  }

  &__txt {
    font-size: 1.2rem;
    line-height: 1.5;

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
    }
  }

  &__annot {
    font-size: 1.2rem;

    @include media-breakpoint-up(md) {

    }
  }
}
