.p-top {

  .l-main {
    padding-bottom: 0;

    @include media-breakpoint-up(md) {
      padding-bottom: 100px;
    }
  }

  &__kv {
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/assets/img/common/kv-sp.jpg");
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding-top: 58px;

    body.is-wide & {
      @include media-breakpoint-down(sm) {
        height: auto;
        padding-bottom: 50px;
      }
    }

    @include media-breakpoint-up(md) {
      height: 770px;
      background-image: url("/assets/img/common/kv.jpg");
      padding-top: 120px;
    }

    &__inner {
      @include media-breakpoint-down(sm) {
        position: relative;
        z-index: 10;
      }
    }

    @include media-breakpoint-down(sm) {

      &::before,
      &::after {
        width: calc(100% / 3);
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        opacity: 0.8;
        z-index: 1;
      }

      &::before {
        background-color: $tricolor-blue-color;
        left: 0;
      }

      &::after {
        background-color: $tricolor-red-color;
        right: 0;
      }
    }

    &__title {
      width: 100%;
      padding-top: 10.8%;
      text-align: center;
      position: relative;
      margin-bottom: 5.8%;
      z-index: 6;
      opacity: 1;
      transition: opacity 0.3s;
      pointer-events: none;

      @include media-breakpoint-up(md) {
        width: 761px;
        padding-top: 45px;
        margin: 0 auto 43px;
      }

      &__img-container {
        display: block;
      }

      &__img-container.-fr01,
      &__img.-fr01 {
        width: 81vw;
        max-width: 81vw;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
          width: 676px;
          max-width: 676px;
        }
      }

      &__img-container.-fr02,
      &__img.-fr02 {
        width: 35vw;
        max-width: 35vw;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
          width: 291px;
          max-width: 291px;
        }
      }

      &__img-container.-fr01 {
        @include media-breakpoint-down(sm) {
          margin-bottom: -0.7%;
        }
      }

      &__img-container.-fr02 {
        margin-bottom: 7.1%;

        @include media-breakpoint-up(md) {
          margin: -7px auto 48px;
          transform: translateX(6px);
        }
      }

      &__img-mask {
        display: block;
        overflow: hidden;
      }

      &__img {

        &.-ja {
          width: 43%;

          @include media-breakpoint-up(md) {
            width: 244px;
          }
        }
      }
    }

    &__read {
      font-size: 1.5rem;
      line-height: 1.7;
      color: white;
      text-align: center;
      margin-bottom: 10%;
      position: relative;
      z-index: 6;
      opacity: 1;
      transition: opacity 0.3s;
      pointer-events: none;

      @media screen and (max-width: 320px) {
        font-size: 1.3rem;
      }

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    body.is-opening-ended &:hover {

      .p-top__kv__title,
      .p-top__kv__read {
        @include media-breakpoint-up(md) {
          opacity: 0.6;
        }
      }
    }
  }
}
