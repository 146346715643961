.l-footer-faq {
  width: 100%;
  height: 142px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);

  @include media-breakpoint-up(md) {
    height: 302px;
  }

  &__link {
    width: calc(100% - 38px);
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.8rem;
    font-weight: bold;
    color: white !important;
    line-height: 1;
    white-space: nowrap;
    border: solid 2px white;
    opacity: 1 !important;
    background-color: transparent;
    transition: all 0.3s;

    @include media-breakpoint-up(md) {
      width: 1000px;
      height: 156px;
      font-size: 2rem;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
      border-color: transparent;
      color: black !important;
    }
  }

  &__icon {
    font-size: 2.4rem;
    margin-bottom: 8px;

    @include media-breakpoint-up(md) {
      font-size: 3.6rem;
      margin: 0 0 30px 12px;
    }
  }
}
