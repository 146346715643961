.c-prix-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: solid 1px $border-gray3;
  font-weight: bold;
  padding: 16px 0 6px;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }

  @include media-breakpoint-up(md) {
    justify-content: space-between;
    padding: 17px 0;
  }

  &__ttl {
    font-size: 1.5rem;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
    }
  }

  &__type {
    font-size: 1.5rem;
    margin-left: auto;

    @include media-breakpoint-up(md) {
      font-size: 2.7rem;
    }
  }

  &__value {
    font-size: 1.5rem;
    margin-left: 20px;
    text-align: right;

    @include media-breakpoint-up(md) {
      font-size: 2.7rem;
      margin-left: 30px;
    }
  }

  &__annot {
    font-size: 1.2rem;
    font-weight: normal;
  }
}
