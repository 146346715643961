.c-accordion-close-btn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 2px 0;
  border-color: $border-gray2;
  position: relative;
  transition: all 0.3s;

  @include media-breakpoint-up(md) {
    height: 79px;
  }

  &:hover {
    @include media-breakpoint-up(md) {
      opacity: 0.7 !important;
    }
  }

  &__txt {
    font-size: 1.8rem;
    color: $dark-gray;

    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
    }
  }

  &__angle-icon {

    &::after {
      content: "\e910";
      /* stylelint-disable-next-line */
      font-family: Premier;
      font-size: 1rem;
      font-style: normal;
      color: #4d4d4d;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);

      @include media-breakpoint-up(md) {
        font-size: 13px;
        right: 32px;
        margin-top: 4px;
      }
    }
  }
}
