@font-face {
  font-family: 'Premier';
  src:
    url('/assets/fonts/Premier.ttf?8oxys4') format('truetype'),
    url('/assets/fonts/Premier.woff?8oxys4') format('woff'),
    url('/assets/fonts/Premier.svg?8oxys4#Premier') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="pi-"], [class*=" pi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Premier' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pi-dormitory:before {
  content: "\e915";
}
.pi-globe:before {
  content: "\e914";
}
.pi-txt-a:before {
  content: "\e912";
}
.pi-txt-q:before {
  content: "\e913";
}
.pi-txt-close:before {
  content: "\e911";
}
.pi-angle-top:before {
  content: "\e910";
}
.pi-exclamation:before {
  content: "\e90f";
}
.pi-caret-bottom:before {
  content: "\e90e";
}
.pi-tel:before {
  content: "\e90d";
}
.pi-txt-inscription:before {
  content: "\e90b";
}
.pi-txt-contact:before {
  content: "\e90c";
}
.pi-txt-faq:before {
  content: "\e90a";
}
.pi-logo:before {
  content: "\e909";
}
.pi-scroll-next:before {
  content: "\e907";
}
.pi-angle-bottom:before {
  content: "\e908";
}
.pi-blank:before {
  content: "\e906";
}
.pi-angle-right:before {
  content: "\e900";
}
.pi-visa:before {
  content: "\e901";
}
.pi-ecole:before {
  content: "\e902";
}
.pi-traduction:before {
  content: "\e903";
}
.pi-mail:before {
  content: "\e904";
}
.pi-inscription:before {
  content: "\e905";
}
