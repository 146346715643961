.c-howto-table {
  width: 100%;
  border: solid 1px $border-gray3;
  background-color: white;

  @include media-breakpoint-down(sm) {
    border-top: none;
  }

  @include media-breakpoint-up(md) {
    display: flex;
  }

  &__left,
  &__right {
    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }

  &__left {
    @include media-breakpoint-up(md) {
      border-right: solid 1px $border-gray3;
    }
  }

  &__th {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
    border-bottom: solid 1px $border-gray3;
    padding: 13px 0;

    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
      border-top: solid 1px $border-gray3;
    }

    @include media-breakpoint-up(md) {
      font-size: 2rem;
      padding: 16px 0;
    }
  }

  &__td {
    vertical-align: top;
    padding: 16px 20px;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
      padding: 21px 30px 30px;
    }
  }

  &__ttl {
    font-size: 1.6rem;
    font-weight: bold;
    border-bottom: solid 1px $border-gray3;
    padding-bottom: 3px;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
      padding-bottom: 1px;
      margin-bottom: 12px;
    }
  }

  &__txt {
    font-size: 1.5rem;
    line-height: 1.8;

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
      line-height: 1.9;
    }
  }

  &__center-txt {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
  }

  &__caret-icon {
    display: block;
    font-size: 2.2rem;
    text-align: center;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
      margin-bottom: 28px;
    }
  }

  &__board {
    border: solid 1px $border-gray3;
    padding: 15px 18px;

    @include media-breakpoint-up(md) {
      padding: 21px 24px;
    }

    &__txt {
      font-size: 1.5rem;
      line-height: 1.5;

      @include media-breakpoint-up(md) {
        font-size: 1.6rem;
      }
    }
  }
}
