.p-premier-other {

  &__intro {
    margin-bottom: 64px;

    @include media-breakpoint-down(sm) {
      padding: 29px 19px 0;
    }

    @include media-breakpoint-up(md) {
      width: 960px;
      padding-top: 55px;
      margin: 0 auto 55px;
    }

    .c-paragraph {
      font-size: 1.5rem;
      line-height: 1.75;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
        line-height: 1.7;
      }
    }
  }

  &__sec {
    margin-bottom: 50px;

    @include media-breakpoint-down(sm) {
      padding: 0 19px;
    }

    @include media-breakpoint-up(md) {
      width: 960px;
      margin: 0 auto 100px;
    }

    &.-padding-top {
      padding-top: 50px;
    }

    .c-fill-title {
      margin-bottom: 25px;

      @include media-breakpoint-up(md) {
        margin-bottom: 30px;
      }
    }

    .c-paragraph {
      @include media-breakpoint-down(sm) {
        line-height: 1.6;
      }
    }

    .c-basic-list__item {
      margin-bottom: 30px;

      @include media-breakpoint-down(sm) {
        line-height: 1.6;
      }
    }
  }
}
