.l-footer-info {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/assets/img/common/footer-bg.jpg");
  background-size: cover;

  @include media-breakpoint-up(md) {
    background-image: url("/assets/img/common/footer-bg.jpg");
  }
}
