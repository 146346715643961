.c-lower-title {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__top-icon {

    &.pi-exclamation {
      font-size: 3rem;
      margin-bottom: 13px;

      @include media-breakpoint-up(md) {
        font-size: 6.8rem;
        margin-bottom: 13px;
      }
    }
  }

  &__fr-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__flag {
    width: 50px;
    margin-right: 10px;

    @include media-breakpoint-up(md) {
      width: 75px;
      margin-right: 22px;
    }
  }

  &__fr {

    &.-categories {
      width: 224px;
      margin-bottom: 8px;

      @include media-breakpoint-up(md) {
        width: 514px;
        margin-bottom: 3px;
      }
    }

    &.-france {
      width: 78px;

      @include media-breakpoint-up(md) {
        width: 175px;
      }
    }

    &.-monaco {
      width: 91px;

      @include media-breakpoint-up(md) {
        width: 206px;
      }
    }

    &.-processus {
      width: 117px;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        width: 269px;
        margin-bottom: 15px;
      }
    }

    &.-attention {
      width: 103px;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        width: 238px;
        margin-bottom: 14px;
      }
    }

    &.-nos-tarifs {
      width: 107px;
      margin-bottom: 8px;

      @include media-breakpoint-up(md) {
        width: 248px;
        margin-bottom: 6px;
      }
    }

    &.-about1 {
      width: 92px;

      @include media-breakpoint-up(md) {
        width: 213px;
      }
    }

    &.-about2 {
      width: 115px;

      @include media-breakpoint-up(md) {
        width: 265px;
      }
    }

    &.-about3 {
      width: 115px;

      @include media-breakpoint-up(md) {
        width: 265px;
      }
    }

    &.-points {
      width: 290px;
      margin-bottom: 11px;

      @include media-breakpoint-up(md) {
        width: 695px;
        margin-bottom: 13px;
      }
    }

    &.-ecole-intro {
      width: 250px;
      margin-bottom: 11px;

      @include media-breakpoint-up(md) {
        width: 578px;
        margin-bottom: 13px;
      }
    }

    &.-france-langue {
      width: 167px;
      margin-bottom: 5px;

      @include media-breakpoint-up(md) {
        width: 385px;
        margin-bottom: 3px;
      }
    }

    &.-traduction {
      width: 220px;
      margin-bottom: 5px;

      @include media-breakpoint-up(md) {
        width: 508px;
        margin-bottom: 3px;
      }
    }
    // 0.433962264150943
  }

  &__ja {
    font-size: 1.8rem;
    font-weight: bold;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }

    &.-large {
      font-size: 1.8rem;

      @include media-breakpoint-up(md) {
        font-size: 3.6rem;
      }
    }
  }
}
