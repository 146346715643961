.c-qa-list {

  &__item {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 31px;
    }
  }

  &__trigger {
    display: block;
    width: 100%;
    position: relative;
    color: white !important;
    font-size: 1.5rem;
    line-height: 1.6;
    font-weight: bold;
    background-color: $tricolor-blue-color;
    opacity: 1 !important;
    padding: 9px 36px 7px 35px;

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
      padding: 7px 70px 7px 83px;
    }

    &::before,
    &::after {
      position: absolute;
      /* stylelint-disable-next-line */
      font-family: Premier;
      line-height: 1;
    }

    &::before {
      content: "\e913";
      font-size: 1.7rem;
      left: 14px;
      top: 11px;

      @include media-breakpoint-up(md) {
        font-size: 2.1rem;
        left: 34px;
        top: 14px;
      }
    }

    &::after {
      content: "\e908";
      top: 50%;
      right: 12px;
      font-size: 1rem;
      transform: translateY(-50%);

      @include media-breakpoint-up(md) {
        right: 32px;
        font-size: 1.3rem;
      }
    }
  }

  &__body {
    background-color: white;

    &__inner {
      position: relative;
      padding: 18px 20px 17px 38px !important;

      @include media-breakpoint-up(md) {
        padding: 23px 40px 32px 85px !important;
      }

      &::before {
        content: "\e912";
        position: absolute;
        top: 14px;
        left: 13px;
        /* stylelint-disable-next-line */
        font-family: Premier;
        font-size: 1.7rem;
        color: $tricolor-blue-color;

        @include media-breakpoint-up(md) {
          top: 20px;
          left: 33px;
          font-size: 1.9rem;
        }
      }
    }

    .c-paragraph {
      font-size: 1.5rem;
      line-height: 1.55;

      @include media-breakpoint-up(md) {
        font-size: 1.6rem;
        line-height: 1.9;
        letter-spacing: 0.3px;
      }
    }
  }

  &__item[data-expanded="true"] &__trigger {

    &::after {
      content: "\e910";
    }
  }
}
