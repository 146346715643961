/* =============================================
 * Base
 * ============================================= */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  font-size: $base-sp-font-size;
  font-family: $base-font-family;
  line-height: $base-line-height;
  min-width: 320px;
  min-height: 100%;

  @include media-breakpoint-up(md) {
    min-width: $min-width;
    font-size: $base-font-size;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul,
ol {
  list-style-type: none;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  vertical-align: top;
}

a {
  color: $base-link-color;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.35s;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  img {
    border: none;
  }

  &:hover {
    color: $base-link-color;
    text-decoration: none;

    @include media-breakpoint-up(md) {
      opacity: 0.7;
    }
  }
}
