.c-basic-list {

  &__item {}

  &.-disc {
    list-style-type: disc;
    margin-left: 1.5em;
  }

  &.-attention {
    list-style-type: none;
    margin-left: 1em;

    .c-basic-list__item {
      display: flex;

      &::before {
        content: "※";
        white-space: nowrap;
        margin-right: 1px;
      }
    }
  }
}
