.c-basic-title {
  font-weight: bold;

  &__sub {
    display: block;
  }

  &.-fs24 {
    font-size: 2rem;

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
    }
  }

  &.-fs20 {
    font-size: 1.6rem;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
  }

  &.-center {
    text-align: center;
  }
}
