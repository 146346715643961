/* =============================================
 * Main
 * ============================================= */

.l-main {
  flex-grow: 1;
  padding-bottom: 100px;

  /* IE11対策 */
  min-height: 0%;

  @include media-breakpoint-up(md) {
    padding-bottom: 150px;
  }
}
