.l-footer-contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 32px 0 85px;

  @include media-breakpoint-up(md) {
    padding: 100px 0 94px;
  }

  &__nav {
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
      width: calc(100% - 38px);
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 71px;
    }

    &__list {
      @include media-breakpoint-up(md) {
        display: flex;
      }
    }

    &__item {

      &:first-of-type {
        @include media-breakpoint-down(sm) {
          margin-bottom: 19px;
        }

        @include media-breakpoint-up(md) {
          margin-right: 44px;
        }
      }
    }
  }

  &__tel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__link {
      display: block;
    }

    &__msg {
      font-size: 1.6rem;
      font-weight: bold;
      white-space: nowrap;
      margin-bottom: 9px;

      @include media-breakpoint-up(md) {
        font-size: 2.5rem;
        margin-bottom: 14px;
      }
    }

    &__number {
      font-size: 3.4rem;
      margin-bottom: 10px;

      @media screen and (max-width: 320px) {
        font-size: 2.9rem;
      }

      @media screen and (min-width: 375px) {
        margin-left: -10px;
      }

      @include media-breakpoint-up(md) {
        width: 550px;
        font-size: 5.7rem;
        margin: 0 0 14px -10px;
      }
    }

    &__annot {
      font-size: 1.35rem;

      @include media-breakpoint-up(md) {
        font-size: 2.15rem;
      }
    }
  }
}
