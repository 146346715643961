body.-nav-top:not(.is-opening-disable) {

  .p-top__kv__title__img-mask {
    width: 0;

    &.-fr01 {
      transition: width 1.5s 2s;
    }

    &.-fr02 {
      transition: width 1s 3.5s;
    }
  }

  &.is-opening-started .p-top__kv__title__img-mask {
    width: 100%;
  }

  .p-top__kv__title__img.-ja {
    visibility: hidden;
    opacity: 0;
    transition: all 1.5s ease-in-out 4.5s;
  }

  &.is-opening-started .p-top__kv__title__img.-ja {
    visibility: visible;
    opacity: 1;
  }

  .p-top__kv__read {
    visibility: hidden;
    opacity: 0;
    transition: all 1.5s ease-in-out 4.5s;
  }

  &.is-opening-started .p-top__kv__read {
    visibility: visible;
    opacity: 1;
  }

  .l-header {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-13px);
    transition: visibility 1s ease-in-out 6s, opacity 1s ease-in-out 6s, transform 1s ease-in-out 6s, background-color 0.3s ease-in-out, height 0.3s ease-in-out;
    will-change: transform;
  }

  &.is-opening-started .l-header {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  .c-top-main-nav__col__inner {
    visibility: hidden;
    opacity: 0;
    transform: translateY(13px);
    transition-property: visibility, opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    transition-delay: 6s;
  }

  &.is-opening-started .c-top-main-nav__col__inner {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}
