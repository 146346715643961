.c-school-list-group {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 16px);
  margin-left: -8px;

  &__item {
    width: 100%;

    @include media-breakpoint-up(md) { }

    &.-col3 {
      @include media-breakpoint-up(md) {
        width: 75%;
      }
    }

    &.-col2 {
      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }

    &.-col1 {
      width: 50%;

      @include media-breakpoint-up(md) {
        width: 25%;
      }

      .c-school-list__item {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }

  &__ttl {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0 8px;
    margin-bottom: 6px;

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
      margin-bottom: 10px;
    }
  }
}
