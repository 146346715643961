.c-school-info {

  &__item {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 33px;
    }
  }

  &__name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1px;

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
      line-height: 1.8;
      margin-bottom: 4px;
    }
  }

  &__dl {
    display: flex;
    line-height: 1.8;

    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
    }

    @include media-breakpoint-up(md) {
      line-height: 1.9;
    }
  }

  &__dt {
    font-weight: normal;
    white-space: nowrap;
    margin-right: 13px;

    @include media-breakpoint-up(md) {
      margin-right: 20px;
    }
  }

  &__dd {
    flex-grow: 1;
  }
}
