.p-premier-index {

  &__intro {
    @include media-breakpoint-down(sm) {
      padding: 29px 19px 0;
      margin-bottom: 64px;
    }

    @include media-breakpoint-up(md) {
      width: 960px;
      padding-top: 70px;
      margin: 0 auto 95px;
    }

    .c-paragraph {
      font-size: 1.5rem;
      line-height: 1.75;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
        line-height: 1.7;
      }
    }
  }

  &__sec {
    margin-bottom: 78px;

    @include media-breakpoint-up(md) {
      width: 960px;
      margin: 0 auto 120px;
    }

    .c-lower-title {
      margin-bottom: 18px;

      @include media-breakpoint-up(md) {
        margin-bottom: 74px;
      }
    }

    .c-board-link-list {
      margin-top: 29px;

      @include media-breakpoint-up(md) {
        margin-top: 52px;
      }
    }

    .c-more-link {
      width: calc(100% - 38px);
      margin: 62px auto 0;

      @include media-breakpoint-up(md) {
        margin: 62px auto 0;
      }
    }
  }
}
