$devices: (all, pc, sp);

/** Spacer
-------------------------- */
/* stylelint-disable */
@mixin setSpacer($prefix) {
  $spaces: (-10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80);
  $positions: (top, bottom, left, right, horizontal, vertical);

  @each $pos in $positions {
    @each $space in $spaces {
      @if $pos == horizontal {

        .#{$prefix}mh#{$space} {
          margin-left: #{$space}px !important;
          margin-right: #{$space}px !important;
        }

        .#{$prefix}ph#{$space} {
          padding-left: #{$space}px !important;
          padding-right: #{$space}px !important;
        }
      }

      @else if $pos == vertical {

        .#{$prefix}mv#{$space} {
          margin-top: #{$space}px !important;
          margin-bottom: #{$space}px !important;
        }

        .#{$prefix}pv#{$space} {
          padding-top: #{$space}px !important;
          padding-bottom: #{$space}px !important;
        }
      }

      @else {

        .#{$prefix}m#{str-slice($pos, 0, 1)}#{$space} {
          margin-#{$pos}: #{$space}px !important;
        }

        .#{$prefix}p#{str-slice($pos, 0, 1)}#{$space} {
          padding-#{$pos}: #{$space}px !important;
        }
      }
    }

    .#{$prefix}m#{str-slice($pos, 0, 1)}-auto {
      margin-#{$pos}: auto !important;
    }
  }
}

/** Size
-------------------------- */
@mixin setSize($prefix) {
  $sizesMap: (
    w: width,
    h: height,
    minw: min-width,
    minh: min-height,
    maxw: max-width,
    maxh: max-height
  );

  @each $key, $name in $sizesMap {
    @for $i from 1 through 140 {

      .#{$prefix}#{$key}#{$i * 5} {
        #{$name}: #{$i * 5}px !important;
      }
    }
  }
}

/** Font Size
-------------------------- */
@mixin setFontSize($prefix) {
  @for $fontSize from 10 through 50 {

    .#{$prefix}fs#{$fontSize} {
      font-size: #{$fontSize / 10}rem !important;
    }
  }
}

/** Text Align
-------------------------- */
@mixin setTextAlign($prefix) {
  $textPositions: (left, center, right);

  @each $pos in $textPositions {

    .#{$prefix}ta-#{$pos} {
      text-align: #{$pos} !important;
    }
  }
}

/** Vertical Align
-------------------------- */
@mixin setVerticalAlign($prefix) {
  $textPositions: (top, middle, bottom);

  @each $pos in $textPositions {

    .#{$prefix}va-#{$pos} {
      vertical-align: #{$pos} !important;
    }
  }
}

@each $device in $devices {
  $prefix: if($device == all, "", "#{$device}-");

  @if $device == all {
    @include setSpacer($prefix);
    @include setSize($prefix);
    @include setFontSize($prefix);
    @include setTextAlign($prefix);
    @include setVerticalAlign($prefix);
  }

  @else if $device == pc {
    @include media-breakpoint-up(md) {
      @include setSpacer($prefix);
      @include setSize($prefix);
      @include setFontSize($prefix);
      @include setTextAlign($prefix);
      @include setVerticalAlign($prefix);
    }
  }

  @else if $device == sp {
    @include media-breakpoint-down(sm) {
      @include setSpacer($prefix);
      @include setSize($prefix);
      @include setFontSize($prefix);
      @include setTextAlign($prefix);
      @include setVerticalAlign($prefix);
    }
  }
}

/** Color
-------------------------- */

.red {
  color: $tricolor-red-color !important;
}

.blue {
  color: $tricolor-blue-color !important;
}

/** Bold
-------------------------- */

.bold {
  font-weight: bold !important;
}

