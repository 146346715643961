.c-slash-txt {
  @include media-breakpoint-down(sm) {

  }

  @include media-breakpoint-up(md) {
  }

  &__item {
    @include media-breakpoint-down(sm) {
      display: block;
    }

    @include media-breakpoint-up(md) {

      &:not(:last-of-type)::after {
        content: "／";
        display: inline;
      }
    }
  }
}
