.c-prix-sub-table {
  width: 100%;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  &__service-type {
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      height: 30px;
      justify-content: center;
      font-weight: bold;
      border-bottom: solid 1px $border-gray3;
    }

    @include media-breakpoint-up(md) {
      width: 190px;
      padding-left: 10px;
    }
  }

  &__list {
    flex-grow: 1;
  }

  &__item {
    display: flex;
    width: 100%;
    padding: 8px 10px;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    @include media-breakpoint-up(md) {
      padding: 8px 10px 8px 0;
    }

    &:not(:last-of-type) {
      border-bottom: solid 1px $border-gray3;
    }
  }

  &__ttl {
    margin-right: auto;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__type {
    margin-right: 15px;
  }

  &__value {
  }
}
