.c-board-link-list {
  display: flex;
  justify-content: space-between;

  &__item {
    width: calc(100% / 3);

    @include media-breakpoint-up(md) {
      width: 280px;
    }
  }
}
