.p-visa-howto {

  &__header {

    .c-lower-title {
      @include media-breakpoint-down(sm) {
        margin-bottom: 8px;
      }
    }

    .c-paragraph {
      @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
      }

      @include media-breakpoint-up(md) {
        text-align: center;
      }
    }
  }

  &__sec {

    .p-visa-howto__header {
      margin-bottom: 31px;

      @include media-breakpoint-up(md) {
        margin-bottom: 59px;
      }
    }

    &.-processus {
      background-color: $bg-light-gray;
      padding: 65px 19px 54px;

      @include media-breakpoint-up(md) {
        padding: 81px 19px 84px;
      }
    }

    &.-attention {
      padding: 65px 19px 0;

      .c-paragraph {
        line-height: 1.9;
      }
    }

    &__inner {
      @include media-breakpoint-up(md) {
        width: 960px;
        margin: 0 auto;
      }
    }
  }
}
