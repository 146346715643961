.c-accordion {

  &__trigger {}

  &[data-expanded="true"] &__trigger {

    .c-more-link__icon {

      &::after {
        content: "\e910";
      }
    }
  }

  &__panel {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.5s;

    &__inner {
      padding: 21px 0 10px;

      @include media-breakpoint-up(md) {
        padding: 38px 0 40px;
      }
    }
  }

  &__close-btn {}

  &[data-expanded="true"] &__panel {
    height: auto;
    opacity: 1;
    visibility: visible;
  }

  @include media-breakpoint-up(md) {

    &.-pc-only &__trigger,
    &.-pc-only &__close-btn {
      display: none !important;
    }

    &.-pc-only &__panel {
      height: auto !important;
      opacity: 1 !important;
      visibility: visible !important;
    }

    &.-pc-only &__panel__inner {
      padding: 0 !important;
    }
  }
}
