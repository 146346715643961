.c-flag-title {
  display: flex;
  align-items: center;

  &__img {
    width: 48px;
    margin-right: 8px;

    @include media-breakpoint-up(md) {
      width: 75px;
      margin-right: 18px;
    }
  }

  &__txt {
    font-size: 2rem;
    font-weight: bold;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      font-size: 2.6rem;
    }
  }
}
