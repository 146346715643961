.c-semester-table {
  width: 100%;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  @include media-breakpoint-up(md) {
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
  }

  &__tr {
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 36px;

      &::after {
        content: "※登録料98€が別途必要";
        display: block;
        text-align: right;
        width: 100%;
        font-size: 1.3rem;
        line-height: 1;
      }
    }
  }

  &__thead {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__tbody {
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &__th,
  &__td {
    font-size: 1.5rem;

    @media screen and (max-width: 320px) {
      font-size: 1.3rem;
    }

    @include media-breakpoint-down(sm) {
      line-height: 1.5;
    }

    @include media-breakpoint-up(md) {
      border: solid 1px $border-gray3;
      font-size: 1.6rem;
    }
  }

  &__th {
    background-color: $bg-table-light-gray;
    font-weight: normal;
    text-align: center;
    padding: 8px 31px;

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
    }

    &.-period {
      width: 394px;
    }

    &.-code {
      width: 142px;
    }

    &.-content {
      width: 282px;
    }

    &.-tuition {
    }
  }

  &__td {
    line-height: 1.5;

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 10px;
    }

    @include media-breakpoint-up(md) {
      line-height: 1.9;
      padding: 20px 31px;
    }

    &.-period,
    &.-code,
    &.-content,
    &.-tuition {
      @include media-breakpoint-down(sm) {

        &::before {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          width: 100%;
          white-space: nowrap;
          background-color: $bg-table-light-gray;
          margin-bottom: 10px;
        }
      }
    }

    &.-code,
    &.-content,
    &.-tuition {
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
    }

    &.-period {
      vertical-align: middle;

      @include media-breakpoint-down(sm) {
        width: 100%;

        &::before {
          content: "期　間";
        }
      }
    }

    &.-code {
      @include media-breakpoint-down(sm) {
        width: 25%;

        &::before {
          content: "コード";
        }
      }
    }

    &.-content {
      @include media-breakpoint-down(sm) {
        width: 50%;
        border-left: solid 2px white;
        border-right: solid 2px white;

        &::before {
          content: "内　容";
        }
      }
    }

    &.-tuition {
      @include media-breakpoint-down(sm) {
        width: 25%;

        &::before {
          content: "学　費";
        }
      }
    }
  }

  &__dl {}

  &__dt {
    font-weight: bold;
  }

  &__dd {}
}
