.c-loading {
  position: fixed;
  width: 100%;
  height: 150%;
  top: 0;
  left: 0;
  background-color: white;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, height 0s ease-in-out 0.5s;
  z-index: 100;

  body.is-loaded & {
    visibility: hidden;
    opacity: 0;
    height: 0;
  }

  &__bar {
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #c9caca;
    position: relative;

    @include media-breakpoint-up(md) {
      height: 20px;
    }

    &::before,
    &::after {
      content: "";
      width: calc(100% / 3);
    }

    &::before {
      background-color: $tricolor-blue-color;
    }

    &::after {
      background-color: $tricolor-red-color;
    }

    &__progress {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: white;
      transition: all 1s;
    }
  }
}

body.-nav-top:not(.is-loaded) {
  overflow: hidden;
}
