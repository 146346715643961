.c-top-sec-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.-qui {
    padding: 28px 20px 65px;

    @include media-breakpoint-up(md) {
      padding: 71px 0 99px;
    }
  }

  &.-service {
    padding: 28px 20px 27px;

    @include media-breakpoint-up(md) {
      padding: 75px 0 91px;
    }
  }

  &__title {
    position: relative;

    &__img{

      &.-pc {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &.-sp {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    &.-qui {
      width: 276px;
      margin-bottom: 7px;

      @include media-breakpoint-up(md) {
        width: 508px;
        margin-bottom: 51px;
      }
    }

    &.-service {
      width: 122px;

      @include media-breakpoint-up(md) {
        width: 224px;
      }
    }

    &__txt {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1.8rem;
      font-weight: bold;
      white-space: nowrap;

      @include media-breakpoint-up(md) {
        font-size: 2rem;
      }

      &.-qui {
        bottom: 4px;

        @include media-breakpoint-up(md) {
          bottom: 9px;
        }
      }

      &.-service {
        bottom: 6px;

        @include media-breakpoint-up(md) {
          bottom: 12px;
        }
      }
    }
  }

  &__txt {
    font-size: 1.5rem;
    line-height: 1.9;
    margin-bottom: 29px;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      text-align: center;
      line-height: 2.2;
      margin-bottom: 36px;
    }
  }

  &__link {}
}
