@charset "UTF-8";
/*!
 * Bootstrap Reboot v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 769px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 769px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 769px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 769px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 769px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/** Spacer
-------------------------- */
/* stylelint-disable */
/** Size
-------------------------- */
/** Font Size
-------------------------- */
/** Text Align
-------------------------- */
/** Vertical Align
-------------------------- */
.mt-10 {
  margin-top: -10px !important; }

.pt-10 {
  padding-top: -10px !important; }

.mt-5 {
  margin-top: -5px !important; }

.pt-5 {
  padding-top: -5px !important; }

.mt0 {
  margin-top: 0px !important; }

.pt0 {
  padding-top: 0px !important; }

.mt5 {
  margin-top: 5px !important; }

.pt5 {
  padding-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.pt10 {
  padding-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.pt15 {
  padding-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.pt20 {
  padding-top: 20px !important; }

.mt25 {
  margin-top: 25px !important; }

.pt25 {
  padding-top: 25px !important; }

.mt30 {
  margin-top: 30px !important; }

.pt30 {
  padding-top: 30px !important; }

.mt35 {
  margin-top: 35px !important; }

.pt35 {
  padding-top: 35px !important; }

.mt40 {
  margin-top: 40px !important; }

.pt40 {
  padding-top: 40px !important; }

.mt45 {
  margin-top: 45px !important; }

.pt45 {
  padding-top: 45px !important; }

.mt50 {
  margin-top: 50px !important; }

.pt50 {
  padding-top: 50px !important; }

.mt55 {
  margin-top: 55px !important; }

.pt55 {
  padding-top: 55px !important; }

.mt60 {
  margin-top: 60px !important; }

.pt60 {
  padding-top: 60px !important; }

.mt65 {
  margin-top: 65px !important; }

.pt65 {
  padding-top: 65px !important; }

.mt70 {
  margin-top: 70px !important; }

.pt70 {
  padding-top: 70px !important; }

.mt75 {
  margin-top: 75px !important; }

.pt75 {
  padding-top: 75px !important; }

.mt80 {
  margin-top: 80px !important; }

.pt80 {
  padding-top: 80px !important; }

.mt-auto {
  margin-top: auto !important; }

.mb-10 {
  margin-bottom: -10px !important; }

.pb-10 {
  padding-bottom: -10px !important; }

.mb-5 {
  margin-bottom: -5px !important; }

.pb-5 {
  padding-bottom: -5px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.pb0 {
  padding-bottom: 0px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.pb5 {
  padding-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.mb55 {
  margin-bottom: 55px !important; }

.pb55 {
  padding-bottom: 55px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.pb65 {
  padding-bottom: 65px !important; }

.mb70 {
  margin-bottom: 70px !important; }

.pb70 {
  padding-bottom: 70px !important; }

.mb75 {
  margin-bottom: 75px !important; }

.pb75 {
  padding-bottom: 75px !important; }

.mb80 {
  margin-bottom: 80px !important; }

.pb80 {
  padding-bottom: 80px !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-10 {
  margin-left: -10px !important; }

.pl-10 {
  padding-left: -10px !important; }

.ml-5 {
  margin-left: -5px !important; }

.pl-5 {
  padding-left: -5px !important; }

.ml0 {
  margin-left: 0px !important; }

.pl0 {
  padding-left: 0px !important; }

.ml5 {
  margin-left: 5px !important; }

.pl5 {
  padding-left: 5px !important; }

.ml10 {
  margin-left: 10px !important; }

.pl10 {
  padding-left: 10px !important; }

.ml15 {
  margin-left: 15px !important; }

.pl15 {
  padding-left: 15px !important; }

.ml20 {
  margin-left: 20px !important; }

.pl20 {
  padding-left: 20px !important; }

.ml25 {
  margin-left: 25px !important; }

.pl25 {
  padding-left: 25px !important; }

.ml30 {
  margin-left: 30px !important; }

.pl30 {
  padding-left: 30px !important; }

.ml35 {
  margin-left: 35px !important; }

.pl35 {
  padding-left: 35px !important; }

.ml40 {
  margin-left: 40px !important; }

.pl40 {
  padding-left: 40px !important; }

.ml45 {
  margin-left: 45px !important; }

.pl45 {
  padding-left: 45px !important; }

.ml50 {
  margin-left: 50px !important; }

.pl50 {
  padding-left: 50px !important; }

.ml55 {
  margin-left: 55px !important; }

.pl55 {
  padding-left: 55px !important; }

.ml60 {
  margin-left: 60px !important; }

.pl60 {
  padding-left: 60px !important; }

.ml65 {
  margin-left: 65px !important; }

.pl65 {
  padding-left: 65px !important; }

.ml70 {
  margin-left: 70px !important; }

.pl70 {
  padding-left: 70px !important; }

.ml75 {
  margin-left: 75px !important; }

.pl75 {
  padding-left: 75px !important; }

.ml80 {
  margin-left: 80px !important; }

.pl80 {
  padding-left: 80px !important; }

.ml-auto {
  margin-left: auto !important; }

.mr-10 {
  margin-right: -10px !important; }

.pr-10 {
  padding-right: -10px !important; }

.mr-5 {
  margin-right: -5px !important; }

.pr-5 {
  padding-right: -5px !important; }

.mr0 {
  margin-right: 0px !important; }

.pr0 {
  padding-right: 0px !important; }

.mr5 {
  margin-right: 5px !important; }

.pr5 {
  padding-right: 5px !important; }

.mr10 {
  margin-right: 10px !important; }

.pr10 {
  padding-right: 10px !important; }

.mr15 {
  margin-right: 15px !important; }

.pr15 {
  padding-right: 15px !important; }

.mr20 {
  margin-right: 20px !important; }

.pr20 {
  padding-right: 20px !important; }

.mr25 {
  margin-right: 25px !important; }

.pr25 {
  padding-right: 25px !important; }

.mr30 {
  margin-right: 30px !important; }

.pr30 {
  padding-right: 30px !important; }

.mr35 {
  margin-right: 35px !important; }

.pr35 {
  padding-right: 35px !important; }

.mr40 {
  margin-right: 40px !important; }

.pr40 {
  padding-right: 40px !important; }

.mr45 {
  margin-right: 45px !important; }

.pr45 {
  padding-right: 45px !important; }

.mr50 {
  margin-right: 50px !important; }

.pr50 {
  padding-right: 50px !important; }

.mr55 {
  margin-right: 55px !important; }

.pr55 {
  padding-right: 55px !important; }

.mr60 {
  margin-right: 60px !important; }

.pr60 {
  padding-right: 60px !important; }

.mr65 {
  margin-right: 65px !important; }

.pr65 {
  padding-right: 65px !important; }

.mr70 {
  margin-right: 70px !important; }

.pr70 {
  padding-right: 70px !important; }

.mr75 {
  margin-right: 75px !important; }

.pr75 {
  padding-right: 75px !important; }

.mr80 {
  margin-right: 80px !important; }

.pr80 {
  padding-right: 80px !important; }

.mr-auto {
  margin-right: auto !important; }

.mh-10 {
  margin-left: -10px !important;
  margin-right: -10px !important; }

.ph-10 {
  padding-left: -10px !important;
  padding-right: -10px !important; }

.mh-5 {
  margin-left: -5px !important;
  margin-right: -5px !important; }

.ph-5 {
  padding-left: -5px !important;
  padding-right: -5px !important; }

.mh0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.ph0 {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.mh5 {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.ph5 {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.mh10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.ph10 {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.mh15 {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.ph15 {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.mh20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.ph20 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.mh25 {
  margin-left: 25px !important;
  margin-right: 25px !important; }

.ph25 {
  padding-left: 25px !important;
  padding-right: 25px !important; }

.mh30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.ph30 {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.mh35 {
  margin-left: 35px !important;
  margin-right: 35px !important; }

.ph35 {
  padding-left: 35px !important;
  padding-right: 35px !important; }

.mh40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.ph40 {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.mh45 {
  margin-left: 45px !important;
  margin-right: 45px !important; }

.ph45 {
  padding-left: 45px !important;
  padding-right: 45px !important; }

.mh50 {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.ph50 {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.mh55 {
  margin-left: 55px !important;
  margin-right: 55px !important; }

.ph55 {
  padding-left: 55px !important;
  padding-right: 55px !important; }

.mh60 {
  margin-left: 60px !important;
  margin-right: 60px !important; }

.ph60 {
  padding-left: 60px !important;
  padding-right: 60px !important; }

.mh65 {
  margin-left: 65px !important;
  margin-right: 65px !important; }

.ph65 {
  padding-left: 65px !important;
  padding-right: 65px !important; }

.mh70 {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.ph70 {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.mh75 {
  margin-left: 75px !important;
  margin-right: 75px !important; }

.ph75 {
  padding-left: 75px !important;
  padding-right: 75px !important; }

.mh80 {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.ph80 {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.mh-auto {
  margin-horizontal: auto !important; }

.mv-10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important; }

.pv-10 {
  padding-top: -10px !important;
  padding-bottom: -10px !important; }

.mv-5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important; }

.pv-5 {
  padding-top: -5px !important;
  padding-bottom: -5px !important; }

.mv0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.pv0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.mv5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.pv5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.mv10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.pv10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.mv15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.pv15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.mv20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.pv20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.mv25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important; }

.pv25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important; }

.mv30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.pv30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.mv35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important; }

.pv35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important; }

.mv40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.pv40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.mv45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important; }

.pv45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important; }

.mv50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.pv50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.mv55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important; }

.pv55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important; }

.mv60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.pv60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important; }

.mv65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important; }

.pv65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important; }

.mv70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.pv70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.mv75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important; }

.pv75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important; }

.mv80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.pv80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.mv-auto {
  margin-vertical: auto !important; }

.w5 {
  width: 5px !important; }

.w10 {
  width: 10px !important; }

.w15 {
  width: 15px !important; }

.w20 {
  width: 20px !important; }

.w25 {
  width: 25px !important; }

.w30 {
  width: 30px !important; }

.w35 {
  width: 35px !important; }

.w40 {
  width: 40px !important; }

.w45 {
  width: 45px !important; }

.w50 {
  width: 50px !important; }

.w55 {
  width: 55px !important; }

.w60 {
  width: 60px !important; }

.w65 {
  width: 65px !important; }

.w70 {
  width: 70px !important; }

.w75 {
  width: 75px !important; }

.w80 {
  width: 80px !important; }

.w85 {
  width: 85px !important; }

.w90 {
  width: 90px !important; }

.w95 {
  width: 95px !important; }

.w100 {
  width: 100px !important; }

.w105 {
  width: 105px !important; }

.w110 {
  width: 110px !important; }

.w115 {
  width: 115px !important; }

.w120 {
  width: 120px !important; }

.w125 {
  width: 125px !important; }

.w130 {
  width: 130px !important; }

.w135 {
  width: 135px !important; }

.w140 {
  width: 140px !important; }

.w145 {
  width: 145px !important; }

.w150 {
  width: 150px !important; }

.w155 {
  width: 155px !important; }

.w160 {
  width: 160px !important; }

.w165 {
  width: 165px !important; }

.w170 {
  width: 170px !important; }

.w175 {
  width: 175px !important; }

.w180 {
  width: 180px !important; }

.w185 {
  width: 185px !important; }

.w190 {
  width: 190px !important; }

.w195 {
  width: 195px !important; }

.w200 {
  width: 200px !important; }

.w205 {
  width: 205px !important; }

.w210 {
  width: 210px !important; }

.w215 {
  width: 215px !important; }

.w220 {
  width: 220px !important; }

.w225 {
  width: 225px !important; }

.w230 {
  width: 230px !important; }

.w235 {
  width: 235px !important; }

.w240 {
  width: 240px !important; }

.w245 {
  width: 245px !important; }

.w250 {
  width: 250px !important; }

.w255 {
  width: 255px !important; }

.w260 {
  width: 260px !important; }

.w265 {
  width: 265px !important; }

.w270 {
  width: 270px !important; }

.w275 {
  width: 275px !important; }

.w280 {
  width: 280px !important; }

.w285 {
  width: 285px !important; }

.w290 {
  width: 290px !important; }

.w295 {
  width: 295px !important; }

.w300 {
  width: 300px !important; }

.w305 {
  width: 305px !important; }

.w310 {
  width: 310px !important; }

.w315 {
  width: 315px !important; }

.w320 {
  width: 320px !important; }

.w325 {
  width: 325px !important; }

.w330 {
  width: 330px !important; }

.w335 {
  width: 335px !important; }

.w340 {
  width: 340px !important; }

.w345 {
  width: 345px !important; }

.w350 {
  width: 350px !important; }

.w355 {
  width: 355px !important; }

.w360 {
  width: 360px !important; }

.w365 {
  width: 365px !important; }

.w370 {
  width: 370px !important; }

.w375 {
  width: 375px !important; }

.w380 {
  width: 380px !important; }

.w385 {
  width: 385px !important; }

.w390 {
  width: 390px !important; }

.w395 {
  width: 395px !important; }

.w400 {
  width: 400px !important; }

.w405 {
  width: 405px !important; }

.w410 {
  width: 410px !important; }

.w415 {
  width: 415px !important; }

.w420 {
  width: 420px !important; }

.w425 {
  width: 425px !important; }

.w430 {
  width: 430px !important; }

.w435 {
  width: 435px !important; }

.w440 {
  width: 440px !important; }

.w445 {
  width: 445px !important; }

.w450 {
  width: 450px !important; }

.w455 {
  width: 455px !important; }

.w460 {
  width: 460px !important; }

.w465 {
  width: 465px !important; }

.w470 {
  width: 470px !important; }

.w475 {
  width: 475px !important; }

.w480 {
  width: 480px !important; }

.w485 {
  width: 485px !important; }

.w490 {
  width: 490px !important; }

.w495 {
  width: 495px !important; }

.w500 {
  width: 500px !important; }

.w505 {
  width: 505px !important; }

.w510 {
  width: 510px !important; }

.w515 {
  width: 515px !important; }

.w520 {
  width: 520px !important; }

.w525 {
  width: 525px !important; }

.w530 {
  width: 530px !important; }

.w535 {
  width: 535px !important; }

.w540 {
  width: 540px !important; }

.w545 {
  width: 545px !important; }

.w550 {
  width: 550px !important; }

.w555 {
  width: 555px !important; }

.w560 {
  width: 560px !important; }

.w565 {
  width: 565px !important; }

.w570 {
  width: 570px !important; }

.w575 {
  width: 575px !important; }

.w580 {
  width: 580px !important; }

.w585 {
  width: 585px !important; }

.w590 {
  width: 590px !important; }

.w595 {
  width: 595px !important; }

.w600 {
  width: 600px !important; }

.w605 {
  width: 605px !important; }

.w610 {
  width: 610px !important; }

.w615 {
  width: 615px !important; }

.w620 {
  width: 620px !important; }

.w625 {
  width: 625px !important; }

.w630 {
  width: 630px !important; }

.w635 {
  width: 635px !important; }

.w640 {
  width: 640px !important; }

.w645 {
  width: 645px !important; }

.w650 {
  width: 650px !important; }

.w655 {
  width: 655px !important; }

.w660 {
  width: 660px !important; }

.w665 {
  width: 665px !important; }

.w670 {
  width: 670px !important; }

.w675 {
  width: 675px !important; }

.w680 {
  width: 680px !important; }

.w685 {
  width: 685px !important; }

.w690 {
  width: 690px !important; }

.w695 {
  width: 695px !important; }

.w700 {
  width: 700px !important; }

.h5 {
  height: 5px !important; }

.h10 {
  height: 10px !important; }

.h15 {
  height: 15px !important; }

.h20 {
  height: 20px !important; }

.h25 {
  height: 25px !important; }

.h30 {
  height: 30px !important; }

.h35 {
  height: 35px !important; }

.h40 {
  height: 40px !important; }

.h45 {
  height: 45px !important; }

.h50 {
  height: 50px !important; }

.h55 {
  height: 55px !important; }

.h60 {
  height: 60px !important; }

.h65 {
  height: 65px !important; }

.h70 {
  height: 70px !important; }

.h75 {
  height: 75px !important; }

.h80 {
  height: 80px !important; }

.h85 {
  height: 85px !important; }

.h90 {
  height: 90px !important; }

.h95 {
  height: 95px !important; }

.h100 {
  height: 100px !important; }

.h105 {
  height: 105px !important; }

.h110 {
  height: 110px !important; }

.h115 {
  height: 115px !important; }

.h120 {
  height: 120px !important; }

.h125 {
  height: 125px !important; }

.h130 {
  height: 130px !important; }

.h135 {
  height: 135px !important; }

.h140 {
  height: 140px !important; }

.h145 {
  height: 145px !important; }

.h150 {
  height: 150px !important; }

.h155 {
  height: 155px !important; }

.h160 {
  height: 160px !important; }

.h165 {
  height: 165px !important; }

.h170 {
  height: 170px !important; }

.h175 {
  height: 175px !important; }

.h180 {
  height: 180px !important; }

.h185 {
  height: 185px !important; }

.h190 {
  height: 190px !important; }

.h195 {
  height: 195px !important; }

.h200 {
  height: 200px !important; }

.h205 {
  height: 205px !important; }

.h210 {
  height: 210px !important; }

.h215 {
  height: 215px !important; }

.h220 {
  height: 220px !important; }

.h225 {
  height: 225px !important; }

.h230 {
  height: 230px !important; }

.h235 {
  height: 235px !important; }

.h240 {
  height: 240px !important; }

.h245 {
  height: 245px !important; }

.h250 {
  height: 250px !important; }

.h255 {
  height: 255px !important; }

.h260 {
  height: 260px !important; }

.h265 {
  height: 265px !important; }

.h270 {
  height: 270px !important; }

.h275 {
  height: 275px !important; }

.h280 {
  height: 280px !important; }

.h285 {
  height: 285px !important; }

.h290 {
  height: 290px !important; }

.h295 {
  height: 295px !important; }

.h300 {
  height: 300px !important; }

.h305 {
  height: 305px !important; }

.h310 {
  height: 310px !important; }

.h315 {
  height: 315px !important; }

.h320 {
  height: 320px !important; }

.h325 {
  height: 325px !important; }

.h330 {
  height: 330px !important; }

.h335 {
  height: 335px !important; }

.h340 {
  height: 340px !important; }

.h345 {
  height: 345px !important; }

.h350 {
  height: 350px !important; }

.h355 {
  height: 355px !important; }

.h360 {
  height: 360px !important; }

.h365 {
  height: 365px !important; }

.h370 {
  height: 370px !important; }

.h375 {
  height: 375px !important; }

.h380 {
  height: 380px !important; }

.h385 {
  height: 385px !important; }

.h390 {
  height: 390px !important; }

.h395 {
  height: 395px !important; }

.h400 {
  height: 400px !important; }

.h405 {
  height: 405px !important; }

.h410 {
  height: 410px !important; }

.h415 {
  height: 415px !important; }

.h420 {
  height: 420px !important; }

.h425 {
  height: 425px !important; }

.h430 {
  height: 430px !important; }

.h435 {
  height: 435px !important; }

.h440 {
  height: 440px !important; }

.h445 {
  height: 445px !important; }

.h450 {
  height: 450px !important; }

.h455 {
  height: 455px !important; }

.h460 {
  height: 460px !important; }

.h465 {
  height: 465px !important; }

.h470 {
  height: 470px !important; }

.h475 {
  height: 475px !important; }

.h480 {
  height: 480px !important; }

.h485 {
  height: 485px !important; }

.h490 {
  height: 490px !important; }

.h495 {
  height: 495px !important; }

.h500 {
  height: 500px !important; }

.h505 {
  height: 505px !important; }

.h510 {
  height: 510px !important; }

.h515 {
  height: 515px !important; }

.h520 {
  height: 520px !important; }

.h525 {
  height: 525px !important; }

.h530 {
  height: 530px !important; }

.h535 {
  height: 535px !important; }

.h540 {
  height: 540px !important; }

.h545 {
  height: 545px !important; }

.h550 {
  height: 550px !important; }

.h555 {
  height: 555px !important; }

.h560 {
  height: 560px !important; }

.h565 {
  height: 565px !important; }

.h570 {
  height: 570px !important; }

.h575 {
  height: 575px !important; }

.h580 {
  height: 580px !important; }

.h585 {
  height: 585px !important; }

.h590 {
  height: 590px !important; }

.h595 {
  height: 595px !important; }

.h600 {
  height: 600px !important; }

.h605 {
  height: 605px !important; }

.h610 {
  height: 610px !important; }

.h615 {
  height: 615px !important; }

.h620 {
  height: 620px !important; }

.h625 {
  height: 625px !important; }

.h630 {
  height: 630px !important; }

.h635 {
  height: 635px !important; }

.h640 {
  height: 640px !important; }

.h645 {
  height: 645px !important; }

.h650 {
  height: 650px !important; }

.h655 {
  height: 655px !important; }

.h660 {
  height: 660px !important; }

.h665 {
  height: 665px !important; }

.h670 {
  height: 670px !important; }

.h675 {
  height: 675px !important; }

.h680 {
  height: 680px !important; }

.h685 {
  height: 685px !important; }

.h690 {
  height: 690px !important; }

.h695 {
  height: 695px !important; }

.h700 {
  height: 700px !important; }

.minw5 {
  min-width: 5px !important; }

.minw10 {
  min-width: 10px !important; }

.minw15 {
  min-width: 15px !important; }

.minw20 {
  min-width: 20px !important; }

.minw25 {
  min-width: 25px !important; }

.minw30 {
  min-width: 30px !important; }

.minw35 {
  min-width: 35px !important; }

.minw40 {
  min-width: 40px !important; }

.minw45 {
  min-width: 45px !important; }

.minw50 {
  min-width: 50px !important; }

.minw55 {
  min-width: 55px !important; }

.minw60 {
  min-width: 60px !important; }

.minw65 {
  min-width: 65px !important; }

.minw70 {
  min-width: 70px !important; }

.minw75 {
  min-width: 75px !important; }

.minw80 {
  min-width: 80px !important; }

.minw85 {
  min-width: 85px !important; }

.minw90 {
  min-width: 90px !important; }

.minw95 {
  min-width: 95px !important; }

.minw100 {
  min-width: 100px !important; }

.minw105 {
  min-width: 105px !important; }

.minw110 {
  min-width: 110px !important; }

.minw115 {
  min-width: 115px !important; }

.minw120 {
  min-width: 120px !important; }

.minw125 {
  min-width: 125px !important; }

.minw130 {
  min-width: 130px !important; }

.minw135 {
  min-width: 135px !important; }

.minw140 {
  min-width: 140px !important; }

.minw145 {
  min-width: 145px !important; }

.minw150 {
  min-width: 150px !important; }

.minw155 {
  min-width: 155px !important; }

.minw160 {
  min-width: 160px !important; }

.minw165 {
  min-width: 165px !important; }

.minw170 {
  min-width: 170px !important; }

.minw175 {
  min-width: 175px !important; }

.minw180 {
  min-width: 180px !important; }

.minw185 {
  min-width: 185px !important; }

.minw190 {
  min-width: 190px !important; }

.minw195 {
  min-width: 195px !important; }

.minw200 {
  min-width: 200px !important; }

.minw205 {
  min-width: 205px !important; }

.minw210 {
  min-width: 210px !important; }

.minw215 {
  min-width: 215px !important; }

.minw220 {
  min-width: 220px !important; }

.minw225 {
  min-width: 225px !important; }

.minw230 {
  min-width: 230px !important; }

.minw235 {
  min-width: 235px !important; }

.minw240 {
  min-width: 240px !important; }

.minw245 {
  min-width: 245px !important; }

.minw250 {
  min-width: 250px !important; }

.minw255 {
  min-width: 255px !important; }

.minw260 {
  min-width: 260px !important; }

.minw265 {
  min-width: 265px !important; }

.minw270 {
  min-width: 270px !important; }

.minw275 {
  min-width: 275px !important; }

.minw280 {
  min-width: 280px !important; }

.minw285 {
  min-width: 285px !important; }

.minw290 {
  min-width: 290px !important; }

.minw295 {
  min-width: 295px !important; }

.minw300 {
  min-width: 300px !important; }

.minw305 {
  min-width: 305px !important; }

.minw310 {
  min-width: 310px !important; }

.minw315 {
  min-width: 315px !important; }

.minw320 {
  min-width: 320px !important; }

.minw325 {
  min-width: 325px !important; }

.minw330 {
  min-width: 330px !important; }

.minw335 {
  min-width: 335px !important; }

.minw340 {
  min-width: 340px !important; }

.minw345 {
  min-width: 345px !important; }

.minw350 {
  min-width: 350px !important; }

.minw355 {
  min-width: 355px !important; }

.minw360 {
  min-width: 360px !important; }

.minw365 {
  min-width: 365px !important; }

.minw370 {
  min-width: 370px !important; }

.minw375 {
  min-width: 375px !important; }

.minw380 {
  min-width: 380px !important; }

.minw385 {
  min-width: 385px !important; }

.minw390 {
  min-width: 390px !important; }

.minw395 {
  min-width: 395px !important; }

.minw400 {
  min-width: 400px !important; }

.minw405 {
  min-width: 405px !important; }

.minw410 {
  min-width: 410px !important; }

.minw415 {
  min-width: 415px !important; }

.minw420 {
  min-width: 420px !important; }

.minw425 {
  min-width: 425px !important; }

.minw430 {
  min-width: 430px !important; }

.minw435 {
  min-width: 435px !important; }

.minw440 {
  min-width: 440px !important; }

.minw445 {
  min-width: 445px !important; }

.minw450 {
  min-width: 450px !important; }

.minw455 {
  min-width: 455px !important; }

.minw460 {
  min-width: 460px !important; }

.minw465 {
  min-width: 465px !important; }

.minw470 {
  min-width: 470px !important; }

.minw475 {
  min-width: 475px !important; }

.minw480 {
  min-width: 480px !important; }

.minw485 {
  min-width: 485px !important; }

.minw490 {
  min-width: 490px !important; }

.minw495 {
  min-width: 495px !important; }

.minw500 {
  min-width: 500px !important; }

.minw505 {
  min-width: 505px !important; }

.minw510 {
  min-width: 510px !important; }

.minw515 {
  min-width: 515px !important; }

.minw520 {
  min-width: 520px !important; }

.minw525 {
  min-width: 525px !important; }

.minw530 {
  min-width: 530px !important; }

.minw535 {
  min-width: 535px !important; }

.minw540 {
  min-width: 540px !important; }

.minw545 {
  min-width: 545px !important; }

.minw550 {
  min-width: 550px !important; }

.minw555 {
  min-width: 555px !important; }

.minw560 {
  min-width: 560px !important; }

.minw565 {
  min-width: 565px !important; }

.minw570 {
  min-width: 570px !important; }

.minw575 {
  min-width: 575px !important; }

.minw580 {
  min-width: 580px !important; }

.minw585 {
  min-width: 585px !important; }

.minw590 {
  min-width: 590px !important; }

.minw595 {
  min-width: 595px !important; }

.minw600 {
  min-width: 600px !important; }

.minw605 {
  min-width: 605px !important; }

.minw610 {
  min-width: 610px !important; }

.minw615 {
  min-width: 615px !important; }

.minw620 {
  min-width: 620px !important; }

.minw625 {
  min-width: 625px !important; }

.minw630 {
  min-width: 630px !important; }

.minw635 {
  min-width: 635px !important; }

.minw640 {
  min-width: 640px !important; }

.minw645 {
  min-width: 645px !important; }

.minw650 {
  min-width: 650px !important; }

.minw655 {
  min-width: 655px !important; }

.minw660 {
  min-width: 660px !important; }

.minw665 {
  min-width: 665px !important; }

.minw670 {
  min-width: 670px !important; }

.minw675 {
  min-width: 675px !important; }

.minw680 {
  min-width: 680px !important; }

.minw685 {
  min-width: 685px !important; }

.minw690 {
  min-width: 690px !important; }

.minw695 {
  min-width: 695px !important; }

.minw700 {
  min-width: 700px !important; }

.minh5 {
  min-height: 5px !important; }

.minh10 {
  min-height: 10px !important; }

.minh15 {
  min-height: 15px !important; }

.minh20 {
  min-height: 20px !important; }

.minh25 {
  min-height: 25px !important; }

.minh30 {
  min-height: 30px !important; }

.minh35 {
  min-height: 35px !important; }

.minh40 {
  min-height: 40px !important; }

.minh45 {
  min-height: 45px !important; }

.minh50 {
  min-height: 50px !important; }

.minh55 {
  min-height: 55px !important; }

.minh60 {
  min-height: 60px !important; }

.minh65 {
  min-height: 65px !important; }

.minh70 {
  min-height: 70px !important; }

.minh75 {
  min-height: 75px !important; }

.minh80 {
  min-height: 80px !important; }

.minh85 {
  min-height: 85px !important; }

.minh90 {
  min-height: 90px !important; }

.minh95 {
  min-height: 95px !important; }

.minh100 {
  min-height: 100px !important; }

.minh105 {
  min-height: 105px !important; }

.minh110 {
  min-height: 110px !important; }

.minh115 {
  min-height: 115px !important; }

.minh120 {
  min-height: 120px !important; }

.minh125 {
  min-height: 125px !important; }

.minh130 {
  min-height: 130px !important; }

.minh135 {
  min-height: 135px !important; }

.minh140 {
  min-height: 140px !important; }

.minh145 {
  min-height: 145px !important; }

.minh150 {
  min-height: 150px !important; }

.minh155 {
  min-height: 155px !important; }

.minh160 {
  min-height: 160px !important; }

.minh165 {
  min-height: 165px !important; }

.minh170 {
  min-height: 170px !important; }

.minh175 {
  min-height: 175px !important; }

.minh180 {
  min-height: 180px !important; }

.minh185 {
  min-height: 185px !important; }

.minh190 {
  min-height: 190px !important; }

.minh195 {
  min-height: 195px !important; }

.minh200 {
  min-height: 200px !important; }

.minh205 {
  min-height: 205px !important; }

.minh210 {
  min-height: 210px !important; }

.minh215 {
  min-height: 215px !important; }

.minh220 {
  min-height: 220px !important; }

.minh225 {
  min-height: 225px !important; }

.minh230 {
  min-height: 230px !important; }

.minh235 {
  min-height: 235px !important; }

.minh240 {
  min-height: 240px !important; }

.minh245 {
  min-height: 245px !important; }

.minh250 {
  min-height: 250px !important; }

.minh255 {
  min-height: 255px !important; }

.minh260 {
  min-height: 260px !important; }

.minh265 {
  min-height: 265px !important; }

.minh270 {
  min-height: 270px !important; }

.minh275 {
  min-height: 275px !important; }

.minh280 {
  min-height: 280px !important; }

.minh285 {
  min-height: 285px !important; }

.minh290 {
  min-height: 290px !important; }

.minh295 {
  min-height: 295px !important; }

.minh300 {
  min-height: 300px !important; }

.minh305 {
  min-height: 305px !important; }

.minh310 {
  min-height: 310px !important; }

.minh315 {
  min-height: 315px !important; }

.minh320 {
  min-height: 320px !important; }

.minh325 {
  min-height: 325px !important; }

.minh330 {
  min-height: 330px !important; }

.minh335 {
  min-height: 335px !important; }

.minh340 {
  min-height: 340px !important; }

.minh345 {
  min-height: 345px !important; }

.minh350 {
  min-height: 350px !important; }

.minh355 {
  min-height: 355px !important; }

.minh360 {
  min-height: 360px !important; }

.minh365 {
  min-height: 365px !important; }

.minh370 {
  min-height: 370px !important; }

.minh375 {
  min-height: 375px !important; }

.minh380 {
  min-height: 380px !important; }

.minh385 {
  min-height: 385px !important; }

.minh390 {
  min-height: 390px !important; }

.minh395 {
  min-height: 395px !important; }

.minh400 {
  min-height: 400px !important; }

.minh405 {
  min-height: 405px !important; }

.minh410 {
  min-height: 410px !important; }

.minh415 {
  min-height: 415px !important; }

.minh420 {
  min-height: 420px !important; }

.minh425 {
  min-height: 425px !important; }

.minh430 {
  min-height: 430px !important; }

.minh435 {
  min-height: 435px !important; }

.minh440 {
  min-height: 440px !important; }

.minh445 {
  min-height: 445px !important; }

.minh450 {
  min-height: 450px !important; }

.minh455 {
  min-height: 455px !important; }

.minh460 {
  min-height: 460px !important; }

.minh465 {
  min-height: 465px !important; }

.minh470 {
  min-height: 470px !important; }

.minh475 {
  min-height: 475px !important; }

.minh480 {
  min-height: 480px !important; }

.minh485 {
  min-height: 485px !important; }

.minh490 {
  min-height: 490px !important; }

.minh495 {
  min-height: 495px !important; }

.minh500 {
  min-height: 500px !important; }

.minh505 {
  min-height: 505px !important; }

.minh510 {
  min-height: 510px !important; }

.minh515 {
  min-height: 515px !important; }

.minh520 {
  min-height: 520px !important; }

.minh525 {
  min-height: 525px !important; }

.minh530 {
  min-height: 530px !important; }

.minh535 {
  min-height: 535px !important; }

.minh540 {
  min-height: 540px !important; }

.minh545 {
  min-height: 545px !important; }

.minh550 {
  min-height: 550px !important; }

.minh555 {
  min-height: 555px !important; }

.minh560 {
  min-height: 560px !important; }

.minh565 {
  min-height: 565px !important; }

.minh570 {
  min-height: 570px !important; }

.minh575 {
  min-height: 575px !important; }

.minh580 {
  min-height: 580px !important; }

.minh585 {
  min-height: 585px !important; }

.minh590 {
  min-height: 590px !important; }

.minh595 {
  min-height: 595px !important; }

.minh600 {
  min-height: 600px !important; }

.minh605 {
  min-height: 605px !important; }

.minh610 {
  min-height: 610px !important; }

.minh615 {
  min-height: 615px !important; }

.minh620 {
  min-height: 620px !important; }

.minh625 {
  min-height: 625px !important; }

.minh630 {
  min-height: 630px !important; }

.minh635 {
  min-height: 635px !important; }

.minh640 {
  min-height: 640px !important; }

.minh645 {
  min-height: 645px !important; }

.minh650 {
  min-height: 650px !important; }

.minh655 {
  min-height: 655px !important; }

.minh660 {
  min-height: 660px !important; }

.minh665 {
  min-height: 665px !important; }

.minh670 {
  min-height: 670px !important; }

.minh675 {
  min-height: 675px !important; }

.minh680 {
  min-height: 680px !important; }

.minh685 {
  min-height: 685px !important; }

.minh690 {
  min-height: 690px !important; }

.minh695 {
  min-height: 695px !important; }

.minh700 {
  min-height: 700px !important; }

.maxw5 {
  max-width: 5px !important; }

.maxw10 {
  max-width: 10px !important; }

.maxw15 {
  max-width: 15px !important; }

.maxw20 {
  max-width: 20px !important; }

.maxw25 {
  max-width: 25px !important; }

.maxw30 {
  max-width: 30px !important; }

.maxw35 {
  max-width: 35px !important; }

.maxw40 {
  max-width: 40px !important; }

.maxw45 {
  max-width: 45px !important; }

.maxw50 {
  max-width: 50px !important; }

.maxw55 {
  max-width: 55px !important; }

.maxw60 {
  max-width: 60px !important; }

.maxw65 {
  max-width: 65px !important; }

.maxw70 {
  max-width: 70px !important; }

.maxw75 {
  max-width: 75px !important; }

.maxw80 {
  max-width: 80px !important; }

.maxw85 {
  max-width: 85px !important; }

.maxw90 {
  max-width: 90px !important; }

.maxw95 {
  max-width: 95px !important; }

.maxw100 {
  max-width: 100px !important; }

.maxw105 {
  max-width: 105px !important; }

.maxw110 {
  max-width: 110px !important; }

.maxw115 {
  max-width: 115px !important; }

.maxw120 {
  max-width: 120px !important; }

.maxw125 {
  max-width: 125px !important; }

.maxw130 {
  max-width: 130px !important; }

.maxw135 {
  max-width: 135px !important; }

.maxw140 {
  max-width: 140px !important; }

.maxw145 {
  max-width: 145px !important; }

.maxw150 {
  max-width: 150px !important; }

.maxw155 {
  max-width: 155px !important; }

.maxw160 {
  max-width: 160px !important; }

.maxw165 {
  max-width: 165px !important; }

.maxw170 {
  max-width: 170px !important; }

.maxw175 {
  max-width: 175px !important; }

.maxw180 {
  max-width: 180px !important; }

.maxw185 {
  max-width: 185px !important; }

.maxw190 {
  max-width: 190px !important; }

.maxw195 {
  max-width: 195px !important; }

.maxw200 {
  max-width: 200px !important; }

.maxw205 {
  max-width: 205px !important; }

.maxw210 {
  max-width: 210px !important; }

.maxw215 {
  max-width: 215px !important; }

.maxw220 {
  max-width: 220px !important; }

.maxw225 {
  max-width: 225px !important; }

.maxw230 {
  max-width: 230px !important; }

.maxw235 {
  max-width: 235px !important; }

.maxw240 {
  max-width: 240px !important; }

.maxw245 {
  max-width: 245px !important; }

.maxw250 {
  max-width: 250px !important; }

.maxw255 {
  max-width: 255px !important; }

.maxw260 {
  max-width: 260px !important; }

.maxw265 {
  max-width: 265px !important; }

.maxw270 {
  max-width: 270px !important; }

.maxw275 {
  max-width: 275px !important; }

.maxw280 {
  max-width: 280px !important; }

.maxw285 {
  max-width: 285px !important; }

.maxw290 {
  max-width: 290px !important; }

.maxw295 {
  max-width: 295px !important; }

.maxw300 {
  max-width: 300px !important; }

.maxw305 {
  max-width: 305px !important; }

.maxw310 {
  max-width: 310px !important; }

.maxw315 {
  max-width: 315px !important; }

.maxw320 {
  max-width: 320px !important; }

.maxw325 {
  max-width: 325px !important; }

.maxw330 {
  max-width: 330px !important; }

.maxw335 {
  max-width: 335px !important; }

.maxw340 {
  max-width: 340px !important; }

.maxw345 {
  max-width: 345px !important; }

.maxw350 {
  max-width: 350px !important; }

.maxw355 {
  max-width: 355px !important; }

.maxw360 {
  max-width: 360px !important; }

.maxw365 {
  max-width: 365px !important; }

.maxw370 {
  max-width: 370px !important; }

.maxw375 {
  max-width: 375px !important; }

.maxw380 {
  max-width: 380px !important; }

.maxw385 {
  max-width: 385px !important; }

.maxw390 {
  max-width: 390px !important; }

.maxw395 {
  max-width: 395px !important; }

.maxw400 {
  max-width: 400px !important; }

.maxw405 {
  max-width: 405px !important; }

.maxw410 {
  max-width: 410px !important; }

.maxw415 {
  max-width: 415px !important; }

.maxw420 {
  max-width: 420px !important; }

.maxw425 {
  max-width: 425px !important; }

.maxw430 {
  max-width: 430px !important; }

.maxw435 {
  max-width: 435px !important; }

.maxw440 {
  max-width: 440px !important; }

.maxw445 {
  max-width: 445px !important; }

.maxw450 {
  max-width: 450px !important; }

.maxw455 {
  max-width: 455px !important; }

.maxw460 {
  max-width: 460px !important; }

.maxw465 {
  max-width: 465px !important; }

.maxw470 {
  max-width: 470px !important; }

.maxw475 {
  max-width: 475px !important; }

.maxw480 {
  max-width: 480px !important; }

.maxw485 {
  max-width: 485px !important; }

.maxw490 {
  max-width: 490px !important; }

.maxw495 {
  max-width: 495px !important; }

.maxw500 {
  max-width: 500px !important; }

.maxw505 {
  max-width: 505px !important; }

.maxw510 {
  max-width: 510px !important; }

.maxw515 {
  max-width: 515px !important; }

.maxw520 {
  max-width: 520px !important; }

.maxw525 {
  max-width: 525px !important; }

.maxw530 {
  max-width: 530px !important; }

.maxw535 {
  max-width: 535px !important; }

.maxw540 {
  max-width: 540px !important; }

.maxw545 {
  max-width: 545px !important; }

.maxw550 {
  max-width: 550px !important; }

.maxw555 {
  max-width: 555px !important; }

.maxw560 {
  max-width: 560px !important; }

.maxw565 {
  max-width: 565px !important; }

.maxw570 {
  max-width: 570px !important; }

.maxw575 {
  max-width: 575px !important; }

.maxw580 {
  max-width: 580px !important; }

.maxw585 {
  max-width: 585px !important; }

.maxw590 {
  max-width: 590px !important; }

.maxw595 {
  max-width: 595px !important; }

.maxw600 {
  max-width: 600px !important; }

.maxw605 {
  max-width: 605px !important; }

.maxw610 {
  max-width: 610px !important; }

.maxw615 {
  max-width: 615px !important; }

.maxw620 {
  max-width: 620px !important; }

.maxw625 {
  max-width: 625px !important; }

.maxw630 {
  max-width: 630px !important; }

.maxw635 {
  max-width: 635px !important; }

.maxw640 {
  max-width: 640px !important; }

.maxw645 {
  max-width: 645px !important; }

.maxw650 {
  max-width: 650px !important; }

.maxw655 {
  max-width: 655px !important; }

.maxw660 {
  max-width: 660px !important; }

.maxw665 {
  max-width: 665px !important; }

.maxw670 {
  max-width: 670px !important; }

.maxw675 {
  max-width: 675px !important; }

.maxw680 {
  max-width: 680px !important; }

.maxw685 {
  max-width: 685px !important; }

.maxw690 {
  max-width: 690px !important; }

.maxw695 {
  max-width: 695px !important; }

.maxw700 {
  max-width: 700px !important; }

.maxh5 {
  max-height: 5px !important; }

.maxh10 {
  max-height: 10px !important; }

.maxh15 {
  max-height: 15px !important; }

.maxh20 {
  max-height: 20px !important; }

.maxh25 {
  max-height: 25px !important; }

.maxh30 {
  max-height: 30px !important; }

.maxh35 {
  max-height: 35px !important; }

.maxh40 {
  max-height: 40px !important; }

.maxh45 {
  max-height: 45px !important; }

.maxh50 {
  max-height: 50px !important; }

.maxh55 {
  max-height: 55px !important; }

.maxh60 {
  max-height: 60px !important; }

.maxh65 {
  max-height: 65px !important; }

.maxh70 {
  max-height: 70px !important; }

.maxh75 {
  max-height: 75px !important; }

.maxh80 {
  max-height: 80px !important; }

.maxh85 {
  max-height: 85px !important; }

.maxh90 {
  max-height: 90px !important; }

.maxh95 {
  max-height: 95px !important; }

.maxh100 {
  max-height: 100px !important; }

.maxh105 {
  max-height: 105px !important; }

.maxh110 {
  max-height: 110px !important; }

.maxh115 {
  max-height: 115px !important; }

.maxh120 {
  max-height: 120px !important; }

.maxh125 {
  max-height: 125px !important; }

.maxh130 {
  max-height: 130px !important; }

.maxh135 {
  max-height: 135px !important; }

.maxh140 {
  max-height: 140px !important; }

.maxh145 {
  max-height: 145px !important; }

.maxh150 {
  max-height: 150px !important; }

.maxh155 {
  max-height: 155px !important; }

.maxh160 {
  max-height: 160px !important; }

.maxh165 {
  max-height: 165px !important; }

.maxh170 {
  max-height: 170px !important; }

.maxh175 {
  max-height: 175px !important; }

.maxh180 {
  max-height: 180px !important; }

.maxh185 {
  max-height: 185px !important; }

.maxh190 {
  max-height: 190px !important; }

.maxh195 {
  max-height: 195px !important; }

.maxh200 {
  max-height: 200px !important; }

.maxh205 {
  max-height: 205px !important; }

.maxh210 {
  max-height: 210px !important; }

.maxh215 {
  max-height: 215px !important; }

.maxh220 {
  max-height: 220px !important; }

.maxh225 {
  max-height: 225px !important; }

.maxh230 {
  max-height: 230px !important; }

.maxh235 {
  max-height: 235px !important; }

.maxh240 {
  max-height: 240px !important; }

.maxh245 {
  max-height: 245px !important; }

.maxh250 {
  max-height: 250px !important; }

.maxh255 {
  max-height: 255px !important; }

.maxh260 {
  max-height: 260px !important; }

.maxh265 {
  max-height: 265px !important; }

.maxh270 {
  max-height: 270px !important; }

.maxh275 {
  max-height: 275px !important; }

.maxh280 {
  max-height: 280px !important; }

.maxh285 {
  max-height: 285px !important; }

.maxh290 {
  max-height: 290px !important; }

.maxh295 {
  max-height: 295px !important; }

.maxh300 {
  max-height: 300px !important; }

.maxh305 {
  max-height: 305px !important; }

.maxh310 {
  max-height: 310px !important; }

.maxh315 {
  max-height: 315px !important; }

.maxh320 {
  max-height: 320px !important; }

.maxh325 {
  max-height: 325px !important; }

.maxh330 {
  max-height: 330px !important; }

.maxh335 {
  max-height: 335px !important; }

.maxh340 {
  max-height: 340px !important; }

.maxh345 {
  max-height: 345px !important; }

.maxh350 {
  max-height: 350px !important; }

.maxh355 {
  max-height: 355px !important; }

.maxh360 {
  max-height: 360px !important; }

.maxh365 {
  max-height: 365px !important; }

.maxh370 {
  max-height: 370px !important; }

.maxh375 {
  max-height: 375px !important; }

.maxh380 {
  max-height: 380px !important; }

.maxh385 {
  max-height: 385px !important; }

.maxh390 {
  max-height: 390px !important; }

.maxh395 {
  max-height: 395px !important; }

.maxh400 {
  max-height: 400px !important; }

.maxh405 {
  max-height: 405px !important; }

.maxh410 {
  max-height: 410px !important; }

.maxh415 {
  max-height: 415px !important; }

.maxh420 {
  max-height: 420px !important; }

.maxh425 {
  max-height: 425px !important; }

.maxh430 {
  max-height: 430px !important; }

.maxh435 {
  max-height: 435px !important; }

.maxh440 {
  max-height: 440px !important; }

.maxh445 {
  max-height: 445px !important; }

.maxh450 {
  max-height: 450px !important; }

.maxh455 {
  max-height: 455px !important; }

.maxh460 {
  max-height: 460px !important; }

.maxh465 {
  max-height: 465px !important; }

.maxh470 {
  max-height: 470px !important; }

.maxh475 {
  max-height: 475px !important; }

.maxh480 {
  max-height: 480px !important; }

.maxh485 {
  max-height: 485px !important; }

.maxh490 {
  max-height: 490px !important; }

.maxh495 {
  max-height: 495px !important; }

.maxh500 {
  max-height: 500px !important; }

.maxh505 {
  max-height: 505px !important; }

.maxh510 {
  max-height: 510px !important; }

.maxh515 {
  max-height: 515px !important; }

.maxh520 {
  max-height: 520px !important; }

.maxh525 {
  max-height: 525px !important; }

.maxh530 {
  max-height: 530px !important; }

.maxh535 {
  max-height: 535px !important; }

.maxh540 {
  max-height: 540px !important; }

.maxh545 {
  max-height: 545px !important; }

.maxh550 {
  max-height: 550px !important; }

.maxh555 {
  max-height: 555px !important; }

.maxh560 {
  max-height: 560px !important; }

.maxh565 {
  max-height: 565px !important; }

.maxh570 {
  max-height: 570px !important; }

.maxh575 {
  max-height: 575px !important; }

.maxh580 {
  max-height: 580px !important; }

.maxh585 {
  max-height: 585px !important; }

.maxh590 {
  max-height: 590px !important; }

.maxh595 {
  max-height: 595px !important; }

.maxh600 {
  max-height: 600px !important; }

.maxh605 {
  max-height: 605px !important; }

.maxh610 {
  max-height: 610px !important; }

.maxh615 {
  max-height: 615px !important; }

.maxh620 {
  max-height: 620px !important; }

.maxh625 {
  max-height: 625px !important; }

.maxh630 {
  max-height: 630px !important; }

.maxh635 {
  max-height: 635px !important; }

.maxh640 {
  max-height: 640px !important; }

.maxh645 {
  max-height: 645px !important; }

.maxh650 {
  max-height: 650px !important; }

.maxh655 {
  max-height: 655px !important; }

.maxh660 {
  max-height: 660px !important; }

.maxh665 {
  max-height: 665px !important; }

.maxh670 {
  max-height: 670px !important; }

.maxh675 {
  max-height: 675px !important; }

.maxh680 {
  max-height: 680px !important; }

.maxh685 {
  max-height: 685px !important; }

.maxh690 {
  max-height: 690px !important; }

.maxh695 {
  max-height: 695px !important; }

.maxh700 {
  max-height: 700px !important; }

.fs10 {
  font-size: 1rem !important; }

.fs11 {
  font-size: 1.1rem !important; }

.fs12 {
  font-size: 1.2rem !important; }

.fs13 {
  font-size: 1.3rem !important; }

.fs14 {
  font-size: 1.4rem !important; }

.fs15 {
  font-size: 1.5rem !important; }

.fs16 {
  font-size: 1.6rem !important; }

.fs17 {
  font-size: 1.7rem !important; }

.fs18 {
  font-size: 1.8rem !important; }

.fs19 {
  font-size: 1.9rem !important; }

.fs20 {
  font-size: 2rem !important; }

.fs21 {
  font-size: 2.1rem !important; }

.fs22 {
  font-size: 2.2rem !important; }

.fs23 {
  font-size: 2.3rem !important; }

.fs24 {
  font-size: 2.4rem !important; }

.fs25 {
  font-size: 2.5rem !important; }

.fs26 {
  font-size: 2.6rem !important; }

.fs27 {
  font-size: 2.7rem !important; }

.fs28 {
  font-size: 2.8rem !important; }

.fs29 {
  font-size: 2.9rem !important; }

.fs30 {
  font-size: 3rem !important; }

.fs31 {
  font-size: 3.1rem !important; }

.fs32 {
  font-size: 3.2rem !important; }

.fs33 {
  font-size: 3.3rem !important; }

.fs34 {
  font-size: 3.4rem !important; }

.fs35 {
  font-size: 3.5rem !important; }

.fs36 {
  font-size: 3.6rem !important; }

.fs37 {
  font-size: 3.7rem !important; }

.fs38 {
  font-size: 3.8rem !important; }

.fs39 {
  font-size: 3.9rem !important; }

.fs40 {
  font-size: 4rem !important; }

.fs41 {
  font-size: 4.1rem !important; }

.fs42 {
  font-size: 4.2rem !important; }

.fs43 {
  font-size: 4.3rem !important; }

.fs44 {
  font-size: 4.4rem !important; }

.fs45 {
  font-size: 4.5rem !important; }

.fs46 {
  font-size: 4.6rem !important; }

.fs47 {
  font-size: 4.7rem !important; }

.fs48 {
  font-size: 4.8rem !important; }

.fs49 {
  font-size: 4.9rem !important; }

.fs50 {
  font-size: 5rem !important; }

.ta-left {
  text-align: left !important; }

.ta-center {
  text-align: center !important; }

.ta-right {
  text-align: right !important; }

.va-top {
  vertical-align: top !important; }

.va-middle {
  vertical-align: middle !important; }

.va-bottom {
  vertical-align: bottom !important; }

@media (min-width: 769px) {
  .pc-mt-10 {
    margin-top: -10px !important; }
  .pc-pt-10 {
    padding-top: -10px !important; }
  .pc-mt-5 {
    margin-top: -5px !important; }
  .pc-pt-5 {
    padding-top: -5px !important; }
  .pc-mt0 {
    margin-top: 0px !important; }
  .pc-pt0 {
    padding-top: 0px !important; }
  .pc-mt5 {
    margin-top: 5px !important; }
  .pc-pt5 {
    padding-top: 5px !important; }
  .pc-mt10 {
    margin-top: 10px !important; }
  .pc-pt10 {
    padding-top: 10px !important; }
  .pc-mt15 {
    margin-top: 15px !important; }
  .pc-pt15 {
    padding-top: 15px !important; }
  .pc-mt20 {
    margin-top: 20px !important; }
  .pc-pt20 {
    padding-top: 20px !important; }
  .pc-mt25 {
    margin-top: 25px !important; }
  .pc-pt25 {
    padding-top: 25px !important; }
  .pc-mt30 {
    margin-top: 30px !important; }
  .pc-pt30 {
    padding-top: 30px !important; }
  .pc-mt35 {
    margin-top: 35px !important; }
  .pc-pt35 {
    padding-top: 35px !important; }
  .pc-mt40 {
    margin-top: 40px !important; }
  .pc-pt40 {
    padding-top: 40px !important; }
  .pc-mt45 {
    margin-top: 45px !important; }
  .pc-pt45 {
    padding-top: 45px !important; }
  .pc-mt50 {
    margin-top: 50px !important; }
  .pc-pt50 {
    padding-top: 50px !important; }
  .pc-mt55 {
    margin-top: 55px !important; }
  .pc-pt55 {
    padding-top: 55px !important; }
  .pc-mt60 {
    margin-top: 60px !important; }
  .pc-pt60 {
    padding-top: 60px !important; }
  .pc-mt65 {
    margin-top: 65px !important; }
  .pc-pt65 {
    padding-top: 65px !important; }
  .pc-mt70 {
    margin-top: 70px !important; }
  .pc-pt70 {
    padding-top: 70px !important; }
  .pc-mt75 {
    margin-top: 75px !important; }
  .pc-pt75 {
    padding-top: 75px !important; }
  .pc-mt80 {
    margin-top: 80px !important; }
  .pc-pt80 {
    padding-top: 80px !important; }
  .pc-mt-auto {
    margin-top: auto !important; }
  .pc-mb-10 {
    margin-bottom: -10px !important; }
  .pc-pb-10 {
    padding-bottom: -10px !important; }
  .pc-mb-5 {
    margin-bottom: -5px !important; }
  .pc-pb-5 {
    padding-bottom: -5px !important; }
  .pc-mb0 {
    margin-bottom: 0px !important; }
  .pc-pb0 {
    padding-bottom: 0px !important; }
  .pc-mb5 {
    margin-bottom: 5px !important; }
  .pc-pb5 {
    padding-bottom: 5px !important; }
  .pc-mb10 {
    margin-bottom: 10px !important; }
  .pc-pb10 {
    padding-bottom: 10px !important; }
  .pc-mb15 {
    margin-bottom: 15px !important; }
  .pc-pb15 {
    padding-bottom: 15px !important; }
  .pc-mb20 {
    margin-bottom: 20px !important; }
  .pc-pb20 {
    padding-bottom: 20px !important; }
  .pc-mb25 {
    margin-bottom: 25px !important; }
  .pc-pb25 {
    padding-bottom: 25px !important; }
  .pc-mb30 {
    margin-bottom: 30px !important; }
  .pc-pb30 {
    padding-bottom: 30px !important; }
  .pc-mb35 {
    margin-bottom: 35px !important; }
  .pc-pb35 {
    padding-bottom: 35px !important; }
  .pc-mb40 {
    margin-bottom: 40px !important; }
  .pc-pb40 {
    padding-bottom: 40px !important; }
  .pc-mb45 {
    margin-bottom: 45px !important; }
  .pc-pb45 {
    padding-bottom: 45px !important; }
  .pc-mb50 {
    margin-bottom: 50px !important; }
  .pc-pb50 {
    padding-bottom: 50px !important; }
  .pc-mb55 {
    margin-bottom: 55px !important; }
  .pc-pb55 {
    padding-bottom: 55px !important; }
  .pc-mb60 {
    margin-bottom: 60px !important; }
  .pc-pb60 {
    padding-bottom: 60px !important; }
  .pc-mb65 {
    margin-bottom: 65px !important; }
  .pc-pb65 {
    padding-bottom: 65px !important; }
  .pc-mb70 {
    margin-bottom: 70px !important; }
  .pc-pb70 {
    padding-bottom: 70px !important; }
  .pc-mb75 {
    margin-bottom: 75px !important; }
  .pc-pb75 {
    padding-bottom: 75px !important; }
  .pc-mb80 {
    margin-bottom: 80px !important; }
  .pc-pb80 {
    padding-bottom: 80px !important; }
  .pc-mb-auto {
    margin-bottom: auto !important; }
  .pc-ml-10 {
    margin-left: -10px !important; }
  .pc-pl-10 {
    padding-left: -10px !important; }
  .pc-ml-5 {
    margin-left: -5px !important; }
  .pc-pl-5 {
    padding-left: -5px !important; }
  .pc-ml0 {
    margin-left: 0px !important; }
  .pc-pl0 {
    padding-left: 0px !important; }
  .pc-ml5 {
    margin-left: 5px !important; }
  .pc-pl5 {
    padding-left: 5px !important; }
  .pc-ml10 {
    margin-left: 10px !important; }
  .pc-pl10 {
    padding-left: 10px !important; }
  .pc-ml15 {
    margin-left: 15px !important; }
  .pc-pl15 {
    padding-left: 15px !important; }
  .pc-ml20 {
    margin-left: 20px !important; }
  .pc-pl20 {
    padding-left: 20px !important; }
  .pc-ml25 {
    margin-left: 25px !important; }
  .pc-pl25 {
    padding-left: 25px !important; }
  .pc-ml30 {
    margin-left: 30px !important; }
  .pc-pl30 {
    padding-left: 30px !important; }
  .pc-ml35 {
    margin-left: 35px !important; }
  .pc-pl35 {
    padding-left: 35px !important; }
  .pc-ml40 {
    margin-left: 40px !important; }
  .pc-pl40 {
    padding-left: 40px !important; }
  .pc-ml45 {
    margin-left: 45px !important; }
  .pc-pl45 {
    padding-left: 45px !important; }
  .pc-ml50 {
    margin-left: 50px !important; }
  .pc-pl50 {
    padding-left: 50px !important; }
  .pc-ml55 {
    margin-left: 55px !important; }
  .pc-pl55 {
    padding-left: 55px !important; }
  .pc-ml60 {
    margin-left: 60px !important; }
  .pc-pl60 {
    padding-left: 60px !important; }
  .pc-ml65 {
    margin-left: 65px !important; }
  .pc-pl65 {
    padding-left: 65px !important; }
  .pc-ml70 {
    margin-left: 70px !important; }
  .pc-pl70 {
    padding-left: 70px !important; }
  .pc-ml75 {
    margin-left: 75px !important; }
  .pc-pl75 {
    padding-left: 75px !important; }
  .pc-ml80 {
    margin-left: 80px !important; }
  .pc-pl80 {
    padding-left: 80px !important; }
  .pc-ml-auto {
    margin-left: auto !important; }
  .pc-mr-10 {
    margin-right: -10px !important; }
  .pc-pr-10 {
    padding-right: -10px !important; }
  .pc-mr-5 {
    margin-right: -5px !important; }
  .pc-pr-5 {
    padding-right: -5px !important; }
  .pc-mr0 {
    margin-right: 0px !important; }
  .pc-pr0 {
    padding-right: 0px !important; }
  .pc-mr5 {
    margin-right: 5px !important; }
  .pc-pr5 {
    padding-right: 5px !important; }
  .pc-mr10 {
    margin-right: 10px !important; }
  .pc-pr10 {
    padding-right: 10px !important; }
  .pc-mr15 {
    margin-right: 15px !important; }
  .pc-pr15 {
    padding-right: 15px !important; }
  .pc-mr20 {
    margin-right: 20px !important; }
  .pc-pr20 {
    padding-right: 20px !important; }
  .pc-mr25 {
    margin-right: 25px !important; }
  .pc-pr25 {
    padding-right: 25px !important; }
  .pc-mr30 {
    margin-right: 30px !important; }
  .pc-pr30 {
    padding-right: 30px !important; }
  .pc-mr35 {
    margin-right: 35px !important; }
  .pc-pr35 {
    padding-right: 35px !important; }
  .pc-mr40 {
    margin-right: 40px !important; }
  .pc-pr40 {
    padding-right: 40px !important; }
  .pc-mr45 {
    margin-right: 45px !important; }
  .pc-pr45 {
    padding-right: 45px !important; }
  .pc-mr50 {
    margin-right: 50px !important; }
  .pc-pr50 {
    padding-right: 50px !important; }
  .pc-mr55 {
    margin-right: 55px !important; }
  .pc-pr55 {
    padding-right: 55px !important; }
  .pc-mr60 {
    margin-right: 60px !important; }
  .pc-pr60 {
    padding-right: 60px !important; }
  .pc-mr65 {
    margin-right: 65px !important; }
  .pc-pr65 {
    padding-right: 65px !important; }
  .pc-mr70 {
    margin-right: 70px !important; }
  .pc-pr70 {
    padding-right: 70px !important; }
  .pc-mr75 {
    margin-right: 75px !important; }
  .pc-pr75 {
    padding-right: 75px !important; }
  .pc-mr80 {
    margin-right: 80px !important; }
  .pc-pr80 {
    padding-right: 80px !important; }
  .pc-mr-auto {
    margin-right: auto !important; }
  .pc-mh-10 {
    margin-left: -10px !important;
    margin-right: -10px !important; }
  .pc-ph-10 {
    padding-left: -10px !important;
    padding-right: -10px !important; }
  .pc-mh-5 {
    margin-left: -5px !important;
    margin-right: -5px !important; }
  .pc-ph-5 {
    padding-left: -5px !important;
    padding-right: -5px !important; }
  .pc-mh0 {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .pc-ph0 {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .pc-mh5 {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .pc-ph5 {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .pc-mh10 {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .pc-ph10 {
    padding-left: 10px !important;
    padding-right: 10px !important; }
  .pc-mh15 {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .pc-ph15 {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .pc-mh20 {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .pc-ph20 {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .pc-mh25 {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .pc-ph25 {
    padding-left: 25px !important;
    padding-right: 25px !important; }
  .pc-mh30 {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .pc-ph30 {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .pc-mh35 {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .pc-ph35 {
    padding-left: 35px !important;
    padding-right: 35px !important; }
  .pc-mh40 {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .pc-ph40 {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .pc-mh45 {
    margin-left: 45px !important;
    margin-right: 45px !important; }
  .pc-ph45 {
    padding-left: 45px !important;
    padding-right: 45px !important; }
  .pc-mh50 {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .pc-ph50 {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .pc-mh55 {
    margin-left: 55px !important;
    margin-right: 55px !important; }
  .pc-ph55 {
    padding-left: 55px !important;
    padding-right: 55px !important; }
  .pc-mh60 {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .pc-ph60 {
    padding-left: 60px !important;
    padding-right: 60px !important; }
  .pc-mh65 {
    margin-left: 65px !important;
    margin-right: 65px !important; }
  .pc-ph65 {
    padding-left: 65px !important;
    padding-right: 65px !important; }
  .pc-mh70 {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .pc-ph70 {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .pc-mh75 {
    margin-left: 75px !important;
    margin-right: 75px !important; }
  .pc-ph75 {
    padding-left: 75px !important;
    padding-right: 75px !important; }
  .pc-mh80 {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .pc-ph80 {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .pc-mh-auto {
    margin-horizontal: auto !important; }
  .pc-mv-10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .pc-pv-10 {
    padding-top: -10px !important;
    padding-bottom: -10px !important; }
  .pc-mv-5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .pc-pv-5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important; }
  .pc-mv0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .pc-pv0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .pc-mv5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .pc-pv5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .pc-mv10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .pc-pv10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .pc-mv15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .pc-pv15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .pc-mv20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .pc-pv20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .pc-mv25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .pc-pv25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .pc-mv30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .pc-pv30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .pc-mv35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .pc-pv35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .pc-mv40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .pc-pv40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .pc-mv45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important; }
  .pc-pv45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important; }
  .pc-mv50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .pc-pv50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .pc-mv55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important; }
  .pc-pv55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important; }
  .pc-mv60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .pc-pv60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .pc-mv65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important; }
  .pc-pv65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important; }
  .pc-mv70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .pc-pv70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .pc-mv75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important; }
  .pc-pv75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important; }
  .pc-mv80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .pc-pv80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .pc-mv-auto {
    margin-vertical: auto !important; }
  .pc-w5 {
    width: 5px !important; }
  .pc-w10 {
    width: 10px !important; }
  .pc-w15 {
    width: 15px !important; }
  .pc-w20 {
    width: 20px !important; }
  .pc-w25 {
    width: 25px !important; }
  .pc-w30 {
    width: 30px !important; }
  .pc-w35 {
    width: 35px !important; }
  .pc-w40 {
    width: 40px !important; }
  .pc-w45 {
    width: 45px !important; }
  .pc-w50 {
    width: 50px !important; }
  .pc-w55 {
    width: 55px !important; }
  .pc-w60 {
    width: 60px !important; }
  .pc-w65 {
    width: 65px !important; }
  .pc-w70 {
    width: 70px !important; }
  .pc-w75 {
    width: 75px !important; }
  .pc-w80 {
    width: 80px !important; }
  .pc-w85 {
    width: 85px !important; }
  .pc-w90 {
    width: 90px !important; }
  .pc-w95 {
    width: 95px !important; }
  .pc-w100 {
    width: 100px !important; }
  .pc-w105 {
    width: 105px !important; }
  .pc-w110 {
    width: 110px !important; }
  .pc-w115 {
    width: 115px !important; }
  .pc-w120 {
    width: 120px !important; }
  .pc-w125 {
    width: 125px !important; }
  .pc-w130 {
    width: 130px !important; }
  .pc-w135 {
    width: 135px !important; }
  .pc-w140 {
    width: 140px !important; }
  .pc-w145 {
    width: 145px !important; }
  .pc-w150 {
    width: 150px !important; }
  .pc-w155 {
    width: 155px !important; }
  .pc-w160 {
    width: 160px !important; }
  .pc-w165 {
    width: 165px !important; }
  .pc-w170 {
    width: 170px !important; }
  .pc-w175 {
    width: 175px !important; }
  .pc-w180 {
    width: 180px !important; }
  .pc-w185 {
    width: 185px !important; }
  .pc-w190 {
    width: 190px !important; }
  .pc-w195 {
    width: 195px !important; }
  .pc-w200 {
    width: 200px !important; }
  .pc-w205 {
    width: 205px !important; }
  .pc-w210 {
    width: 210px !important; }
  .pc-w215 {
    width: 215px !important; }
  .pc-w220 {
    width: 220px !important; }
  .pc-w225 {
    width: 225px !important; }
  .pc-w230 {
    width: 230px !important; }
  .pc-w235 {
    width: 235px !important; }
  .pc-w240 {
    width: 240px !important; }
  .pc-w245 {
    width: 245px !important; }
  .pc-w250 {
    width: 250px !important; }
  .pc-w255 {
    width: 255px !important; }
  .pc-w260 {
    width: 260px !important; }
  .pc-w265 {
    width: 265px !important; }
  .pc-w270 {
    width: 270px !important; }
  .pc-w275 {
    width: 275px !important; }
  .pc-w280 {
    width: 280px !important; }
  .pc-w285 {
    width: 285px !important; }
  .pc-w290 {
    width: 290px !important; }
  .pc-w295 {
    width: 295px !important; }
  .pc-w300 {
    width: 300px !important; }
  .pc-w305 {
    width: 305px !important; }
  .pc-w310 {
    width: 310px !important; }
  .pc-w315 {
    width: 315px !important; }
  .pc-w320 {
    width: 320px !important; }
  .pc-w325 {
    width: 325px !important; }
  .pc-w330 {
    width: 330px !important; }
  .pc-w335 {
    width: 335px !important; }
  .pc-w340 {
    width: 340px !important; }
  .pc-w345 {
    width: 345px !important; }
  .pc-w350 {
    width: 350px !important; }
  .pc-w355 {
    width: 355px !important; }
  .pc-w360 {
    width: 360px !important; }
  .pc-w365 {
    width: 365px !important; }
  .pc-w370 {
    width: 370px !important; }
  .pc-w375 {
    width: 375px !important; }
  .pc-w380 {
    width: 380px !important; }
  .pc-w385 {
    width: 385px !important; }
  .pc-w390 {
    width: 390px !important; }
  .pc-w395 {
    width: 395px !important; }
  .pc-w400 {
    width: 400px !important; }
  .pc-w405 {
    width: 405px !important; }
  .pc-w410 {
    width: 410px !important; }
  .pc-w415 {
    width: 415px !important; }
  .pc-w420 {
    width: 420px !important; }
  .pc-w425 {
    width: 425px !important; }
  .pc-w430 {
    width: 430px !important; }
  .pc-w435 {
    width: 435px !important; }
  .pc-w440 {
    width: 440px !important; }
  .pc-w445 {
    width: 445px !important; }
  .pc-w450 {
    width: 450px !important; }
  .pc-w455 {
    width: 455px !important; }
  .pc-w460 {
    width: 460px !important; }
  .pc-w465 {
    width: 465px !important; }
  .pc-w470 {
    width: 470px !important; }
  .pc-w475 {
    width: 475px !important; }
  .pc-w480 {
    width: 480px !important; }
  .pc-w485 {
    width: 485px !important; }
  .pc-w490 {
    width: 490px !important; }
  .pc-w495 {
    width: 495px !important; }
  .pc-w500 {
    width: 500px !important; }
  .pc-w505 {
    width: 505px !important; }
  .pc-w510 {
    width: 510px !important; }
  .pc-w515 {
    width: 515px !important; }
  .pc-w520 {
    width: 520px !important; }
  .pc-w525 {
    width: 525px !important; }
  .pc-w530 {
    width: 530px !important; }
  .pc-w535 {
    width: 535px !important; }
  .pc-w540 {
    width: 540px !important; }
  .pc-w545 {
    width: 545px !important; }
  .pc-w550 {
    width: 550px !important; }
  .pc-w555 {
    width: 555px !important; }
  .pc-w560 {
    width: 560px !important; }
  .pc-w565 {
    width: 565px !important; }
  .pc-w570 {
    width: 570px !important; }
  .pc-w575 {
    width: 575px !important; }
  .pc-w580 {
    width: 580px !important; }
  .pc-w585 {
    width: 585px !important; }
  .pc-w590 {
    width: 590px !important; }
  .pc-w595 {
    width: 595px !important; }
  .pc-w600 {
    width: 600px !important; }
  .pc-w605 {
    width: 605px !important; }
  .pc-w610 {
    width: 610px !important; }
  .pc-w615 {
    width: 615px !important; }
  .pc-w620 {
    width: 620px !important; }
  .pc-w625 {
    width: 625px !important; }
  .pc-w630 {
    width: 630px !important; }
  .pc-w635 {
    width: 635px !important; }
  .pc-w640 {
    width: 640px !important; }
  .pc-w645 {
    width: 645px !important; }
  .pc-w650 {
    width: 650px !important; }
  .pc-w655 {
    width: 655px !important; }
  .pc-w660 {
    width: 660px !important; }
  .pc-w665 {
    width: 665px !important; }
  .pc-w670 {
    width: 670px !important; }
  .pc-w675 {
    width: 675px !important; }
  .pc-w680 {
    width: 680px !important; }
  .pc-w685 {
    width: 685px !important; }
  .pc-w690 {
    width: 690px !important; }
  .pc-w695 {
    width: 695px !important; }
  .pc-w700 {
    width: 700px !important; }
  .pc-h5 {
    height: 5px !important; }
  .pc-h10 {
    height: 10px !important; }
  .pc-h15 {
    height: 15px !important; }
  .pc-h20 {
    height: 20px !important; }
  .pc-h25 {
    height: 25px !important; }
  .pc-h30 {
    height: 30px !important; }
  .pc-h35 {
    height: 35px !important; }
  .pc-h40 {
    height: 40px !important; }
  .pc-h45 {
    height: 45px !important; }
  .pc-h50 {
    height: 50px !important; }
  .pc-h55 {
    height: 55px !important; }
  .pc-h60 {
    height: 60px !important; }
  .pc-h65 {
    height: 65px !important; }
  .pc-h70 {
    height: 70px !important; }
  .pc-h75 {
    height: 75px !important; }
  .pc-h80 {
    height: 80px !important; }
  .pc-h85 {
    height: 85px !important; }
  .pc-h90 {
    height: 90px !important; }
  .pc-h95 {
    height: 95px !important; }
  .pc-h100 {
    height: 100px !important; }
  .pc-h105 {
    height: 105px !important; }
  .pc-h110 {
    height: 110px !important; }
  .pc-h115 {
    height: 115px !important; }
  .pc-h120 {
    height: 120px !important; }
  .pc-h125 {
    height: 125px !important; }
  .pc-h130 {
    height: 130px !important; }
  .pc-h135 {
    height: 135px !important; }
  .pc-h140 {
    height: 140px !important; }
  .pc-h145 {
    height: 145px !important; }
  .pc-h150 {
    height: 150px !important; }
  .pc-h155 {
    height: 155px !important; }
  .pc-h160 {
    height: 160px !important; }
  .pc-h165 {
    height: 165px !important; }
  .pc-h170 {
    height: 170px !important; }
  .pc-h175 {
    height: 175px !important; }
  .pc-h180 {
    height: 180px !important; }
  .pc-h185 {
    height: 185px !important; }
  .pc-h190 {
    height: 190px !important; }
  .pc-h195 {
    height: 195px !important; }
  .pc-h200 {
    height: 200px !important; }
  .pc-h205 {
    height: 205px !important; }
  .pc-h210 {
    height: 210px !important; }
  .pc-h215 {
    height: 215px !important; }
  .pc-h220 {
    height: 220px !important; }
  .pc-h225 {
    height: 225px !important; }
  .pc-h230 {
    height: 230px !important; }
  .pc-h235 {
    height: 235px !important; }
  .pc-h240 {
    height: 240px !important; }
  .pc-h245 {
    height: 245px !important; }
  .pc-h250 {
    height: 250px !important; }
  .pc-h255 {
    height: 255px !important; }
  .pc-h260 {
    height: 260px !important; }
  .pc-h265 {
    height: 265px !important; }
  .pc-h270 {
    height: 270px !important; }
  .pc-h275 {
    height: 275px !important; }
  .pc-h280 {
    height: 280px !important; }
  .pc-h285 {
    height: 285px !important; }
  .pc-h290 {
    height: 290px !important; }
  .pc-h295 {
    height: 295px !important; }
  .pc-h300 {
    height: 300px !important; }
  .pc-h305 {
    height: 305px !important; }
  .pc-h310 {
    height: 310px !important; }
  .pc-h315 {
    height: 315px !important; }
  .pc-h320 {
    height: 320px !important; }
  .pc-h325 {
    height: 325px !important; }
  .pc-h330 {
    height: 330px !important; }
  .pc-h335 {
    height: 335px !important; }
  .pc-h340 {
    height: 340px !important; }
  .pc-h345 {
    height: 345px !important; }
  .pc-h350 {
    height: 350px !important; }
  .pc-h355 {
    height: 355px !important; }
  .pc-h360 {
    height: 360px !important; }
  .pc-h365 {
    height: 365px !important; }
  .pc-h370 {
    height: 370px !important; }
  .pc-h375 {
    height: 375px !important; }
  .pc-h380 {
    height: 380px !important; }
  .pc-h385 {
    height: 385px !important; }
  .pc-h390 {
    height: 390px !important; }
  .pc-h395 {
    height: 395px !important; }
  .pc-h400 {
    height: 400px !important; }
  .pc-h405 {
    height: 405px !important; }
  .pc-h410 {
    height: 410px !important; }
  .pc-h415 {
    height: 415px !important; }
  .pc-h420 {
    height: 420px !important; }
  .pc-h425 {
    height: 425px !important; }
  .pc-h430 {
    height: 430px !important; }
  .pc-h435 {
    height: 435px !important; }
  .pc-h440 {
    height: 440px !important; }
  .pc-h445 {
    height: 445px !important; }
  .pc-h450 {
    height: 450px !important; }
  .pc-h455 {
    height: 455px !important; }
  .pc-h460 {
    height: 460px !important; }
  .pc-h465 {
    height: 465px !important; }
  .pc-h470 {
    height: 470px !important; }
  .pc-h475 {
    height: 475px !important; }
  .pc-h480 {
    height: 480px !important; }
  .pc-h485 {
    height: 485px !important; }
  .pc-h490 {
    height: 490px !important; }
  .pc-h495 {
    height: 495px !important; }
  .pc-h500 {
    height: 500px !important; }
  .pc-h505 {
    height: 505px !important; }
  .pc-h510 {
    height: 510px !important; }
  .pc-h515 {
    height: 515px !important; }
  .pc-h520 {
    height: 520px !important; }
  .pc-h525 {
    height: 525px !important; }
  .pc-h530 {
    height: 530px !important; }
  .pc-h535 {
    height: 535px !important; }
  .pc-h540 {
    height: 540px !important; }
  .pc-h545 {
    height: 545px !important; }
  .pc-h550 {
    height: 550px !important; }
  .pc-h555 {
    height: 555px !important; }
  .pc-h560 {
    height: 560px !important; }
  .pc-h565 {
    height: 565px !important; }
  .pc-h570 {
    height: 570px !important; }
  .pc-h575 {
    height: 575px !important; }
  .pc-h580 {
    height: 580px !important; }
  .pc-h585 {
    height: 585px !important; }
  .pc-h590 {
    height: 590px !important; }
  .pc-h595 {
    height: 595px !important; }
  .pc-h600 {
    height: 600px !important; }
  .pc-h605 {
    height: 605px !important; }
  .pc-h610 {
    height: 610px !important; }
  .pc-h615 {
    height: 615px !important; }
  .pc-h620 {
    height: 620px !important; }
  .pc-h625 {
    height: 625px !important; }
  .pc-h630 {
    height: 630px !important; }
  .pc-h635 {
    height: 635px !important; }
  .pc-h640 {
    height: 640px !important; }
  .pc-h645 {
    height: 645px !important; }
  .pc-h650 {
    height: 650px !important; }
  .pc-h655 {
    height: 655px !important; }
  .pc-h660 {
    height: 660px !important; }
  .pc-h665 {
    height: 665px !important; }
  .pc-h670 {
    height: 670px !important; }
  .pc-h675 {
    height: 675px !important; }
  .pc-h680 {
    height: 680px !important; }
  .pc-h685 {
    height: 685px !important; }
  .pc-h690 {
    height: 690px !important; }
  .pc-h695 {
    height: 695px !important; }
  .pc-h700 {
    height: 700px !important; }
  .pc-minw5 {
    min-width: 5px !important; }
  .pc-minw10 {
    min-width: 10px !important; }
  .pc-minw15 {
    min-width: 15px !important; }
  .pc-minw20 {
    min-width: 20px !important; }
  .pc-minw25 {
    min-width: 25px !important; }
  .pc-minw30 {
    min-width: 30px !important; }
  .pc-minw35 {
    min-width: 35px !important; }
  .pc-minw40 {
    min-width: 40px !important; }
  .pc-minw45 {
    min-width: 45px !important; }
  .pc-minw50 {
    min-width: 50px !important; }
  .pc-minw55 {
    min-width: 55px !important; }
  .pc-minw60 {
    min-width: 60px !important; }
  .pc-minw65 {
    min-width: 65px !important; }
  .pc-minw70 {
    min-width: 70px !important; }
  .pc-minw75 {
    min-width: 75px !important; }
  .pc-minw80 {
    min-width: 80px !important; }
  .pc-minw85 {
    min-width: 85px !important; }
  .pc-minw90 {
    min-width: 90px !important; }
  .pc-minw95 {
    min-width: 95px !important; }
  .pc-minw100 {
    min-width: 100px !important; }
  .pc-minw105 {
    min-width: 105px !important; }
  .pc-minw110 {
    min-width: 110px !important; }
  .pc-minw115 {
    min-width: 115px !important; }
  .pc-minw120 {
    min-width: 120px !important; }
  .pc-minw125 {
    min-width: 125px !important; }
  .pc-minw130 {
    min-width: 130px !important; }
  .pc-minw135 {
    min-width: 135px !important; }
  .pc-minw140 {
    min-width: 140px !important; }
  .pc-minw145 {
    min-width: 145px !important; }
  .pc-minw150 {
    min-width: 150px !important; }
  .pc-minw155 {
    min-width: 155px !important; }
  .pc-minw160 {
    min-width: 160px !important; }
  .pc-minw165 {
    min-width: 165px !important; }
  .pc-minw170 {
    min-width: 170px !important; }
  .pc-minw175 {
    min-width: 175px !important; }
  .pc-minw180 {
    min-width: 180px !important; }
  .pc-minw185 {
    min-width: 185px !important; }
  .pc-minw190 {
    min-width: 190px !important; }
  .pc-minw195 {
    min-width: 195px !important; }
  .pc-minw200 {
    min-width: 200px !important; }
  .pc-minw205 {
    min-width: 205px !important; }
  .pc-minw210 {
    min-width: 210px !important; }
  .pc-minw215 {
    min-width: 215px !important; }
  .pc-minw220 {
    min-width: 220px !important; }
  .pc-minw225 {
    min-width: 225px !important; }
  .pc-minw230 {
    min-width: 230px !important; }
  .pc-minw235 {
    min-width: 235px !important; }
  .pc-minw240 {
    min-width: 240px !important; }
  .pc-minw245 {
    min-width: 245px !important; }
  .pc-minw250 {
    min-width: 250px !important; }
  .pc-minw255 {
    min-width: 255px !important; }
  .pc-minw260 {
    min-width: 260px !important; }
  .pc-minw265 {
    min-width: 265px !important; }
  .pc-minw270 {
    min-width: 270px !important; }
  .pc-minw275 {
    min-width: 275px !important; }
  .pc-minw280 {
    min-width: 280px !important; }
  .pc-minw285 {
    min-width: 285px !important; }
  .pc-minw290 {
    min-width: 290px !important; }
  .pc-minw295 {
    min-width: 295px !important; }
  .pc-minw300 {
    min-width: 300px !important; }
  .pc-minw305 {
    min-width: 305px !important; }
  .pc-minw310 {
    min-width: 310px !important; }
  .pc-minw315 {
    min-width: 315px !important; }
  .pc-minw320 {
    min-width: 320px !important; }
  .pc-minw325 {
    min-width: 325px !important; }
  .pc-minw330 {
    min-width: 330px !important; }
  .pc-minw335 {
    min-width: 335px !important; }
  .pc-minw340 {
    min-width: 340px !important; }
  .pc-minw345 {
    min-width: 345px !important; }
  .pc-minw350 {
    min-width: 350px !important; }
  .pc-minw355 {
    min-width: 355px !important; }
  .pc-minw360 {
    min-width: 360px !important; }
  .pc-minw365 {
    min-width: 365px !important; }
  .pc-minw370 {
    min-width: 370px !important; }
  .pc-minw375 {
    min-width: 375px !important; }
  .pc-minw380 {
    min-width: 380px !important; }
  .pc-minw385 {
    min-width: 385px !important; }
  .pc-minw390 {
    min-width: 390px !important; }
  .pc-minw395 {
    min-width: 395px !important; }
  .pc-minw400 {
    min-width: 400px !important; }
  .pc-minw405 {
    min-width: 405px !important; }
  .pc-minw410 {
    min-width: 410px !important; }
  .pc-minw415 {
    min-width: 415px !important; }
  .pc-minw420 {
    min-width: 420px !important; }
  .pc-minw425 {
    min-width: 425px !important; }
  .pc-minw430 {
    min-width: 430px !important; }
  .pc-minw435 {
    min-width: 435px !important; }
  .pc-minw440 {
    min-width: 440px !important; }
  .pc-minw445 {
    min-width: 445px !important; }
  .pc-minw450 {
    min-width: 450px !important; }
  .pc-minw455 {
    min-width: 455px !important; }
  .pc-minw460 {
    min-width: 460px !important; }
  .pc-minw465 {
    min-width: 465px !important; }
  .pc-minw470 {
    min-width: 470px !important; }
  .pc-minw475 {
    min-width: 475px !important; }
  .pc-minw480 {
    min-width: 480px !important; }
  .pc-minw485 {
    min-width: 485px !important; }
  .pc-minw490 {
    min-width: 490px !important; }
  .pc-minw495 {
    min-width: 495px !important; }
  .pc-minw500 {
    min-width: 500px !important; }
  .pc-minw505 {
    min-width: 505px !important; }
  .pc-minw510 {
    min-width: 510px !important; }
  .pc-minw515 {
    min-width: 515px !important; }
  .pc-minw520 {
    min-width: 520px !important; }
  .pc-minw525 {
    min-width: 525px !important; }
  .pc-minw530 {
    min-width: 530px !important; }
  .pc-minw535 {
    min-width: 535px !important; }
  .pc-minw540 {
    min-width: 540px !important; }
  .pc-minw545 {
    min-width: 545px !important; }
  .pc-minw550 {
    min-width: 550px !important; }
  .pc-minw555 {
    min-width: 555px !important; }
  .pc-minw560 {
    min-width: 560px !important; }
  .pc-minw565 {
    min-width: 565px !important; }
  .pc-minw570 {
    min-width: 570px !important; }
  .pc-minw575 {
    min-width: 575px !important; }
  .pc-minw580 {
    min-width: 580px !important; }
  .pc-minw585 {
    min-width: 585px !important; }
  .pc-minw590 {
    min-width: 590px !important; }
  .pc-minw595 {
    min-width: 595px !important; }
  .pc-minw600 {
    min-width: 600px !important; }
  .pc-minw605 {
    min-width: 605px !important; }
  .pc-minw610 {
    min-width: 610px !important; }
  .pc-minw615 {
    min-width: 615px !important; }
  .pc-minw620 {
    min-width: 620px !important; }
  .pc-minw625 {
    min-width: 625px !important; }
  .pc-minw630 {
    min-width: 630px !important; }
  .pc-minw635 {
    min-width: 635px !important; }
  .pc-minw640 {
    min-width: 640px !important; }
  .pc-minw645 {
    min-width: 645px !important; }
  .pc-minw650 {
    min-width: 650px !important; }
  .pc-minw655 {
    min-width: 655px !important; }
  .pc-minw660 {
    min-width: 660px !important; }
  .pc-minw665 {
    min-width: 665px !important; }
  .pc-minw670 {
    min-width: 670px !important; }
  .pc-minw675 {
    min-width: 675px !important; }
  .pc-minw680 {
    min-width: 680px !important; }
  .pc-minw685 {
    min-width: 685px !important; }
  .pc-minw690 {
    min-width: 690px !important; }
  .pc-minw695 {
    min-width: 695px !important; }
  .pc-minw700 {
    min-width: 700px !important; }
  .pc-minh5 {
    min-height: 5px !important; }
  .pc-minh10 {
    min-height: 10px !important; }
  .pc-minh15 {
    min-height: 15px !important; }
  .pc-minh20 {
    min-height: 20px !important; }
  .pc-minh25 {
    min-height: 25px !important; }
  .pc-minh30 {
    min-height: 30px !important; }
  .pc-minh35 {
    min-height: 35px !important; }
  .pc-minh40 {
    min-height: 40px !important; }
  .pc-minh45 {
    min-height: 45px !important; }
  .pc-minh50 {
    min-height: 50px !important; }
  .pc-minh55 {
    min-height: 55px !important; }
  .pc-minh60 {
    min-height: 60px !important; }
  .pc-minh65 {
    min-height: 65px !important; }
  .pc-minh70 {
    min-height: 70px !important; }
  .pc-minh75 {
    min-height: 75px !important; }
  .pc-minh80 {
    min-height: 80px !important; }
  .pc-minh85 {
    min-height: 85px !important; }
  .pc-minh90 {
    min-height: 90px !important; }
  .pc-minh95 {
    min-height: 95px !important; }
  .pc-minh100 {
    min-height: 100px !important; }
  .pc-minh105 {
    min-height: 105px !important; }
  .pc-minh110 {
    min-height: 110px !important; }
  .pc-minh115 {
    min-height: 115px !important; }
  .pc-minh120 {
    min-height: 120px !important; }
  .pc-minh125 {
    min-height: 125px !important; }
  .pc-minh130 {
    min-height: 130px !important; }
  .pc-minh135 {
    min-height: 135px !important; }
  .pc-minh140 {
    min-height: 140px !important; }
  .pc-minh145 {
    min-height: 145px !important; }
  .pc-minh150 {
    min-height: 150px !important; }
  .pc-minh155 {
    min-height: 155px !important; }
  .pc-minh160 {
    min-height: 160px !important; }
  .pc-minh165 {
    min-height: 165px !important; }
  .pc-minh170 {
    min-height: 170px !important; }
  .pc-minh175 {
    min-height: 175px !important; }
  .pc-minh180 {
    min-height: 180px !important; }
  .pc-minh185 {
    min-height: 185px !important; }
  .pc-minh190 {
    min-height: 190px !important; }
  .pc-minh195 {
    min-height: 195px !important; }
  .pc-minh200 {
    min-height: 200px !important; }
  .pc-minh205 {
    min-height: 205px !important; }
  .pc-minh210 {
    min-height: 210px !important; }
  .pc-minh215 {
    min-height: 215px !important; }
  .pc-minh220 {
    min-height: 220px !important; }
  .pc-minh225 {
    min-height: 225px !important; }
  .pc-minh230 {
    min-height: 230px !important; }
  .pc-minh235 {
    min-height: 235px !important; }
  .pc-minh240 {
    min-height: 240px !important; }
  .pc-minh245 {
    min-height: 245px !important; }
  .pc-minh250 {
    min-height: 250px !important; }
  .pc-minh255 {
    min-height: 255px !important; }
  .pc-minh260 {
    min-height: 260px !important; }
  .pc-minh265 {
    min-height: 265px !important; }
  .pc-minh270 {
    min-height: 270px !important; }
  .pc-minh275 {
    min-height: 275px !important; }
  .pc-minh280 {
    min-height: 280px !important; }
  .pc-minh285 {
    min-height: 285px !important; }
  .pc-minh290 {
    min-height: 290px !important; }
  .pc-minh295 {
    min-height: 295px !important; }
  .pc-minh300 {
    min-height: 300px !important; }
  .pc-minh305 {
    min-height: 305px !important; }
  .pc-minh310 {
    min-height: 310px !important; }
  .pc-minh315 {
    min-height: 315px !important; }
  .pc-minh320 {
    min-height: 320px !important; }
  .pc-minh325 {
    min-height: 325px !important; }
  .pc-minh330 {
    min-height: 330px !important; }
  .pc-minh335 {
    min-height: 335px !important; }
  .pc-minh340 {
    min-height: 340px !important; }
  .pc-minh345 {
    min-height: 345px !important; }
  .pc-minh350 {
    min-height: 350px !important; }
  .pc-minh355 {
    min-height: 355px !important; }
  .pc-minh360 {
    min-height: 360px !important; }
  .pc-minh365 {
    min-height: 365px !important; }
  .pc-minh370 {
    min-height: 370px !important; }
  .pc-minh375 {
    min-height: 375px !important; }
  .pc-minh380 {
    min-height: 380px !important; }
  .pc-minh385 {
    min-height: 385px !important; }
  .pc-minh390 {
    min-height: 390px !important; }
  .pc-minh395 {
    min-height: 395px !important; }
  .pc-minh400 {
    min-height: 400px !important; }
  .pc-minh405 {
    min-height: 405px !important; }
  .pc-minh410 {
    min-height: 410px !important; }
  .pc-minh415 {
    min-height: 415px !important; }
  .pc-minh420 {
    min-height: 420px !important; }
  .pc-minh425 {
    min-height: 425px !important; }
  .pc-minh430 {
    min-height: 430px !important; }
  .pc-minh435 {
    min-height: 435px !important; }
  .pc-minh440 {
    min-height: 440px !important; }
  .pc-minh445 {
    min-height: 445px !important; }
  .pc-minh450 {
    min-height: 450px !important; }
  .pc-minh455 {
    min-height: 455px !important; }
  .pc-minh460 {
    min-height: 460px !important; }
  .pc-minh465 {
    min-height: 465px !important; }
  .pc-minh470 {
    min-height: 470px !important; }
  .pc-minh475 {
    min-height: 475px !important; }
  .pc-minh480 {
    min-height: 480px !important; }
  .pc-minh485 {
    min-height: 485px !important; }
  .pc-minh490 {
    min-height: 490px !important; }
  .pc-minh495 {
    min-height: 495px !important; }
  .pc-minh500 {
    min-height: 500px !important; }
  .pc-minh505 {
    min-height: 505px !important; }
  .pc-minh510 {
    min-height: 510px !important; }
  .pc-minh515 {
    min-height: 515px !important; }
  .pc-minh520 {
    min-height: 520px !important; }
  .pc-minh525 {
    min-height: 525px !important; }
  .pc-minh530 {
    min-height: 530px !important; }
  .pc-minh535 {
    min-height: 535px !important; }
  .pc-minh540 {
    min-height: 540px !important; }
  .pc-minh545 {
    min-height: 545px !important; }
  .pc-minh550 {
    min-height: 550px !important; }
  .pc-minh555 {
    min-height: 555px !important; }
  .pc-minh560 {
    min-height: 560px !important; }
  .pc-minh565 {
    min-height: 565px !important; }
  .pc-minh570 {
    min-height: 570px !important; }
  .pc-minh575 {
    min-height: 575px !important; }
  .pc-minh580 {
    min-height: 580px !important; }
  .pc-minh585 {
    min-height: 585px !important; }
  .pc-minh590 {
    min-height: 590px !important; }
  .pc-minh595 {
    min-height: 595px !important; }
  .pc-minh600 {
    min-height: 600px !important; }
  .pc-minh605 {
    min-height: 605px !important; }
  .pc-minh610 {
    min-height: 610px !important; }
  .pc-minh615 {
    min-height: 615px !important; }
  .pc-minh620 {
    min-height: 620px !important; }
  .pc-minh625 {
    min-height: 625px !important; }
  .pc-minh630 {
    min-height: 630px !important; }
  .pc-minh635 {
    min-height: 635px !important; }
  .pc-minh640 {
    min-height: 640px !important; }
  .pc-minh645 {
    min-height: 645px !important; }
  .pc-minh650 {
    min-height: 650px !important; }
  .pc-minh655 {
    min-height: 655px !important; }
  .pc-minh660 {
    min-height: 660px !important; }
  .pc-minh665 {
    min-height: 665px !important; }
  .pc-minh670 {
    min-height: 670px !important; }
  .pc-minh675 {
    min-height: 675px !important; }
  .pc-minh680 {
    min-height: 680px !important; }
  .pc-minh685 {
    min-height: 685px !important; }
  .pc-minh690 {
    min-height: 690px !important; }
  .pc-minh695 {
    min-height: 695px !important; }
  .pc-minh700 {
    min-height: 700px !important; }
  .pc-maxw5 {
    max-width: 5px !important; }
  .pc-maxw10 {
    max-width: 10px !important; }
  .pc-maxw15 {
    max-width: 15px !important; }
  .pc-maxw20 {
    max-width: 20px !important; }
  .pc-maxw25 {
    max-width: 25px !important; }
  .pc-maxw30 {
    max-width: 30px !important; }
  .pc-maxw35 {
    max-width: 35px !important; }
  .pc-maxw40 {
    max-width: 40px !important; }
  .pc-maxw45 {
    max-width: 45px !important; }
  .pc-maxw50 {
    max-width: 50px !important; }
  .pc-maxw55 {
    max-width: 55px !important; }
  .pc-maxw60 {
    max-width: 60px !important; }
  .pc-maxw65 {
    max-width: 65px !important; }
  .pc-maxw70 {
    max-width: 70px !important; }
  .pc-maxw75 {
    max-width: 75px !important; }
  .pc-maxw80 {
    max-width: 80px !important; }
  .pc-maxw85 {
    max-width: 85px !important; }
  .pc-maxw90 {
    max-width: 90px !important; }
  .pc-maxw95 {
    max-width: 95px !important; }
  .pc-maxw100 {
    max-width: 100px !important; }
  .pc-maxw105 {
    max-width: 105px !important; }
  .pc-maxw110 {
    max-width: 110px !important; }
  .pc-maxw115 {
    max-width: 115px !important; }
  .pc-maxw120 {
    max-width: 120px !important; }
  .pc-maxw125 {
    max-width: 125px !important; }
  .pc-maxw130 {
    max-width: 130px !important; }
  .pc-maxw135 {
    max-width: 135px !important; }
  .pc-maxw140 {
    max-width: 140px !important; }
  .pc-maxw145 {
    max-width: 145px !important; }
  .pc-maxw150 {
    max-width: 150px !important; }
  .pc-maxw155 {
    max-width: 155px !important; }
  .pc-maxw160 {
    max-width: 160px !important; }
  .pc-maxw165 {
    max-width: 165px !important; }
  .pc-maxw170 {
    max-width: 170px !important; }
  .pc-maxw175 {
    max-width: 175px !important; }
  .pc-maxw180 {
    max-width: 180px !important; }
  .pc-maxw185 {
    max-width: 185px !important; }
  .pc-maxw190 {
    max-width: 190px !important; }
  .pc-maxw195 {
    max-width: 195px !important; }
  .pc-maxw200 {
    max-width: 200px !important; }
  .pc-maxw205 {
    max-width: 205px !important; }
  .pc-maxw210 {
    max-width: 210px !important; }
  .pc-maxw215 {
    max-width: 215px !important; }
  .pc-maxw220 {
    max-width: 220px !important; }
  .pc-maxw225 {
    max-width: 225px !important; }
  .pc-maxw230 {
    max-width: 230px !important; }
  .pc-maxw235 {
    max-width: 235px !important; }
  .pc-maxw240 {
    max-width: 240px !important; }
  .pc-maxw245 {
    max-width: 245px !important; }
  .pc-maxw250 {
    max-width: 250px !important; }
  .pc-maxw255 {
    max-width: 255px !important; }
  .pc-maxw260 {
    max-width: 260px !important; }
  .pc-maxw265 {
    max-width: 265px !important; }
  .pc-maxw270 {
    max-width: 270px !important; }
  .pc-maxw275 {
    max-width: 275px !important; }
  .pc-maxw280 {
    max-width: 280px !important; }
  .pc-maxw285 {
    max-width: 285px !important; }
  .pc-maxw290 {
    max-width: 290px !important; }
  .pc-maxw295 {
    max-width: 295px !important; }
  .pc-maxw300 {
    max-width: 300px !important; }
  .pc-maxw305 {
    max-width: 305px !important; }
  .pc-maxw310 {
    max-width: 310px !important; }
  .pc-maxw315 {
    max-width: 315px !important; }
  .pc-maxw320 {
    max-width: 320px !important; }
  .pc-maxw325 {
    max-width: 325px !important; }
  .pc-maxw330 {
    max-width: 330px !important; }
  .pc-maxw335 {
    max-width: 335px !important; }
  .pc-maxw340 {
    max-width: 340px !important; }
  .pc-maxw345 {
    max-width: 345px !important; }
  .pc-maxw350 {
    max-width: 350px !important; }
  .pc-maxw355 {
    max-width: 355px !important; }
  .pc-maxw360 {
    max-width: 360px !important; }
  .pc-maxw365 {
    max-width: 365px !important; }
  .pc-maxw370 {
    max-width: 370px !important; }
  .pc-maxw375 {
    max-width: 375px !important; }
  .pc-maxw380 {
    max-width: 380px !important; }
  .pc-maxw385 {
    max-width: 385px !important; }
  .pc-maxw390 {
    max-width: 390px !important; }
  .pc-maxw395 {
    max-width: 395px !important; }
  .pc-maxw400 {
    max-width: 400px !important; }
  .pc-maxw405 {
    max-width: 405px !important; }
  .pc-maxw410 {
    max-width: 410px !important; }
  .pc-maxw415 {
    max-width: 415px !important; }
  .pc-maxw420 {
    max-width: 420px !important; }
  .pc-maxw425 {
    max-width: 425px !important; }
  .pc-maxw430 {
    max-width: 430px !important; }
  .pc-maxw435 {
    max-width: 435px !important; }
  .pc-maxw440 {
    max-width: 440px !important; }
  .pc-maxw445 {
    max-width: 445px !important; }
  .pc-maxw450 {
    max-width: 450px !important; }
  .pc-maxw455 {
    max-width: 455px !important; }
  .pc-maxw460 {
    max-width: 460px !important; }
  .pc-maxw465 {
    max-width: 465px !important; }
  .pc-maxw470 {
    max-width: 470px !important; }
  .pc-maxw475 {
    max-width: 475px !important; }
  .pc-maxw480 {
    max-width: 480px !important; }
  .pc-maxw485 {
    max-width: 485px !important; }
  .pc-maxw490 {
    max-width: 490px !important; }
  .pc-maxw495 {
    max-width: 495px !important; }
  .pc-maxw500 {
    max-width: 500px !important; }
  .pc-maxw505 {
    max-width: 505px !important; }
  .pc-maxw510 {
    max-width: 510px !important; }
  .pc-maxw515 {
    max-width: 515px !important; }
  .pc-maxw520 {
    max-width: 520px !important; }
  .pc-maxw525 {
    max-width: 525px !important; }
  .pc-maxw530 {
    max-width: 530px !important; }
  .pc-maxw535 {
    max-width: 535px !important; }
  .pc-maxw540 {
    max-width: 540px !important; }
  .pc-maxw545 {
    max-width: 545px !important; }
  .pc-maxw550 {
    max-width: 550px !important; }
  .pc-maxw555 {
    max-width: 555px !important; }
  .pc-maxw560 {
    max-width: 560px !important; }
  .pc-maxw565 {
    max-width: 565px !important; }
  .pc-maxw570 {
    max-width: 570px !important; }
  .pc-maxw575 {
    max-width: 575px !important; }
  .pc-maxw580 {
    max-width: 580px !important; }
  .pc-maxw585 {
    max-width: 585px !important; }
  .pc-maxw590 {
    max-width: 590px !important; }
  .pc-maxw595 {
    max-width: 595px !important; }
  .pc-maxw600 {
    max-width: 600px !important; }
  .pc-maxw605 {
    max-width: 605px !important; }
  .pc-maxw610 {
    max-width: 610px !important; }
  .pc-maxw615 {
    max-width: 615px !important; }
  .pc-maxw620 {
    max-width: 620px !important; }
  .pc-maxw625 {
    max-width: 625px !important; }
  .pc-maxw630 {
    max-width: 630px !important; }
  .pc-maxw635 {
    max-width: 635px !important; }
  .pc-maxw640 {
    max-width: 640px !important; }
  .pc-maxw645 {
    max-width: 645px !important; }
  .pc-maxw650 {
    max-width: 650px !important; }
  .pc-maxw655 {
    max-width: 655px !important; }
  .pc-maxw660 {
    max-width: 660px !important; }
  .pc-maxw665 {
    max-width: 665px !important; }
  .pc-maxw670 {
    max-width: 670px !important; }
  .pc-maxw675 {
    max-width: 675px !important; }
  .pc-maxw680 {
    max-width: 680px !important; }
  .pc-maxw685 {
    max-width: 685px !important; }
  .pc-maxw690 {
    max-width: 690px !important; }
  .pc-maxw695 {
    max-width: 695px !important; }
  .pc-maxw700 {
    max-width: 700px !important; }
  .pc-maxh5 {
    max-height: 5px !important; }
  .pc-maxh10 {
    max-height: 10px !important; }
  .pc-maxh15 {
    max-height: 15px !important; }
  .pc-maxh20 {
    max-height: 20px !important; }
  .pc-maxh25 {
    max-height: 25px !important; }
  .pc-maxh30 {
    max-height: 30px !important; }
  .pc-maxh35 {
    max-height: 35px !important; }
  .pc-maxh40 {
    max-height: 40px !important; }
  .pc-maxh45 {
    max-height: 45px !important; }
  .pc-maxh50 {
    max-height: 50px !important; }
  .pc-maxh55 {
    max-height: 55px !important; }
  .pc-maxh60 {
    max-height: 60px !important; }
  .pc-maxh65 {
    max-height: 65px !important; }
  .pc-maxh70 {
    max-height: 70px !important; }
  .pc-maxh75 {
    max-height: 75px !important; }
  .pc-maxh80 {
    max-height: 80px !important; }
  .pc-maxh85 {
    max-height: 85px !important; }
  .pc-maxh90 {
    max-height: 90px !important; }
  .pc-maxh95 {
    max-height: 95px !important; }
  .pc-maxh100 {
    max-height: 100px !important; }
  .pc-maxh105 {
    max-height: 105px !important; }
  .pc-maxh110 {
    max-height: 110px !important; }
  .pc-maxh115 {
    max-height: 115px !important; }
  .pc-maxh120 {
    max-height: 120px !important; }
  .pc-maxh125 {
    max-height: 125px !important; }
  .pc-maxh130 {
    max-height: 130px !important; }
  .pc-maxh135 {
    max-height: 135px !important; }
  .pc-maxh140 {
    max-height: 140px !important; }
  .pc-maxh145 {
    max-height: 145px !important; }
  .pc-maxh150 {
    max-height: 150px !important; }
  .pc-maxh155 {
    max-height: 155px !important; }
  .pc-maxh160 {
    max-height: 160px !important; }
  .pc-maxh165 {
    max-height: 165px !important; }
  .pc-maxh170 {
    max-height: 170px !important; }
  .pc-maxh175 {
    max-height: 175px !important; }
  .pc-maxh180 {
    max-height: 180px !important; }
  .pc-maxh185 {
    max-height: 185px !important; }
  .pc-maxh190 {
    max-height: 190px !important; }
  .pc-maxh195 {
    max-height: 195px !important; }
  .pc-maxh200 {
    max-height: 200px !important; }
  .pc-maxh205 {
    max-height: 205px !important; }
  .pc-maxh210 {
    max-height: 210px !important; }
  .pc-maxh215 {
    max-height: 215px !important; }
  .pc-maxh220 {
    max-height: 220px !important; }
  .pc-maxh225 {
    max-height: 225px !important; }
  .pc-maxh230 {
    max-height: 230px !important; }
  .pc-maxh235 {
    max-height: 235px !important; }
  .pc-maxh240 {
    max-height: 240px !important; }
  .pc-maxh245 {
    max-height: 245px !important; }
  .pc-maxh250 {
    max-height: 250px !important; }
  .pc-maxh255 {
    max-height: 255px !important; }
  .pc-maxh260 {
    max-height: 260px !important; }
  .pc-maxh265 {
    max-height: 265px !important; }
  .pc-maxh270 {
    max-height: 270px !important; }
  .pc-maxh275 {
    max-height: 275px !important; }
  .pc-maxh280 {
    max-height: 280px !important; }
  .pc-maxh285 {
    max-height: 285px !important; }
  .pc-maxh290 {
    max-height: 290px !important; }
  .pc-maxh295 {
    max-height: 295px !important; }
  .pc-maxh300 {
    max-height: 300px !important; }
  .pc-maxh305 {
    max-height: 305px !important; }
  .pc-maxh310 {
    max-height: 310px !important; }
  .pc-maxh315 {
    max-height: 315px !important; }
  .pc-maxh320 {
    max-height: 320px !important; }
  .pc-maxh325 {
    max-height: 325px !important; }
  .pc-maxh330 {
    max-height: 330px !important; }
  .pc-maxh335 {
    max-height: 335px !important; }
  .pc-maxh340 {
    max-height: 340px !important; }
  .pc-maxh345 {
    max-height: 345px !important; }
  .pc-maxh350 {
    max-height: 350px !important; }
  .pc-maxh355 {
    max-height: 355px !important; }
  .pc-maxh360 {
    max-height: 360px !important; }
  .pc-maxh365 {
    max-height: 365px !important; }
  .pc-maxh370 {
    max-height: 370px !important; }
  .pc-maxh375 {
    max-height: 375px !important; }
  .pc-maxh380 {
    max-height: 380px !important; }
  .pc-maxh385 {
    max-height: 385px !important; }
  .pc-maxh390 {
    max-height: 390px !important; }
  .pc-maxh395 {
    max-height: 395px !important; }
  .pc-maxh400 {
    max-height: 400px !important; }
  .pc-maxh405 {
    max-height: 405px !important; }
  .pc-maxh410 {
    max-height: 410px !important; }
  .pc-maxh415 {
    max-height: 415px !important; }
  .pc-maxh420 {
    max-height: 420px !important; }
  .pc-maxh425 {
    max-height: 425px !important; }
  .pc-maxh430 {
    max-height: 430px !important; }
  .pc-maxh435 {
    max-height: 435px !important; }
  .pc-maxh440 {
    max-height: 440px !important; }
  .pc-maxh445 {
    max-height: 445px !important; }
  .pc-maxh450 {
    max-height: 450px !important; }
  .pc-maxh455 {
    max-height: 455px !important; }
  .pc-maxh460 {
    max-height: 460px !important; }
  .pc-maxh465 {
    max-height: 465px !important; }
  .pc-maxh470 {
    max-height: 470px !important; }
  .pc-maxh475 {
    max-height: 475px !important; }
  .pc-maxh480 {
    max-height: 480px !important; }
  .pc-maxh485 {
    max-height: 485px !important; }
  .pc-maxh490 {
    max-height: 490px !important; }
  .pc-maxh495 {
    max-height: 495px !important; }
  .pc-maxh500 {
    max-height: 500px !important; }
  .pc-maxh505 {
    max-height: 505px !important; }
  .pc-maxh510 {
    max-height: 510px !important; }
  .pc-maxh515 {
    max-height: 515px !important; }
  .pc-maxh520 {
    max-height: 520px !important; }
  .pc-maxh525 {
    max-height: 525px !important; }
  .pc-maxh530 {
    max-height: 530px !important; }
  .pc-maxh535 {
    max-height: 535px !important; }
  .pc-maxh540 {
    max-height: 540px !important; }
  .pc-maxh545 {
    max-height: 545px !important; }
  .pc-maxh550 {
    max-height: 550px !important; }
  .pc-maxh555 {
    max-height: 555px !important; }
  .pc-maxh560 {
    max-height: 560px !important; }
  .pc-maxh565 {
    max-height: 565px !important; }
  .pc-maxh570 {
    max-height: 570px !important; }
  .pc-maxh575 {
    max-height: 575px !important; }
  .pc-maxh580 {
    max-height: 580px !important; }
  .pc-maxh585 {
    max-height: 585px !important; }
  .pc-maxh590 {
    max-height: 590px !important; }
  .pc-maxh595 {
    max-height: 595px !important; }
  .pc-maxh600 {
    max-height: 600px !important; }
  .pc-maxh605 {
    max-height: 605px !important; }
  .pc-maxh610 {
    max-height: 610px !important; }
  .pc-maxh615 {
    max-height: 615px !important; }
  .pc-maxh620 {
    max-height: 620px !important; }
  .pc-maxh625 {
    max-height: 625px !important; }
  .pc-maxh630 {
    max-height: 630px !important; }
  .pc-maxh635 {
    max-height: 635px !important; }
  .pc-maxh640 {
    max-height: 640px !important; }
  .pc-maxh645 {
    max-height: 645px !important; }
  .pc-maxh650 {
    max-height: 650px !important; }
  .pc-maxh655 {
    max-height: 655px !important; }
  .pc-maxh660 {
    max-height: 660px !important; }
  .pc-maxh665 {
    max-height: 665px !important; }
  .pc-maxh670 {
    max-height: 670px !important; }
  .pc-maxh675 {
    max-height: 675px !important; }
  .pc-maxh680 {
    max-height: 680px !important; }
  .pc-maxh685 {
    max-height: 685px !important; }
  .pc-maxh690 {
    max-height: 690px !important; }
  .pc-maxh695 {
    max-height: 695px !important; }
  .pc-maxh700 {
    max-height: 700px !important; }
  .pc-fs10 {
    font-size: 1rem !important; }
  .pc-fs11 {
    font-size: 1.1rem !important; }
  .pc-fs12 {
    font-size: 1.2rem !important; }
  .pc-fs13 {
    font-size: 1.3rem !important; }
  .pc-fs14 {
    font-size: 1.4rem !important; }
  .pc-fs15 {
    font-size: 1.5rem !important; }
  .pc-fs16 {
    font-size: 1.6rem !important; }
  .pc-fs17 {
    font-size: 1.7rem !important; }
  .pc-fs18 {
    font-size: 1.8rem !important; }
  .pc-fs19 {
    font-size: 1.9rem !important; }
  .pc-fs20 {
    font-size: 2rem !important; }
  .pc-fs21 {
    font-size: 2.1rem !important; }
  .pc-fs22 {
    font-size: 2.2rem !important; }
  .pc-fs23 {
    font-size: 2.3rem !important; }
  .pc-fs24 {
    font-size: 2.4rem !important; }
  .pc-fs25 {
    font-size: 2.5rem !important; }
  .pc-fs26 {
    font-size: 2.6rem !important; }
  .pc-fs27 {
    font-size: 2.7rem !important; }
  .pc-fs28 {
    font-size: 2.8rem !important; }
  .pc-fs29 {
    font-size: 2.9rem !important; }
  .pc-fs30 {
    font-size: 3rem !important; }
  .pc-fs31 {
    font-size: 3.1rem !important; }
  .pc-fs32 {
    font-size: 3.2rem !important; }
  .pc-fs33 {
    font-size: 3.3rem !important; }
  .pc-fs34 {
    font-size: 3.4rem !important; }
  .pc-fs35 {
    font-size: 3.5rem !important; }
  .pc-fs36 {
    font-size: 3.6rem !important; }
  .pc-fs37 {
    font-size: 3.7rem !important; }
  .pc-fs38 {
    font-size: 3.8rem !important; }
  .pc-fs39 {
    font-size: 3.9rem !important; }
  .pc-fs40 {
    font-size: 4rem !important; }
  .pc-fs41 {
    font-size: 4.1rem !important; }
  .pc-fs42 {
    font-size: 4.2rem !important; }
  .pc-fs43 {
    font-size: 4.3rem !important; }
  .pc-fs44 {
    font-size: 4.4rem !important; }
  .pc-fs45 {
    font-size: 4.5rem !important; }
  .pc-fs46 {
    font-size: 4.6rem !important; }
  .pc-fs47 {
    font-size: 4.7rem !important; }
  .pc-fs48 {
    font-size: 4.8rem !important; }
  .pc-fs49 {
    font-size: 4.9rem !important; }
  .pc-fs50 {
    font-size: 5rem !important; }
  .pc-ta-left {
    text-align: left !important; }
  .pc-ta-center {
    text-align: center !important; }
  .pc-ta-right {
    text-align: right !important; }
  .pc-va-top {
    vertical-align: top !important; }
  .pc-va-middle {
    vertical-align: middle !important; }
  .pc-va-bottom {
    vertical-align: bottom !important; } }

@media (max-width: 768.98px) {
  .sp-mt-10 {
    margin-top: -10px !important; }
  .sp-pt-10 {
    padding-top: -10px !important; }
  .sp-mt-5 {
    margin-top: -5px !important; }
  .sp-pt-5 {
    padding-top: -5px !important; }
  .sp-mt0 {
    margin-top: 0px !important; }
  .sp-pt0 {
    padding-top: 0px !important; }
  .sp-mt5 {
    margin-top: 5px !important; }
  .sp-pt5 {
    padding-top: 5px !important; }
  .sp-mt10 {
    margin-top: 10px !important; }
  .sp-pt10 {
    padding-top: 10px !important; }
  .sp-mt15 {
    margin-top: 15px !important; }
  .sp-pt15 {
    padding-top: 15px !important; }
  .sp-mt20 {
    margin-top: 20px !important; }
  .sp-pt20 {
    padding-top: 20px !important; }
  .sp-mt25 {
    margin-top: 25px !important; }
  .sp-pt25 {
    padding-top: 25px !important; }
  .sp-mt30 {
    margin-top: 30px !important; }
  .sp-pt30 {
    padding-top: 30px !important; }
  .sp-mt35 {
    margin-top: 35px !important; }
  .sp-pt35 {
    padding-top: 35px !important; }
  .sp-mt40 {
    margin-top: 40px !important; }
  .sp-pt40 {
    padding-top: 40px !important; }
  .sp-mt45 {
    margin-top: 45px !important; }
  .sp-pt45 {
    padding-top: 45px !important; }
  .sp-mt50 {
    margin-top: 50px !important; }
  .sp-pt50 {
    padding-top: 50px !important; }
  .sp-mt55 {
    margin-top: 55px !important; }
  .sp-pt55 {
    padding-top: 55px !important; }
  .sp-mt60 {
    margin-top: 60px !important; }
  .sp-pt60 {
    padding-top: 60px !important; }
  .sp-mt65 {
    margin-top: 65px !important; }
  .sp-pt65 {
    padding-top: 65px !important; }
  .sp-mt70 {
    margin-top: 70px !important; }
  .sp-pt70 {
    padding-top: 70px !important; }
  .sp-mt75 {
    margin-top: 75px !important; }
  .sp-pt75 {
    padding-top: 75px !important; }
  .sp-mt80 {
    margin-top: 80px !important; }
  .sp-pt80 {
    padding-top: 80px !important; }
  .sp-mt-auto {
    margin-top: auto !important; }
  .sp-mb-10 {
    margin-bottom: -10px !important; }
  .sp-pb-10 {
    padding-bottom: -10px !important; }
  .sp-mb-5 {
    margin-bottom: -5px !important; }
  .sp-pb-5 {
    padding-bottom: -5px !important; }
  .sp-mb0 {
    margin-bottom: 0px !important; }
  .sp-pb0 {
    padding-bottom: 0px !important; }
  .sp-mb5 {
    margin-bottom: 5px !important; }
  .sp-pb5 {
    padding-bottom: 5px !important; }
  .sp-mb10 {
    margin-bottom: 10px !important; }
  .sp-pb10 {
    padding-bottom: 10px !important; }
  .sp-mb15 {
    margin-bottom: 15px !important; }
  .sp-pb15 {
    padding-bottom: 15px !important; }
  .sp-mb20 {
    margin-bottom: 20px !important; }
  .sp-pb20 {
    padding-bottom: 20px !important; }
  .sp-mb25 {
    margin-bottom: 25px !important; }
  .sp-pb25 {
    padding-bottom: 25px !important; }
  .sp-mb30 {
    margin-bottom: 30px !important; }
  .sp-pb30 {
    padding-bottom: 30px !important; }
  .sp-mb35 {
    margin-bottom: 35px !important; }
  .sp-pb35 {
    padding-bottom: 35px !important; }
  .sp-mb40 {
    margin-bottom: 40px !important; }
  .sp-pb40 {
    padding-bottom: 40px !important; }
  .sp-mb45 {
    margin-bottom: 45px !important; }
  .sp-pb45 {
    padding-bottom: 45px !important; }
  .sp-mb50 {
    margin-bottom: 50px !important; }
  .sp-pb50 {
    padding-bottom: 50px !important; }
  .sp-mb55 {
    margin-bottom: 55px !important; }
  .sp-pb55 {
    padding-bottom: 55px !important; }
  .sp-mb60 {
    margin-bottom: 60px !important; }
  .sp-pb60 {
    padding-bottom: 60px !important; }
  .sp-mb65 {
    margin-bottom: 65px !important; }
  .sp-pb65 {
    padding-bottom: 65px !important; }
  .sp-mb70 {
    margin-bottom: 70px !important; }
  .sp-pb70 {
    padding-bottom: 70px !important; }
  .sp-mb75 {
    margin-bottom: 75px !important; }
  .sp-pb75 {
    padding-bottom: 75px !important; }
  .sp-mb80 {
    margin-bottom: 80px !important; }
  .sp-pb80 {
    padding-bottom: 80px !important; }
  .sp-mb-auto {
    margin-bottom: auto !important; }
  .sp-ml-10 {
    margin-left: -10px !important; }
  .sp-pl-10 {
    padding-left: -10px !important; }
  .sp-ml-5 {
    margin-left: -5px !important; }
  .sp-pl-5 {
    padding-left: -5px !important; }
  .sp-ml0 {
    margin-left: 0px !important; }
  .sp-pl0 {
    padding-left: 0px !important; }
  .sp-ml5 {
    margin-left: 5px !important; }
  .sp-pl5 {
    padding-left: 5px !important; }
  .sp-ml10 {
    margin-left: 10px !important; }
  .sp-pl10 {
    padding-left: 10px !important; }
  .sp-ml15 {
    margin-left: 15px !important; }
  .sp-pl15 {
    padding-left: 15px !important; }
  .sp-ml20 {
    margin-left: 20px !important; }
  .sp-pl20 {
    padding-left: 20px !important; }
  .sp-ml25 {
    margin-left: 25px !important; }
  .sp-pl25 {
    padding-left: 25px !important; }
  .sp-ml30 {
    margin-left: 30px !important; }
  .sp-pl30 {
    padding-left: 30px !important; }
  .sp-ml35 {
    margin-left: 35px !important; }
  .sp-pl35 {
    padding-left: 35px !important; }
  .sp-ml40 {
    margin-left: 40px !important; }
  .sp-pl40 {
    padding-left: 40px !important; }
  .sp-ml45 {
    margin-left: 45px !important; }
  .sp-pl45 {
    padding-left: 45px !important; }
  .sp-ml50 {
    margin-left: 50px !important; }
  .sp-pl50 {
    padding-left: 50px !important; }
  .sp-ml55 {
    margin-left: 55px !important; }
  .sp-pl55 {
    padding-left: 55px !important; }
  .sp-ml60 {
    margin-left: 60px !important; }
  .sp-pl60 {
    padding-left: 60px !important; }
  .sp-ml65 {
    margin-left: 65px !important; }
  .sp-pl65 {
    padding-left: 65px !important; }
  .sp-ml70 {
    margin-left: 70px !important; }
  .sp-pl70 {
    padding-left: 70px !important; }
  .sp-ml75 {
    margin-left: 75px !important; }
  .sp-pl75 {
    padding-left: 75px !important; }
  .sp-ml80 {
    margin-left: 80px !important; }
  .sp-pl80 {
    padding-left: 80px !important; }
  .sp-ml-auto {
    margin-left: auto !important; }
  .sp-mr-10 {
    margin-right: -10px !important; }
  .sp-pr-10 {
    padding-right: -10px !important; }
  .sp-mr-5 {
    margin-right: -5px !important; }
  .sp-pr-5 {
    padding-right: -5px !important; }
  .sp-mr0 {
    margin-right: 0px !important; }
  .sp-pr0 {
    padding-right: 0px !important; }
  .sp-mr5 {
    margin-right: 5px !important; }
  .sp-pr5 {
    padding-right: 5px !important; }
  .sp-mr10 {
    margin-right: 10px !important; }
  .sp-pr10 {
    padding-right: 10px !important; }
  .sp-mr15 {
    margin-right: 15px !important; }
  .sp-pr15 {
    padding-right: 15px !important; }
  .sp-mr20 {
    margin-right: 20px !important; }
  .sp-pr20 {
    padding-right: 20px !important; }
  .sp-mr25 {
    margin-right: 25px !important; }
  .sp-pr25 {
    padding-right: 25px !important; }
  .sp-mr30 {
    margin-right: 30px !important; }
  .sp-pr30 {
    padding-right: 30px !important; }
  .sp-mr35 {
    margin-right: 35px !important; }
  .sp-pr35 {
    padding-right: 35px !important; }
  .sp-mr40 {
    margin-right: 40px !important; }
  .sp-pr40 {
    padding-right: 40px !important; }
  .sp-mr45 {
    margin-right: 45px !important; }
  .sp-pr45 {
    padding-right: 45px !important; }
  .sp-mr50 {
    margin-right: 50px !important; }
  .sp-pr50 {
    padding-right: 50px !important; }
  .sp-mr55 {
    margin-right: 55px !important; }
  .sp-pr55 {
    padding-right: 55px !important; }
  .sp-mr60 {
    margin-right: 60px !important; }
  .sp-pr60 {
    padding-right: 60px !important; }
  .sp-mr65 {
    margin-right: 65px !important; }
  .sp-pr65 {
    padding-right: 65px !important; }
  .sp-mr70 {
    margin-right: 70px !important; }
  .sp-pr70 {
    padding-right: 70px !important; }
  .sp-mr75 {
    margin-right: 75px !important; }
  .sp-pr75 {
    padding-right: 75px !important; }
  .sp-mr80 {
    margin-right: 80px !important; }
  .sp-pr80 {
    padding-right: 80px !important; }
  .sp-mr-auto {
    margin-right: auto !important; }
  .sp-mh-10 {
    margin-left: -10px !important;
    margin-right: -10px !important; }
  .sp-ph-10 {
    padding-left: -10px !important;
    padding-right: -10px !important; }
  .sp-mh-5 {
    margin-left: -5px !important;
    margin-right: -5px !important; }
  .sp-ph-5 {
    padding-left: -5px !important;
    padding-right: -5px !important; }
  .sp-mh0 {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .sp-ph0 {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .sp-mh5 {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sp-ph5 {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sp-mh10 {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .sp-ph10 {
    padding-left: 10px !important;
    padding-right: 10px !important; }
  .sp-mh15 {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sp-ph15 {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sp-mh20 {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .sp-ph20 {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .sp-mh25 {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .sp-ph25 {
    padding-left: 25px !important;
    padding-right: 25px !important; }
  .sp-mh30 {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sp-ph30 {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sp-mh35 {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .sp-ph35 {
    padding-left: 35px !important;
    padding-right: 35px !important; }
  .sp-mh40 {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .sp-ph40 {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .sp-mh45 {
    margin-left: 45px !important;
    margin-right: 45px !important; }
  .sp-ph45 {
    padding-left: 45px !important;
    padding-right: 45px !important; }
  .sp-mh50 {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sp-ph50 {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sp-mh55 {
    margin-left: 55px !important;
    margin-right: 55px !important; }
  .sp-ph55 {
    padding-left: 55px !important;
    padding-right: 55px !important; }
  .sp-mh60 {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .sp-ph60 {
    padding-left: 60px !important;
    padding-right: 60px !important; }
  .sp-mh65 {
    margin-left: 65px !important;
    margin-right: 65px !important; }
  .sp-ph65 {
    padding-left: 65px !important;
    padding-right: 65px !important; }
  .sp-mh70 {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sp-ph70 {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sp-mh75 {
    margin-left: 75px !important;
    margin-right: 75px !important; }
  .sp-ph75 {
    padding-left: 75px !important;
    padding-right: 75px !important; }
  .sp-mh80 {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .sp-ph80 {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .sp-mh-auto {
    margin-horizontal: auto !important; }
  .sp-mv-10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .sp-pv-10 {
    padding-top: -10px !important;
    padding-bottom: -10px !important; }
  .sp-mv-5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .sp-pv-5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important; }
  .sp-mv0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .sp-pv0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .sp-mv5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sp-pv5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sp-mv10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .sp-pv10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .sp-mv15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sp-pv15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sp-mv20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .sp-pv20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .sp-mv25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .sp-pv25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .sp-mv30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sp-pv30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sp-mv35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .sp-pv35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .sp-mv40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .sp-pv40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .sp-mv45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important; }
  .sp-pv45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important; }
  .sp-mv50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sp-pv50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sp-mv55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important; }
  .sp-pv55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important; }
  .sp-mv60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .sp-pv60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .sp-mv65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important; }
  .sp-pv65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important; }
  .sp-mv70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sp-pv70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sp-mv75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important; }
  .sp-pv75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important; }
  .sp-mv80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .sp-pv80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .sp-mv-auto {
    margin-vertical: auto !important; }
  .sp-w5 {
    width: 5px !important; }
  .sp-w10 {
    width: 10px !important; }
  .sp-w15 {
    width: 15px !important; }
  .sp-w20 {
    width: 20px !important; }
  .sp-w25 {
    width: 25px !important; }
  .sp-w30 {
    width: 30px !important; }
  .sp-w35 {
    width: 35px !important; }
  .sp-w40 {
    width: 40px !important; }
  .sp-w45 {
    width: 45px !important; }
  .sp-w50 {
    width: 50px !important; }
  .sp-w55 {
    width: 55px !important; }
  .sp-w60 {
    width: 60px !important; }
  .sp-w65 {
    width: 65px !important; }
  .sp-w70 {
    width: 70px !important; }
  .sp-w75 {
    width: 75px !important; }
  .sp-w80 {
    width: 80px !important; }
  .sp-w85 {
    width: 85px !important; }
  .sp-w90 {
    width: 90px !important; }
  .sp-w95 {
    width: 95px !important; }
  .sp-w100 {
    width: 100px !important; }
  .sp-w105 {
    width: 105px !important; }
  .sp-w110 {
    width: 110px !important; }
  .sp-w115 {
    width: 115px !important; }
  .sp-w120 {
    width: 120px !important; }
  .sp-w125 {
    width: 125px !important; }
  .sp-w130 {
    width: 130px !important; }
  .sp-w135 {
    width: 135px !important; }
  .sp-w140 {
    width: 140px !important; }
  .sp-w145 {
    width: 145px !important; }
  .sp-w150 {
    width: 150px !important; }
  .sp-w155 {
    width: 155px !important; }
  .sp-w160 {
    width: 160px !important; }
  .sp-w165 {
    width: 165px !important; }
  .sp-w170 {
    width: 170px !important; }
  .sp-w175 {
    width: 175px !important; }
  .sp-w180 {
    width: 180px !important; }
  .sp-w185 {
    width: 185px !important; }
  .sp-w190 {
    width: 190px !important; }
  .sp-w195 {
    width: 195px !important; }
  .sp-w200 {
    width: 200px !important; }
  .sp-w205 {
    width: 205px !important; }
  .sp-w210 {
    width: 210px !important; }
  .sp-w215 {
    width: 215px !important; }
  .sp-w220 {
    width: 220px !important; }
  .sp-w225 {
    width: 225px !important; }
  .sp-w230 {
    width: 230px !important; }
  .sp-w235 {
    width: 235px !important; }
  .sp-w240 {
    width: 240px !important; }
  .sp-w245 {
    width: 245px !important; }
  .sp-w250 {
    width: 250px !important; }
  .sp-w255 {
    width: 255px !important; }
  .sp-w260 {
    width: 260px !important; }
  .sp-w265 {
    width: 265px !important; }
  .sp-w270 {
    width: 270px !important; }
  .sp-w275 {
    width: 275px !important; }
  .sp-w280 {
    width: 280px !important; }
  .sp-w285 {
    width: 285px !important; }
  .sp-w290 {
    width: 290px !important; }
  .sp-w295 {
    width: 295px !important; }
  .sp-w300 {
    width: 300px !important; }
  .sp-w305 {
    width: 305px !important; }
  .sp-w310 {
    width: 310px !important; }
  .sp-w315 {
    width: 315px !important; }
  .sp-w320 {
    width: 320px !important; }
  .sp-w325 {
    width: 325px !important; }
  .sp-w330 {
    width: 330px !important; }
  .sp-w335 {
    width: 335px !important; }
  .sp-w340 {
    width: 340px !important; }
  .sp-w345 {
    width: 345px !important; }
  .sp-w350 {
    width: 350px !important; }
  .sp-w355 {
    width: 355px !important; }
  .sp-w360 {
    width: 360px !important; }
  .sp-w365 {
    width: 365px !important; }
  .sp-w370 {
    width: 370px !important; }
  .sp-w375 {
    width: 375px !important; }
  .sp-w380 {
    width: 380px !important; }
  .sp-w385 {
    width: 385px !important; }
  .sp-w390 {
    width: 390px !important; }
  .sp-w395 {
    width: 395px !important; }
  .sp-w400 {
    width: 400px !important; }
  .sp-w405 {
    width: 405px !important; }
  .sp-w410 {
    width: 410px !important; }
  .sp-w415 {
    width: 415px !important; }
  .sp-w420 {
    width: 420px !important; }
  .sp-w425 {
    width: 425px !important; }
  .sp-w430 {
    width: 430px !important; }
  .sp-w435 {
    width: 435px !important; }
  .sp-w440 {
    width: 440px !important; }
  .sp-w445 {
    width: 445px !important; }
  .sp-w450 {
    width: 450px !important; }
  .sp-w455 {
    width: 455px !important; }
  .sp-w460 {
    width: 460px !important; }
  .sp-w465 {
    width: 465px !important; }
  .sp-w470 {
    width: 470px !important; }
  .sp-w475 {
    width: 475px !important; }
  .sp-w480 {
    width: 480px !important; }
  .sp-w485 {
    width: 485px !important; }
  .sp-w490 {
    width: 490px !important; }
  .sp-w495 {
    width: 495px !important; }
  .sp-w500 {
    width: 500px !important; }
  .sp-w505 {
    width: 505px !important; }
  .sp-w510 {
    width: 510px !important; }
  .sp-w515 {
    width: 515px !important; }
  .sp-w520 {
    width: 520px !important; }
  .sp-w525 {
    width: 525px !important; }
  .sp-w530 {
    width: 530px !important; }
  .sp-w535 {
    width: 535px !important; }
  .sp-w540 {
    width: 540px !important; }
  .sp-w545 {
    width: 545px !important; }
  .sp-w550 {
    width: 550px !important; }
  .sp-w555 {
    width: 555px !important; }
  .sp-w560 {
    width: 560px !important; }
  .sp-w565 {
    width: 565px !important; }
  .sp-w570 {
    width: 570px !important; }
  .sp-w575 {
    width: 575px !important; }
  .sp-w580 {
    width: 580px !important; }
  .sp-w585 {
    width: 585px !important; }
  .sp-w590 {
    width: 590px !important; }
  .sp-w595 {
    width: 595px !important; }
  .sp-w600 {
    width: 600px !important; }
  .sp-w605 {
    width: 605px !important; }
  .sp-w610 {
    width: 610px !important; }
  .sp-w615 {
    width: 615px !important; }
  .sp-w620 {
    width: 620px !important; }
  .sp-w625 {
    width: 625px !important; }
  .sp-w630 {
    width: 630px !important; }
  .sp-w635 {
    width: 635px !important; }
  .sp-w640 {
    width: 640px !important; }
  .sp-w645 {
    width: 645px !important; }
  .sp-w650 {
    width: 650px !important; }
  .sp-w655 {
    width: 655px !important; }
  .sp-w660 {
    width: 660px !important; }
  .sp-w665 {
    width: 665px !important; }
  .sp-w670 {
    width: 670px !important; }
  .sp-w675 {
    width: 675px !important; }
  .sp-w680 {
    width: 680px !important; }
  .sp-w685 {
    width: 685px !important; }
  .sp-w690 {
    width: 690px !important; }
  .sp-w695 {
    width: 695px !important; }
  .sp-w700 {
    width: 700px !important; }
  .sp-h5 {
    height: 5px !important; }
  .sp-h10 {
    height: 10px !important; }
  .sp-h15 {
    height: 15px !important; }
  .sp-h20 {
    height: 20px !important; }
  .sp-h25 {
    height: 25px !important; }
  .sp-h30 {
    height: 30px !important; }
  .sp-h35 {
    height: 35px !important; }
  .sp-h40 {
    height: 40px !important; }
  .sp-h45 {
    height: 45px !important; }
  .sp-h50 {
    height: 50px !important; }
  .sp-h55 {
    height: 55px !important; }
  .sp-h60 {
    height: 60px !important; }
  .sp-h65 {
    height: 65px !important; }
  .sp-h70 {
    height: 70px !important; }
  .sp-h75 {
    height: 75px !important; }
  .sp-h80 {
    height: 80px !important; }
  .sp-h85 {
    height: 85px !important; }
  .sp-h90 {
    height: 90px !important; }
  .sp-h95 {
    height: 95px !important; }
  .sp-h100 {
    height: 100px !important; }
  .sp-h105 {
    height: 105px !important; }
  .sp-h110 {
    height: 110px !important; }
  .sp-h115 {
    height: 115px !important; }
  .sp-h120 {
    height: 120px !important; }
  .sp-h125 {
    height: 125px !important; }
  .sp-h130 {
    height: 130px !important; }
  .sp-h135 {
    height: 135px !important; }
  .sp-h140 {
    height: 140px !important; }
  .sp-h145 {
    height: 145px !important; }
  .sp-h150 {
    height: 150px !important; }
  .sp-h155 {
    height: 155px !important; }
  .sp-h160 {
    height: 160px !important; }
  .sp-h165 {
    height: 165px !important; }
  .sp-h170 {
    height: 170px !important; }
  .sp-h175 {
    height: 175px !important; }
  .sp-h180 {
    height: 180px !important; }
  .sp-h185 {
    height: 185px !important; }
  .sp-h190 {
    height: 190px !important; }
  .sp-h195 {
    height: 195px !important; }
  .sp-h200 {
    height: 200px !important; }
  .sp-h205 {
    height: 205px !important; }
  .sp-h210 {
    height: 210px !important; }
  .sp-h215 {
    height: 215px !important; }
  .sp-h220 {
    height: 220px !important; }
  .sp-h225 {
    height: 225px !important; }
  .sp-h230 {
    height: 230px !important; }
  .sp-h235 {
    height: 235px !important; }
  .sp-h240 {
    height: 240px !important; }
  .sp-h245 {
    height: 245px !important; }
  .sp-h250 {
    height: 250px !important; }
  .sp-h255 {
    height: 255px !important; }
  .sp-h260 {
    height: 260px !important; }
  .sp-h265 {
    height: 265px !important; }
  .sp-h270 {
    height: 270px !important; }
  .sp-h275 {
    height: 275px !important; }
  .sp-h280 {
    height: 280px !important; }
  .sp-h285 {
    height: 285px !important; }
  .sp-h290 {
    height: 290px !important; }
  .sp-h295 {
    height: 295px !important; }
  .sp-h300 {
    height: 300px !important; }
  .sp-h305 {
    height: 305px !important; }
  .sp-h310 {
    height: 310px !important; }
  .sp-h315 {
    height: 315px !important; }
  .sp-h320 {
    height: 320px !important; }
  .sp-h325 {
    height: 325px !important; }
  .sp-h330 {
    height: 330px !important; }
  .sp-h335 {
    height: 335px !important; }
  .sp-h340 {
    height: 340px !important; }
  .sp-h345 {
    height: 345px !important; }
  .sp-h350 {
    height: 350px !important; }
  .sp-h355 {
    height: 355px !important; }
  .sp-h360 {
    height: 360px !important; }
  .sp-h365 {
    height: 365px !important; }
  .sp-h370 {
    height: 370px !important; }
  .sp-h375 {
    height: 375px !important; }
  .sp-h380 {
    height: 380px !important; }
  .sp-h385 {
    height: 385px !important; }
  .sp-h390 {
    height: 390px !important; }
  .sp-h395 {
    height: 395px !important; }
  .sp-h400 {
    height: 400px !important; }
  .sp-h405 {
    height: 405px !important; }
  .sp-h410 {
    height: 410px !important; }
  .sp-h415 {
    height: 415px !important; }
  .sp-h420 {
    height: 420px !important; }
  .sp-h425 {
    height: 425px !important; }
  .sp-h430 {
    height: 430px !important; }
  .sp-h435 {
    height: 435px !important; }
  .sp-h440 {
    height: 440px !important; }
  .sp-h445 {
    height: 445px !important; }
  .sp-h450 {
    height: 450px !important; }
  .sp-h455 {
    height: 455px !important; }
  .sp-h460 {
    height: 460px !important; }
  .sp-h465 {
    height: 465px !important; }
  .sp-h470 {
    height: 470px !important; }
  .sp-h475 {
    height: 475px !important; }
  .sp-h480 {
    height: 480px !important; }
  .sp-h485 {
    height: 485px !important; }
  .sp-h490 {
    height: 490px !important; }
  .sp-h495 {
    height: 495px !important; }
  .sp-h500 {
    height: 500px !important; }
  .sp-h505 {
    height: 505px !important; }
  .sp-h510 {
    height: 510px !important; }
  .sp-h515 {
    height: 515px !important; }
  .sp-h520 {
    height: 520px !important; }
  .sp-h525 {
    height: 525px !important; }
  .sp-h530 {
    height: 530px !important; }
  .sp-h535 {
    height: 535px !important; }
  .sp-h540 {
    height: 540px !important; }
  .sp-h545 {
    height: 545px !important; }
  .sp-h550 {
    height: 550px !important; }
  .sp-h555 {
    height: 555px !important; }
  .sp-h560 {
    height: 560px !important; }
  .sp-h565 {
    height: 565px !important; }
  .sp-h570 {
    height: 570px !important; }
  .sp-h575 {
    height: 575px !important; }
  .sp-h580 {
    height: 580px !important; }
  .sp-h585 {
    height: 585px !important; }
  .sp-h590 {
    height: 590px !important; }
  .sp-h595 {
    height: 595px !important; }
  .sp-h600 {
    height: 600px !important; }
  .sp-h605 {
    height: 605px !important; }
  .sp-h610 {
    height: 610px !important; }
  .sp-h615 {
    height: 615px !important; }
  .sp-h620 {
    height: 620px !important; }
  .sp-h625 {
    height: 625px !important; }
  .sp-h630 {
    height: 630px !important; }
  .sp-h635 {
    height: 635px !important; }
  .sp-h640 {
    height: 640px !important; }
  .sp-h645 {
    height: 645px !important; }
  .sp-h650 {
    height: 650px !important; }
  .sp-h655 {
    height: 655px !important; }
  .sp-h660 {
    height: 660px !important; }
  .sp-h665 {
    height: 665px !important; }
  .sp-h670 {
    height: 670px !important; }
  .sp-h675 {
    height: 675px !important; }
  .sp-h680 {
    height: 680px !important; }
  .sp-h685 {
    height: 685px !important; }
  .sp-h690 {
    height: 690px !important; }
  .sp-h695 {
    height: 695px !important; }
  .sp-h700 {
    height: 700px !important; }
  .sp-minw5 {
    min-width: 5px !important; }
  .sp-minw10 {
    min-width: 10px !important; }
  .sp-minw15 {
    min-width: 15px !important; }
  .sp-minw20 {
    min-width: 20px !important; }
  .sp-minw25 {
    min-width: 25px !important; }
  .sp-minw30 {
    min-width: 30px !important; }
  .sp-minw35 {
    min-width: 35px !important; }
  .sp-minw40 {
    min-width: 40px !important; }
  .sp-minw45 {
    min-width: 45px !important; }
  .sp-minw50 {
    min-width: 50px !important; }
  .sp-minw55 {
    min-width: 55px !important; }
  .sp-minw60 {
    min-width: 60px !important; }
  .sp-minw65 {
    min-width: 65px !important; }
  .sp-minw70 {
    min-width: 70px !important; }
  .sp-minw75 {
    min-width: 75px !important; }
  .sp-minw80 {
    min-width: 80px !important; }
  .sp-minw85 {
    min-width: 85px !important; }
  .sp-minw90 {
    min-width: 90px !important; }
  .sp-minw95 {
    min-width: 95px !important; }
  .sp-minw100 {
    min-width: 100px !important; }
  .sp-minw105 {
    min-width: 105px !important; }
  .sp-minw110 {
    min-width: 110px !important; }
  .sp-minw115 {
    min-width: 115px !important; }
  .sp-minw120 {
    min-width: 120px !important; }
  .sp-minw125 {
    min-width: 125px !important; }
  .sp-minw130 {
    min-width: 130px !important; }
  .sp-minw135 {
    min-width: 135px !important; }
  .sp-minw140 {
    min-width: 140px !important; }
  .sp-minw145 {
    min-width: 145px !important; }
  .sp-minw150 {
    min-width: 150px !important; }
  .sp-minw155 {
    min-width: 155px !important; }
  .sp-minw160 {
    min-width: 160px !important; }
  .sp-minw165 {
    min-width: 165px !important; }
  .sp-minw170 {
    min-width: 170px !important; }
  .sp-minw175 {
    min-width: 175px !important; }
  .sp-minw180 {
    min-width: 180px !important; }
  .sp-minw185 {
    min-width: 185px !important; }
  .sp-minw190 {
    min-width: 190px !important; }
  .sp-minw195 {
    min-width: 195px !important; }
  .sp-minw200 {
    min-width: 200px !important; }
  .sp-minw205 {
    min-width: 205px !important; }
  .sp-minw210 {
    min-width: 210px !important; }
  .sp-minw215 {
    min-width: 215px !important; }
  .sp-minw220 {
    min-width: 220px !important; }
  .sp-minw225 {
    min-width: 225px !important; }
  .sp-minw230 {
    min-width: 230px !important; }
  .sp-minw235 {
    min-width: 235px !important; }
  .sp-minw240 {
    min-width: 240px !important; }
  .sp-minw245 {
    min-width: 245px !important; }
  .sp-minw250 {
    min-width: 250px !important; }
  .sp-minw255 {
    min-width: 255px !important; }
  .sp-minw260 {
    min-width: 260px !important; }
  .sp-minw265 {
    min-width: 265px !important; }
  .sp-minw270 {
    min-width: 270px !important; }
  .sp-minw275 {
    min-width: 275px !important; }
  .sp-minw280 {
    min-width: 280px !important; }
  .sp-minw285 {
    min-width: 285px !important; }
  .sp-minw290 {
    min-width: 290px !important; }
  .sp-minw295 {
    min-width: 295px !important; }
  .sp-minw300 {
    min-width: 300px !important; }
  .sp-minw305 {
    min-width: 305px !important; }
  .sp-minw310 {
    min-width: 310px !important; }
  .sp-minw315 {
    min-width: 315px !important; }
  .sp-minw320 {
    min-width: 320px !important; }
  .sp-minw325 {
    min-width: 325px !important; }
  .sp-minw330 {
    min-width: 330px !important; }
  .sp-minw335 {
    min-width: 335px !important; }
  .sp-minw340 {
    min-width: 340px !important; }
  .sp-minw345 {
    min-width: 345px !important; }
  .sp-minw350 {
    min-width: 350px !important; }
  .sp-minw355 {
    min-width: 355px !important; }
  .sp-minw360 {
    min-width: 360px !important; }
  .sp-minw365 {
    min-width: 365px !important; }
  .sp-minw370 {
    min-width: 370px !important; }
  .sp-minw375 {
    min-width: 375px !important; }
  .sp-minw380 {
    min-width: 380px !important; }
  .sp-minw385 {
    min-width: 385px !important; }
  .sp-minw390 {
    min-width: 390px !important; }
  .sp-minw395 {
    min-width: 395px !important; }
  .sp-minw400 {
    min-width: 400px !important; }
  .sp-minw405 {
    min-width: 405px !important; }
  .sp-minw410 {
    min-width: 410px !important; }
  .sp-minw415 {
    min-width: 415px !important; }
  .sp-minw420 {
    min-width: 420px !important; }
  .sp-minw425 {
    min-width: 425px !important; }
  .sp-minw430 {
    min-width: 430px !important; }
  .sp-minw435 {
    min-width: 435px !important; }
  .sp-minw440 {
    min-width: 440px !important; }
  .sp-minw445 {
    min-width: 445px !important; }
  .sp-minw450 {
    min-width: 450px !important; }
  .sp-minw455 {
    min-width: 455px !important; }
  .sp-minw460 {
    min-width: 460px !important; }
  .sp-minw465 {
    min-width: 465px !important; }
  .sp-minw470 {
    min-width: 470px !important; }
  .sp-minw475 {
    min-width: 475px !important; }
  .sp-minw480 {
    min-width: 480px !important; }
  .sp-minw485 {
    min-width: 485px !important; }
  .sp-minw490 {
    min-width: 490px !important; }
  .sp-minw495 {
    min-width: 495px !important; }
  .sp-minw500 {
    min-width: 500px !important; }
  .sp-minw505 {
    min-width: 505px !important; }
  .sp-minw510 {
    min-width: 510px !important; }
  .sp-minw515 {
    min-width: 515px !important; }
  .sp-minw520 {
    min-width: 520px !important; }
  .sp-minw525 {
    min-width: 525px !important; }
  .sp-minw530 {
    min-width: 530px !important; }
  .sp-minw535 {
    min-width: 535px !important; }
  .sp-minw540 {
    min-width: 540px !important; }
  .sp-minw545 {
    min-width: 545px !important; }
  .sp-minw550 {
    min-width: 550px !important; }
  .sp-minw555 {
    min-width: 555px !important; }
  .sp-minw560 {
    min-width: 560px !important; }
  .sp-minw565 {
    min-width: 565px !important; }
  .sp-minw570 {
    min-width: 570px !important; }
  .sp-minw575 {
    min-width: 575px !important; }
  .sp-minw580 {
    min-width: 580px !important; }
  .sp-minw585 {
    min-width: 585px !important; }
  .sp-minw590 {
    min-width: 590px !important; }
  .sp-minw595 {
    min-width: 595px !important; }
  .sp-minw600 {
    min-width: 600px !important; }
  .sp-minw605 {
    min-width: 605px !important; }
  .sp-minw610 {
    min-width: 610px !important; }
  .sp-minw615 {
    min-width: 615px !important; }
  .sp-minw620 {
    min-width: 620px !important; }
  .sp-minw625 {
    min-width: 625px !important; }
  .sp-minw630 {
    min-width: 630px !important; }
  .sp-minw635 {
    min-width: 635px !important; }
  .sp-minw640 {
    min-width: 640px !important; }
  .sp-minw645 {
    min-width: 645px !important; }
  .sp-minw650 {
    min-width: 650px !important; }
  .sp-minw655 {
    min-width: 655px !important; }
  .sp-minw660 {
    min-width: 660px !important; }
  .sp-minw665 {
    min-width: 665px !important; }
  .sp-minw670 {
    min-width: 670px !important; }
  .sp-minw675 {
    min-width: 675px !important; }
  .sp-minw680 {
    min-width: 680px !important; }
  .sp-minw685 {
    min-width: 685px !important; }
  .sp-minw690 {
    min-width: 690px !important; }
  .sp-minw695 {
    min-width: 695px !important; }
  .sp-minw700 {
    min-width: 700px !important; }
  .sp-minh5 {
    min-height: 5px !important; }
  .sp-minh10 {
    min-height: 10px !important; }
  .sp-minh15 {
    min-height: 15px !important; }
  .sp-minh20 {
    min-height: 20px !important; }
  .sp-minh25 {
    min-height: 25px !important; }
  .sp-minh30 {
    min-height: 30px !important; }
  .sp-minh35 {
    min-height: 35px !important; }
  .sp-minh40 {
    min-height: 40px !important; }
  .sp-minh45 {
    min-height: 45px !important; }
  .sp-minh50 {
    min-height: 50px !important; }
  .sp-minh55 {
    min-height: 55px !important; }
  .sp-minh60 {
    min-height: 60px !important; }
  .sp-minh65 {
    min-height: 65px !important; }
  .sp-minh70 {
    min-height: 70px !important; }
  .sp-minh75 {
    min-height: 75px !important; }
  .sp-minh80 {
    min-height: 80px !important; }
  .sp-minh85 {
    min-height: 85px !important; }
  .sp-minh90 {
    min-height: 90px !important; }
  .sp-minh95 {
    min-height: 95px !important; }
  .sp-minh100 {
    min-height: 100px !important; }
  .sp-minh105 {
    min-height: 105px !important; }
  .sp-minh110 {
    min-height: 110px !important; }
  .sp-minh115 {
    min-height: 115px !important; }
  .sp-minh120 {
    min-height: 120px !important; }
  .sp-minh125 {
    min-height: 125px !important; }
  .sp-minh130 {
    min-height: 130px !important; }
  .sp-minh135 {
    min-height: 135px !important; }
  .sp-minh140 {
    min-height: 140px !important; }
  .sp-minh145 {
    min-height: 145px !important; }
  .sp-minh150 {
    min-height: 150px !important; }
  .sp-minh155 {
    min-height: 155px !important; }
  .sp-minh160 {
    min-height: 160px !important; }
  .sp-minh165 {
    min-height: 165px !important; }
  .sp-minh170 {
    min-height: 170px !important; }
  .sp-minh175 {
    min-height: 175px !important; }
  .sp-minh180 {
    min-height: 180px !important; }
  .sp-minh185 {
    min-height: 185px !important; }
  .sp-minh190 {
    min-height: 190px !important; }
  .sp-minh195 {
    min-height: 195px !important; }
  .sp-minh200 {
    min-height: 200px !important; }
  .sp-minh205 {
    min-height: 205px !important; }
  .sp-minh210 {
    min-height: 210px !important; }
  .sp-minh215 {
    min-height: 215px !important; }
  .sp-minh220 {
    min-height: 220px !important; }
  .sp-minh225 {
    min-height: 225px !important; }
  .sp-minh230 {
    min-height: 230px !important; }
  .sp-minh235 {
    min-height: 235px !important; }
  .sp-minh240 {
    min-height: 240px !important; }
  .sp-minh245 {
    min-height: 245px !important; }
  .sp-minh250 {
    min-height: 250px !important; }
  .sp-minh255 {
    min-height: 255px !important; }
  .sp-minh260 {
    min-height: 260px !important; }
  .sp-minh265 {
    min-height: 265px !important; }
  .sp-minh270 {
    min-height: 270px !important; }
  .sp-minh275 {
    min-height: 275px !important; }
  .sp-minh280 {
    min-height: 280px !important; }
  .sp-minh285 {
    min-height: 285px !important; }
  .sp-minh290 {
    min-height: 290px !important; }
  .sp-minh295 {
    min-height: 295px !important; }
  .sp-minh300 {
    min-height: 300px !important; }
  .sp-minh305 {
    min-height: 305px !important; }
  .sp-minh310 {
    min-height: 310px !important; }
  .sp-minh315 {
    min-height: 315px !important; }
  .sp-minh320 {
    min-height: 320px !important; }
  .sp-minh325 {
    min-height: 325px !important; }
  .sp-minh330 {
    min-height: 330px !important; }
  .sp-minh335 {
    min-height: 335px !important; }
  .sp-minh340 {
    min-height: 340px !important; }
  .sp-minh345 {
    min-height: 345px !important; }
  .sp-minh350 {
    min-height: 350px !important; }
  .sp-minh355 {
    min-height: 355px !important; }
  .sp-minh360 {
    min-height: 360px !important; }
  .sp-minh365 {
    min-height: 365px !important; }
  .sp-minh370 {
    min-height: 370px !important; }
  .sp-minh375 {
    min-height: 375px !important; }
  .sp-minh380 {
    min-height: 380px !important; }
  .sp-minh385 {
    min-height: 385px !important; }
  .sp-minh390 {
    min-height: 390px !important; }
  .sp-minh395 {
    min-height: 395px !important; }
  .sp-minh400 {
    min-height: 400px !important; }
  .sp-minh405 {
    min-height: 405px !important; }
  .sp-minh410 {
    min-height: 410px !important; }
  .sp-minh415 {
    min-height: 415px !important; }
  .sp-minh420 {
    min-height: 420px !important; }
  .sp-minh425 {
    min-height: 425px !important; }
  .sp-minh430 {
    min-height: 430px !important; }
  .sp-minh435 {
    min-height: 435px !important; }
  .sp-minh440 {
    min-height: 440px !important; }
  .sp-minh445 {
    min-height: 445px !important; }
  .sp-minh450 {
    min-height: 450px !important; }
  .sp-minh455 {
    min-height: 455px !important; }
  .sp-minh460 {
    min-height: 460px !important; }
  .sp-minh465 {
    min-height: 465px !important; }
  .sp-minh470 {
    min-height: 470px !important; }
  .sp-minh475 {
    min-height: 475px !important; }
  .sp-minh480 {
    min-height: 480px !important; }
  .sp-minh485 {
    min-height: 485px !important; }
  .sp-minh490 {
    min-height: 490px !important; }
  .sp-minh495 {
    min-height: 495px !important; }
  .sp-minh500 {
    min-height: 500px !important; }
  .sp-minh505 {
    min-height: 505px !important; }
  .sp-minh510 {
    min-height: 510px !important; }
  .sp-minh515 {
    min-height: 515px !important; }
  .sp-minh520 {
    min-height: 520px !important; }
  .sp-minh525 {
    min-height: 525px !important; }
  .sp-minh530 {
    min-height: 530px !important; }
  .sp-minh535 {
    min-height: 535px !important; }
  .sp-minh540 {
    min-height: 540px !important; }
  .sp-minh545 {
    min-height: 545px !important; }
  .sp-minh550 {
    min-height: 550px !important; }
  .sp-minh555 {
    min-height: 555px !important; }
  .sp-minh560 {
    min-height: 560px !important; }
  .sp-minh565 {
    min-height: 565px !important; }
  .sp-minh570 {
    min-height: 570px !important; }
  .sp-minh575 {
    min-height: 575px !important; }
  .sp-minh580 {
    min-height: 580px !important; }
  .sp-minh585 {
    min-height: 585px !important; }
  .sp-minh590 {
    min-height: 590px !important; }
  .sp-minh595 {
    min-height: 595px !important; }
  .sp-minh600 {
    min-height: 600px !important; }
  .sp-minh605 {
    min-height: 605px !important; }
  .sp-minh610 {
    min-height: 610px !important; }
  .sp-minh615 {
    min-height: 615px !important; }
  .sp-minh620 {
    min-height: 620px !important; }
  .sp-minh625 {
    min-height: 625px !important; }
  .sp-minh630 {
    min-height: 630px !important; }
  .sp-minh635 {
    min-height: 635px !important; }
  .sp-minh640 {
    min-height: 640px !important; }
  .sp-minh645 {
    min-height: 645px !important; }
  .sp-minh650 {
    min-height: 650px !important; }
  .sp-minh655 {
    min-height: 655px !important; }
  .sp-minh660 {
    min-height: 660px !important; }
  .sp-minh665 {
    min-height: 665px !important; }
  .sp-minh670 {
    min-height: 670px !important; }
  .sp-minh675 {
    min-height: 675px !important; }
  .sp-minh680 {
    min-height: 680px !important; }
  .sp-minh685 {
    min-height: 685px !important; }
  .sp-minh690 {
    min-height: 690px !important; }
  .sp-minh695 {
    min-height: 695px !important; }
  .sp-minh700 {
    min-height: 700px !important; }
  .sp-maxw5 {
    max-width: 5px !important; }
  .sp-maxw10 {
    max-width: 10px !important; }
  .sp-maxw15 {
    max-width: 15px !important; }
  .sp-maxw20 {
    max-width: 20px !important; }
  .sp-maxw25 {
    max-width: 25px !important; }
  .sp-maxw30 {
    max-width: 30px !important; }
  .sp-maxw35 {
    max-width: 35px !important; }
  .sp-maxw40 {
    max-width: 40px !important; }
  .sp-maxw45 {
    max-width: 45px !important; }
  .sp-maxw50 {
    max-width: 50px !important; }
  .sp-maxw55 {
    max-width: 55px !important; }
  .sp-maxw60 {
    max-width: 60px !important; }
  .sp-maxw65 {
    max-width: 65px !important; }
  .sp-maxw70 {
    max-width: 70px !important; }
  .sp-maxw75 {
    max-width: 75px !important; }
  .sp-maxw80 {
    max-width: 80px !important; }
  .sp-maxw85 {
    max-width: 85px !important; }
  .sp-maxw90 {
    max-width: 90px !important; }
  .sp-maxw95 {
    max-width: 95px !important; }
  .sp-maxw100 {
    max-width: 100px !important; }
  .sp-maxw105 {
    max-width: 105px !important; }
  .sp-maxw110 {
    max-width: 110px !important; }
  .sp-maxw115 {
    max-width: 115px !important; }
  .sp-maxw120 {
    max-width: 120px !important; }
  .sp-maxw125 {
    max-width: 125px !important; }
  .sp-maxw130 {
    max-width: 130px !important; }
  .sp-maxw135 {
    max-width: 135px !important; }
  .sp-maxw140 {
    max-width: 140px !important; }
  .sp-maxw145 {
    max-width: 145px !important; }
  .sp-maxw150 {
    max-width: 150px !important; }
  .sp-maxw155 {
    max-width: 155px !important; }
  .sp-maxw160 {
    max-width: 160px !important; }
  .sp-maxw165 {
    max-width: 165px !important; }
  .sp-maxw170 {
    max-width: 170px !important; }
  .sp-maxw175 {
    max-width: 175px !important; }
  .sp-maxw180 {
    max-width: 180px !important; }
  .sp-maxw185 {
    max-width: 185px !important; }
  .sp-maxw190 {
    max-width: 190px !important; }
  .sp-maxw195 {
    max-width: 195px !important; }
  .sp-maxw200 {
    max-width: 200px !important; }
  .sp-maxw205 {
    max-width: 205px !important; }
  .sp-maxw210 {
    max-width: 210px !important; }
  .sp-maxw215 {
    max-width: 215px !important; }
  .sp-maxw220 {
    max-width: 220px !important; }
  .sp-maxw225 {
    max-width: 225px !important; }
  .sp-maxw230 {
    max-width: 230px !important; }
  .sp-maxw235 {
    max-width: 235px !important; }
  .sp-maxw240 {
    max-width: 240px !important; }
  .sp-maxw245 {
    max-width: 245px !important; }
  .sp-maxw250 {
    max-width: 250px !important; }
  .sp-maxw255 {
    max-width: 255px !important; }
  .sp-maxw260 {
    max-width: 260px !important; }
  .sp-maxw265 {
    max-width: 265px !important; }
  .sp-maxw270 {
    max-width: 270px !important; }
  .sp-maxw275 {
    max-width: 275px !important; }
  .sp-maxw280 {
    max-width: 280px !important; }
  .sp-maxw285 {
    max-width: 285px !important; }
  .sp-maxw290 {
    max-width: 290px !important; }
  .sp-maxw295 {
    max-width: 295px !important; }
  .sp-maxw300 {
    max-width: 300px !important; }
  .sp-maxw305 {
    max-width: 305px !important; }
  .sp-maxw310 {
    max-width: 310px !important; }
  .sp-maxw315 {
    max-width: 315px !important; }
  .sp-maxw320 {
    max-width: 320px !important; }
  .sp-maxw325 {
    max-width: 325px !important; }
  .sp-maxw330 {
    max-width: 330px !important; }
  .sp-maxw335 {
    max-width: 335px !important; }
  .sp-maxw340 {
    max-width: 340px !important; }
  .sp-maxw345 {
    max-width: 345px !important; }
  .sp-maxw350 {
    max-width: 350px !important; }
  .sp-maxw355 {
    max-width: 355px !important; }
  .sp-maxw360 {
    max-width: 360px !important; }
  .sp-maxw365 {
    max-width: 365px !important; }
  .sp-maxw370 {
    max-width: 370px !important; }
  .sp-maxw375 {
    max-width: 375px !important; }
  .sp-maxw380 {
    max-width: 380px !important; }
  .sp-maxw385 {
    max-width: 385px !important; }
  .sp-maxw390 {
    max-width: 390px !important; }
  .sp-maxw395 {
    max-width: 395px !important; }
  .sp-maxw400 {
    max-width: 400px !important; }
  .sp-maxw405 {
    max-width: 405px !important; }
  .sp-maxw410 {
    max-width: 410px !important; }
  .sp-maxw415 {
    max-width: 415px !important; }
  .sp-maxw420 {
    max-width: 420px !important; }
  .sp-maxw425 {
    max-width: 425px !important; }
  .sp-maxw430 {
    max-width: 430px !important; }
  .sp-maxw435 {
    max-width: 435px !important; }
  .sp-maxw440 {
    max-width: 440px !important; }
  .sp-maxw445 {
    max-width: 445px !important; }
  .sp-maxw450 {
    max-width: 450px !important; }
  .sp-maxw455 {
    max-width: 455px !important; }
  .sp-maxw460 {
    max-width: 460px !important; }
  .sp-maxw465 {
    max-width: 465px !important; }
  .sp-maxw470 {
    max-width: 470px !important; }
  .sp-maxw475 {
    max-width: 475px !important; }
  .sp-maxw480 {
    max-width: 480px !important; }
  .sp-maxw485 {
    max-width: 485px !important; }
  .sp-maxw490 {
    max-width: 490px !important; }
  .sp-maxw495 {
    max-width: 495px !important; }
  .sp-maxw500 {
    max-width: 500px !important; }
  .sp-maxw505 {
    max-width: 505px !important; }
  .sp-maxw510 {
    max-width: 510px !important; }
  .sp-maxw515 {
    max-width: 515px !important; }
  .sp-maxw520 {
    max-width: 520px !important; }
  .sp-maxw525 {
    max-width: 525px !important; }
  .sp-maxw530 {
    max-width: 530px !important; }
  .sp-maxw535 {
    max-width: 535px !important; }
  .sp-maxw540 {
    max-width: 540px !important; }
  .sp-maxw545 {
    max-width: 545px !important; }
  .sp-maxw550 {
    max-width: 550px !important; }
  .sp-maxw555 {
    max-width: 555px !important; }
  .sp-maxw560 {
    max-width: 560px !important; }
  .sp-maxw565 {
    max-width: 565px !important; }
  .sp-maxw570 {
    max-width: 570px !important; }
  .sp-maxw575 {
    max-width: 575px !important; }
  .sp-maxw580 {
    max-width: 580px !important; }
  .sp-maxw585 {
    max-width: 585px !important; }
  .sp-maxw590 {
    max-width: 590px !important; }
  .sp-maxw595 {
    max-width: 595px !important; }
  .sp-maxw600 {
    max-width: 600px !important; }
  .sp-maxw605 {
    max-width: 605px !important; }
  .sp-maxw610 {
    max-width: 610px !important; }
  .sp-maxw615 {
    max-width: 615px !important; }
  .sp-maxw620 {
    max-width: 620px !important; }
  .sp-maxw625 {
    max-width: 625px !important; }
  .sp-maxw630 {
    max-width: 630px !important; }
  .sp-maxw635 {
    max-width: 635px !important; }
  .sp-maxw640 {
    max-width: 640px !important; }
  .sp-maxw645 {
    max-width: 645px !important; }
  .sp-maxw650 {
    max-width: 650px !important; }
  .sp-maxw655 {
    max-width: 655px !important; }
  .sp-maxw660 {
    max-width: 660px !important; }
  .sp-maxw665 {
    max-width: 665px !important; }
  .sp-maxw670 {
    max-width: 670px !important; }
  .sp-maxw675 {
    max-width: 675px !important; }
  .sp-maxw680 {
    max-width: 680px !important; }
  .sp-maxw685 {
    max-width: 685px !important; }
  .sp-maxw690 {
    max-width: 690px !important; }
  .sp-maxw695 {
    max-width: 695px !important; }
  .sp-maxw700 {
    max-width: 700px !important; }
  .sp-maxh5 {
    max-height: 5px !important; }
  .sp-maxh10 {
    max-height: 10px !important; }
  .sp-maxh15 {
    max-height: 15px !important; }
  .sp-maxh20 {
    max-height: 20px !important; }
  .sp-maxh25 {
    max-height: 25px !important; }
  .sp-maxh30 {
    max-height: 30px !important; }
  .sp-maxh35 {
    max-height: 35px !important; }
  .sp-maxh40 {
    max-height: 40px !important; }
  .sp-maxh45 {
    max-height: 45px !important; }
  .sp-maxh50 {
    max-height: 50px !important; }
  .sp-maxh55 {
    max-height: 55px !important; }
  .sp-maxh60 {
    max-height: 60px !important; }
  .sp-maxh65 {
    max-height: 65px !important; }
  .sp-maxh70 {
    max-height: 70px !important; }
  .sp-maxh75 {
    max-height: 75px !important; }
  .sp-maxh80 {
    max-height: 80px !important; }
  .sp-maxh85 {
    max-height: 85px !important; }
  .sp-maxh90 {
    max-height: 90px !important; }
  .sp-maxh95 {
    max-height: 95px !important; }
  .sp-maxh100 {
    max-height: 100px !important; }
  .sp-maxh105 {
    max-height: 105px !important; }
  .sp-maxh110 {
    max-height: 110px !important; }
  .sp-maxh115 {
    max-height: 115px !important; }
  .sp-maxh120 {
    max-height: 120px !important; }
  .sp-maxh125 {
    max-height: 125px !important; }
  .sp-maxh130 {
    max-height: 130px !important; }
  .sp-maxh135 {
    max-height: 135px !important; }
  .sp-maxh140 {
    max-height: 140px !important; }
  .sp-maxh145 {
    max-height: 145px !important; }
  .sp-maxh150 {
    max-height: 150px !important; }
  .sp-maxh155 {
    max-height: 155px !important; }
  .sp-maxh160 {
    max-height: 160px !important; }
  .sp-maxh165 {
    max-height: 165px !important; }
  .sp-maxh170 {
    max-height: 170px !important; }
  .sp-maxh175 {
    max-height: 175px !important; }
  .sp-maxh180 {
    max-height: 180px !important; }
  .sp-maxh185 {
    max-height: 185px !important; }
  .sp-maxh190 {
    max-height: 190px !important; }
  .sp-maxh195 {
    max-height: 195px !important; }
  .sp-maxh200 {
    max-height: 200px !important; }
  .sp-maxh205 {
    max-height: 205px !important; }
  .sp-maxh210 {
    max-height: 210px !important; }
  .sp-maxh215 {
    max-height: 215px !important; }
  .sp-maxh220 {
    max-height: 220px !important; }
  .sp-maxh225 {
    max-height: 225px !important; }
  .sp-maxh230 {
    max-height: 230px !important; }
  .sp-maxh235 {
    max-height: 235px !important; }
  .sp-maxh240 {
    max-height: 240px !important; }
  .sp-maxh245 {
    max-height: 245px !important; }
  .sp-maxh250 {
    max-height: 250px !important; }
  .sp-maxh255 {
    max-height: 255px !important; }
  .sp-maxh260 {
    max-height: 260px !important; }
  .sp-maxh265 {
    max-height: 265px !important; }
  .sp-maxh270 {
    max-height: 270px !important; }
  .sp-maxh275 {
    max-height: 275px !important; }
  .sp-maxh280 {
    max-height: 280px !important; }
  .sp-maxh285 {
    max-height: 285px !important; }
  .sp-maxh290 {
    max-height: 290px !important; }
  .sp-maxh295 {
    max-height: 295px !important; }
  .sp-maxh300 {
    max-height: 300px !important; }
  .sp-maxh305 {
    max-height: 305px !important; }
  .sp-maxh310 {
    max-height: 310px !important; }
  .sp-maxh315 {
    max-height: 315px !important; }
  .sp-maxh320 {
    max-height: 320px !important; }
  .sp-maxh325 {
    max-height: 325px !important; }
  .sp-maxh330 {
    max-height: 330px !important; }
  .sp-maxh335 {
    max-height: 335px !important; }
  .sp-maxh340 {
    max-height: 340px !important; }
  .sp-maxh345 {
    max-height: 345px !important; }
  .sp-maxh350 {
    max-height: 350px !important; }
  .sp-maxh355 {
    max-height: 355px !important; }
  .sp-maxh360 {
    max-height: 360px !important; }
  .sp-maxh365 {
    max-height: 365px !important; }
  .sp-maxh370 {
    max-height: 370px !important; }
  .sp-maxh375 {
    max-height: 375px !important; }
  .sp-maxh380 {
    max-height: 380px !important; }
  .sp-maxh385 {
    max-height: 385px !important; }
  .sp-maxh390 {
    max-height: 390px !important; }
  .sp-maxh395 {
    max-height: 395px !important; }
  .sp-maxh400 {
    max-height: 400px !important; }
  .sp-maxh405 {
    max-height: 405px !important; }
  .sp-maxh410 {
    max-height: 410px !important; }
  .sp-maxh415 {
    max-height: 415px !important; }
  .sp-maxh420 {
    max-height: 420px !important; }
  .sp-maxh425 {
    max-height: 425px !important; }
  .sp-maxh430 {
    max-height: 430px !important; }
  .sp-maxh435 {
    max-height: 435px !important; }
  .sp-maxh440 {
    max-height: 440px !important; }
  .sp-maxh445 {
    max-height: 445px !important; }
  .sp-maxh450 {
    max-height: 450px !important; }
  .sp-maxh455 {
    max-height: 455px !important; }
  .sp-maxh460 {
    max-height: 460px !important; }
  .sp-maxh465 {
    max-height: 465px !important; }
  .sp-maxh470 {
    max-height: 470px !important; }
  .sp-maxh475 {
    max-height: 475px !important; }
  .sp-maxh480 {
    max-height: 480px !important; }
  .sp-maxh485 {
    max-height: 485px !important; }
  .sp-maxh490 {
    max-height: 490px !important; }
  .sp-maxh495 {
    max-height: 495px !important; }
  .sp-maxh500 {
    max-height: 500px !important; }
  .sp-maxh505 {
    max-height: 505px !important; }
  .sp-maxh510 {
    max-height: 510px !important; }
  .sp-maxh515 {
    max-height: 515px !important; }
  .sp-maxh520 {
    max-height: 520px !important; }
  .sp-maxh525 {
    max-height: 525px !important; }
  .sp-maxh530 {
    max-height: 530px !important; }
  .sp-maxh535 {
    max-height: 535px !important; }
  .sp-maxh540 {
    max-height: 540px !important; }
  .sp-maxh545 {
    max-height: 545px !important; }
  .sp-maxh550 {
    max-height: 550px !important; }
  .sp-maxh555 {
    max-height: 555px !important; }
  .sp-maxh560 {
    max-height: 560px !important; }
  .sp-maxh565 {
    max-height: 565px !important; }
  .sp-maxh570 {
    max-height: 570px !important; }
  .sp-maxh575 {
    max-height: 575px !important; }
  .sp-maxh580 {
    max-height: 580px !important; }
  .sp-maxh585 {
    max-height: 585px !important; }
  .sp-maxh590 {
    max-height: 590px !important; }
  .sp-maxh595 {
    max-height: 595px !important; }
  .sp-maxh600 {
    max-height: 600px !important; }
  .sp-maxh605 {
    max-height: 605px !important; }
  .sp-maxh610 {
    max-height: 610px !important; }
  .sp-maxh615 {
    max-height: 615px !important; }
  .sp-maxh620 {
    max-height: 620px !important; }
  .sp-maxh625 {
    max-height: 625px !important; }
  .sp-maxh630 {
    max-height: 630px !important; }
  .sp-maxh635 {
    max-height: 635px !important; }
  .sp-maxh640 {
    max-height: 640px !important; }
  .sp-maxh645 {
    max-height: 645px !important; }
  .sp-maxh650 {
    max-height: 650px !important; }
  .sp-maxh655 {
    max-height: 655px !important; }
  .sp-maxh660 {
    max-height: 660px !important; }
  .sp-maxh665 {
    max-height: 665px !important; }
  .sp-maxh670 {
    max-height: 670px !important; }
  .sp-maxh675 {
    max-height: 675px !important; }
  .sp-maxh680 {
    max-height: 680px !important; }
  .sp-maxh685 {
    max-height: 685px !important; }
  .sp-maxh690 {
    max-height: 690px !important; }
  .sp-maxh695 {
    max-height: 695px !important; }
  .sp-maxh700 {
    max-height: 700px !important; }
  .sp-fs10 {
    font-size: 1rem !important; }
  .sp-fs11 {
    font-size: 1.1rem !important; }
  .sp-fs12 {
    font-size: 1.2rem !important; }
  .sp-fs13 {
    font-size: 1.3rem !important; }
  .sp-fs14 {
    font-size: 1.4rem !important; }
  .sp-fs15 {
    font-size: 1.5rem !important; }
  .sp-fs16 {
    font-size: 1.6rem !important; }
  .sp-fs17 {
    font-size: 1.7rem !important; }
  .sp-fs18 {
    font-size: 1.8rem !important; }
  .sp-fs19 {
    font-size: 1.9rem !important; }
  .sp-fs20 {
    font-size: 2rem !important; }
  .sp-fs21 {
    font-size: 2.1rem !important; }
  .sp-fs22 {
    font-size: 2.2rem !important; }
  .sp-fs23 {
    font-size: 2.3rem !important; }
  .sp-fs24 {
    font-size: 2.4rem !important; }
  .sp-fs25 {
    font-size: 2.5rem !important; }
  .sp-fs26 {
    font-size: 2.6rem !important; }
  .sp-fs27 {
    font-size: 2.7rem !important; }
  .sp-fs28 {
    font-size: 2.8rem !important; }
  .sp-fs29 {
    font-size: 2.9rem !important; }
  .sp-fs30 {
    font-size: 3rem !important; }
  .sp-fs31 {
    font-size: 3.1rem !important; }
  .sp-fs32 {
    font-size: 3.2rem !important; }
  .sp-fs33 {
    font-size: 3.3rem !important; }
  .sp-fs34 {
    font-size: 3.4rem !important; }
  .sp-fs35 {
    font-size: 3.5rem !important; }
  .sp-fs36 {
    font-size: 3.6rem !important; }
  .sp-fs37 {
    font-size: 3.7rem !important; }
  .sp-fs38 {
    font-size: 3.8rem !important; }
  .sp-fs39 {
    font-size: 3.9rem !important; }
  .sp-fs40 {
    font-size: 4rem !important; }
  .sp-fs41 {
    font-size: 4.1rem !important; }
  .sp-fs42 {
    font-size: 4.2rem !important; }
  .sp-fs43 {
    font-size: 4.3rem !important; }
  .sp-fs44 {
    font-size: 4.4rem !important; }
  .sp-fs45 {
    font-size: 4.5rem !important; }
  .sp-fs46 {
    font-size: 4.6rem !important; }
  .sp-fs47 {
    font-size: 4.7rem !important; }
  .sp-fs48 {
    font-size: 4.8rem !important; }
  .sp-fs49 {
    font-size: 4.9rem !important; }
  .sp-fs50 {
    font-size: 5rem !important; }
  .sp-ta-left {
    text-align: left !important; }
  .sp-ta-center {
    text-align: center !important; }
  .sp-ta-right {
    text-align: right !important; }
  .sp-va-top {
    vertical-align: top !important; }
  .sp-va-middle {
    vertical-align: middle !important; }
  .sp-va-bottom {
    vertical-align: bottom !important; } }

/** Color
-------------------------- */
.red {
  color: #d80031 !important; }

.blue {
  color: #323e95 !important; }

/** Bold
-------------------------- */
.bold {
  font-weight: bold !important; }

@font-face {
  font-family: 'Premier';
  src: url("/assets/fonts/Premier.ttf?8oxys4") format("truetype"), url("/assets/fonts/Premier.woff?8oxys4") format("woff"), url("/assets/fonts/Premier.svg?8oxys4#Premier") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="pi-"], [class*=" pi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Premier' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.pi-dormitory:before {
  content: "\e915"; }

.pi-globe:before {
  content: "\e914"; }

.pi-txt-a:before {
  content: "\e912"; }

.pi-txt-q:before {
  content: "\e913"; }

.pi-txt-close:before {
  content: "\e911"; }

.pi-angle-top:before {
  content: "\e910"; }

.pi-exclamation:before {
  content: "\e90f"; }

.pi-caret-bottom:before {
  content: "\e90e"; }

.pi-tel:before {
  content: "\e90d"; }

.pi-txt-inscription:before {
  content: "\e90b"; }

.pi-txt-contact:before {
  content: "\e90c"; }

.pi-txt-faq:before {
  content: "\e90a"; }

.pi-logo:before {
  content: "\e909"; }

.pi-scroll-next:before {
  content: "\e907"; }

.pi-angle-bottom:before {
  content: "\e908"; }

.pi-blank:before {
  content: "\e906"; }

.pi-angle-right:before {
  content: "\e900"; }

.pi-visa:before {
  content: "\e901"; }

.pi-ecole:before {
  content: "\e902"; }

.pi-traduction:before {
  content: "\e903"; }

.pi-mail:before {
  content: "\e904"; }

.pi-inscription:before {
  content: "\e905"; }

/* =============================================
 * Base
 * ============================================= */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0; }

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  height: 100%; }

body {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック", YuGothic, "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 1.8;
  min-width: 320px;
  min-height: 100%; }
  @media (min-width: 769px) {
    body {
      min-width: 1024px;
      font-size: 1.6rem; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal; }

ul,
ol {
  list-style-type: none; }

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  vertical-align: top; }

a {
  color: #000;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.35s;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }
  a img {
    border: none; }
  a:hover {
    color: #000;
    text-decoration: none; }
    @media (min-width: 769px) {
      a:hover {
        opacity: 0.7; } }

/* =============================================
 * Wrapper
 * ============================================= */
.l-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative; }
  body:not(.-nav-top) .l-wrapper {
    padding-top: 58px; }
    @media (min-width: 769px) {
      body:not(.-nav-top) .l-wrapper {
        padding-top: 117px; } }

/* =============================================
 * Header
 * ============================================= */
.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: transparent;
  /* animations */
  /* lower */
  /* stylelint-disable-next-line */
  /* stylelint-disable */ }
  @media (max-width: 768.98px) {
    .l-header {
      height: 58px; }
      body.is-opened--drawer-menu .l-header {
        height: 100vh; }
        body.is-opened--drawer-menu .l-header::before, body.is-opened--drawer-menu .l-header::after {
          visibility: visible;
          opacity: 1; } }
  @media (min-width: 769px) {
    .l-header {
      height: 117px;
      padding: 0 20px; }
      .l-header::before {
        content: "";
        width: calc(100% - 40px);
        min-width: 1024px;
        max-width: 1920px;
        border-bottom: solid 1px white;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%); } }
  .l-header::before, .l-header::after {
    height: 100vh;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s;
    pointer-events: none; }
    @media (min-width: 769px) {
      .l-header::before, .l-header::after {
        display: none !important; } }
  .l-header::before {
    background: url("/assets/img/common/kv-sp.jpg") no-repeat center;
    background-size: cover; }
  .l-header::after {
    background-color: rgba(255, 255, 255, 0.8); }
  .l-header__inner {
    width: 100%;
    position: relative; }
    @media (max-width: 768.98px) {
      .l-header__inner {
        height: 58px; } }
    @media (min-width: 769px) {
      .l-header__inner {
        min-width: 1024px;
        max-width: 1920px;
        transition: all 0.5s;
        margin: 0 auto; } }
  .l-header__read {
    width: calc(100% - 495px);
    height: 40px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 1.2rem;
    color: white;
    visibility: visible;
    opacity: 1;
    transition: all 0.5s 0s; }
    @media (max-width: 768.98px) {
      .l-header__read {
        display: none !important; } }
  .l-header__logo {
    position: relative;
    z-index: 1; }
    @media (max-width: 768.98px) {
      .l-header__logo {
        width: 134px;
        padding: 17px 0 0 19px; } }
    @media (min-width: 769px) {
      .l-header__logo {
        position: absolute;
        left: 2px;
        top: 58px;
        transition: top 0.5s; } }
    .l-header__logo__link {
      display: block; }
    .l-header__logo__icon {
      font-size: 2.26rem;
      color: white;
      transition: all 0.5s; }
      @media (max-width: 768.98px) {
        body.is-opened--drawer-menu .l-header__logo__icon {
          color: black; } }
      @media (min-width: 769px) {
        .l-header__logo__icon {
          font-size: 3.8rem; } }
  .l-header__tel {
    position: absolute;
    top: 19px;
    right: 242px;
    visibility: visible;
    opacity: 1;
    transition: all 0.5s 0s; }
    @media (max-width: 768.98px) {
      .l-header__tel {
        display: none; } }
    .l-header__tel__link {
      display: block; }
    .l-header__tel__number {
      color: white;
      font-size: 2.4rem; }
    .l-header__tel__txt {
      font-size: 1.2rem;
      white-space: nowrap;
      color: white; }
  .l-header__helper-list {
    display: flex;
    position: absolute;
    top: 15px;
    right: 63px;
    visibility: visible;
    opacity: 1;
    transition: all 0.3s; }
    @media (min-width: 769px) {
      .l-header__helper-list {
        top: 21px;
        right: 0; } }
    body.is-opened--drawer-menu .l-header__helper-list {
      visibility: hidden;
      opacity: 0; }
    .l-header__helper-list__item:first-of-type {
      margin-right: 6px; }
      @media (max-width: 768.98px) {
        .l-header__helper-list__item:first-of-type {
          display: none; } }
  .l-header.-fixed {
    background-color: white; }
    @media (min-width: 769px) {
      .l-header.-fixed {
        height: 65px; } }
  @media (min-width: 769px) {
    .l-header.-fixed .l-header__inner {
      border-color: transparent;
      padding-top: 18px; } }
  .l-header.-fixed .l-header__read {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s; }
  @media (min-width: 769px) {
    .l-header.-fixed .l-header__logo {
      top: 19px; } }
  .l-header.-fixed .l-header__logo__icon {
    color: black; }
    @media (min-width: 769px) {
      .l-header.-fixed .l-header__logo__icon {
        font-size: 2.9rem; } }
  .l-header.-fixed .l-header__tel {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s; }
  .l-header.-fixed .l-header__helper-list .c-border-link {
    color: black;
    border-color: #ccc;
    background-color: #ccc;
    transition: all 0.3s ease-in-out; }
    .l-header.-fixed .l-header__helper-list .c-border-link:hover {
      color: white;
      border-color: black;
      background-color: black; }
  body.-lower .l-header {
    background-color: white; }
  body.-lower .l-header__inner {
    border: none; }
  body.-lower .l-header__read,
  body.-lower .l-header__logo__icon,
  body.-lower .l-header__tel__number,
  body.-lower .l-header__tel__txt,
  body.-lower .l-header__logo__icon {
    color: black; }
  body.-lower .l-header__tel__number {
    color: #323e95; }
  body.-lower .l-header__helper-list .c-border-link {
    background-color: #ccc;
    border-color: #ccc;
    color: black; }
    body.-lower .l-header__helper-list .c-border-link:hover {
      background-color: black;
      border-color: black;
      color: white; }

/* =============================================
 * Gnav
 * ============================================= */
.l-gnav {
  position: absolute;
  right: -2px;
  top: 74px;
  transition: all 0.5s;
  /* stylelint-disable-next-line */ }
  @media (max-width: 768.98px) {
    .l-gnav {
      display: none; } }
  .l-gnav__list {
    display: flex;
    justify-content: center; }
  .l-gnav__link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 1.3rem;
    font-weight: bold;
    white-space: nowrap;
    color: white;
    padding: 0 6.4px;
    position: relative;
    transition: color 0.5s;
    opacity: 1 !important; }
    .l-gnav__link::after {
      content: "";
      width: calc(100% - 13px);
      height: 2px;
      background-color: white;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 5px);
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s; }
    .l-gnav__link:hover {
      color: white; }
      .l-gnav__link:hover::after {
        visibility: visible;
        opacity: 1;
        transform: translate(-50%, 2px); }
    .l-header.-fixed .l-gnav__link {
      color: black; }
      .l-header.-fixed .l-gnav__link::after {
        background-color: black; }
  .l-header.-fixed .l-gnav {
    right: 248px;
    top: 19px; }
  body.-lower .l-gnav__link {
    color: black;
    /* stylelint-disable-next-line */ }
    body.-lower .l-gnav__link::after {
      background-color: black; }

/* =============================================
 * Main
 * ============================================= */
.l-main {
  flex-grow: 1;
  padding-bottom: 100px;
  /* IE11対策 */
  min-height: 0%; }
  @media (min-width: 769px) {
    .l-main {
      padding-bottom: 150px; } }

/* =============================================
 * Footer
 * ============================================= */
.l-footer {
  background-color: #b3b3b3;
  position: relative; }
  @media (min-width: 769px) {
    .l-footer {
      background-color: white; } }
  .l-footer::before, .l-footer::after {
    content: "";
    width: calc(100% / 3);
    position: absolute;
    top: 0;
    bottom: 0; }
  .l-footer::before {
    left: 0;
    background-color: #323e95; }
  .l-footer::after {
    right: 0;
    background-color: #d80031; }
  .l-footer__main {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.85);
    position: relative;
    z-index: 1;
    padding: 0 18px; }
    .l-footer__main__inner {
      width: 100%;
      padding: 22px 0 61px; }
      @media (min-width: 769px) {
        .l-footer__main__inner {
          width: 1000px;
          padding: 30px 0 30px; } }
  .l-footer__top-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px; }
    .l-footer__top-group__left {
      display: flex; }
  @media (min-width: 769px) {
    .l-footer__bottom-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-top: solid 1px white;
      padding-top: 14px; } }
  @media (max-width: 768.98px) {
    .l-footer__bottom-group__left {
      margin-bottom: 30px; } }
  .l-footer__logo {
    width: 106px; }
    @media (min-width: 769px) {
      .l-footer__logo {
        width: 171px;
        margin-right: 13px; } }
    .l-footer__logo__link {
      display: block; }
  .l-footer__read {
    font-size: 1.2rem;
    line-height: 1.6;
    color: white; }
    @media (max-width: 768.98px) {
      .l-footer__read {
        display: none; } }
  .l-footer__helper-list {
    display: flex;
    padding: 0; }
    @media (max-width: 768.98px) {
      .l-footer__helper-list {
        flex-wrap: wrap; } }
    .l-footer__helper-list__item:first-of-type {
      margin-right: 7px; }
    @media screen and (max-width: 320px) {
      .l-footer__helper-list__item .c-border-link {
        width: 80px; } }
  .l-footer__banner {
    width: 240px;
    margin: 0 auto; }
    @media (min-width: 769px) {
      .l-footer__banner {
        width: 232px;
        margin: 10px 2px 0 0; } }
  .l-footer__copy {
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 1.2rem;
    white-space: nowrap;
    z-index: 1; }
    @media (max-width: 768.98px) {
      .l-footer__copy {
        color: white; } }
    @media (min-width: 769px) {
      .l-footer__copy {
        height: 85px;
        font-size: 1.4rem; } }

.l-footer-gnav {
  margin-bottom: 33px; }
  @media (max-width: 768.98px) {
    .l-footer-gnav {
      width: 100%; } }
  @media (min-width: 769px) {
    .l-footer-gnav {
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px; } }
  @media (max-width: 768.98px) {
    .l-footer-gnav__col.-col1 {
      display: flex;
      margin-bottom: 13px; } }
  @media (max-width: 768.98px) {
    .l-footer-gnav__col.-col2 {
      margin-bottom: 27px; } }
  @media (max-width: 768.98px) {
    .l-footer-gnav__col.-col3 {
      margin-bottom: 28px; } }
  .l-footer-gnav__title {
    margin-bottom: 9px; }
    @media (max-width: 768.98px) {
      .l-footer-gnav__title.-faq {
        margin-left: 26px; } }
    @media (min-width: 769px) {
      .l-footer-gnav__title.-faq {
        margin-top: 19px; } }
    .l-footer-gnav__title__link {
      font-size: 1.6rem;
      font-weight: bold;
      white-space: nowrap;
      color: white;
      padding: 3px 0; }
      @media (max-width: 768.98px) {
        .l-footer-gnav__title__link {
          display: flex;
          align-items: center; } }
      @media (min-width: 769px) {
        .l-footer-gnav__title__link {
          font-size: 1.7rem; } }
      .l-footer-gnav__title__link:hover {
        color: white; }
    .l-footer-gnav__title__icon {
      color: white; }
      @media (min-width: 769px) {
        .l-footer-gnav__title__icon {
          display: none; } }
      .l-footer-gnav__title__icon.pi-visa {
        font-size: 3.8rem;
        margin-right: 10px; }
      .l-footer-gnav__title__icon.pi-ecole {
        font-size: 3.7rem;
        margin-right: 17px; }
      .l-footer-gnav__title__icon.pi-traduction {
        font-size: 3.3rem;
        margin-right: 17px; }
  .l-footer-gnav__list {
    padding: 0;
    margin: 0; }
  @media (max-width: 768.98px) {
    .l-footer-gnav__item {
      border-bottom: solid 1px white; }
      .l-footer-gnav__item:first-of-type {
        border-top: solid 1px white; } }
  @media (min-width: 769px) {
    .l-footer-gnav__item {
      margin-bottom: 10px; } }
  .l-footer-gnav__link {
    font-size: 1.4rem;
    color: white;
    padding: 3px 0; }
    @media (max-width: 768.98px) {
      .l-footer-gnav__link {
        height: 42px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between; } }
    .l-footer-gnav__link:hover {
      color: white; }
  .l-footer-gnav__angle-icon {
    color: white;
    font-size: 1rem;
    margin-right: 5px; }
    @media (min-width: 769px) {
      .l-footer-gnav__angle-icon {
        display: none; } }

.l-footer-sub-nav__list {
  display: flex; }
  @media (max-width: 768.98px) {
    .l-footer-sub-nav__list {
      flex-wrap: wrap; } }

.l-footer-sub-nav__item {
  margin-right: 24px; }
  @media (max-width: 768.98px) {
    .l-footer-sub-nav__item {
      margin-bottom: 5px; } }
  @media (min-width: 769px) {
    .l-footer-sub-nav__item {
      margin-right: 24px; } }

.l-footer-sub-nav__link {
  font-size: 1.4rem;
  color: white;
  white-space: nowrap;
  padding: 3px 0; }
  .l-footer-sub-nav__link:hover {
    color: white; }

.l-footer-sub-nav__item:first-of-type .l-footer-sub-nav__link {
  padding-left: 0; }

.l-footer-sub-nav__icon {
  font-size: 1.6rem;
  margin-left: 2px; }

.l-footer-info {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/assets/img/common/footer-bg.jpg");
  background-size: cover; }
  @media (min-width: 769px) {
    .l-footer-info {
      background-image: url("/assets/img/common/footer-bg.jpg"); } }

.l-footer-faq {
  width: 100%;
  height: 142px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3); }
  @media (min-width: 769px) {
    .l-footer-faq {
      height: 302px; } }
  .l-footer-faq__link {
    width: calc(100% - 38px);
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.8rem;
    font-weight: bold;
    color: white !important;
    line-height: 1;
    white-space: nowrap;
    border: solid 2px white;
    opacity: 1 !important;
    background-color: transparent;
    transition: all 0.3s; }
    @media (min-width: 769px) {
      .l-footer-faq__link {
        width: 1000px;
        height: 156px;
        font-size: 2rem; } }
    .l-footer-faq__link:hover {
      background-color: rgba(255, 255, 255, 0.8);
      border-color: transparent;
      color: black !important; }
  .l-footer-faq__icon {
    font-size: 2.4rem;
    margin-bottom: 8px; }
    @media (min-width: 769px) {
      .l-footer-faq__icon {
        font-size: 3.6rem;
        margin: 0 0 30px 12px; } }

.l-footer-contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 32px 0 85px; }
  @media (min-width: 769px) {
    .l-footer-contact {
      padding: 100px 0 94px; } }
  .l-footer-contact__nav {
    margin-bottom: 20px; }
    @media (max-width: 768.98px) {
      .l-footer-contact__nav {
        width: calc(100% - 38px); } }
    @media (min-width: 769px) {
      .l-footer-contact__nav {
        margin-bottom: 71px; } }
    @media (min-width: 769px) {
      .l-footer-contact__nav__list {
        display: flex; } }
    @media (max-width: 768.98px) {
      .l-footer-contact__nav__item:first-of-type {
        margin-bottom: 19px; } }
    @media (min-width: 769px) {
      .l-footer-contact__nav__item:first-of-type {
        margin-right: 44px; } }
  .l-footer-contact__tel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .l-footer-contact__tel__link {
      display: block; }
    .l-footer-contact__tel__msg {
      font-size: 1.6rem;
      font-weight: bold;
      white-space: nowrap;
      margin-bottom: 9px; }
      @media (min-width: 769px) {
        .l-footer-contact__tel__msg {
          font-size: 2.5rem;
          margin-bottom: 14px; } }
    .l-footer-contact__tel__number {
      font-size: 3.4rem;
      margin-bottom: 10px; }
      @media screen and (max-width: 320px) {
        .l-footer-contact__tel__number {
          font-size: 2.9rem; } }
      @media screen and (min-width: 375px) {
        .l-footer-contact__tel__number {
          margin-left: -10px; } }
      @media (min-width: 769px) {
        .l-footer-contact__tel__number {
          width: 550px;
          font-size: 5.7rem;
          margin: 0 0 14px -10px; } }
    .l-footer-contact__tel__annot {
      font-size: 1.35rem; }
      @media (min-width: 769px) {
        .l-footer-contact__tel__annot {
          font-size: 2.15rem; } }

.c-breadcrumb {
  padding: 0 19px; }
  .c-breadcrumb__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    @media (min-width: 769px) {
      .c-breadcrumb__list {
        max-width: 1920px;
        min-width: 1024px; } }
  .c-breadcrumb__item {
    display: flex;
    align-items: center; }
    .c-breadcrumb__item:not(:last-of-type) {
      margin-right: 5px; }
      .c-breadcrumb__item:not(:last-of-type)::after {
        content: ">";
        font-size: 1.2rem;
        margin-left: 5px; }
  .c-breadcrumb__link {
    font-size: 1.2rem;
    white-space: nowrap; }

.c-drawer-menu {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition-property: visibility, opacity;
  transition-duration: 0.5s;
  overflow-y: auto; }
  @media (min-width: 769px) {
    .c-drawer-menu {
      display: none !important; } }
  body.is-opened--drawer-menu .c-drawer-menu {
    opacity: 1;
    visibility: visible; }
  .c-drawer-menu__inner {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    padding: 0 0 80px; }
  .c-drawer-menu__list {
    width: calc(100% - 38px);
    margin: 0 auto; }
    .c-drawer-menu__list.-faq {
      margin-top: 14px; }
  .c-drawer-menu__item {
    border-bottom: solid 1px black; }
    .c-drawer-menu__item:first-of-type {
      border-top: solid 1px black; }
  .c-drawer-menu__link {
    width: 100%;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.6rem;
    font-weight: bold;
    padding-left: 1px; }
  .c-drawer-menu__icon {
    color: black;
    margin-right: 5px; }
  .c-drawer-menu__accordion {
    margin-top: 15px; }
    .c-drawer-menu__accordion__list {
      display: flex;
      margin-bottom: 11px; }
    .c-drawer-menu__accordion__item {
      width: calc(100% / 3); }
      .c-drawer-menu__accordion__item.-visa {
        background-color: #323e95; }
      .c-drawer-menu__accordion__item.-ecole {
        background-color: #ccc; }
      .c-drawer-menu__accordion__item.-traduction {
        background-color: #d80031; }
    .c-drawer-menu__accordion__link {
      height: 136px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white !important;
      padding: 16px 0 6px; }
      .c-drawer-menu__accordion__link::after {
        content: "\e908";
        /* stylelint-disable-next-line */
        font-family: Premier;
        font-size: 1rem;
        transform: rotate(0);
        margin-top: auto; }
      .c-drawer-menu__accordion__link.-ecole {
        color: black !important; }
    .c-drawer-menu__accordion[data-accordion-expanded-name="visa"] .c-drawer-menu__accordion__link.-visa::after,
    .c-drawer-menu__accordion[data-accordion-expanded-name="ecole"] .c-drawer-menu__accordion__link.-ecole::after,
    .c-drawer-menu__accordion[data-accordion-expanded-name="traduction"] .c-drawer-menu__accordion__link.-traduction::after {
      transform: rotate(180deg); }
    .c-drawer-menu__accordion__icon {
      pointer-events: none; }
      .c-drawer-menu__accordion__icon.pi-visa {
        font-size: 4rem;
        padding-top: 2px;
        margin-bottom: 8px; }
      .c-drawer-menu__accordion__icon.pi-ecole {
        font-size: 4rem;
        color: black;
        margin-bottom: 9px; }
      .c-drawer-menu__accordion__icon.pi-traduction {
        font-size: 3.4rem;
        padding-top: 2px;
        margin-bottom: 13px; }
    .c-drawer-menu__accordion__label {
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center;
      line-height: 1.3;
      pointer-events: none; }
    .c-drawer-menu__accordion__panel-container {
      width: calc(100% - 38px);
      position: relative;
      margin: 0 auto; }
    .c-drawer-menu__accordion__panel {
      width: 100%;
      height: 0;
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out; }
    .c-drawer-menu__accordion[data-accordion-expanded-name="visa"] .c-drawer-menu__accordion__panel.-visa,
    .c-drawer-menu__accordion[data-accordion-expanded-name="ecole"] .c-drawer-menu__accordion__panel.-ecole,
    .c-drawer-menu__accordion[data-accordion-expanded-name="traduction"] .c-drawer-menu__accordion__panel.-traduction {
      opacity: 1;
      visibility: visible; }
    .c-drawer-menu__accordion[data-accordion-expanded-name="visa"] .c-drawer-menu__accordion__panel.-visa {
      height: 99px; }
    .c-drawer-menu__accordion[data-accordion-expanded-name="ecole"] .c-drawer-menu__accordion__panel.-ecole {
      height: 132px; }
    .c-drawer-menu__accordion[data-accordion-expanded-name="traduction"] .c-drawer-menu__accordion__panel.-traduction {
      height: 99px; }
    .c-drawer-menu__accordion__child-list {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0; }
    .c-drawer-menu__accordion__child-link {
      min-width: 129px;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.5rem;
      white-space: nowrap;
      padding: 3px 0; }
    .c-drawer-menu__accordion__child-icon {
      font-size: 1rem;
      margin-left: 1.5em; }
  .c-drawer-menu__banner {
    width: calc(100% - 38px);
    margin: 10px auto 0; }
    .c-drawer-menu__banner__item {
      margin-bottom: 11px; }
    .c-drawer-menu__banner__link {
      height: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      font-weight: bold;
      white-space: nowrap;
      color: white !important; }
      .c-drawer-menu__banner__link.-contact {
        background-color: #323e95; }
      .c-drawer-menu__banner__link.-inscription {
        background-color: #d80031; }
    .c-drawer-menu__banner__icon.pi-mail {
      font-size: 2.8rem;
      margin-right: 11px; }
    .c-drawer-menu__banner__icon.pi-inscription {
      font-size: 3.2rem;
      margin: -5px 14px 0 0; }
  .c-drawer-menu__sub {
    width: calc(100% - 38px);
    margin: 0 auto; }
    .c-drawer-menu__sub__list {
      display: flex;
      flex-wrap: wrap; }
    .c-drawer-menu__sub__item {
      margin-right: 23px; }
    .c-drawer-menu__sub__link {
      font-size: 1.4rem;
      white-space: nowrap;
      padding: 3px 0; }
    .c-drawer-menu__sub__icon {
      font-size: 1.7rem; }

.c-pageup {
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 18px;
  bottom: 12px;
  background-color: #333;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 10; }
  @media (min-width: 769px) {
    .c-pageup {
      right: 30px;
      bottom: 20px; }
      .c-pageup:hover {
        opacity: 0.7 !important; } }
  .c-pageup.-static {
    position: absolute;
    top: -66px;
    bottom: inherit; }
    @media (min-width: 769px) {
      .c-pageup.-static {
        top: -75px; } }
  body.-visible-pageup .c-pageup {
    opacity: 1;
    visibility: visible; }

.c-curtain {
  width: 150%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 9;
  pointer-events: none;
  margin-left: -25%; }
  .c-curtain__item {
    width: 100%;
    height: 200%;
    position: relative;
    top: -50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.1) 80%, rgba(0, 0, 0, 0));
    transform-origin: top center;
    will-change: transform; }
  .c-curtain #c-curtain__item--01 {
    animation: curtain01 13s ease-in-out alternate infinite; }
  .c-curtain #c-curtain__item--02 {
    animation: curtain02 15s ease-in-out alternate-reverse infinite; }
  .c-curtain #c-curtain__item--03 {
    animation: curtain03 12s ease-in-out alternate infinite; }
  .c-curtain #c-curtain__item--04 {
    animation: curtain04 14s ease-in-out alternate infinite; }
  .c-curtain #c-curtain__item--05 {
    animation: curtain05 12s ease-in-out alternate infinite; }

@keyframes curtain01 {
  0% {
    transform: matrix3d(0.9, -0.82, 0, 0, 0.82, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  15% {
    transform: matrix3d(1.87, -0.78, 0, 0, 0.78, 1.87, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  27% {
    transform: matrix3d(1.93, -0.89, 0, 0, 0.89, 1.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  40% {
    transform: matrix3d(2.55, -0.35, 0, 0, 0.35, 2.55, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  50% {
    transform: matrix3d(1.48, -0.65, 0, 0, 0.65, 1.48, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  60% {
    transform: matrix3d(1.9, -0.71, 0, 0, 0.71, 1.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  70% {
    transform: matrix3d(2.32, -0.66, 0, 0, 0.66, 2.32, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85% {
    transform: matrix3d(1.03, -0.59, 0, 0, 0.59, 1.03, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% {
    transform: matrix3d(1.79, -0.7, 0, 0, 0.7, 1.79, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } }

@keyframes curtain02 {
  0% {
    transform: matrix3d(2.55, -0.35, 0, 0, 0.35, 2.55, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  15% {
    transform: matrix3d(1.48, -0.65, 0, 0, 0.65, 1.48, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  27% {
    transform: matrix3d(1.79, -0.7, 0, 0, 0.7, 1.79, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  40% {
    transform: matrix3d(0.9, -0.82, 0, 0, 0.82, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  50% {
    transform: matrix3d(1.87, -0.78, 0, 0, 0.78, 1.87, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  60% {
    transform: matrix3d(1.9, -0.71, 0, 0, 0.71, 1.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  70% {
    transform: matrix3d(1.03, -0.59, 0, 0, 0.59, 1.03, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85% {
    transform: matrix3d(1.93, -0.89, 0, 0, 0.89, 1.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% {
    transform: matrix3d(2.32, -0.66, 0, 0, 0.66, 2.32, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } }

@keyframes curtain03 {
  0% {
    transform: matrix3d(1.79, -0.7, 0, 0, 0.7, 1.79, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  15% {
    transform: matrix3d(1.93, -0.89, 0, 0, 0.89, 1.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  27% {
    transform: matrix3d(0.9, -0.82, 0, 0, 0.82, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  40% {
    transform: matrix3d(1.9, -0.71, 0, 0, 0.71, 1.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  50% {
    transform: matrix3d(1.48, -0.65, 0, 0, 0.65, 1.48, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  60% {
    transform: matrix3d(2.55, -0.35, 0, 0, 0.35, 2.55, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  70% {
    transform: matrix3d(2.32, -0.66, 0, 0, 0.66, 2.32, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85% {
    transform: matrix3d(1.03, -0.59, 0, 0, 0.59, 1.03, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% {
    transform: matrix3d(1.87, -0.78, 0, 0, 0.78, 1.87, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } }

@keyframes curtain04 {
  0% {
    transform: matrix3d(1.9, -0.71, 0, 0, 0.71, 1.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  15% {
    transform: matrix3d(1.79, -0.7, 0, 0, 0.7, 1.79, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  27% {
    transform: matrix3d(1.93, -0.89, 0, 0, 0.89, 1.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  40% {
    transform: matrix3d(2.55, -0.35, 0, 0, 0.35, 2.55, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  50% {
    transform: matrix3d(1.48, -0.65, 0, 0, 0.65, 1.48, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  60% {
    transform: matrix3d(0.9, -0.82, 0, 0, 0.82, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  70% {
    transform: matrix3d(2.32, -0.66, 0, 0, 0.66, 2.32, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85% {
    transform: matrix3d(1.03, -0.59, 0, 0, 0.59, 1.03, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% {
    transform: matrix3d(1.87, -0.78, 0, 0, 0.78, 1.87, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } }

@keyframes curtain05 {
  0% {
    transform: matrix3d(1.03, -0.59, 0, 0, 0.59, 1.03, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  15% {
    transform: matrix3d(1.93, -0.89, 0, 0, 0.89, 1.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  27% {
    transform: matrix3d(1.87, -0.78, 0, 0, 0.78, 1.87, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  40% {
    transform: matrix3d(1.48, -0.65, 0, 0, 0.65, 1.48, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  50% {
    transform: matrix3d(2.55, -0.35, 0, 0, 0.35, 2.55, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  60% {
    transform: matrix3d(1.9, -0.71, 0, 0, 0.71, 1.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  70% {
    transform: matrix3d(0.9, -0.82, 0, 0, 0.82, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85% {
    transform: matrix3d(2.32, -0.66, 0, 0, 0.66, 2.32, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% {
    transform: matrix3d(1.79, -0.7, 0, 0, 0.7, 1.79, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } }

.c-lower-kv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  margin-bottom: 11px; }
  @media (max-width: 768.98px) {
    .c-lower-kv {
      padding-top: 37.6%; } }
  @media (min-width: 769px) {
    .c-lower-kv {
      min-width: 1044px;
      height: 350px;
      margin-bottom: 25px; } }
  .c-lower-kv::before, .c-lower-kv::after {
    width: calc(100% / 3);
    height: 100%;
    display: block;
    content: "";
    position: absolute;
    opacity: 0.8;
    top: 0; }
  .c-lower-kv::before {
    background-color: #323e95;
    left: 0; }
  .c-lower-kv::after {
    background-color: #d80031;
    right: 0; }
  .c-lower-kv__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    color: white; }
    @media (max-width: 768.98px) {
      .c-lower-kv__title {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }
    @media (min-width: 769px) {
      .c-lower-kv__title {
        position: relative; } }
    .c-lower-kv__title__fr-img {
      flex-shrink: 0;
      margin-bottom: 5px; }
    .c-lower-kv__title__txt {
      font-size: 1.5rem;
      white-space: nowrap; }
      @media (min-width: 769px) {
        .c-lower-kv__title__txt {
          font-size: 2.6rem; } }
  .c-lower-kv__ja-title {
    font-size: 4.2vw;
    font-weight: bold; }
    @media (min-width: 769px) {
      .c-lower-kv__ja-title {
        font-size: 5rem; } }
  .c-lower-kv.-premier {
    background-image: url("/assets/img/premier/kv.jpg"); }
  .c-lower-kv.-premier .c-lower-kv__title {
    margin-top: 11px; }
    @media (min-width: 769px) {
      .c-lower-kv.-premier .c-lower-kv__title {
        margin-top: 31px; } }
    .c-lower-kv.-premier .c-lower-kv__title__fr-img {
      width: 69%;
      margin-right: 10px;
      margin-bottom: 5px; }
      @media (min-width: 769px) {
        .c-lower-kv.-premier .c-lower-kv__title__fr-img {
          width: 590px;
          margin-bottom: 12px; } }
  .c-lower-kv.-visa {
    background-image: url("/assets/img/visa/kv.jpg"); }
  .c-lower-kv.-visa .c-lower-kv__title {
    margin-top: -2%; }
    @media (min-width: 769px) {
      .c-lower-kv.-visa .c-lower-kv__title {
        margin-top: -21px; } }
    .c-lower-kv.-visa .c-lower-kv__title__icon {
      font-size: 4rem;
      margin-bottom: 5px; }
      @media (min-width: 769px) {
        .c-lower-kv.-visa .c-lower-kv__title__icon {
          font-size: 6.4rem;
          margin-bottom: 20px; } }
    .c-lower-kv.-visa .c-lower-kv__title__fr-img {
      width: 72%;
      margin-bottom: 2px; }
      @media (min-width: 769px) {
        .c-lower-kv.-visa .c-lower-kv__title__fr-img {
          width: 620px;
          margin-bottom: 5px; } }
  .c-lower-kv.-ecole {
    background-image: url("/assets/img/ecole/kv.jpg"); }
  .c-lower-kv.-ecole .c-lower-kv__title {
    margin-top: -2%; }
    @media (min-width: 769px) {
      .c-lower-kv.-ecole .c-lower-kv__title {
        margin-top: -23px; } }
    .c-lower-kv.-ecole .c-lower-kv__title__icon {
      font-size: 4rem;
      margin-bottom: 1.9%; }
      @media (min-width: 769px) {
        .c-lower-kv.-ecole .c-lower-kv__title__icon {
          font-size: 7.1rem;
          margin-bottom: 13px; } }
    .c-lower-kv.-ecole .c-lower-kv__title__fr-img {
      width: 19.5%;
      margin-bottom: 1.8%; }
      @media (min-width: 769px) {
        .c-lower-kv.-ecole .c-lower-kv__title__fr-img {
          width: 168px;
          margin-bottom: 16px; } }
  .c-lower-kv.-translation {
    background-image: url("/assets/img/translation/kv.jpg"); }
  .c-lower-kv.-translation .c-lower-kv__title {
    margin-top: -3%; }
    @media (min-width: 769px) {
      .c-lower-kv.-translation .c-lower-kv__title {
        margin-top: -22px; } }
    .c-lower-kv.-translation .c-lower-kv__title__icon {
      font-size: 3.7rem;
      margin-bottom: 2%; }
      @media (min-width: 769px) {
        .c-lower-kv.-translation .c-lower-kv__title__icon {
          font-size: 6.4rem;
          margin-bottom: 18px; } }
    .c-lower-kv.-translation .c-lower-kv__title__fr-img {
      width: 38%;
      margin-bottom: 1.6%; }
      @media (min-width: 769px) {
        .c-lower-kv.-translation .c-lower-kv__title__fr-img {
          width: 327px;
          margin-bottom: 14px; } }
  .c-lower-kv.-qa {
    background-image: url("/assets/img/qa/kv.jpg"); }
  .c-lower-kv.-qa .c-lower-kv__title {
    margin-top: 2%; }
    @media (min-width: 769px) {
      .c-lower-kv.-qa .c-lower-kv__title {
        margin-top: 33px; } }
    .c-lower-kv.-qa .c-lower-kv__title__fr-img {
      width: 19.5%;
      margin-bottom: 1%; }
      @media (min-width: 769px) {
        .c-lower-kv.-qa .c-lower-kv__title__fr-img {
          width: 178px;
          margin-bottom: 12px; } }
  .c-lower-kv.-premier-other {
    background-image: url("/assets/img/premier/other-kv.jpg"); }
  @media (min-width: 769px) {
    .c-lower-kv.-premier-other .c-lower-kv__title {
      margin-top: -13px; } }
  .c-lower-kv.-premier-other .c-lower-kv__title__fr-img {
    width: 19.5%;
    margin-bottom: 1%; }
    @media (min-width: 769px) {
      .c-lower-kv.-premier-other .c-lower-kv__title__fr-img {
        width: 178px;
        margin-bottom: 12px; } }

.c-loading {
  position: fixed;
  width: 100%;
  height: 150%;
  top: 0;
  left: 0;
  background-color: white;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, height 0s ease-in-out 0.5s;
  z-index: 100; }
  body.is-loaded .c-loading {
    visibility: hidden;
    opacity: 0;
    height: 0; }
  .c-loading__bar {
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #c9caca;
    position: relative; }
    @media (min-width: 769px) {
      .c-loading__bar {
        height: 20px; } }
    .c-loading__bar::before, .c-loading__bar::after {
      content: "";
      width: calc(100% / 3); }
    .c-loading__bar::before {
      background-color: #323e95; }
    .c-loading__bar::after {
      background-color: #d80031; }
    .c-loading__bar__progress {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: white;
      transition: all 1s; }

body.-nav-top:not(.is-loaded) {
  overflow: hidden; }

.c-lower-title {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .c-lower-title__top-icon.pi-exclamation {
    font-size: 3rem;
    margin-bottom: 13px; }
    @media (min-width: 769px) {
      .c-lower-title__top-icon.pi-exclamation {
        font-size: 6.8rem;
        margin-bottom: 13px; } }
  .c-lower-title__fr-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
  .c-lower-title__flag {
    width: 50px;
    margin-right: 10px; }
    @media (min-width: 769px) {
      .c-lower-title__flag {
        width: 75px;
        margin-right: 22px; } }
  .c-lower-title__fr.-categories {
    width: 224px;
    margin-bottom: 8px; }
    @media (min-width: 769px) {
      .c-lower-title__fr.-categories {
        width: 514px;
        margin-bottom: 3px; } }
  .c-lower-title__fr.-france {
    width: 78px; }
    @media (min-width: 769px) {
      .c-lower-title__fr.-france {
        width: 175px; } }
  .c-lower-title__fr.-monaco {
    width: 91px; }
    @media (min-width: 769px) {
      .c-lower-title__fr.-monaco {
        width: 206px; } }
  .c-lower-title__fr.-processus {
    width: 117px;
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .c-lower-title__fr.-processus {
        width: 269px;
        margin-bottom: 15px; } }
  .c-lower-title__fr.-attention {
    width: 103px;
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .c-lower-title__fr.-attention {
        width: 238px;
        margin-bottom: 14px; } }
  .c-lower-title__fr.-nos-tarifs {
    width: 107px;
    margin-bottom: 8px; }
    @media (min-width: 769px) {
      .c-lower-title__fr.-nos-tarifs {
        width: 248px;
        margin-bottom: 6px; } }
  .c-lower-title__fr.-about1 {
    width: 92px; }
    @media (min-width: 769px) {
      .c-lower-title__fr.-about1 {
        width: 213px; } }
  .c-lower-title__fr.-about2 {
    width: 115px; }
    @media (min-width: 769px) {
      .c-lower-title__fr.-about2 {
        width: 265px; } }
  .c-lower-title__fr.-about3 {
    width: 115px; }
    @media (min-width: 769px) {
      .c-lower-title__fr.-about3 {
        width: 265px; } }
  .c-lower-title__fr.-points {
    width: 290px;
    margin-bottom: 11px; }
    @media (min-width: 769px) {
      .c-lower-title__fr.-points {
        width: 695px;
        margin-bottom: 13px; } }
  .c-lower-title__fr.-ecole-intro {
    width: 250px;
    margin-bottom: 11px; }
    @media (min-width: 769px) {
      .c-lower-title__fr.-ecole-intro {
        width: 578px;
        margin-bottom: 13px; } }
  .c-lower-title__fr.-france-langue {
    width: 167px;
    margin-bottom: 5px; }
    @media (min-width: 769px) {
      .c-lower-title__fr.-france-langue {
        width: 385px;
        margin-bottom: 3px; } }
  .c-lower-title__fr.-traduction {
    width: 220px;
    margin-bottom: 5px; }
    @media (min-width: 769px) {
      .c-lower-title__fr.-traduction {
        width: 508px;
        margin-bottom: 3px; } }
  .c-lower-title__ja {
    font-size: 1.8rem;
    font-weight: bold;
    white-space: nowrap; }
    @media (min-width: 769px) {
      .c-lower-title__ja {
        font-size: 2rem; } }
    .c-lower-title__ja.-large {
      font-size: 1.8rem; }
      @media (min-width: 769px) {
        .c-lower-title__ja.-large {
          font-size: 3.6rem; } }

.c-flag-title {
  display: flex;
  align-items: center; }
  .c-flag-title__img {
    width: 48px;
    margin-right: 8px; }
    @media (min-width: 769px) {
      .c-flag-title__img {
        width: 75px;
        margin-right: 18px; } }
  .c-flag-title__txt {
    font-size: 2rem;
    font-weight: bold;
    white-space: nowrap; }
    @media (min-width: 769px) {
      .c-flag-title__txt {
        font-size: 2.6rem; } }

.c-border-title {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold; }
  @media (min-width: 769px) {
    .c-border-title {
      font-size: 2.4rem; } }
  .c-border-title::after {
    content: "";
    flex-grow: 1;
    height: 2px;
    background-color: #b2b2b2;
    margin-left: 14px; }

.c-basic-title {
  font-weight: bold; }
  .c-basic-title__sub {
    display: block; }
  .c-basic-title.-fs24 {
    font-size: 2rem; }
    @media (min-width: 769px) {
      .c-basic-title.-fs24 {
        font-size: 2.4rem; } }
  .c-basic-title.-fs20 {
    font-size: 1.6rem; }
    @media (min-width: 769px) {
      .c-basic-title.-fs20 {
        font-size: 2rem; } }
  .c-basic-title.-center {
    text-align: center; }

.c-step-title {
  width: 100%;
  min-height: 30px;
  display: flex;
  background-color: white; }
  @media (min-width: 769px) {
    .c-step-title {
      height: 56px; } }
  .c-step-title__label {
    width: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #323e95; }
    @media (min-width: 769px) {
      .c-step-title__label {
        width: 150px; } }
    .c-step-title__label__img {
      width: 56px; }
      @media (min-width: 769px) {
        .c-step-title__label__img {
          width: 98px; } }
  .c-step-title__txt {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: bold;
    padding: 0 6px; }
    @media (min-width: 769px) {
      .c-step-title__txt {
        font-size: 2.6rem;
        padding: 0 20px; } }

.c-fill-title {
  font-size: 1.6rem;
  color: white;
  padding: 3px 12px; }
  @media (min-width: 769px) {
    .c-fill-title {
      font-size: 2.4rem;
      color: white;
      padding: 7px 20px; } }
  .c-fill-title.-blue {
    background-color: #323e95; }
  .c-fill-title.-light-blue {
    font-weight: bold;
    color: #323e95;
    background-color: #ebecf4; }
    @media (max-width: 768.98px) {
      .c-fill-title.-light-blue {
        font-size: 1.5rem; } }

.c-underline-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #323e95;
  border-bottom: solid 2px #323e95;
  padding: 2px 0 1px 1px; }
  @media (min-width: 769px) {
    .c-underline-title {
      font-size: 2.4rem;
      padding: 5px 0 6px 2px; } }

.c-border-link {
  width: 102px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px white;
  font-size: 1.2rem;
  line-height: 1;
  white-space: nowrap;
  transition: all 0.3s ease;
  opacity: 1 !important; }
  @media (min-width: 769px) {
    .c-border-link {
      width: 105px; } }
  .c-border-link.-white {
    color: white;
    background-color: transparent; }
    .c-border-link.-white:hover {
      color: black;
      background-color: white; }

.c-more-link {
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px 0;
  border-color: #b2b2b2;
  position: relative; }
  @media (min-width: 769px) {
    .c-more-link {
      width: 364px;
      height: 49px; }
      .c-more-link.-small {
        width: 272px; } }
  .c-more-link::before, .c-more-link::after {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    left: 0;
    transition: all 0.3s; }
  .c-more-link::before {
    top: -1px;
    background-color: #323e95; }
  .c-more-link::after {
    bottom: -1px;
    background-color: #d80031; }
  .c-more-link.-accordion::before, .c-more-link.-accordion::after, .c-more-link.-reverse::before, .c-more-link.-reverse::after {
    left: inherit;
    right: 0;
    width: 100%; }
  .c-more-link:hover {
    opacity: 0.7 !important; }
  .c-more-link:hover::before, .c-more-link:hover::after {
    width: 100%; }
  .c-more-link.-reverse:hover::before, .c-more-link.-reverse:hover::after {
    width: 0; }
  .c-more-link__img {
    width: 137px;
    margin-right: 10px; }
    @media (min-width: 769px) {
      .c-more-link__img {
        width: 147px; } }
  .c-more-link__label {
    font-size: 1.4rem;
    white-space: nowrap; }
    @media (min-width: 769px) {
      .c-more-link__label {
        font-size: 1.5rem; } }
  .c-more-link__icon::after {
    content: "\e900";
    /* stylelint-disable-next-line */
    font-family: Premier;
    font-size: 1rem;
    font-style: normal;
    color: #b2b2b2;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%); }
  .c-more-link.-accordion {
    width: 100%;
    height: 50px;
    margin: 0 auto; }
    @media (min-width: 769px) {
      .c-more-link.-accordion {
        height: 79px; } }
  .c-more-link.-accordion .c-more-link__icon::after {
    content: "\e908"; }
    @media (min-width: 769px) {
      .c-more-link.-accordion .c-more-link__icon::after {
        font-size: 1.3rem;
        right: 34px; } }
  .c-more-link.-accordion .c-more-link__img {
    width: 108px;
    margin-right: 8px; }
    @media (min-width: 769px) {
      .c-more-link.-accordion .c-more-link__img {
        width: 220px;
        margin-right: 16px; } }
  .c-more-link.-accordion .c-more-link__label {
    font-size: 1.4rem; }
    @media (min-width: 769px) {
      .c-more-link.-accordion .c-more-link__label {
        font-size: 2.3rem; } }

.c-anchor-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  padding: 15px 20px;
  position: relative; }
  @media (min-width: 769px) {
    .c-anchor-link {
      height: 71px;
      padding: 0 25px;
      opacity: 1 !important;
      transition: opacity 0.3s; }
      .c-anchor-link:hover {
        opacity: 0.7 !important; } }
  .c-anchor-link::after {
    /* stylelint-disable-next-line */
    font-family: Premier;
    content: "\e908";
    font-size: 1.3rem;
    color: gray;
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%); }
    @media (min-width: 769px) {
      .c-anchor-link::after {
        right: 26px; } }
  @media (min-width: 769px) {
    .c-anchor-link.-row2 {
      height: 168px; } }
  .c-anchor-link__ttl {
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: bold;
    white-space: nowrap; }
    @media (min-width: 769px) {
      .c-anchor-link__ttl {
        font-size: 2.2rem; } }
  .c-anchor-link__txt {
    font-size: 1.2rem;
    line-height: 1.5; }
    @media (min-width: 769px) {
      .c-anchor-link__txt {
        font-size: 1.6rem; } }
  .c-anchor-link__annot {
    font-size: 1.2rem; }

.c-board-link {
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media (min-width: 769px) {
    .c-board-link {
      height: 280px; } }
  .c-board-link.-ecole {
    background-color: #323e95; }
    @media (max-width: 768.98px) {
      .c-board-link.-ecole {
        padding-top: 10px; } }
    @media (min-width: 769px) {
      .c-board-link.-ecole {
        padding-bottom: 10px; } }
  .c-board-link.-visa {
    background-color: #4d4d4d; }
    @media (max-width: 768.98px) {
      .c-board-link.-visa {
        padding-top: 10px; } }
    @media (min-width: 769px) {
      .c-board-link.-visa {
        padding-bottom: 9px; } }
  .c-board-link.-traduction {
    background-color: #d80031; }
    @media (max-width: 768.98px) {
      .c-board-link.-traduction {
        padding-top: 10px; } }
    @media (min-width: 769px) {
      .c-board-link.-traduction {
        padding-bottom: 10px; } }
  .c-board-link__icon {
    color: white; }
    .c-board-link__icon.pi-ecole {
      font-size: 6rem;
      margin-bottom: 12px; }
      @media (min-width: 769px) {
        .c-board-link__icon.pi-ecole {
          font-size: 9.2rem;
          margin-bottom: 27px; } }
    .c-board-link__icon.pi-visa {
      font-size: 6rem;
      margin-bottom: 12px; }
      @media (min-width: 769px) {
        .c-board-link__icon.pi-visa {
          font-size: 9.2rem;
          margin-bottom: 29px; } }
    .c-board-link__icon.pi-traduction {
      font-size: 5.4rem;
      margin-bottom: 17px; }
      @media (min-width: 769px) {
        .c-board-link__icon.pi-traduction {
          font-size: 8.2rem;
          margin-bottom: 41px; } }
  .c-board-link__label-container {
    display: flex;
    flex-direction: column; }
    @media (min-width: 769px) {
      .c-board-link__label-container {
        width: 100%;
        align-items: center; } }
  @media (max-width: 768.98px) {
    .c-board-link__label-fr {
      display: none !important; } }
  .c-board-link__label-fr.-ecole {
    width: 63px;
    margin-bottom: 27px; }
  .c-board-link__label-fr.-visa {
    width: 220px;
    margin-bottom: 23px; }
  .c-board-link__label-fr.-traduction {
    width: 124px;
    margin-bottom: 27px; }
  .c-board-link__label-ja {
    font-size: 1.5rem;
    white-space: nowrap;
    color: white;
    font-weight: bold;
    line-height: 1.4; }
    @media (max-width: 768.98px) {
      .c-board-link__label-ja {
        text-align: center; } }
    @media (min-width: 769px) {
      .c-board-link__label-ja {
        font-size: 2.4rem;
        line-height: 1; } }

.c-website-link {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  white-space: nowrap; }
  @media (min-width: 769px) {
    .c-website-link {
      font-size: 1.6rem; } }
  .c-website-link::after {
    content: "\e900";
    /* stylelint-disable-next-line */
    font-family: Premier;
    font-size: 1rem;
    line-height: 1;
    margin: 3px 2px 0 6px; }
  .c-website-link__icon {
    font-size: 1.5rem;
    margin-right: 3px; }
    @media (min-width: 769px) {
      .c-website-link__icon {
        font-size: 1.9rem; } }

.c-helper-btn {
  width: 100%;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1 !important;
  position: relative;
  transition: all 0.3s; }
  .c-helper-btn::before, .c-helper-btn::after {
    content: "";
    height: 2px;
    width: 0;
    position: absolute;
    transition: all 0.3s; }
  .c-helper-btn::before {
    top: 0;
    right: 0; }
  .c-helper-btn::after {
    bottom: 0;
    left: 0; }
  .c-helper-btn__icon {
    color: white;
    transition: all 0.3s; }
    .c-helper-btn__icon.pi-mail {
      font-size: 3.9rem;
      margin-right: 15px; }
      @media (min-width: 769px) {
        .c-helper-btn__icon.pi-mail {
          font-size: 5.6rem;
          margin-right: 22px; } }
    .c-helper-btn__icon.pi-inscription {
      font-size: 4.5rem;
      margin-right: 15px; }
      @media (min-width: 769px) {
        .c-helper-btn__icon.pi-inscription {
          font-size: 6.4rem;
          margin-right: 22px; } }
  .c-helper-btn__txt-icon {
    color: white;
    transition: all 0.3s; }
    .c-helper-btn__txt-icon.pi-txt-contact {
      font-size: 2rem;
      margin-bottom: 10px; }
      @media (min-width: 769px) {
        .c-helper-btn__txt-icon.pi-txt-contact {
          font-size: 2.8rem;
          margin-bottom: 15px; } }
    .c-helper-btn__txt-icon.pi-txt-inscription {
      font-size: 2.35rem;
      margin-bottom: 8px; }
      @media (min-width: 769px) {
        .c-helper-btn__txt-icon.pi-txt-inscription {
          font-size: 3.3rem;
          margin-bottom: 10px; } }
  .c-helper-btn__label-container {
    display: flex;
    flex-direction: column; }
  .c-helper-btn__img-txt.-contact {
    width: 200px;
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .c-helper-btn__img-txt.-contact {
        width: 280px;
        margin-bottom: 15px; } }
  .c-helper-btn__img-txt.-inscription {
    width: 131px;
    margin-bottom: 3px; }
    @media (min-width: 769px) {
      .c-helper-btn__img-txt.-inscription {
        width: 187px;
        margin-bottom: 10px; } }
  .c-helper-btn__label {
    font-size: 1.2rem;
    line-height: 1;
    color: white !important;
    white-space: nowrap;
    transition: all 0.3s; }
    @media (min-width: 769px) {
      .c-helper-btn__label {
        font-size: 1.7rem; } }
  .c-helper-btn.-contact::before, .c-helper-btn.-contact::after {
    background-color: #323e95; }
  .c-helper-btn.-inscription::before, .c-helper-btn.-inscription::after {
    background-color: #d80031; }
  @media (min-width: 769px) {
    .c-helper-btn {
      width: 477px;
      height: 134px; } }
  .c-helper-btn.-contact {
    background-color: #323e95; }
    .c-helper-btn.-contact:hover .c-helper-btn__icon,
    .c-helper-btn.-contact:hover .c-helper-btn__label,
    .c-helper-btn.-contact:hover .c-helper-btn__txt-icon {
      color: #323e95 !important; }
  .c-helper-btn.-inscription {
    background-color: #d80031; }
    .c-helper-btn.-inscription:hover .c-helper-btn__icon,
    .c-helper-btn.-inscription:hover .c-helper-btn__label,
    .c-helper-btn.-inscription:hover .c-helper-btn__txt-icon {
      color: #d80031 !important; }
  .c-helper-btn:hover {
    background-color: transparent !important; }
  .c-helper-btn:hover::before, .c-helper-btn:hover::after {
    width: 100%;
    transition: all 0.3s 0.15s; }

.c-hamburger-btn {
  width: 58px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 2px;
  z-index: 1; }
  @media (min-width: 769px) {
    .c-hamburger-btn {
      display: none; } }
  .c-hamburger-btn__bar {
    width: calc(100% - 30px);
    height: 3px;
    position: relative;
    background-color: white;
    transition: all 0.3s;
    /* stylelint-disable-next-line */
    /* stylelint-disable-next-line */ }
    body.is-opened--drawer-menu .c-hamburger-btn__bar {
      background-color: transparent !important; }
    .c-hamburger-btn__bar::before, .c-hamburger-btn__bar::after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      position: absolute;
      background-color: white;
      transition: all 0.3s; }
      body.is-opened--drawer-menu .c-hamburger-btn__bar::before, body.is-opened--drawer-menu .c-hamburger-btn__bar::after {
        background-color: black; }
    .c-hamburger-btn__bar::before {
      top: -9px; }
      body.is-opened--drawer-menu .c-hamburger-btn__bar::before {
        top: 0;
        transform: rotate(225deg); }
    .c-hamburger-btn__bar::after {
      top: 9px; }
      body.is-opened--drawer-menu .c-hamburger-btn__bar::after {
        top: 0;
        transform: rotate(-225deg); }
  body.-lower .c-hamburger-btn__bar,
  .l-header.-fixed .c-hamburger-btn__bar {
    background-color: black; }
    body.-lower .c-hamburger-btn__bar::before, body.-lower .c-hamburger-btn__bar::after,
    .l-header.-fixed .c-hamburger-btn__bar::before,
    .l-header.-fixed .c-hamburger-btn__bar::after {
      background-color: black; }

.c-accordion-close-btn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 2px 0;
  border-color: #b2b2b2;
  position: relative;
  transition: all 0.3s; }
  @media (min-width: 769px) {
    .c-accordion-close-btn {
      height: 79px; } }
  @media (min-width: 769px) {
    .c-accordion-close-btn:hover {
      opacity: 0.7 !important; } }
  .c-accordion-close-btn__txt {
    font-size: 1.8rem;
    color: #4d4d4d; }
    @media (min-width: 769px) {
      .c-accordion-close-btn__txt {
        font-size: 2.5rem; } }
  .c-accordion-close-btn__angle-icon::after {
    content: "\e910";
    /* stylelint-disable-next-line */
    font-family: Premier;
    font-size: 1rem;
    font-style: normal;
    color: #4d4d4d;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%); }
    @media (min-width: 769px) {
      .c-accordion-close-btn__angle-icon::after {
        font-size: 13px;
        right: 32px;
        margin-top: 4px; } }

.c-paragraph.-large {
  font-size: 1.5rem; }
  @media (min-width: 769px) {
    .c-paragraph.-large {
      font-size: 1.8rem; } }

.c-marker-txt {
  display: inline-flex;
  background-color: #8990c1;
  font-size: 1.6rem;
  font-weight: bold;
  color: white;
  padding: 3px 15px; }
  @media (min-width: 769px) {
    .c-marker-txt {
      font-size: 2.5rem;
      padding: 3px 22px; } }

.c-annot {
  font-size: 1.2rem; }

@media (max-width: 768.98px) {
  .c-slash-txt__item {
    display: block; } }

@media (min-width: 769px) {
  .c-slash-txt__item:not(:last-of-type)::after {
    content: "／";
    display: inline; } }

.c-top-main-nav {
  display: flex;
  width: calc(100% - 40px);
  position: relative;
  z-index: 5; }
  @media (max-width: 768.98px) {
    .c-top-main-nav {
      flex-direction: column;
      margin: 0 auto; } }
  @media (min-width: 769px) {
    .c-top-main-nav {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; } }
  @media (max-width: 768.98px) {
    .c-top-main-nav__col {
      width: 100%; } }
  @media (min-width: 769px) {
    .c-top-main-nav__col {
      width: calc(100% / 3);
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: relative;
      opacity: 1;
      transition: opacity 0.3s; }
      body.is-opening-ended .c-top-main-nav__col {
        cursor: pointer; }
      .c-top-main-nav__col::before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.8;
        transition: all 0.3s; }
      body.is-opening-ended .c-top-main-nav__col:hover {
        opacity: 1 !important; }
        body.is-opening-ended .c-top-main-nav__col:hover::before {
          opacity: 0.7; }
      body.is-opening-ended .c-top-main-nav__col:hover .c-top-main-nav__col__scale-container {
        transform: scale(1.15); } }
  @media (max-width: 768.98px) {
    .c-top-main-nav__col.-ecole {
      order: 1; } }
  @media (min-width: 769px) {
    .c-top-main-nav__col.-ecole::before {
      background-color: #323e95; } }
  @media (max-width: 768.98px) {
    .c-top-main-nav__col.-visa {
      order: 0; } }
  @media (max-width: 768.98px) {
    .c-top-main-nav__col.-traduction {
      order: 2; } }
  @media (min-width: 769px) {
    .c-top-main-nav__col.-traduction::before {
      background-color: #d80031; } }
  @media (min-width: 769px) {
    .c-top-main-nav__col__inner {
      height: 325px; } }
  .c-top-main-nav__col__scale-container {
    transform: scale(1);
    transform-origin: bottom center;
    transition: transform 0.3s; }
  .c-top-main-nav__title {
    position: relative; }
    @media (max-width: 768.98px) {
      .c-top-main-nav__title:not(.-traduction) {
        border-bottom: solid 1px white; } }
    @media (min-width: 769px) {
      .c-top-main-nav__title {
        margin-bottom: 16px; } }
    .c-top-main-nav__title__link {
      display: flex;
      align-items: center; }
      @media (max-width: 768.98px) {
        .c-top-main-nav__title__link {
          height: 75px; } }
      @media (min-width: 769px) {
        .c-top-main-nav__title__link {
          flex-direction: column; } }
    .c-top-main-nav__title__icon {
      color: white; }
      @media (max-width: 768.98px) {
        .c-top-main-nav__title__icon.pi-ecole {
          font-size: 4.7rem;
          margin-right: 19px; } }
      @media (min-width: 769px) {
        .c-top-main-nav__title__icon.pi-ecole {
          font-size: 6.3rem;
          margin-bottom: 15px; } }
      @media (max-width: 768.98px) {
        .c-top-main-nav__title__icon.pi-visa {
          font-size: 4.7rem;
          margin-right: 9px; } }
      @media (min-width: 769px) {
        .c-top-main-nav__title__icon.pi-visa {
          font-size: 6.1rem;
          margin-bottom: 17px; } }
      @media (max-width: 768.98px) {
        .c-top-main-nav__title__icon.pi-traduction {
          font-size: 4.2rem;
          margin-right: 19px; } }
      @media (min-width: 769px) {
        .c-top-main-nav__title__icon.pi-traduction {
          font-size: 5.5rem;
          margin-bottom: 23px; } }
    .c-top-main-nav__title__txt-container {
      display: flex;
      flex-direction: column; }
      @media (min-width: 769px) {
        .c-top-main-nav__title__txt-container {
          width: 100%;
          align-items: center; } }
    .c-top-main-nav__title__img-txt.-ecole {
      width: 64px;
      margin-bottom: 10px; }
      @media (min-width: 769px) {
        .c-top-main-nav__title__img-txt.-ecole {
          width: 66px;
          margin-bottom: 10px; } }
    .c-top-main-nav__title__img-txt.-visa {
      width: 234px;
      margin-bottom: 4px; }
      @media (min-width: 769px) {
        .c-top-main-nav__title__img-txt.-visa {
          width: 252px;
          margin-bottom: 5px; } }
    .c-top-main-nav__title__img-txt.-traduction {
      width: 123px;
      margin-bottom: 10px; }
      @media (min-width: 769px) {
        .c-top-main-nav__title__img-txt.-traduction {
          width: 132px;
          margin-bottom: 9px; } }
    .c-top-main-nav__title__txt {
      font-size: 1.6rem;
      white-space: nowrap;
      color: white;
      font-weight: bold; }
      @media (max-width: 768.98px) {
        .c-top-main-nav__title__txt {
          line-height: 1; } }
      @media (min-width: 769px) {
        .c-top-main-nav__title__txt {
          font-size: 1.8rem; } }
    .c-top-main-nav__title__angle-icon {
      color: white;
      font-size: 1.4rem;
      margin: 0 10px 0 auto; }
      @media (min-width: 769px) {
        .c-top-main-nav__title__angle-icon {
          display: none; } }
  .c-top-main-nav__list {
    width: 202px;
    position: relative;
    margin: 0 auto; }
    @media (max-width: 768.98px) {
      .c-top-main-nav__list {
        display: none; } }
  .c-top-main-nav__item {
    border-bottom: solid 1px white; }
    .c-top-main-nav__item:first-of-type {
      border-top: solid 1px white; }
  .c-top-main-nav__link {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: white;
    position: relative; }
    .c-top-main-nav__link:hover {
      color: white; }
  .c-top-main-nav__icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    font-size: 10px; }
  @media (min-width: 769px) {
    body.is-opening-ended .c-top-main-nav:hover .c-top-main-nav__col {
      opacity: 0.6; }
    body.is-opening-ended .c-top-main-nav:hover .c-top-main-nav__col.-visa::before {
      background-color: black;
      opacity: 0.2; } }

.c-tab-nav {
  position: relative; }
  .c-tab-nav::before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #cbcbcb;
    position: absolute;
    bottom: 0;
    left: 0; }
  .c-tab-nav__list {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768.98px) {
      .c-tab-nav__list {
        width: 100%;
        padding: 0 19px; } }
    @media (min-width: 769px) {
      .c-tab-nav__list {
        width: 960px;
        margin: 0 auto; } }
  .c-tab-nav__item {
    height: 88px;
    position: relative; }
    @media (min-width: 769px) {
      .c-tab-nav__item {
        height: 116px; } }
  .c-tab-nav__link {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #323e95;
    font-size: 1.2rem;
    line-height: 1.3;
    color: white !important;
    white-space: nowrap;
    position: relative; }
    @media (max-width: 768.98px) {
      .c-tab-nav__link {
        text-align: center; } }
    @media (min-width: 769px) {
      .c-tab-nav__link {
        height: 72px;
        font-size: 2rem; } }
    .c-tab-nav__link:not(.-current) {
      opacity: 0.5;
      transition: opacity 0.3s; }
    .c-tab-nav__link:hover {
      opacity: 1; }
    .c-tab-nav__link::before, .c-tab-nav__link::after {
      content: "";
      display: block;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
      z-index: 1; }
    .c-tab-nav__link::before {
      height: 8px;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      border-left: solid 5px transparent;
      border-right: solid 5px transparent;
      border-top: solid 8px #323e95; }
      @media (min-width: 769px) {
        .c-tab-nav__link::before {
          height: 15px;
          bottom: -15px;
          border-left: solid 13px transparent;
          border-right: solid 13px transparent;
          border-top: solid 13px #323e95; } }
    .c-tab-nav__link::after {
      width: 100%;
      height: 2px;
      bottom: -28px;
      left: 0;
      background-color: #323e95; }
      @media (min-width: 769px) {
        .c-tab-nav__link::after {
          bottom: -44px; } }
    .c-tab-nav__link.-current::before, .c-tab-nav__link.-current::after {
      visibility: visible;
      opacity: 1; }
  .c-tab-nav.-col3 .c-tab-nav__item {
    width: calc((100% - 10px) / 3); }
    @media (min-width: 769px) {
      .c-tab-nav.-col3 .c-tab-nav__item {
        width: 310px; } }
  .c-tab-nav.-col4 .c-tab-nav__item {
    width: calc((100% - 15px) / 4); }
    @media (min-width: 769px) {
      .c-tab-nav.-col4 .c-tab-nav__item {
        width: 228px; } }

@media (min-width: 769px) {
  .c-anchor-nav {
    display: flex;
    justify-content: space-between; } }

@media (min-width: 769px) {
  .c-anchor-nav__left, .c-anchor-nav__right {
    width: calc((100% - 16px) / 2); } }

.c-anchor-nav__item {
  margin-bottom: 22px; }
  @media (min-width: 769px) {
    .c-anchor-nav__item {
      margin-bottom: 25px; } }

@media (max-width: 768.98px) {
  .c-anchor-nav.-ecole-list-map .c-anchor-link {
    padding-top: 17px;
    padding-bottom: 17px;
    padding-right: 65px; } }

@media (min-width: 769px) {
  .c-anchor-nav.-ecole-list-map .c-anchor-link {
    display: block;
    height: 168px;
    padding-top: 32px; } }

.c-anchor-nav.-ecole-list-map .c-anchor-link__ttl {
  margin-bottom: 11px; }
  @media (min-width: 769px) {
    .c-anchor-nav.-ecole-list-map .c-anchor-link__ttl {
      margin-bottom: 11px; } }

.c-anchor-nav.-ecole-list-map .c-anchor-link__txt {
  line-height: 1.6; }
  @media (max-width: 768.98px) {
    .c-anchor-nav.-ecole-list-map .c-anchor-link__txt {
      font-size: 1.5rem; } }
  @media (min-width: 769px) {
    .c-anchor-nav.-ecole-list-map .c-anchor-link__txt {
      line-height: 1.9; } }

@media (min-width: 769px) {
  .c-anchor-nav.-ecole-list-map .c-anchor-nav__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

.c-anchor-nav.-ecole-list-map .c-anchor-nav__item {
  margin-bottom: 18px; }
  @media (min-width: 769px) {
    .c-anchor-nav.-ecole-list-map .c-anchor-nav__item {
      width: calc((100% - 16px) / 2); } }

.c-basic-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  /* stylelint-disable-next-line */
  /* stylelint-disable-next-line */ }
  .c-basic-table__th, .c-basic-table__td {
    border: solid 1px #989898;
    padding: 8px 10px; }
    @media (min-width: 769px) {
      .c-basic-table__th, .c-basic-table__td {
        border: solid 1px #989898;
        padding: 18px 30px; } }
  .c-basic-table__th {
    background-color: #f5f5f5;
    font-weight: normal; }
  .c-basic-table__td {
    line-height: 1.7; }
    @media (max-width: 768.98px) {
      .c-basic-table__td {
        font-size: 1.3rem; }
        .c-basic-table__td.-sp-none {
          display: none !important; }
        .c-basic-table__td.-sp100 {
          width: 100% !important;
          border-left: solid 1px #989898 !important; } }
    @media (min-width: 769px) {
      .c-basic-table__td {
        line-height: 1.9; } }
  @media (max-width: 768.98px) {
    .c-basic-table:not(.-no-flex) {
      display: flex;
      flex-direction: column;
      border-top: solid 1px #989898; } }
  @media (max-width: 768.98px) {
    .c-basic-table:not(.-no-flex)__tr {
      width: 100%;
      display: flex;
      flex-wrap: wrap; } }
  @media (max-width: 768.98px) {
    .c-basic-table:not(.-no-flex)__th, .c-basic-table:not(.-no-flex)__td {
      border-top: none; } }
  @media (max-width: 768.98px) {
    .c-basic-table:not(.-no-flex)__th {
      width: 100%;
      text-align: center; } }
  @media (min-width: 769px) {
    .c-basic-table.-visa-howto .c-basic-table__th {
      width: 258px; } }
  .c-basic-table.-visa-howto .c-basic-table__td:nth-of-type(1) {
    width: 54px;
    text-align: center;
    vertical-align: middle;
    padding: 0; }
    @media (max-width: 768.98px) {
      .c-basic-table.-visa-howto .c-basic-table__td:nth-of-type(1) {
        display: flex;
        justify-content: center;
        align-items: center; } }
    @media (min-width: 769px) {
      .c-basic-table.-visa-howto .c-basic-table__td:nth-of-type(1) {
        width: 127px; } }
  @media (max-width: 768.98px) {
    .c-basic-table.-visa-howto .c-basic-table__td:nth-of-type(2) {
      width: calc(100% - 54px);
      border-left: none; } }
  .c-basic-table__dt {
    font-weight: bold; }
    .c-basic-table__dt:not(:first-of-type) {
      margin-top: 2px; }
  .c-basic-table.-bg-white {
    background-color: white; }
  .c-basic-table.-bg-white .c-basic-table__th {
    font-weight: bold;
    background-color: white; }
  @media (max-width: 768.98px) {
    .c-basic-table.-col2 .c-basic-table__td:nth-of-type(1) {
      width: 100%;
      padding: 8px 0; } }
  .c-basic-table.-prix {
    width: 100%; }
    @media (min-width: 769px) {
      .c-basic-table.-prix {
        width: 650px;
        margin: 0 auto; } }
  .c-basic-table.-prix .c-basic-table__th {
    width: 140px;
    background-color: #f5f5f5; }
    @media (min-width: 769px) {
      .c-basic-table.-prix .c-basic-table__th {
        width: 260px; } }
  .c-basic-table.-prix .c-basic-table__td {
    background-color: white;
    text-align: right; }

.c-prix-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: white; }
  @media (max-width: 768.98px) {
    .c-prix-table {
      display: flex;
      flex-direction: column;
      border-top: solid 1px #989898; } }
  @media (max-width: 768.98px) {
    .c-prix-table__tr {
      width: 100%;
      display: flex;
      flex-wrap: wrap; } }
  .c-prix-table__th, .c-prix-table__td {
    border: solid 1px #989898; }
    @media (max-width: 768.98px) {
      .c-prix-table__th, .c-prix-table__td {
        border-top: none; } }
    @media (min-width: 769px) {
      .c-prix-table__th, .c-prix-table__td {
        border: solid 1px #989898; } }
  .c-prix-table__th {
    width: 100%;
    background-color: #f5f5f5;
    font-weight: normal; }
    @media (max-width: 768.98px) {
      .c-prix-table__th {
        text-align: center;
        padding: 7px 0; } }
    @media (min-width: 769px) {
      .c-prix-table__th {
        width: 210px;
        padding-left: 10px; } }
  .c-prix-table__td {
    line-height: 1.7; }
    @media (max-width: 768.98px) {
      .c-prix-table__td {
        font-size: 1.3rem;
        width: 100% !important; } }
    @media (min-width: 769px) {
      .c-prix-table__td {
        line-height: 1.9; } }

.c-prix-sub-table {
  width: 100%; }
  @media (min-width: 769px) {
    .c-prix-sub-table {
      display: flex; } }
  .c-prix-sub-table__service-type {
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap; }
    @media (max-width: 768.98px) {
      .c-prix-sub-table__service-type {
        height: 30px;
        justify-content: center;
        font-weight: bold;
        border-bottom: solid 1px #989898; } }
    @media (min-width: 769px) {
      .c-prix-sub-table__service-type {
        width: 190px;
        padding-left: 10px; } }
  .c-prix-sub-table__list {
    flex-grow: 1; }
  .c-prix-sub-table__item {
    display: flex;
    width: 100%;
    padding: 8px 10px; }
    @media (max-width: 768.98px) {
      .c-prix-sub-table__item {
        flex-wrap: wrap;
        justify-content: flex-end; } }
    @media (min-width: 769px) {
      .c-prix-sub-table__item {
        padding: 8px 10px 8px 0; } }
    .c-prix-sub-table__item:not(:last-of-type) {
      border-bottom: solid 1px #989898; }
  .c-prix-sub-table__ttl {
    margin-right: auto; }
    @media (max-width: 768.98px) {
      .c-prix-sub-table__ttl {
        width: 100%; } }
  .c-prix-sub-table__type {
    margin-right: 15px; }

.c-border-table {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0; }
  @media (max-width: 768.98px) {
    .c-border-table__tr {
      border-bottom: solid 1px #989898; }
      .c-border-table__tr:first-of-type {
        border-top: solid 1px #989898; } }
  @media (min-width: 769px) {
    .c-border-table__tr:not(:last-of-type) {
      border-bottom: solid 1px #989898; } }
  .c-border-table__th, .c-border-table__td {
    font-size: 1.5rem;
    padding: 7px 0; }
    @media (max-width: 768.98px) {
      .c-border-table__th, .c-border-table__td {
        line-height: 1.7; } }
    @media (min-width: 769px) {
      .c-border-table__th, .c-border-table__td {
        font-size: 1.8rem;
        padding: 8px 0; } }
  .c-border-table__th {
    width: 131px;
    font-weight: bold;
    vertical-align: top; }
    @media (min-width: 769px) {
      .c-border-table__th {
        width: 158px; } }

.c-separate-table {
  width: 100%; }
  @media (max-width: 768.98px) {
    .c-separate-table {
      display: block; }
      .c-separate-table thead,
      .c-separate-table tbody {
        display: block; } }
  @media (min-width: 769px) {
    .c-separate-table {
      width: calc(100% + 40px);
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 20px;
      margin-left: -20px; } }
  @media (max-width: 768.98px) {
    .c-separate-table__tr {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }
  .c-separate-table__th, .c-separate-table__td {
    min-height: 49px;
    vertical-align: middle;
    font-size: 1.5rem;
    line-height: 1.5; }
    @media screen and (max-width: 320px) {
      .c-separate-table__th, .c-separate-table__td {
        font-size: 1.3rem; } }
    @media (max-width: 768.98px) {
      .c-separate-table__th, .c-separate-table__td {
        display: flex;
        align-items: center;
        margin-bottom: 5px; } }
    @media (min-width: 769px) {
      .c-separate-table__th, .c-separate-table__td {
        height: 56px;
        font-size: 1.6rem;
        line-height: 1.65; }
        .c-separate-table__th:last-child, .c-separate-table__td:last-child {
          border-right: none; } }
  .c-separate-table__th {
    background-color: #efefef;
    font-weight: normal;
    text-align: center; }
    @media (max-width: 768.98px) {
      .c-separate-table__th {
        justify-content: center;
        align-items: center; } }
    @media (min-width: 769px) {
      .c-separate-table__th {
        width: 228px; } }
  .c-separate-table__td {
    padding-left: 5px; }

.c-semester-table {
  width: 100%; }
  @media (max-width: 768.98px) {
    .c-semester-table {
      display: block; } }
  @media (min-width: 769px) {
    .c-semester-table {
      table-layout: fixed;
      border-collapse: collapse;
      border-spacing: 0; } }
  @media (max-width: 768.98px) {
    .c-semester-table__tr {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 36px; }
      .c-semester-table__tr::after {
        content: "※登録料98€が別途必要";
        display: block;
        text-align: right;
        width: 100%;
        font-size: 1.3rem;
        line-height: 1; } }
  @media (max-width: 768.98px) {
    .c-semester-table__thead {
      display: none; } }
  @media (max-width: 768.98px) {
    .c-semester-table__tbody {
      display: block; } }
  .c-semester-table__th, .c-semester-table__td {
    font-size: 1.5rem; }
    @media screen and (max-width: 320px) {
      .c-semester-table__th, .c-semester-table__td {
        font-size: 1.3rem; } }
    @media (max-width: 768.98px) {
      .c-semester-table__th, .c-semester-table__td {
        line-height: 1.5; } }
    @media (min-width: 769px) {
      .c-semester-table__th, .c-semester-table__td {
        border: solid 1px #989898;
        font-size: 1.6rem; } }
  .c-semester-table__th {
    background-color: #efefef;
    font-weight: normal;
    text-align: center;
    padding: 8px 31px; }
    @media (min-width: 769px) {
      .c-semester-table__th {
        font-size: 1.6rem; } }
    .c-semester-table__th.-period {
      width: 394px; }
    .c-semester-table__th.-code {
      width: 142px; }
    .c-semester-table__th.-content {
      width: 282px; }
  .c-semester-table__td {
    line-height: 1.5; }
    @media (max-width: 768.98px) {
      .c-semester-table__td {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px; } }
    @media (min-width: 769px) {
      .c-semester-table__td {
        line-height: 1.9;
        padding: 20px 31px; } }
    @media (max-width: 768.98px) {
      .c-semester-table__td.-period::before, .c-semester-table__td.-code::before, .c-semester-table__td.-content::before, .c-semester-table__td.-tuition::before {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 100%;
        white-space: nowrap;
        background-color: #efefef;
        margin-bottom: 10px; } }
    .c-semester-table__td.-code, .c-semester-table__td.-content, .c-semester-table__td.-tuition {
      text-align: center; }
    @media (min-width: 769px) {
      .c-semester-table__td {
        font-size: 1.6rem; } }
    .c-semester-table__td.-period {
      vertical-align: middle; }
      @media (max-width: 768.98px) {
        .c-semester-table__td.-period {
          width: 100%; }
          .c-semester-table__td.-period::before {
            content: "期　間"; } }
    @media (max-width: 768.98px) {
      .c-semester-table__td.-code {
        width: 25%; }
        .c-semester-table__td.-code::before {
          content: "コード"; } }
    @media (max-width: 768.98px) {
      .c-semester-table__td.-content {
        width: 50%;
        border-left: solid 2px white;
        border-right: solid 2px white; }
        .c-semester-table__td.-content::before {
          content: "内　容"; } }
    @media (max-width: 768.98px) {
      .c-semester-table__td.-tuition {
        width: 25%; }
        .c-semester-table__td.-tuition::before {
          content: "学　費"; } }
  .c-semester-table__dt {
    font-weight: bold; }

.c-howto-table {
  width: 100%;
  border: solid 1px #989898;
  background-color: white; }
  @media (max-width: 768.98px) {
    .c-howto-table {
      border-top: none; } }
  @media (min-width: 769px) {
    .c-howto-table {
      display: flex; } }
  @media (min-width: 769px) {
    .c-howto-table__left, .c-howto-table__right {
      width: 50%; } }
  @media (min-width: 769px) {
    .c-howto-table__left {
      border-right: solid 1px #989898; } }
  .c-howto-table__th {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
    border-bottom: solid 1px #989898;
    padding: 13px 0; }
    @media (max-width: 768.98px) {
      .c-howto-table__th {
        width: 100%;
        text-align: center;
        border-top: solid 1px #989898; } }
    @media (min-width: 769px) {
      .c-howto-table__th {
        font-size: 2rem;
        padding: 16px 0; } }
  .c-howto-table__td {
    vertical-align: top;
    padding: 16px 20px; }
    @media (min-width: 769px) {
      .c-howto-table__td {
        font-size: 2rem;
        padding: 21px 30px 30px; } }
  .c-howto-table__ttl {
    font-size: 1.6rem;
    font-weight: bold;
    border-bottom: solid 1px #989898;
    padding-bottom: 3px;
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .c-howto-table__ttl {
        font-size: 2rem;
        padding-bottom: 1px;
        margin-bottom: 12px; } }
  .c-howto-table__txt {
    font-size: 1.5rem;
    line-height: 1.8; }
    @media (min-width: 769px) {
      .c-howto-table__txt {
        font-size: 1.6rem;
        line-height: 1.9; } }
  .c-howto-table__center-txt {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center; }
    @media (min-width: 769px) {
      .c-howto-table__center-txt {
        font-size: 2rem; } }
  .c-howto-table__caret-icon {
    display: block;
    font-size: 2.2rem;
    text-align: center;
    margin-bottom: 20px; }
    @media (min-width: 769px) {
      .c-howto-table__caret-icon {
        font-size: 2.5rem;
        margin-bottom: 28px; } }
  .c-howto-table__board {
    border: solid 1px #989898;
    padding: 15px 18px; }
    @media (min-width: 769px) {
      .c-howto-table__board {
        padding: 21px 24px; } }
    .c-howto-table__board__txt {
      font-size: 1.5rem;
      line-height: 1.5; }
      @media (min-width: 769px) {
        .c-howto-table__board__txt {
          font-size: 1.6rem; } }

.c-translation-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0; }
  @media (max-width: 768.98px) {
    .c-translation-table {
      display: block;
      border-bottom: solid 1px #989898; }
      .c-translation-table__thead {
        display: none; }
      .c-translation-table__tbody {
        width: 100%;
        display: block; } }
  @media (max-width: 768.98px) {
    .c-translation-table__tr {
      display: block; } }
  .c-translation-table__th, .c-translation-table__td {
    border: solid 1px #989898; }
    @media (max-width: 768.98px) {
      .c-translation-table__th, .c-translation-table__td {
        width: 100%;
        display: block;
        border-bottom: none; } }
    @media (min-width: 769px) {
      .c-translation-table__th, .c-translation-table__td {
        border: solid 1px #989898; } }
  .c-translation-table__th {
    background-color: #f5f5f5;
    font-weight: normal;
    line-height: 1.6; }
    @media (min-width: 769px) {
      .c-translation-table__th {
        padding: 14px 0; }
        .c-translation-table__th:nth-of-type(1) {
          width: 500px; }
        .c-translation-table__th:nth-of-type(2) {
          width: 248px; } }
    .c-translation-table__th__inner {
      width: 100%;
      display: flex;
      justify-content: center; }
  .c-translation-table__td {
    background-color: white;
    font-size: 1.6rem;
    line-height: 1.6; }
    @media (max-width: 768.98px) {
      .c-translation-table__td:not(:first-child) {
        padding: 15px 19px; }
      .c-translation-table__td:first-child .c-translation-table__ttl {
        padding: 16px 0; } }
    @media (min-width: 769px) {
      .c-translation-table__td {
        padding: 15px 19px; } }
  @media (max-width: 768.98px) {
    .c-translation-table__ttl {
      text-align: center; } }
  .c-translation-table__sp-th-top {
    background-color: #f5f5f5;
    text-align: center;
    border-bottom: solid 1px #989898;
    padding: 14px 0; }
    @media (min-width: 769px) {
      .c-translation-table__sp-th-top {
        display: none; } }
  .c-translation-table__sp-th {
    margin-bottom: 10px; }
    @media (min-width: 769px) {
      .c-translation-table__sp-th {
        display: none; } }

@media (max-width: 768.98px) {
  .c-more-link-list {
    padding: 0 19px; } }

@media (min-width: 769px) {
  .c-more-link-list {
    display: flex;
    justify-content: center; } }

@media (max-width: 768.98px) {
  .c-more-link-list__item:first-of-type {
    margin-bottom: 27px; } }

@media (min-width: 769px) {
  .c-more-link-list__item:first-of-type {
    margin-right: 50px; } }

.c-step-list__item {
  position: relative; }
  .c-step-list__item:not(:last-of-type) {
    padding-bottom: 79px;
    margin-bottom: 25px; }
    @media (min-width: 769px) {
      .c-step-list__item:not(:last-of-type) {
        padding-bottom: 103px;
        margin-bottom: 61px; } }
    .c-step-list__item:not(:last-of-type)::after {
      content: "\e90e";
      /* stylelint-disable */
      font-family: Premier;
      font-size: 2.6rem;
      color: black;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%); }
  .c-step-list__item .c-step-title {
    margin-bottom: 14px; }
    @media (min-width: 769px) {
      .c-step-list__item .c-step-title {
        margin-bottom: 34px; } }
  .c-step-list__item .c-paragraph {
    line-height: 1.8; }
    @media (min-width: 769px) {
      .c-step-list__item .c-paragraph {
        line-height: 1.9; } }
  .c-step-list__item .c-more-link {
    margin: 0 auto; }
  .c-step-list__item .c-helper-btn {
    margin: 0 auto; }
  .c-step-list__item .c-basic-title {
    margin-bottom: 10px; }
  .c-step-list__item .c-marker-txt {
    margin-bottom: 18px; }

.c-board-link-list {
  display: flex;
  justify-content: space-between; }
  .c-board-link-list__item {
    width: calc(100% / 3); }
    @media (min-width: 769px) {
      .c-board-link-list__item {
        width: 280px; } }

.c-school-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 768.98px) {
    .c-school-list__item {
      width: calc(100% / 2);
      padding: 0 8px;
      margin-bottom: 17px; } }
  @media (min-width: 769px) {
    .c-school-list__item {
      width: 244px;
      padding: 0 8px;
      margin-bottom: 37px; } }
  .c-school-list__link {
    display: block; }
  .c-school-list__img {
    margin-bottom: 6px; }
    @media (min-width: 769px) {
      .c-school-list__img {
        margin-bottom: 15px; } }
  .c-school-list__ttl {
    font-size: 1.3rem;
    text-align: center; }
    @media (min-width: 769px) {
      .c-school-list__ttl {
        font-size: 1.6rem;
        padding-left: 5px; } }

.c-separate-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .c-separate-list__item {
    width: calc((100% - 6px) / 3); }
    @media (min-width: 769px) {
      .c-separate-list__item {
        width: calc((100% - 75px) / 6); } }
  .c-separate-list__label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49px;
    background-color: #efefef;
    margin-bottom: 4px; }
    @media (max-width: 768.98px) {
      .c-separate-list__label {
        font-size: 1.3rem;
        line-height: 1.3;
        text-align: center; } }
    @media (min-width: 769px) {
      .c-separate-list__label {
        height: 43px;
        margin-bottom: 10px; } }
  .c-separate-list__value {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 768.98px) {
      .c-separate-list__value {
        padding-bottom: 4px; } }

.c-qa-list__item {
  margin-bottom: 20px; }
  @media (min-width: 769px) {
    .c-qa-list__item {
      margin-bottom: 31px; } }

.c-qa-list__trigger {
  display: block;
  width: 100%;
  position: relative;
  color: white !important;
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: bold;
  background-color: #323e95;
  opacity: 1 !important;
  padding: 9px 36px 7px 35px; }
  @media (min-width: 769px) {
    .c-qa-list__trigger {
      font-size: 2.4rem;
      padding: 7px 70px 7px 83px; } }
  .c-qa-list__trigger::before, .c-qa-list__trigger::after {
    position: absolute;
    /* stylelint-disable-next-line */
    font-family: Premier;
    line-height: 1; }
  .c-qa-list__trigger::before {
    content: "\e913";
    font-size: 1.7rem;
    left: 14px;
    top: 11px; }
    @media (min-width: 769px) {
      .c-qa-list__trigger::before {
        font-size: 2.1rem;
        left: 34px;
        top: 14px; } }
  .c-qa-list__trigger::after {
    content: "\e908";
    top: 50%;
    right: 12px;
    font-size: 1rem;
    transform: translateY(-50%); }
    @media (min-width: 769px) {
      .c-qa-list__trigger::after {
        right: 32px;
        font-size: 1.3rem; } }

.c-qa-list__body {
  background-color: white; }
  .c-qa-list__body__inner {
    position: relative;
    padding: 18px 20px 17px 38px !important; }
    @media (min-width: 769px) {
      .c-qa-list__body__inner {
        padding: 23px 40px 32px 85px !important; } }
    .c-qa-list__body__inner::before {
      content: "\e912";
      position: absolute;
      top: 14px;
      left: 13px;
      /* stylelint-disable-next-line */
      font-family: Premier;
      font-size: 1.7rem;
      color: #323e95; }
      @media (min-width: 769px) {
        .c-qa-list__body__inner::before {
          top: 20px;
          left: 33px;
          font-size: 1.9rem; } }
  .c-qa-list__body .c-paragraph {
    font-size: 1.5rem;
    line-height: 1.55; }
    @media (min-width: 769px) {
      .c-qa-list__body .c-paragraph {
        font-size: 1.6rem;
        line-height: 1.9;
        letter-spacing: 0.3px; } }

.c-qa-list__item[data-expanded="true"] .c-qa-list__trigger::after {
  content: "\e910"; }

.c-basic-list.-disc {
  list-style-type: disc;
  margin-left: 1.5em; }

.c-basic-list.-attention {
  list-style-type: none;
  margin-left: 1em; }
  .c-basic-list.-attention .c-basic-list__item {
    display: flex; }
    .c-basic-list.-attention .c-basic-list__item::before {
      content: "※";
      white-space: nowrap;
      margin-right: 1px; }

.c-accordion[data-expanded="true"] .c-accordion__trigger .c-more-link__icon::after {
  content: "\e910"; }

.c-accordion__panel {
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: all 0.5s; }
  .c-accordion__panel__inner {
    padding: 21px 0 10px; }
    @media (min-width: 769px) {
      .c-accordion__panel__inner {
        padding: 38px 0 40px; } }

.c-accordion[data-expanded="true"] .c-accordion__panel {
  height: auto;
  opacity: 1;
  visibility: visible; }

@media (min-width: 769px) {
  .c-accordion.-pc-only .c-accordion__trigger,
  .c-accordion.-pc-only .c-accordion__close-btn {
    display: none !important; }
  .c-accordion.-pc-only .c-accordion__panel {
    height: auto !important;
    opacity: 1 !important;
    visibility: visible !important; }
  .c-accordion.-pc-only .c-accordion__panel__inner {
    padding: 0 !important; } }

.c-visa-post__row {
  display: flex;
  margin-bottom: 15px; }
  @media (max-width: 768.98px) {
    .c-visa-post__row {
      flex-direction: column; } }
  @media (min-width: 769px) {
    .c-visa-post__row {
      justify-content: space-between;
      margin-bottom: 25px; } }

.c-visa-post__img {
  flex-shrink: 0;
  margin-top: 3px; }
  @media (max-width: 768.98px) {
    .c-visa-post__img {
      order: 0; } }

@media (max-width: 768.98px) {
  .c-visa-post__body {
    order: 1;
    padding: 0 19px; } }

@media (min-width: 769px) {
  .c-visa-post:not(.-prix) .c-visa-post__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between; } }

@media (min-width: 769px) {
  .c-visa-post.-col2 .c-visa-post__img,
  .c-visa-post.-col2 .c-visa-post__body {
    width: calc((100% - 20px) / 2); } }

@media (max-width: 768.98px) {
  .c-visa-post__ttl {
    display: inline-block;
    background: white;
    padding: 25px 17px 17px;
    margin: -52px 0 0 -19px; } }

.c-visa-post__ttl__fr {
  margin-bottom: 8px; }
  @media (min-width: 769px) {
    .c-visa-post__ttl__fr {
      margin-bottom: 10px; } }
  .c-visa-post__ttl__fr.-etudiant {
    width: 151px; }
    @media (min-width: 769px) {
      .c-visa-post__ttl__fr.-etudiant {
        width: 295px; } }
  .c-visa-post__ttl__fr.-scolarise {
    width: 198px; }
    @media (min-width: 769px) {
      .c-visa-post__ttl__fr.-scolarise {
        width: 388px; } }
  .c-visa-post__ttl__fr.-concours {
    width: 212px; }
    @media (min-width: 769px) {
      .c-visa-post__ttl__fr.-concours {
        width: 416px; } }

.c-visa-post__ttl__ja {
  display: block;
  font-weight: bold; }
  .c-visa-post__ttl__ja__main {
    font-size: 1.8rem; }
    @media (min-width: 769px) {
      .c-visa-post__ttl__ja__main {
        font-size: 2.9rem; } }
  .c-visa-post__ttl__ja__sub {
    font-size: 1.2rem; }
    @media (min-width: 769px) {
      .c-visa-post__ttl__ja__sub {
        font-size: 1.7rem; } }

.c-visa-post__paragraph {
  line-height: 1.8;
  margin-bottom: 43px; }
  @media (max-width: 768.98px) {
    .c-visa-post__paragraph {
      padding: 0 19px; } }
  @media (min-width: 769px) {
    .c-visa-post__paragraph {
      line-height: 2;
      margin-bottom: 16px; } }

.c-visa-post:not(.-col2) .c-visa-post__paragraph {
  margin-top: 25px; }

.c-visa-post__ref-link {
  display: block;
  font-size: 1.2rem; }
  @media (max-width: 768.98px) {
    .c-visa-post__ref-link {
      padding: 0 19px; } }

.c-visa-post__link-list {
  margin-top: 34px; }
  @media (min-width: 769px) {
    .c-visa-post__link-list {
      margin-top: 39px; } }

@media (min-width: 769px) {
  .c-visa-post.-col2.-prix .c-visa-post__img {
    width: calc(100% / 2 - 10px); } }

@media (max-width: 768.98px) {
  .c-visa-post.-col2.-prix .c-visa-post__body {
    margin-top: -30px; } }

@media (min-width: 769px) {
  .c-visa-post.-col2.-prix .c-visa-post__body {
    width: calc(100% / 2 - 35px); } }

.c-visa-post.-prix .c-visa-post__ttl {
  margin-bottom: 20px; }
  @media (max-width: 768.98px) {
    .c-visa-post.-prix .c-visa-post__ttl {
      transform: translateY(-19px);
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 15px;
      padding-bottom: 12px; } }
  .c-visa-post.-prix .c-visa-post__ttl__ja {
    line-height: 1.3; }
    .c-visa-post.-prix .c-visa-post__ttl__ja__main {
      font-size: 1.8rem; }
      @media (min-width: 769px) {
        .c-visa-post.-prix .c-visa-post__ttl__ja__main {
          font-size: 3.6rem; } }
    .c-visa-post.-prix .c-visa-post__ttl__ja__sub {
      font-size: 1.2rem; }
      @media (min-width: 769px) {
        .c-visa-post.-prix .c-visa-post__ttl__ja__sub {
          font-size: 2.2rem; } }

.c-visa-post.-prix .c-visa-post__paragraph {
  font-size: 1.6rem;
  line-height: 1.8;
  margin-bottom: 15px; }
  @media (max-width: 768.98px) {
    .c-visa-post.-prix .c-visa-post__paragraph {
      padding: 0 20px; } }
  @media (min-width: 769px) {
    .c-visa-post.-prix .c-visa-post__paragraph {
      line-height: 1.9;
      margin-bottom: 38px; } }

.c-visa-post.-prix .c-visa-post__board {
  padding: 12px 24px; }
  @media (min-width: 769px) {
    .c-visa-post.-prix .c-visa-post__board {
      padding: 10px 24px; } }

.c-prix-post {
  padding-bottom: 30px; }
  @media (min-width: 769px) {
    .c-prix-post {
      padding-bottom: 90px; } }
  .c-prix-post__ttl {
    margin-bottom: 15px; }
    @media (min-width: 769px) {
      .c-prix-post__ttl {
        margin-bottom: 12px; } }
  .c-prix-post__paragraph {
    line-height: 1.6; }
    @media (min-width: 769px) {
      .c-prix-post__paragraph {
        line-height: 1.9; } }
  .c-prix-post__board {
    padding: 10px 14px 14px;
    margin-top: 20px; }
    @media (min-width: 769px) {
      .c-prix-post__board {
        padding: 44px 40px 48px;
        margin-top: 37px; } }
    .c-prix-post__board .c-board__txt {
      font-size: 1.2rem;
      line-height: 1.7; }
      @media (min-width: 769px) {
        .c-prix-post__board .c-board__txt {
          font-size: 1.6rem;
          line-height: 1.95; } }
  .c-prix-post .c-helper-btn {
    margin: 22px auto 0; }
    @media (min-width: 769px) {
      .c-prix-post .c-helper-btn {
        margin: 80px auto 0; } }

@media (min-width: 769px) {
  .c-avantage-post {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; } }

@media (max-width: 768.98px) {
  .c-avantage-post__img {
    margin-bottom: 30px; } }

@media (min-width: 769px) {
  .c-avantage-post__img {
    width: 465px; } }

@media (max-width: 768.98px) {
  .c-avantage-post__body {
    padding: 0 19px; } }

@media (min-width: 769px) {
  .c-avantage-post__body {
    width: 455px; } }

.c-avantage-post__ttl {
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: bold;
  margin-bottom: 12px; }
  @media (min-width: 769px) {
    .c-avantage-post__ttl {
      font-size: 2.6rem;
      margin: -6px 0 20px; } }

.c-avantage-post__paragraph {
  font-size: 1.3rem; }
  @media (min-width: 769px) {
    .c-avantage-post__paragraph {
      font-size: 1.6rem;
      line-height: 1.9; } }

.c-dormitory-panel {
  border: solid 1px #323e95;
  border-radius: 10px;
  padding: 17px 20px 6px; }
  @media (min-width: 769px) {
    .c-dormitory-panel {
      padding: 30px 47px 26px; } }
  @media screen and (max-width: 320px) {
    .c-dormitory-panel {
      padding: 17px 15px 6px; } }
  .c-dormitory-panel__ttl {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #323e95;
    white-space: nowrap;
    margin-bottom: 11px; }
    @media (min-width: 769px) {
      .c-dormitory-panel__ttl {
        font-size: 2.4rem;
        margin-bottom: 8px; } }
    .c-dormitory-panel__ttl__icon {
      font-size: 1.9rem;
      margin-right: 10px; }
      @media (min-width: 769px) {
        .c-dormitory-panel__ttl__icon {
          font-size: 2.3rem;
          margin-right: 5px; } }
  .c-dormitory-panel__description {
    font-size: 1.3rem;
    line-height: 1.65;
    margin-bottom: 17px; }
    @media (min-width: 769px) {
      .c-dormitory-panel__description {
        font-size: 1.6rem;
        line-height: 1.9;
        margin-bottom: 10px; } }
  .c-dormitory-panel .c-separate-table {
    width: 100%; }
    @media (min-width: 769px) {
      .c-dormitory-panel .c-separate-table {
        width: 700px; } }
    @media (max-width: 768.98px) {
      .c-dormitory-panel .c-separate-table__tr {
        width: 100%; } }
    .c-dormitory-panel .c-separate-table__th {
      width: 365px; }
      @media (max-width: 768.98px) {
        .c-dormitory-panel .c-separate-table__th {
          width: 100%; } }
    @media (max-width: 768.98px) {
      .c-dormitory-panel .c-separate-table__td {
        width: calc(100% / 2);
        min-height: 32px;
        justify-content: center; } }

.c-board {
  width: 100%;
  padding: 16px 19px; }
  .c-board__ttl {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px; }
    @media (min-width: 769px) {
      .c-board__ttl {
        font-size: 2.4rem; } }
    .c-board__ttl:not(:first-of-type) {
      margin-top: 10px; }
      @media (min-width: 769px) {
        .c-board__ttl:not(:first-of-type) {
          margin-top: 30px; } }
  .c-board__txt {
    line-height: 1.8; }
    @media (min-width: 769px) {
      .c-board__txt {
        line-height: 1.7; } }
  .c-board.-gray {
    background-color: #f5f5f5; }
  .c-board.-white {
    background-color: white;
    border: solid 1px #989898;
    padding: 17px 15px; }
    .c-board.-white .c-board__txt {
      line-height: 1.9; }
    @media (min-width: 769px) {
      .c-board.-white {
        padding: 27px 35px; } }
  @media (min-width: 769px) {
    .c-board {
      padding: 21px 33px; }
      .c-board.-h105 {
        display: flex;
        align-items: center;
        height: 105px;
        padding-top: 0;
        padding-bottom: 0; } }
  .c-board__prix {
    font-size: 1.5rem;
    font-weight: bold; }
    @media (max-width: 768.98px) {
      .c-board__prix {
        display: flex;
        justify-content: center; } }
    @media (min-width: 769px) {
      .c-board__prix {
        font-size: 2.4rem; } }
    .c-board__prix__ttl {
      white-space: nowrap;
      margin-right: 16px; }
    .c-board__prix__value {
      white-space: nowrap; }

.c-school-list-group {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 16px);
  margin-left: -8px; }
  .c-school-list-group__item {
    width: 100%; }
    @media (min-width: 769px) {
      .c-school-list-group__item.-col3 {
        width: 75%; } }
    @media (min-width: 769px) {
      .c-school-list-group__item.-col2 {
        width: 50%; } }
    .c-school-list-group__item.-col1 {
      width: 50%; }
      @media (min-width: 769px) {
        .c-school-list-group__item.-col1 {
          width: 25%; } }
      @media (max-width: 768.98px) {
        .c-school-list-group__item.-col1 .c-school-list__item {
          width: 100%; } }
  .c-school-list-group__ttl {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0 8px;
    margin-bottom: 6px; }
    @media (min-width: 769px) {
      .c-school-list-group__ttl {
        font-size: 2.4rem;
        margin-bottom: 10px; } }

.c-img-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0; }
  .c-img-grid__item {
    width: calc(100% / 2); }
    @media (min-width: 769px) {
      .c-img-grid__item {
        width: calc(100% / 3); }
        .c-img-grid__item *::-ms-backdrop, .c-img-grid__item {
          width: 33.33%; } }
  .c-img-grid__link {
    display: block;
    position: relative; }
    .c-img-grid__link::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3); }
  .c-img-grid__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.6rem;
    font-weight: bold;
    color: white;
    line-height: 1.3;
    text-align: center;
    white-space: nowrap;
    z-index: 1; }
    @media (min-width: 769px) {
      .c-img-grid__title {
        font-size: 2.5rem; } }

.c-service-card {
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden; }
  @media (max-width: 768.98px) {
    .c-service-card {
      flex-direction: column; } }
  .c-service-card__title {
    z-index: 1; }
    @media (max-width: 768.98px) {
      .c-service-card__title {
        position: absolute;
        top: 0;
        left: 0; } }
    @media (max-width: 768.98px) {
      .c-service-card__title.-visa {
        width: 91%;
        padding-top: 12%;
        margin-left: 4.5%; } }
    @media (min-width: 769px) {
      .c-service-card__title.-visa {
        width: 776px;
        transform: translateX(-316px);
        margin-bottom: 7px; } }
    @media (max-width: 768.98px) {
      .c-service-card__title.-ecole {
        width: 25%;
        margin-left: 5%;
        padding-top: 11.8%; } }
    @media (min-width: 769px) {
      .c-service-card__title.-ecole {
        width: 207px;
        margin-bottom: 17px; } }
    @media (max-width: 768.98px) {
      .c-service-card__title.-traduction {
        width: 49%;
        margin-left: 5%;
        padding-top: 12%; } }
    @media (min-width: 769px) {
      .c-service-card__title.-traduction {
        width: 412px;
        transform: translateX(-164px);
        margin-bottom: 23px; } }
  .c-service-card__sub-title {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: bold;
    white-space: nowrap; }
    @media (min-width: 769px) {
      .c-service-card__sub-title {
        font-size: 2.3rem; } }
    .c-service-card__sub-title.-visa {
      margin-bottom: 8px; }
      @media (min-width: 769px) {
        .c-service-card__sub-title.-visa {
          margin-bottom: 17px; } }
    .c-service-card__sub-title.-ecole {
      margin-bottom: 10px; }
      @media (min-width: 769px) {
        .c-service-card__sub-title.-ecole {
          margin-bottom: 15px; } }
    .c-service-card__sub-title.-traduction {
      margin-bottom: 11px; }
      @media (min-width: 769px) {
        .c-service-card__sub-title.-traduction {
          margin-bottom: 14px; } }
    .c-service-card__sub-title__icon.pi-visa {
      font-size: 3rem;
      margin-right: 8px; }
      @media (min-width: 769px) {
        .c-service-card__sub-title__icon.pi-visa {
          font-size: 4rem;
          margin-right: 8px; } }
    .c-service-card__sub-title__icon.pi-ecole {
      font-size: 3.1rem;
      margin-right: 11px; }
      @media (min-width: 769px) {
        .c-service-card__sub-title__icon.pi-ecole {
          font-size: 4.2rem;
          margin-right: 14px; } }
    .c-service-card__sub-title__icon.pi-traduction {
      font-size: 2.7rem;
      margin-right: 14px; }
      @media (min-width: 769px) {
        .c-service-card__sub-title__icon.pi-traduction {
          font-size: 3.6rem;
          margin-right: 13px; } }
  @media (min-width: 769px) {
    .c-service-card__img-container {
      width: 50%;
      min-width: 50%;
      min-height: 450px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
      .c-service-card__img-container::before {
        content: "";
        display: block;
        padding-top: 73%; }
      .c-service-card__img-container.-visa {
        background-image: url("/assets/img/top/service-img-visa.jpg"); }
      .c-service-card__img-container.-ecole {
        background-image: url("/assets/img/top/service-img-ecole.jpg"); }
      .c-service-card__img-container.-traduction {
        background-image: url("/assets/img/top/service-img-traduction.jpg"); } }
  @media (min-width: 769px) {
    .c-service-card__img {
      display: none; } }
  .c-service-card__body {
    width: calc(100% - 38px); }
    @media (max-width: 768.98px) {
      .c-service-card__body {
        padding: 12px 0 40px;
        margin: 0 auto; } }
    @media (min-width: 769px) {
      .c-service-card__body {
        width: 50%;
        min-width: 50%;
        padding-top: 63px; } }
    @media (max-width: 768.98px) {
      .c-service-card__body.-left {
        order: 2; } }
    @media (min-width: 769px) {
      .c-service-card__body.-left {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 53px; } }
    @media (min-width: 769px) {
      .c-service-card__body.-right {
        padding-left: 47px; } }
  .c-service-card__txt {
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.7;
    margin-bottom: 32px; }
    @media (min-width: 769px) {
      .c-service-card__txt {
        width: 450px;
        font-size: 1.7rem;
        line-height: 1.8;
        margin-bottom: 41px; } }
  @media (min-width: 769px) {
    .c-service-card__link {
      margin-left: 4px; } }
  @media (min-width: 769px) {
    .c-service-card__link.-right {
      margin-left: 0;
      margin-right: 83px; } }

.c-top-sec-header {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .c-top-sec-header.-qui {
    padding: 28px 20px 65px; }
    @media (min-width: 769px) {
      .c-top-sec-header.-qui {
        padding: 71px 0 99px; } }
  .c-top-sec-header.-service {
    padding: 28px 20px 27px; }
    @media (min-width: 769px) {
      .c-top-sec-header.-service {
        padding: 75px 0 91px; } }
  .c-top-sec-header__title {
    position: relative; }
    @media (max-width: 768.98px) {
      .c-top-sec-header__title__img.-pc {
        display: none; } }
    @media (min-width: 769px) {
      .c-top-sec-header__title__img.-sp {
        display: none; } }
    .c-top-sec-header__title.-qui {
      width: 276px;
      margin-bottom: 7px; }
      @media (min-width: 769px) {
        .c-top-sec-header__title.-qui {
          width: 508px;
          margin-bottom: 51px; } }
    .c-top-sec-header__title.-service {
      width: 122px; }
      @media (min-width: 769px) {
        .c-top-sec-header__title.-service {
          width: 224px; } }
    .c-top-sec-header__title__txt {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1.8rem;
      font-weight: bold;
      white-space: nowrap; }
      @media (min-width: 769px) {
        .c-top-sec-header__title__txt {
          font-size: 2rem; } }
      .c-top-sec-header__title__txt.-qui {
        bottom: 4px; }
        @media (min-width: 769px) {
          .c-top-sec-header__title__txt.-qui {
            bottom: 9px; } }
      .c-top-sec-header__title__txt.-service {
        bottom: 6px; }
        @media (min-width: 769px) {
          .c-top-sec-header__title__txt.-service {
            bottom: 12px; } }
  .c-top-sec-header__txt {
    font-size: 1.5rem;
    line-height: 1.9;
    margin-bottom: 29px; }
    @media (min-width: 769px) {
      .c-top-sec-header__txt {
        font-size: 1.8rem;
        text-align: center;
        line-height: 2.2;
        margin-bottom: 36px; } }

.c-sec-visual {
  position: relative; }
  .c-sec-visual__title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .c-sec-visual__title__txt {
      display: block;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1;
      color: white; }
      @media (min-width: 769px) {
        .c-sec-visual__title__txt {
          font-size: 2.4rem; } }
    .c-sec-visual__title.-pour-etudier {
      right: 20px;
      margin-top: 6px; }
      @media (min-width: 769px) {
        .c-sec-visual__title.-pour-etudier {
          right: 46px;
          margin-top: 6px; } }
      .c-sec-visual__title.-pour-etudier .c-sec-visual__title__img {
        width: 241px;
        margin-bottom: 8px; }
        @media (min-width: 769px) {
          .c-sec-visual__title.-pour-etudier .c-sec-visual__title__img {
            width: 484px;
            margin-bottom: 10px; } }
      @media (max-width: 768.98px) {
        .c-sec-visual__title.-pour-etudier .c-sec-visual__title__txt {
          text-align: right; } }
    .c-sec-visual__title.-visiteur {
      left: 20px;
      margin-top: 5px; }
      @media (min-width: 769px) {
        .c-sec-visual__title.-visiteur {
          left: 48px;
          margin-top: 11px; } }
      .c-sec-visual__title.-visiteur .c-sec-visual__title__img {
        width: 162px;
        margin-bottom: 10px; }
        @media (min-width: 769px) {
          .c-sec-visual__title.-visiteur .c-sec-visual__title__img {
            width: 326px;
            margin-bottom: 18px; } }
    .c-sec-visual__title.-vacances {
      left: 20px;
      margin-top: 4px; }
      @media (min-width: 769px) {
        .c-sec-visual__title.-vacances {
          left: 50px;
          margin-top: 9px; } }
      .c-sec-visual__title.-vacances .c-sec-visual__title__img {
        width: 280px;
        margin-bottom: 10px; }
        @media (min-width: 769px) {
          .c-sec-visual__title.-vacances .c-sec-visual__title__img {
            width: 564px;
            margin-bottom: 19px; } }
    .c-sec-visual__title.-scientifique {
      left: 20px;
      margin-top: 15px; }
      @media (min-width: 769px) {
        .c-sec-visual__title.-scientifique {
          left: 50px;
          margin-top: 31px; } }
      .c-sec-visual__title.-scientifique .c-sec-visual__title__img {
        width: 296px;
        margin-bottom: 10px; }
        @media (min-width: 769px) {
          .c-sec-visual__title.-scientifique .c-sec-visual__title__img {
            width: 596px;
            margin-bottom: 19px; } }
    .c-sec-visual__title.-passeport {
      right: 20px;
      margin-top: 4px; }
      @media (min-width: 769px) {
        .c-sec-visual__title.-passeport {
          right: 69px;
          margin-top: 8px; } }
      .c-sec-visual__title.-passeport .c-sec-visual__title__img {
        width: 281px;
        margin-bottom: 10px; }
        @media (min-width: 769px) {
          .c-sec-visual__title.-passeport .c-sec-visual__title__img {
            width: 566px;
            margin-bottom: 11px; } }
    .c-sec-visual__title.-scolarise-monaco {
      left: 20px;
      margin-top: 5px; }
      @media (min-width: 769px) {
        .c-sec-visual__title.-scolarise-monaco {
          left: 50px;
          margin-top: 10px; } }
      .c-sec-visual__title.-scolarise-monaco .c-sec-visual__title__img {
        width: 251px;
        margin-bottom: 10px; }
        @media (min-width: 769px) {
          .c-sec-visual__title.-scolarise-monaco .c-sec-visual__title__img {
            width: 505px;
            margin-bottom: 14px; } }
      .c-sec-visual__title.-scolarise-monaco .c-sec-visual__title__txt {
        color: black; }

.c-prix-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: solid 1px #989898;
  font-weight: bold;
  padding: 16px 0 6px; }
  @media (max-width: 768.98px) {
    .c-prix-bar {
      flex-wrap: wrap; } }
  @media (min-width: 769px) {
    .c-prix-bar {
      justify-content: space-between;
      padding: 17px 0; } }
  .c-prix-bar__ttl {
    font-size: 1.5rem; }
    @media (max-width: 768.98px) {
      .c-prix-bar__ttl {
        width: 100%; } }
    @media (min-width: 769px) {
      .c-prix-bar__ttl {
        font-size: 2.4rem; } }
  .c-prix-bar__type {
    font-size: 1.5rem;
    margin-left: auto; }
    @media (min-width: 769px) {
      .c-prix-bar__type {
        font-size: 2.7rem; } }
  .c-prix-bar__value {
    font-size: 1.5rem;
    margin-left: 20px;
    text-align: right; }
    @media (min-width: 769px) {
      .c-prix-bar__value {
        font-size: 2.7rem;
        margin-left: 30px; } }
  .c-prix-bar__annot {
    font-size: 1.2rem;
    font-weight: normal; }

.c-school-info__item {
  margin-bottom: 20px; }
  @media (min-width: 769px) {
    .c-school-info__item {
      margin-bottom: 33px; } }

.c-school-info__name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1px; }
  @media (min-width: 769px) {
    .c-school-info__name {
      font-size: 1.6rem;
      line-height: 1.8;
      margin-bottom: 4px; } }

.c-school-info__dl {
  display: flex;
  line-height: 1.8; }
  @media (max-width: 768.98px) {
    .c-school-info__dl {
      font-size: 1.2rem; } }
  @media (min-width: 769px) {
    .c-school-info__dl {
      line-height: 1.9; } }

.c-school-info__dt {
  font-weight: normal;
  white-space: nowrap;
  margin-right: 13px; }
  @media (min-width: 769px) {
    .c-school-info__dt {
      margin-right: 20px; } }

.c-school-info__dd {
  flex-grow: 1; }

.p-top .l-main {
  padding-bottom: 0; }
  @media (min-width: 769px) {
    .p-top .l-main {
      padding-bottom: 100px; } }

.p-top__kv {
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/assets/img/common/kv-sp.jpg");
  background-size: cover;
  position: relative;
  overflow: hidden;
  padding-top: 58px; }
  @media (max-width: 768.98px) {
    body.is-wide .p-top__kv {
      height: auto;
      padding-bottom: 50px; } }
  @media (min-width: 769px) {
    .p-top__kv {
      height: 770px;
      background-image: url("/assets/img/common/kv.jpg");
      padding-top: 120px; } }
  @media (max-width: 768.98px) {
    .p-top__kv__inner {
      position: relative;
      z-index: 10; } }
  @media (max-width: 768.98px) {
    .p-top__kv::before, .p-top__kv::after {
      width: calc(100% / 3);
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      opacity: 0.8;
      z-index: 1; }
    .p-top__kv::before {
      background-color: #323e95;
      left: 0; }
    .p-top__kv::after {
      background-color: #d80031;
      right: 0; } }
  .p-top__kv__title {
    width: 100%;
    padding-top: 10.8%;
    text-align: center;
    position: relative;
    margin-bottom: 5.8%;
    z-index: 6;
    opacity: 1;
    transition: opacity 0.3s;
    pointer-events: none; }
    @media (min-width: 769px) {
      .p-top__kv__title {
        width: 761px;
        padding-top: 45px;
        margin: 0 auto 43px; } }
    .p-top__kv__title__img-container {
      display: block; }
    .p-top__kv__title__img-container.-fr01, .p-top__kv__title__img.-fr01 {
      width: 81vw;
      max-width: 81vw;
      margin: 0 auto; }
      @media (min-width: 769px) {
        .p-top__kv__title__img-container.-fr01, .p-top__kv__title__img.-fr01 {
          width: 676px;
          max-width: 676px; } }
    .p-top__kv__title__img-container.-fr02, .p-top__kv__title__img.-fr02 {
      width: 35vw;
      max-width: 35vw;
      margin: 0 auto; }
      @media (min-width: 769px) {
        .p-top__kv__title__img-container.-fr02, .p-top__kv__title__img.-fr02 {
          width: 291px;
          max-width: 291px; } }
    @media (max-width: 768.98px) {
      .p-top__kv__title__img-container.-fr01 {
        margin-bottom: -0.7%; } }
    .p-top__kv__title__img-container.-fr02 {
      margin-bottom: 7.1%; }
      @media (min-width: 769px) {
        .p-top__kv__title__img-container.-fr02 {
          margin: -7px auto 48px;
          transform: translateX(6px); } }
    .p-top__kv__title__img-mask {
      display: block;
      overflow: hidden; }
    .p-top__kv__title__img.-ja {
      width: 43%; }
      @media (min-width: 769px) {
        .p-top__kv__title__img.-ja {
          width: 244px; } }
  .p-top__kv__read {
    font-size: 1.5rem;
    line-height: 1.7;
    color: white;
    text-align: center;
    margin-bottom: 10%;
    position: relative;
    z-index: 6;
    opacity: 1;
    transition: opacity 0.3s;
    pointer-events: none; }
    @media screen and (max-width: 320px) {
      .p-top__kv__read {
        font-size: 1.3rem; } }
    @media (min-width: 769px) {
      .p-top__kv__read {
        display: none; } }
  @media (min-width: 769px) {
    body.is-opening-ended .p-top__kv:hover .p-top__kv__title,
    body.is-opening-ended .p-top__kv:hover .p-top__kv__read {
      opacity: 0.6; } }

.p-visa-index__intro {
  width: 100%; }
  @media (max-width: 768.98px) {
    .p-visa-index__intro {
      padding: 30px 19px 0;
      margin-bottom: 33px; } }
  @media (min-width: 769px) {
    .p-visa-index__intro {
      max-width: 960px;
      padding-top: 70px;
      margin: 0 auto 70px; } }
  .p-visa-index__intro .c-paragraph {
    letter-spacing: 0.3px; }

.p-visa-index__area.-france {
  padding: 42px 0 22px; }
  @media (min-width: 769px) {
    .p-visa-index__area.-france {
      padding: 94px 0 22px; } }
  .p-visa-index__area.-france .c-lower-title {
    margin-bottom: 50px; }
    @media (min-width: 769px) {
      .p-visa-index__area.-france .c-lower-title {
        margin-bottom: 73px; } }
  .p-visa-index__area.-france .c-sec-visual {
    margin-bottom: 25px; }
    .p-visa-index__area.-france .c-sec-visual.-pour-etudier {
      margin-bottom: 16px; }
  .p-visa-index__area.-france .c-visa-post {
    margin-bottom: 45px; }
    @media (min-width: 769px) {
      .p-visa-index__area.-france .c-visa-post {
        margin-bottom: 95px; } }
    .p-visa-index__area.-france .c-visa-post.-etudiant {
      margin-top: 35px; }
      @media (min-width: 769px) {
        .p-visa-index__area.-france .c-visa-post.-etudiant {
          margin-top: 85px; } }
    .p-visa-index__area.-france .c-visa-post.-concours, .p-visa-index__area.-france .c-visa-post.-visiteur, .p-visa-index__area.-france .c-visa-post.-vacances, .p-visa-index__area.-france .c-visa-post.-scientifique {
      margin-bottom: 45px; }
      @media (min-width: 769px) {
        .p-visa-index__area.-france .c-visa-post.-concours, .p-visa-index__area.-france .c-visa-post.-visiteur, .p-visa-index__area.-france .c-visa-post.-vacances, .p-visa-index__area.-france .c-visa-post.-scientifique {
          margin-bottom: 120px; } }

.p-visa-index__area.-monaco .c-lower-title {
  margin-bottom: 50px; }
  @media (min-width: 769px) {
    .p-visa-index__area.-monaco .c-lower-title {
      margin-bottom: 73px; } }

.p-visa-index__area.-monaco .c-sec-visual {
  margin-bottom: 25px; }

.p-visa-index__sec.-categories {
  background-color: #f5f5f5;
  padding: 63px 19px 36px; }
  @media (min-width: 769px) {
    .p-visa-index__sec.-categories {
      padding: 80px 0 76px; } }
  .p-visa-index__sec.-categories .c-lower-title {
    margin-bottom: 50px; }
    @media (min-width: 769px) {
      .p-visa-index__sec.-categories .c-lower-title {
        margin-bottom: 60px; } }
  .p-visa-index__sec.-categories .c-flag-title {
    margin-bottom: 27px; }
    @media (min-width: 769px) {
      .p-visa-index__sec.-categories .c-flag-title {
        margin-bottom: 40px; } }

@media (min-width: 769px) {
  .p-visa-index__sec__inner {
    width: 960px;
    margin: 0 auto; } }

.p-visa-index__cat-sec.-fr {
  margin-bottom: 52px; }
  @media (min-width: 769px) {
    .p-visa-index__cat-sec.-fr {
      margin-bottom: 40px; } }

.p-visa-index__img-caption {
  margin-bottom: 28px; }
  @media (max-width: 768.98px) {
    .p-visa-index__img-caption {
      padding: 0 19px; } }
  @media (min-width: 769px) {
    .p-visa-index__img-caption {
      margin-bottom: 48px; } }

.p-visa-index__about-sec {
  margin-bottom: 25px; }
  @media (min-width: 769px) {
    .p-visa-index__about-sec {
      margin-bottom: 35px; } }
  @media (max-width: 768.98px) {
    .p-visa-index__about-sec {
      padding: 0 19px; } }
  .p-visa-index__about-sec .c-border-title {
    margin-bottom: 9px; }
  .p-visa-index__about-sec .c-paragraph {
    line-height: 1.8; }
    @media (min-width: 769px) {
      .p-visa-index__about-sec .c-paragraph {
        line-height: 2; } }

@media (max-width: 768.98px) {
  .p-visa-howto__header .c-lower-title {
    margin-bottom: 8px; } }

@media (max-width: 768.98px) {
  .p-visa-howto__header .c-paragraph {
    font-size: 1.2rem; } }

@media (min-width: 769px) {
  .p-visa-howto__header .c-paragraph {
    text-align: center; } }

.p-visa-howto__sec .p-visa-howto__header {
  margin-bottom: 31px; }
  @media (min-width: 769px) {
    .p-visa-howto__sec .p-visa-howto__header {
      margin-bottom: 59px; } }

.p-visa-howto__sec.-processus {
  background-color: #f5f5f5;
  padding: 65px 19px 54px; }
  @media (min-width: 769px) {
    .p-visa-howto__sec.-processus {
      padding: 81px 19px 84px; } }

.p-visa-howto__sec.-attention {
  padding: 65px 19px 0; }
  .p-visa-howto__sec.-attention .c-paragraph {
    line-height: 1.9; }

@media (min-width: 769px) {
  .p-visa-howto__sec__inner {
    width: 960px;
    margin: 0 auto; } }

@media (min-width: 769px) {
  .p-visa-tarif__sec__inner {
    width: 960px;
    margin: 0 auto; } }

.p-visa-tarif__sec.-des-prix {
  background-color: #f5f5f5;
  padding: 65px 19px 54px; }
  @media (min-width: 769px) {
    .p-visa-tarif__sec.-des-prix {
      padding: 86px 19px 84px; } }
  .p-visa-tarif__sec.-des-prix .c-lower-title {
    margin-bottom: 54px; }
    @media (min-width: 769px) {
      .p-visa-tarif__sec.-des-prix .c-lower-title {
        margin-bottom: 59px; } }
  .p-visa-tarif__sec.-des-prix .c-prix-table {
    margin-bottom: 30px; }

.p-visa-tarif__sec.-service-content {
  padding: 65px 0 0; }
  @media (min-width: 769px) {
    .p-visa-tarif__sec.-service-content {
      padding: 86px 19px 0; } }

.p-visa-tarif__sec__service-ttl {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px; }
  @media (min-width: 769px) {
    .p-visa-tarif__sec__service-ttl {
      font-size: 3.6rem;
      margin-bottom: 63px; } }

.p-visa-tarif__service-sec {
  margin-bottom: 25px; }
  @media (min-width: 769px) {
    .p-visa-tarif__service-sec {
      margin-bottom: 97px; } }
  .p-visa-tarif__service-sec .c-visa-post {
    margin-bottom: 19px; }
    @media (min-width: 769px) {
      .p-visa-tarif__service-sec .c-visa-post {
        margin-bottom: 63px; } }
  @media (max-width: 768.98px) {
    .p-visa-tarif__service-sec .c-accordion {
      padding: 0 19px; } }

.p-visa-tarif__prix-ttl {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 22px; }
  @media (min-width: 769px) {
    .p-visa-tarif__prix-ttl {
      font-size: 3.6rem;
      margin-bottom: 22px; } }
  .p-visa-tarif__prix-ttl__sub {
    display: block;
    font-size: 1.4rem; }
    @media (min-width: 769px) {
      .p-visa-tarif__prix-ttl__sub {
        font-size: 2.6rem; } }

.p-visa-tarif__country-ttl {
  font-size: 1.8rem;
  font-weight: bold;
  white-space: nowrap;
  margin-bottom: 5px; }
  @media (min-width: 769px) {
    .p-visa-tarif__country-ttl {
      font-size: 2.6rem; } }

@media (max-width: 768.98px) {
  .p-premier-index__intro {
    padding: 29px 19px 0;
    margin-bottom: 64px; } }

@media (min-width: 769px) {
  .p-premier-index__intro {
    width: 960px;
    padding-top: 70px;
    margin: 0 auto 95px; } }

.p-premier-index__intro .c-paragraph {
  font-size: 1.5rem;
  line-height: 1.75; }
  @media (min-width: 769px) {
    .p-premier-index__intro .c-paragraph {
      font-size: 1.8rem;
      line-height: 1.7; } }

.p-premier-index__sec {
  margin-bottom: 78px; }
  @media (min-width: 769px) {
    .p-premier-index__sec {
      width: 960px;
      margin: 0 auto 120px; } }
  .p-premier-index__sec .c-lower-title {
    margin-bottom: 18px; }
    @media (min-width: 769px) {
      .p-premier-index__sec .c-lower-title {
        margin-bottom: 74px; } }
  .p-premier-index__sec .c-board-link-list {
    margin-top: 29px; }
    @media (min-width: 769px) {
      .p-premier-index__sec .c-board-link-list {
        margin-top: 52px; } }
  .p-premier-index__sec .c-more-link {
    width: calc(100% - 38px);
    margin: 62px auto 0; }
    @media (min-width: 769px) {
      .p-premier-index__sec .c-more-link {
        margin: 62px auto 0; } }

.p-premier-other__intro {
  margin-bottom: 64px; }
  @media (max-width: 768.98px) {
    .p-premier-other__intro {
      padding: 29px 19px 0; } }
  @media (min-width: 769px) {
    .p-premier-other__intro {
      width: 960px;
      padding-top: 55px;
      margin: 0 auto 55px; } }
  .p-premier-other__intro .c-paragraph {
    font-size: 1.5rem;
    line-height: 1.75; }
    @media (min-width: 769px) {
      .p-premier-other__intro .c-paragraph {
        font-size: 1.8rem;
        line-height: 1.7; } }

.p-premier-other__sec {
  margin-bottom: 50px; }
  @media (max-width: 768.98px) {
    .p-premier-other__sec {
      padding: 0 19px; } }
  @media (min-width: 769px) {
    .p-premier-other__sec {
      width: 960px;
      margin: 0 auto 100px; } }
  .p-premier-other__sec.-padding-top {
    padding-top: 50px; }
  .p-premier-other__sec .c-fill-title {
    margin-bottom: 25px; }
    @media (min-width: 769px) {
      .p-premier-other__sec .c-fill-title {
        margin-bottom: 30px; } }
  @media (max-width: 768.98px) {
    .p-premier-other__sec .c-paragraph {
      line-height: 1.6; } }
  .p-premier-other__sec .c-basic-list__item {
    margin-bottom: 30px; }
    @media (max-width: 768.98px) {
      .p-premier-other__sec .c-basic-list__item {
        line-height: 1.6; } }

@media (max-width: 768.98px) {
  .p-ecole-index__intro {
    padding: 29px 19px 0;
    margin-bottom: 64px; } }

@media (min-width: 769px) {
  .p-ecole-index__intro {
    width: 960px;
    padding-top: 70px;
    margin: 0 auto 76px; } }

.p-ecole-index__intro .c-paragraph {
  font-size: 1.5rem;
  line-height: 1.7; }
  @media (min-width: 769px) {
    .p-ecole-index__intro .c-paragraph {
      font-size: 1.8rem; } }

.p-ecole-index__header {
  margin-bottom: 44px; }
  @media (min-width: 769px) {
    .p-ecole-index__header {
      margin-bottom: 64px; } }
  .p-ecole-index__header .c-lower-title {
    margin-bottom: 32px; }
    @media (min-width: 769px) {
      .p-ecole-index__header .c-lower-title {
        margin-bottom: 57px; } }
  .p-ecole-index__header .c-paragraph {
    font-size: 1.5rem;
    line-height: 1.6; }
    @media (min-width: 769px) {
      .p-ecole-index__header .c-paragraph {
        font-size: 1.8rem;
        line-height: 1.7; } }

@media (max-width: 768.98px) {
  .p-ecole-index__sec {
    padding: 63px 19px 0; } }

@media (min-width: 769px) {
  .p-ecole-index__sec {
    width: 960px;
    padding-top: 80px;
    margin: 0 auto; } }

@media (min-width: 769px) {
  .p-ecole-index__child-sec.-diff {
    margin-bottom: 7px; } }

.p-ecole-index__grand-child-sec {
  /* stylelint-disable-next-line */ }
  .p-ecole-index__grand-child-sec .c-fill-title {
    margin-bottom: 19px; }
    @media (min-width: 769px) {
      .p-ecole-index__grand-child-sec .c-fill-title {
        margin-bottom: 39px; } }
  .p-ecole-index__grand-child-sec .c-paragraph {
    line-height: 1.95;
    margin-bottom: 25px; }
  .p-ecole-index__grand-child-sec .c-more-link {
    margin: 38px auto 0; }
    @media (min-width: 769px) {
      .p-ecole-index__grand-child-sec .c-more-link {
        width: 270px;
        margin-top: 73px; } }
  .p-ecole-index__grand-child-sec .c-underline-title {
    margin-bottom: 34px; }
    @media (min-width: 769px) {
      .p-ecole-index__grand-child-sec .c-underline-title {
        margin-bottom: 30px; } }

.p-ecole-index__child-sec.-diff .p-ecole-index__grand-child-sec {
  padding-bottom: 50px; }
  @media (min-width: 769px) {
    .p-ecole-index__child-sec.-diff .p-ecole-index__grand-child-sec {
      padding-bottom: 106px; } }
  @media (max-width: 768.98px) {
    .p-ecole-index__child-sec.-diff .p-ecole-index__grand-child-sec .c-paragraph {
      line-height: 1.6;
      margin-bottom: 31px;
      font-size: 1.3rem; } }
  @media (min-width: 769px) {
    .p-ecole-index__child-sec.-diff .p-ecole-index__grand-child-sec .c-paragraph {
      padding-left: 10px; } }

.p-ecole-index__child-sec.-find .p-ecole-index__grand-child-sec {
  padding-bottom: 32px; }
  @media (min-width: 769px) {
    .p-ecole-index__child-sec.-find .p-ecole-index__grand-child-sec {
      padding-bottom: 32px; } }
  @media (max-width: 768.98px) {
    .p-ecole-index__child-sec.-find .p-ecole-index__grand-child-sec .c-paragraph {
      line-height: 1.65;
      font-size: 1.3rem; } }
  @media (min-width: 769px) {
    .p-ecole-index__child-sec.-find .p-ecole-index__grand-child-sec .c-paragraph {
      padding-left: 10px; } }

@media (min-width: 769px) {
  .p-ecole-index__col2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; } }

.p-ecole-index__col2.-type1 {
  margin-bottom: 37px; }
  @media (min-width: 769px) {
    .p-ecole-index__col2.-type1 {
      margin-bottom: 24px; } }

@media (max-width: 768.98px) {
  .p-ecole-index__col2.-type1 .p-ecole-index__col2__left {
    margin-bottom: 28px; } }

@media (min-width: 769px) {
  .p-ecole-index__col2.-type1 .p-ecole-index__col2__left {
    width: 468px; } }

@media (min-width: 769px) {
  .p-ecole-index__col2.-type1 .p-ecole-index__col2__right {
    width: 444px; } }

.p-ecole-index__col2.-type1 .p-ecole-index__col2__right .c-border-table {
  margin-top: -11px; }

.p-ecole-index__col2.-type2 {
  margin-bottom: 7px; }
  @media (max-width: 768.98px) {
    .p-ecole-index__col2.-type2 {
      display: flex;
      flex-direction: column; } }
  @media (min-width: 769px) {
    .p-ecole-index__col2.-type2 {
      margin-bottom: 36px; } }

@media (max-width: 768.98px) {
  .p-ecole-index__col2.-type2 .p-ecole-index__col2__left {
    order: 1; } }

@media (min-width: 769px) {
  .p-ecole-index__col2.-type2 .p-ecole-index__col2__left {
    width: 480px; } }

@media (max-width: 768.98px) {
  .p-ecole-index__col2.-type2 .p-ecole-index__col2__right {
    order: 0;
    margin-bottom: 13px; } }

@media (min-width: 769px) {
  .p-ecole-index__col2.-type2 .p-ecole-index__col2__right {
    width: 432px;
    padding-top: 5px; } }

.p-ecole-index__num-ttl {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 22px; }
  @media (max-width: 768.98px) {
    .p-ecole-index__num-ttl {
      line-height: 1.3; } }
  @media (min-width: 769px) {
    .p-ecole-index__num-ttl {
      font-size: 2.6rem;
      margin-bottom: 30px; } }

@media (max-width: 768.98px) {
  .p-ecole-index__child-sec.-find .p-ecole-index__num-ttl {
    margin-bottom: 28px; } }

.p-ecole-index__post-ttl {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 5px; }
  @media (min-width: 769px) {
    .p-ecole-index__post-ttl {
      font-size: 2.4rem;
      margin-bottom: 17px; } }

.p-ecole-list__header {
  margin-bottom: 24px; }
  @media (min-width: 769px) {
    .p-ecole-list__header {
      margin-bottom: 88px; } }

.p-ecole-list__sec {
  background-color: #f5f5f5;
  padding-top: 63px; }
  @media (min-width: 769px) {
    .p-ecole-list__sec {
      padding-top: 80px; } }

@media (max-width: 768.98px) {
  .p-ecole-list__map-container__inner {
    padding: 0 19px 10px; } }

@media (min-width: 769px) {
  .p-ecole-list__map-container__inner {
    width: 960px;
    padding-bottom: 84px;
    margin: 0 auto; } }

.p-ecole-list__map {
  width: 80%;
  margin: 0 auto 42px; }
  @media (min-width: 769px) {
    .p-ecole-list__map {
      width: 520px;
      margin: 0 auto 72px;
      transform: translateX(-22px); } }

.p-ecole-list__list-container {
  background-color: white; }
  @media (max-width: 768.98px) {
    .p-ecole-list__list-container__inner {
      padding: 32px 19px 0; } }
  @media (min-width: 769px) {
    .p-ecole-list__list-container__inner {
      width: 960px;
      padding-top: 101px;
      margin: 0 auto; } }

.p-ecole-list__school-sec {
  margin-bottom: 31px; }
  @media (min-width: 769px) {
    .p-ecole-list__school-sec {
      margin-bottom: 31px; } }
  .p-ecole-list__school-sec .c-fill-title {
    margin-bottom: 13px; }
    @media (min-width: 769px) {
      .p-ecole-list__school-sec .c-fill-title {
        margin-bottom: 31px; } }
  .p-ecole-list__school-sec .c-school-list:not(:last-of-type) {
    margin-bottom: 24px; }
    @media (min-width: 769px) {
      .p-ecole-list__school-sec .c-school-list:not(:last-of-type) {
        margin-bottom: 24px; } }

.p-ecole-list__city-group {
  display: flex;
  flex-wrap: wrap; }

.p-ecole-detail__header {
  margin-bottom: 32px; }
  @media (min-width: 769px) {
    .p-ecole-detail__header {
      margin-bottom: 76px; } }

.p-ecole-detail__lower-header {
  margin: 39px 0 30px; }
  @media (min-width: 769px) {
    .p-ecole-detail__lower-header {
      margin: 88px 0 35px; } }
  .p-ecole-detail__lower-header__txt {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    margin-top: 7px; }
    @media (max-width: 768.98px) {
      .p-ecole-detail__lower-header__txt {
        line-height: 1.5; } }
    @media (min-width: 769px) {
      .p-ecole-detail__lower-header__txt {
        font-size: 1.8rem;
        margin-top: 7px; } }

.p-ecole-detail__catch-copy {
  border-width: 1px 0;
  border-style: solid;
  border-color: black;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  padding: 7px 0;
  margin-bottom: 31px; }
  @media (min-width: 769px) {
    .p-ecole-detail__catch-copy {
      font-size: 2.6rem;
      padding: 13px 0;
      margin-bottom: 68px; } }

@media (max-width: 768.98px) {
  .p-ecole-detail__sec {
    padding: 40px 19px 0; } }

@media (min-width: 769px) {
  .p-ecole-detail__sec {
    width: 960px;
    padding-top: 70px;
    margin: 0 auto; } }

.p-ecole-detail__detail-ttl {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 4px; }
  @media (min-width: 769px) {
    .p-ecole-detail__detail-ttl {
      font-size: 2.6rem;
      margin-bottom: 18px; } }

.p-ecole-detail__detail-txt {
  font-size: 1.3rem; }
  @media (max-width: 768.98px) {
    .p-ecole-detail__detail-txt {
      line-height: 1.6; } }
  @media (min-width: 769px) {
    .p-ecole-detail__detail-txt {
      font-size: 1.6rem; } }

.p-ecole-detail__table__annot {
  text-align: right;
  margin-top: 12px; }
  @media (max-width: 768.98px) {
    .p-ecole-detail__table__annot {
      display: none; } }

.p-ecole-detail__info-sec {
  margin-bottom: 43px; }
  @media (min-width: 769px) {
    .p-ecole-detail__info-sec {
      margin-bottom: 64px; } }
  .p-ecole-detail__info-sec .p-ecole-detail__detail-ttl {
    margin-bottom: 11px; }
    @media (min-width: 769px) {
      .p-ecole-detail__info-sec .p-ecole-detail__detail-ttl {
        margin-bottom: 17px; } }
  .p-ecole-detail__info-sec .p-ecole-detail__col2 {
    display: flex;
    margin-bottom: 16px; }
    @media (max-width: 768.98px) {
      .p-ecole-detail__info-sec .p-ecole-detail__col2 {
        flex-direction: column; } }
    @media (min-width: 769px) {
      .p-ecole-detail__info-sec .p-ecole-detail__col2 {
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 26px; } }
    @media (max-width: 768.98px) {
      .p-ecole-detail__info-sec .p-ecole-detail__col2__left {
        order: 1; } }
    @media (min-width: 769px) {
      .p-ecole-detail__info-sec .p-ecole-detail__col2__left {
        flex-grow: 1; } }
    @media (max-width: 768.98px) {
      .p-ecole-detail__info-sec .p-ecole-detail__col2__right {
        order: 0;
        margin-bottom: 26px; } }
    @media (min-width: 769px) {
      .p-ecole-detail__info-sec .p-ecole-detail__col2__right {
        width: 432px;
        padding-top: 70px;
        margin-left: 48px; } }
    .p-ecole-detail__info-sec .p-ecole-detail__col2__img {
      margin-bottom: 3px; }
      @media (min-width: 769px) {
        .p-ecole-detail__info-sec .p-ecole-detail__col2__img {
          margin-bottom: 10px; } }
  @media (max-width: 768.98px) {
    .p-ecole-detail__info-sec .c-separate-table {
      margin-bottom: 16px; } }
  @media (max-width: 768.98px) {
    .p-ecole-detail__info-sec .c-separate-table__th {
      width: 122px; } }
  @media screen and (max-width: 320px) {
    .p-ecole-detail__info-sec .c-separate-table__th {
      width: 107px; } }
  @media (max-width: 768.98px) {
    .p-ecole-detail__info-sec .c-separate-table__td {
      width: calc(100% - 137px); } }
  @media screen and (max-width: 320px) {
    .p-ecole-detail__info-sec .c-separate-table__td {
      width: calc(100% - 117px); } }
  .p-ecole-detail__info-sec .c-separate-list {
    margin-top: 16px; }
    @media (min-width: 769px) {
      .p-ecole-detail__info-sec .c-separate-list {
        margin-top: 20px; } }

.p-ecole-detail__basic-sec {
  margin-bottom: 46px; }
  @media (min-width: 769px) {
    .p-ecole-detail__basic-sec {
      margin-bottom: 46px; } }
  .p-ecole-detail__basic-sec .c-separate-table {
    margin-top: 15px; }
    .p-ecole-detail__basic-sec .c-separate-table__th {
      width: 120px; }
      @media (min-width: 769px) {
        .p-ecole-detail__basic-sec .c-separate-table__th {
          width: 250px; } }
      @media screen and (max-width: 320px) {
        .p-ecole-detail__basic-sec .c-separate-table__th {
          width: 105px; } }
    @media (max-width: 768.98px) {
      .p-ecole-detail__basic-sec .c-separate-table__td {
        width: calc(100% - 135px); }
        .p-ecole-detail__basic-sec .c-separate-table__td .c-slash-txt {
          padding: 7px 0; } }
    @media screen and (max-width: 320px) {
      .p-ecole-detail__basic-sec .c-separate-table__td {
        width: calc(100% - 115px); } }

.p-ecole-detail__strength-sec {
  margin-bottom: 53px; }
  @media (min-width: 576px) {
    .p-ecole-detail__strength-sec {
      margin-bottom: 70px; } }
  .p-ecole-detail__strength-sec .c-dormitory-panel {
    margin-top: 25px; }
    @media (min-width: 769px) {
      .p-ecole-detail__strength-sec .c-dormitory-panel {
        margin-top: 52px; } }

.p-ecole-detail__semester-sec {
  margin-bottom: 18px; }
  @media (min-width: 769px) {
    .p-ecole-detail__semester-sec {
      margin-bottom: 66px; } }
  .p-ecole-detail__semester-sec .p-ecole-detail__detail-ttl {
    margin-bottom: 13px; }
    @media (min-width: 769px) {
      .p-ecole-detail__semester-sec .p-ecole-detail__detail-ttl {
        margin-bottom: 24px; } }

.p-ecole-detail__accommodation-sec {
  margin-bottom: 47px; }
  @media (min-width: 769px) {
    .p-ecole-detail__accommodation-sec {
      margin-bottom: 63px; } }
  @media (max-width: 768.98px) {
    .p-ecole-detail__accommodation-sec .p-ecole-detail__detail-ttl {
      margin-bottom: 9px; } }
  @media (max-width: 768.98px) {
    .p-ecole-detail__accommodation-sec .p-ecole-detail__detail-txt {
      font-size: 1.6rem; } }

@media (max-width: 768.98px) {
  .p-ecole-detail__period-sec .p-ecole-detail__detail-ttl {
    margin-bottom: 9px; } }

@media (max-width: 768.98px) {
  .p-ecole-detail__period-sec .p-ecole-detail__detail-txt {
    font-size: 1.5rem; } }

.p-ecole-detail__period-sec .c-separate-table {
  margin-top: 16px; }
  @media (max-width: 768.98px) {
    .p-ecole-detail__period-sec .c-separate-table__th {
      width: 120px;
      font-size: 1.4rem; } }
  @media (max-width: 768.98px) {
    .p-ecole-detail__period-sec .c-separate-table__td {
      width: calc(100% - 135px);
      font-size: 1.4rem; } }

.p-ecole-detail__return-link {
  font-size: 1.5rem;
  margin-top: 54px; }
  @media (min-width: 769px) {
    .p-ecole-detail__return-link {
      width: 270px;
      margin: 62px auto 0; } }

@media (max-width: 768.98px) {
  .p-translation-index__intro {
    padding: 30px 19px 0;
    margin-bottom: 26px; } }

@media (min-width: 769px) {
  .p-translation-index__intro {
    width: 960px;
    padding-top: 71px;
    margin: 0 auto 67px; } }

.p-translation-index__intro .c-paragraph {
  font-size: 1.5rem;
  line-height: 1.6; }
  @media (min-width: 769px) {
    .p-translation-index__intro .c-paragraph {
      font-size: 1.8rem; } }

.p-translation-index__header {
  margin-bottom: 57px; }
  @media (min-width: 769px) {
    .p-translation-index__header {
      margin-bottom: 57px; } }
  .p-translation-index__header .c-lower-title {
    margin-bottom: 27px; }
    @media (min-width: 769px) {
      .p-translation-index__header .c-lower-title {
        margin-bottom: 43px; } }
  .p-translation-index__header .c-paragraph {
    font-size: 1.5rem;
    line-height: 1.6; }
    @media (min-width: 769px) {
      .p-translation-index__header .c-paragraph {
        font-size: 1.8rem;
        line-height: 1.7; } }

@media (max-width: 768.98px) {
  .p-translation-index__sec {
    padding: 63px 19px 0; } }

@media (min-width: 769px) {
  .p-translation-index__sec {
    width: 960px;
    padding-top: 79px;
    margin: 0 auto; } }

.p-translation-index__child-sec {
  margin-bottom: 50px; }
  @media (min-width: 769px) {
    .p-translation-index__child-sec {
      margin-bottom: 80px; } }
  .p-translation-index__child-sec .c-fill-title {
    margin-bottom: 20px; }
    @media (min-width: 769px) {
      .p-translation-index__child-sec .c-fill-title {
        margin-bottom: 78px; } }

@media (min-width: 769px) {
  .p-translation-index__link-group {
    display: flex;
    justify-content: center; } }

.p-translation-index__link-group .c-more-link {
  font-size: 1.4rem; }
  @media (min-width: 769px) {
    .p-translation-index__link-group .c-more-link {
      width: 272px;
      font-size: 1.5rem; } }
  @media (max-width: 768.98px) {
    .p-translation-index__link-group .c-more-link:first-of-type {
      margin-bottom: 20px; } }
  @media (min-width: 769px) {
    .p-translation-index__link-group .c-more-link:first-of-type {
      margin-right: 50px; } }

.p-translation-tarif__annot {
  line-height: 2; }

.p-qa-index .l-main {
  padding-bottom: 0; }

@media (max-width: 768.98px) {
  .p-qa-index__intro {
    padding: 22px 19px 0;
    margin-bottom: 77px; } }

@media (min-width: 769px) {
  .p-qa-index__intro {
    width: 960px;
    padding-top: 71px;
    margin: 0 auto 80px; } }

.p-qa-index__intro .c-paragraph {
  font-size: 1.5rem;
  line-height: 1.6; }
  @media (min-width: 769px) {
    .p-qa-index__intro .c-paragraph {
      font-size: 1.8rem; } }

@media screen and (max-width: 320px) {
  .p-qa-index .c-tab-nav__link {
    font-size: 1rem; } }

.p-qa-index__ttl {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 35px; }
  @media (min-width: 769px) {
    .p-qa-index__ttl {
      font-size: 3.6rem;
      margin-bottom: 71px; } }

.p-qa-index__sec {
  background-color: #f5f5f5; }
  @media (max-width: 768.98px) {
    .p-qa-index__sec__inner {
      padding: 51px 19px 100px; } }
  @media (min-width: 769px) {
    .p-qa-index__sec__inner {
      width: 960px;
      padding: 75px 0 150px;
      margin: 0 auto; } }

body.-nav-top:not(.is-opening-disable) .p-top__kv__title__img-mask {
  width: 0; }
  body.-nav-top:not(.is-opening-disable) .p-top__kv__title__img-mask.-fr01 {
    transition: width 1.5s 2s; }
  body.-nav-top:not(.is-opening-disable) .p-top__kv__title__img-mask.-fr02 {
    transition: width 1s 3.5s; }

body.-nav-top:not(.is-opening-disable).is-opening-started .p-top__kv__title__img-mask {
  width: 100%; }

body.-nav-top:not(.is-opening-disable) .p-top__kv__title__img.-ja {
  visibility: hidden;
  opacity: 0;
  transition: all 1.5s ease-in-out 4.5s; }

body.-nav-top:not(.is-opening-disable).is-opening-started .p-top__kv__title__img.-ja {
  visibility: visible;
  opacity: 1; }

body.-nav-top:not(.is-opening-disable) .p-top__kv__read {
  visibility: hidden;
  opacity: 0;
  transition: all 1.5s ease-in-out 4.5s; }

body.-nav-top:not(.is-opening-disable).is-opening-started .p-top__kv__read {
  visibility: visible;
  opacity: 1; }

body.-nav-top:not(.is-opening-disable) .l-header {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-13px);
  transition: visibility 1s ease-in-out 6s, opacity 1s ease-in-out 6s, transform 1s ease-in-out 6s, background-color 0.3s ease-in-out, height 0.3s ease-in-out;
  will-change: transform; }

body.-nav-top:not(.is-opening-disable).is-opening-started .l-header {
  visibility: visible;
  opacity: 1;
  transform: translateY(0); }

body.-nav-top:not(.is-opening-disable) .c-top-main-nav__col__inner {
  visibility: hidden;
  opacity: 0;
  transform: translateY(13px);
  transition-property: visibility, opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
  transition-delay: 6s; }

body.-nav-top:not(.is-opening-disable).is-opening-started .c-top-main-nav__col__inner {
  visibility: visible;
  opacity: 1;
  transform: translateY(0); }

body.-nav-top #QuiHeaderTtl .c-top-sec-header__title__img,
body.-nav-top #QuiHeaderTtl .c-top-sec-header__title__txt,
body.-nav-top #ServiceHeaderTtl .c-top-sec-header__title__img,
body.-nav-top #ServiceHeaderTtl .c-top-sec-header__title__txt {
  opacity: 0;
  visibility: hidden; }

body.-nav-top #QuiHeaderTtl .c-top-sec-header__title__img,
body.-nav-top #ServiceHeaderTtl .c-top-sec-header__title__img {
  transition: all 0.5s ease-in-out; }

body.-nav-top #QuiHeaderTtl .c-top-sec-header__title__txt,
body.-nav-top #ServiceHeaderTtl .c-top-sec-header__title__txt {
  transition: all 0.5s 0.3s; }

body.-nav-top #QuiHeaderTtl.-active .c-top-sec-header__title__img,
body.-nav-top #QuiHeaderTtl.-active .c-top-sec-header__title__txt,
body.-nav-top #ServiceHeaderTtl.-active .c-top-sec-header__title__img,
body.-nav-top #ServiceHeaderTtl.-active .c-top-sec-header__title__txt {
  opacity: 1;
  visibility: visible; }

body.-nav-top #QuiHeaderTxt {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s; }
  body.-nav-top #QuiHeaderTxt.-active {
    visibility: visible;
    opacity: 1; }

body.-nav-top #QuiHeaderLink {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s; }
  body.-nav-top #QuiHeaderLink.-active {
    visibility: visible;
    opacity: 1; }

body.-nav-top #QuiImgGrid {
  visibility: hidden;
  opacity: 0;
  transition: all 0.7s; }
  body.-nav-top #QuiImgGrid.-active {
    visibility: visible;
    opacity: 1; }

body.-nav-top #Visa .c-service-card__img-container,
body.-nav-top #Ecole .c-service-card__img-container,
body.-nav-top #Traduction .c-service-card__img-container {
  visibility: hidden;
  opacity: 0;
  transition: all 1s cubic-bezier(0.21, 0.61, 0.35, 1); }
  @media (min-width: 769px) {
    body.-nav-top #Visa .c-service-card__img-container,
    body.-nav-top #Ecole .c-service-card__img-container,
    body.-nav-top #Traduction .c-service-card__img-container {
      transform: translateX(-150px); } }
  @media (min-width: 769px) {
    body.-nav-top #Visa .c-service-card__img-container.-ecole,
    body.-nav-top #Ecole .c-service-card__img-container.-ecole,
    body.-nav-top #Traduction .c-service-card__img-container.-ecole {
      transform: translateX(150px); } }

body.-nav-top #Visa .c-service-card__title,
body.-nav-top #Visa .c-service-card__body__detail,
body.-nav-top #Ecole .c-service-card__title,
body.-nav-top #Ecole .c-service-card__body__detail,
body.-nav-top #Traduction .c-service-card__title,
body.-nav-top #Traduction .c-service-card__body__detail {
  visibility: hidden;
  opacity: 0; }

body.-nav-top #Visa .c-service-card__title,
body.-nav-top #Ecole .c-service-card__title,
body.-nav-top #Traduction .c-service-card__title {
  transition: all 0.5s ease-in-out 0.2s; }
  @media (min-width: 769px) {
    body.-nav-top #Visa .c-service-card__title,
    body.-nav-top #Ecole .c-service-card__title,
    body.-nav-top #Traduction .c-service-card__title {
      transition: all 0.5s ease-in-out 0.5s; } }

body.-nav-top #Visa .c-service-card__body__detail,
body.-nav-top #Ecole .c-service-card__body__detail,
body.-nav-top #Traduction .c-service-card__body__detail {
  transition: all 0.5s ease-in-out 0.5s; }
  @media (min-width: 769px) {
    body.-nav-top #Visa .c-service-card__body__detail,
    body.-nav-top #Ecole .c-service-card__body__detail,
    body.-nav-top #Traduction .c-service-card__body__detail {
      transition: all 0.5s ease-in-out 0.9s; } }

body.-nav-top #Visa.-active .c-service-card__img-container,
body.-nav-top #Ecole.-active .c-service-card__img-container,
body.-nav-top #Traduction.-active .c-service-card__img-container {
  visibility: visible;
  opacity: 1;
  transform: translateX(0); }

body.-nav-top #Visa.-active .c-service-card__title,
body.-nav-top #Visa.-active .c-service-card__body__detail,
body.-nav-top #Ecole.-active .c-service-card__title,
body.-nav-top #Ecole.-active .c-service-card__body__detail,
body.-nav-top #Traduction.-active .c-service-card__title,
body.-nav-top #Traduction.-active .c-service-card__body__detail {
  visibility: visible;
  opacity: 1; }
