// ==============================================
// ブレイクポイント設定
// ==============================================
// これ以上の場合にPC表示になる
$breakpoint-pc: 768px;
$max-width: 1920px;
$lower-max-width: 960px;
$min-width: 1024px;
$lower-min-width: 960px;

// ==============================================
// フォント設定
// ==============================================
// ベースのフォントサイズ 1rem = 10px
$base-font-size: 1.6rem;
$base-sp-font-size: 1.5rem;

// ベースのフォントカラー
$base-font-color: #000;

// ベースのリンクカラー
$base-link-color: #000;

// ベースの行間
$base-line-height: 1.8;

// ベースのフォント
$base-font-family: 'Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3', 'Hiragino Sans','ヒラギノ角ゴシック',YuGothic,'Yu Gothic','メイリオ',Meiryo,'ＭＳ Ｐゴシック','MS PGothic',sans-serif;

// ==============================================
// 色設定
// ==============================================
// テーマカラー
$tricolor-blue-color: #323e95;
$tricolor-white-color: #fff;
$tricolor-red-color: #d80031;
$border-gray: #cbcbcb;
$border-gray2: #b2b2b2;
$border-gray3: #989898;
$bg-light-gray: #f5f5f5;
$bg-table-light-gray: #efefef;
$bg-marker-blue: #8990c1;
$dark-gray: #4d4d4d;
