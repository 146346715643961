.c-border-link {
  width: 102px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px white;
  font-size: 1.2rem;
  line-height: 1;
  white-space: nowrap;
  transition: all 0.3s ease;
  opacity: 1 !important;

  @include media-breakpoint-up(md) {
    width: 105px;
  }

  &.-white {
    color: white;
    background-color: transparent;

    &:hover {
      color: black;
      background-color: white;
    }
  }
}
