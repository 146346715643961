/* =============================================
 * Footer
 * ============================================= */

.l-footer {
  background-color: #b3b3b3;
  position: relative;

  @include media-breakpoint-up(md) {
    background-color: white;
  }

  &::before,
  &::after {
    content: "";
    width: calc(100% / 3);
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &::before {
    left: 0;
    background-color: $tricolor-blue-color;
  }

  &::after {
    right: 0;
    background-color: $tricolor-red-color;
  }

  &__main {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.85);
    position: relative;
    z-index: 1;
    padding: 0 18px;

    &__inner {
      width: 100%;
      padding: 22px 0 61px;

      @include media-breakpoint-up(md) {
        width: 1000px;
        padding: 30px 0 30px;
      }
    }
  }

  &__top-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;

    &__left {
      display: flex;
    }
  }

  &__bottom-group {
    @include media-breakpoint-up(md) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-top: solid 1px white;
      padding-top: 14px;
    }

    &__left {
      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }
    }
    &__right {}
  }

  &__logo {
    width: 106px;

    @include media-breakpoint-up(md) {
      width: 171px;
      margin-right: 13px;
    }

    &__link {
      display: block;
    }

    &__img {}
  }

  &__read {
    font-size: 1.2rem;
    line-height: 1.6;
    color: white;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__helper-list {
    display: flex;
    padding: 0;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }

    &__item {

      &:first-of-type {
        margin-right: 7px;
      }

      .c-border-link {
        @media screen and (max-width: 320px) {
          width: 80px;
        }
      }
    }
  }

  &__banner {
    width: 240px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      width: 232px;
      margin: 10px 2px 0 0;
    }

    &__link {}
    &__img {}
  }

  &__copy {
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 1.2rem;
    white-space: nowrap;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      color: white;
    }

    @include media-breakpoint-up(md) {
      height: 85px;
      font-size: 1.4rem;
    }
  }
}
