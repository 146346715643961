.c-step-list {

  &__item {
    position: relative;

    &:not(:last-of-type) {
      padding-bottom: 79px;
      margin-bottom: 25px;

      @include media-breakpoint-up(md) {
        padding-bottom: 103px;
        margin-bottom: 61px;
      }

      &::after {
        content: "\e90e";
        /* stylelint-disable */
        font-family: Premier;
        font-size: 2.6rem;
        color: black;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }

    .c-step-title {
      margin-bottom: 14px;

      @include media-breakpoint-up(md) {
        margin-bottom: 34px;
      }
    }

    .c-paragraph {
      line-height: 1.8;

      @include media-breakpoint-up(md) {
        line-height: 1.9;
      }
    }

    .c-more-link {
      margin: 0 auto;
    }

    .c-helper-btn {
      margin: 0 auto;
    }

    .c-basic-title {
      margin-bottom: 10px;
    }

    .c-marker-txt {
      margin-bottom: 18px;
    }
  }
}
