.p-ecole-index {

  &__intro {
    @include media-breakpoint-down(sm) {
      padding: 29px 19px 0;
      margin-bottom: 64px;
    }

    @include media-breakpoint-up(md) {
      width: 960px;
      padding-top: 70px;
      margin: 0 auto 76px;
    }

    .c-paragraph {
      font-size: 1.5rem;
      line-height: 1.7;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
      }
    }
  }

  &__header {
    margin-bottom: 44px;

    @include media-breakpoint-up(md) {
      margin-bottom: 64px;
    }

    .c-lower-title {
      margin-bottom: 32px;

      @include media-breakpoint-up(md) {
        margin-bottom: 57px;
      }
    }

    .c-paragraph {
      font-size: 1.5rem;
      line-height: 1.6;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
        line-height: 1.7;
      }
    }
  }

  &__sec {
    @include media-breakpoint-down(sm) {
      padding: 63px 19px 0;
    }

    @include media-breakpoint-up(md) {
      width: 960px;
      padding-top: 80px;
      margin: 0 auto;
    }
  }

  &__child-sec {

    &.-diff {
      @include media-breakpoint-up(md) {
        margin-bottom: 7px;
      }
    }
  }

  &__grand-child-sec {

    .c-fill-title {
      margin-bottom: 19px;

      @include media-breakpoint-up(md) {
        margin-bottom: 39px;
      }
    }

    /* stylelint-disable-next-line */
    .c-paragraph {
      line-height: 1.95;
      margin-bottom: 25px;
    }

    .c-more-link {
      margin: 38px auto 0;

      @include media-breakpoint-up(md) {
        width: 270px;
        margin-top: 73px;
      }
    }

    .c-underline-title {
      margin-bottom: 34px;

      @include media-breakpoint-up(md) {
        margin-bottom: 30px;
      }
    }
  }

  &__child-sec.-diff &__grand-child-sec {
    padding-bottom: 50px;

    @include media-breakpoint-up(md) {
      padding-bottom: 106px;
    }

    .c-paragraph {
      @include media-breakpoint-down(sm) {
        line-height: 1.6;
        margin-bottom: 31px;
        font-size: 1.3rem;
      }

      @include media-breakpoint-up(md) {
        padding-left: 10px;
      }
    }
  }

  &__child-sec.-find &__grand-child-sec {
    padding-bottom: 32px;

    @include media-breakpoint-up(md) {
      padding-bottom: 32px;
    }

    .c-paragraph {
      @include media-breakpoint-down(sm) {
        line-height: 1.65;
        font-size: 1.3rem;
      }

      @include media-breakpoint-up(md) {
        padding-left: 10px;
      }
    }
  }

  &__col2 {
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &.-type1 {
      margin-bottom: 37px;

      @include media-breakpoint-up(md) {
        margin-bottom: 24px;
      }
    }

    &.-type1 &__left {
      @include media-breakpoint-down(sm) {
        margin-bottom: 28px;
      }

      @include media-breakpoint-up(md) {
        width: 468px;
      }
    }

    &.-type1 &__right {
      @include media-breakpoint-up(md) {
        width: 444px;
      }

      .c-border-table {
        margin-top: -11px;
      }
    }

    &.-type2 {
      margin-bottom: 7px;

      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 36px;
      }
    }

    &.-type2 &__left {
      @include media-breakpoint-down(sm) {
        order: 1;
      }

      @include media-breakpoint-up(md) {
        width: 480px;
      }
    }

    &.-type2 &__right {
      @include media-breakpoint-down(sm) {
        order: 0;
        margin-bottom: 13px;
      }

      @include media-breakpoint-up(md) {
        width: 432px;
        padding-top: 5px;
      }
    }
  }

  &__num-ttl {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 22px;

    @include media-breakpoint-down(sm) {
      line-height: 1.3;
    }

    @include media-breakpoint-up(md) {
      font-size: 2.6rem;
      margin-bottom: 30px;
    }
  }

  &__child-sec.-find &__num-ttl {
    @include media-breakpoint-down(sm) {
      margin-bottom: 28px;
    }
  }

  &__post-ttl {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
      margin-bottom: 17px;
    }
  }
}
