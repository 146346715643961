.c-visa-post {

  &__row {
    display: flex;
    margin-bottom: 15px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    @include media-breakpoint-up(md) {
      justify-content: space-between;
      margin-bottom: 25px;
    }
  }

  &__img {
    flex-shrink: 0;
    margin-top: 3px;

    @include media-breakpoint-down(sm) {
      order: 0;
    }
  }

  &__body {
    @include media-breakpoint-down(sm) {
      order: 1;
      padding: 0 19px;
    }
  }

  &:not(.-prix) &__body {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &.-col2 &__img,
  &.-col2 &__body {
    @include media-breakpoint-up(md) {
      width: calc((100% - 20px) / 2);
    }
  }

  &__ttl {
    @include media-breakpoint-down(sm) {
      display: inline-block;
      background: white;
      padding: 25px 17px 17px;
      margin: -52px 0 0 -19px;
    }

    &__fr {
      margin-bottom: 8px;

      @include media-breakpoint-up(md) {
        margin-bottom: 10px;
      }

      &.-etudiant {
        width: 151px;

        @include media-breakpoint-up(md) {
          width: 295px;
        }
      }

      &.-scolarise {
        width: 198px;

        @include media-breakpoint-up(md) {
          width: 388px;
        }
      }

      &.-concours {
        width: 212px;

        @include media-breakpoint-up(md) {
          width: 416px;
        }
      }
    }

    &__ja {
      display: block;
      font-weight: bold;

      &__main {
        font-size: 1.8rem;

        @include media-breakpoint-up(md) {
          font-size: 2.9rem;
        }
      }

      &__sub {
        font-size: 1.2rem;

        @include media-breakpoint-up(md) {
          font-size: 1.7rem;
        }
      }
    }
  }

  &__paragraph {
    line-height: 1.8;
    margin-bottom: 43px;

    @include media-breakpoint-down(sm) {
      padding: 0 19px;
    }

    @include media-breakpoint-up(md) {
      line-height: 2;
      margin-bottom: 16px;
    }
  }

  &__board {}

  &:not(.-col2) &__paragraph {
    margin-top: 25px;
  }

  &__ref-link {
    display: block;
    font-size: 1.2rem;

    @include media-breakpoint-down(sm) {
      padding: 0 19px;
    }
  }

  &__link-list {
    margin-top: 34px;

    @include media-breakpoint-up(md) {
      margin-top: 39px;
    }
  }

  &.-col2.-prix &__img {
    @include media-breakpoint-up(md) {
      width: calc(100% / 2 - 10px);
    }
  }

  &.-col2.-prix &__body {
    @include media-breakpoint-down(sm) {
      margin-top: -30px;
    }

    @include media-breakpoint-up(md) {
      width: calc(100% / 2 - 35px);
    }
  }

  &.-prix &__ttl {
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
      transform: translateY(-19px);
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 15px;
      padding-bottom: 12px;
    }

    &__ja {
      line-height: 1.3;

      &__main {
        font-size: 1.8rem;

        @include media-breakpoint-up(md) {
          font-size: 3.6rem;
        }
      }

      &__sub {
        font-size: 1.2rem;

        @include media-breakpoint-up(md) {
          font-size: 2.2rem;
        }
      }
    }
  }

  &.-prix &__paragraph {
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 15px;

    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }

    @include media-breakpoint-up(md) {
      line-height: 1.9;
      margin-bottom: 38px;
    }
  }

  &.-prix &__board {
    padding: 12px 24px;

    @include media-breakpoint-up(md) {
      padding: 10px 24px;
    }
  }
}
