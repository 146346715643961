.p-qa-index {

  .l-main {
    padding-bottom: 0;
  }

  &__intro {
    @include media-breakpoint-down(sm) {
      padding: 22px 19px 0;
      margin-bottom: 77px;
    }

    @include media-breakpoint-up(md) {
      width: 960px;
      padding-top: 71px;
      margin: 0 auto 80px;
    }

    .c-paragraph {
      font-size: 1.5rem;
      line-height: 1.6;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
      }
    }
  }

  .c-tab-nav__link {
    @media screen and (max-width: 320px){
      font-size: 1rem;
    }
  }

  &__ttl {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 35px;

    @include media-breakpoint-up(md) {
      font-size: 3.6rem;
      margin-bottom: 71px;
    }
  }

  &__sec {
    background-color: $bg-light-gray;

    &__inner {
      @include media-breakpoint-down(sm) {
        padding: 51px 19px 100px;
      }

      @include media-breakpoint-up(md) {
        width: 960px;
        padding: 75px 0 150px;
        margin: 0 auto;
      }
    }
  }
}
