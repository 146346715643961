.c-drawer-menu {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition-property: visibility, opacity;
  transition-duration: 0.5s;
  overflow-y: auto;

  @include media-breakpoint-up(md) {
    display: none !important;
  }

  body.is-opened--drawer-menu & {
    opacity: 1;
    visibility: visible;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    padding: 0 0 80px;
  }

  &__list {
    width: calc(100% - 38px);
    margin: 0 auto;

    &.-faq {
      margin-top: 14px;
    }
  }

  &__item {
    border-bottom: solid 1px black;

    &:first-of-type {
      border-top: solid 1px black;
    }
  }

  &__link {
    width: 100%;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.6rem;
    font-weight: bold;
    padding-left: 1px;
  }

  &__icon {
    color: black;
    margin-right: 5px;
  }

  &__accordion {
    margin-top: 15px;

    &__list {
      display: flex;
      margin-bottom: 11px;
    }

    &__item {
      width: calc(100% / 3);

      &.-visa {
        background-color: $tricolor-blue-color;
      }

      &.-ecole {
        background-color: #ccc;
      }

      &.-traduction {
        background-color: $tricolor-red-color;
      }
    }

    &__link {
      height: 136px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white !important;
      padding: 16px 0 6px;

      &::after {
        content: "\e908";
        /* stylelint-disable-next-line */
        font-family: Premier;
        font-size: 1rem;
        transform: rotate(0);
        //transition: all 0.3s;
        margin-top: auto;
      }

      &.-ecole {
        color: black !important;
      }
    }

    &[data-accordion-expanded-name="visa"] &__link.-visa::after,
    &[data-accordion-expanded-name="ecole"] &__link.-ecole::after,
    &[data-accordion-expanded-name="traduction"] &__link.-traduction::after {
      transform: rotate(180deg);
    }

    &__icon {
      pointer-events: none;

      &.pi-visa {
        font-size: 4rem;
        padding-top: 2px;
        margin-bottom: 8px;
      }

      &.pi-ecole {
        font-size: 4rem;
        color: black;
        margin-bottom: 9px;
      }

      &.pi-traduction {
        font-size: 3.4rem;
        padding-top: 2px;
        margin-bottom: 13px;
      }
    }

    &__label {
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center;
      line-height: 1.3;
      pointer-events: none;
    }

    &__panel-container {
      width: calc(100% - 38px);
      position: relative;
      margin: 0 auto;
    }

    &__panel {
      width: 100%;
      height: 0;
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }

    &[data-accordion-expanded-name="visa"] &__panel.-visa,
    &[data-accordion-expanded-name="ecole"] &__panel.-ecole,
    &[data-accordion-expanded-name="traduction"] &__panel.-traduction {
      opacity: 1;
      visibility: visible;
    }

    &[data-accordion-expanded-name="visa"] &__panel.-visa {
      height: 99px;
    }

    &[data-accordion-expanded-name="ecole"] &__panel.-ecole {
      height: 132px;
    }

    &[data-accordion-expanded-name="traduction"] &__panel.-traduction {
      height: 99px;
    }

    &__child {

      &-list {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &-item {
      }

      &-link {
        min-width: 129px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.5rem;
        white-space: nowrap;
        padding: 3px 0;
      }

      &-icon {
        font-size: 1rem;
        margin-left: 1.5em;
      }
    }
  }

  &__banner {
    width: calc(100% - 38px);
    margin: 10px auto 0;

    &__list {}

    &__item {
      margin-bottom: 11px;
    }

    &__link {
      height: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      font-weight: bold;
      white-space: nowrap;
      color: white !important;

      &.-contact {
        background-color: $tricolor-blue-color;
      }

      &.-inscription {
        background-color: $tricolor-red-color;
      }
    }

    &__icon {

      &.pi-mail {
        font-size: 2.8rem;
        margin-right: 11px;
      }

      &.pi-inscription {
        font-size: 3.2rem;
        margin: -5px 14px 0 0;
      }
    }
  }

  &__sub {
    width: calc(100% - 38px);
    margin: 0 auto;

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      margin-right: 23px;
    }

    &__link {
      font-size: 1.4rem;
      white-space: nowrap;
      padding: 3px 0;
    }

    &__icon {
      font-size: 1.7rem;
    }
  }
}
