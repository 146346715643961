.c-img-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0;

  &__item {
    width: calc(100% / 2);

    @include media-breakpoint-up(md) {
      width: calc(100% / 3);

      *::-ms-backdrop,
      & {
        width: 33.33%;
      }
    }
  }

  &__link {
    display: block;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &__img {}

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.6rem;
    font-weight: bold;
    color: white;
    line-height: 1.3;
    text-align: center;
    white-space: nowrap;
    z-index: 1;

    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
    }
  }
}
