.c-marker-txt {
  display: inline-flex;
  background-color: #8990c1;
  font-size: 1.6rem;
  font-weight: bold;
  color: white;
  padding: 3px 15px;

  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
    padding: 3px 22px;
  }
}
