.p-translation-index {

  &__intro {
    @include media-breakpoint-down(sm) {
      padding: 30px 19px 0;
      margin-bottom: 26px;
    }

    @include media-breakpoint-up(md) {
      width: 960px;
      padding-top: 71px;
      margin: 0 auto 67px;
    }

    .c-paragraph {
      font-size: 1.5rem;
      line-height: 1.6;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
      }
    }
  }

  &__header {
    margin-bottom: 57px;

    @include media-breakpoint-up(md) {
      margin-bottom: 57px;
    }

    .c-lower-title {
      margin-bottom: 27px;

      @include media-breakpoint-up(md) {
        margin-bottom: 43px;
      }
    }

    .c-paragraph {
      font-size: 1.5rem;
      line-height: 1.6;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
        line-height: 1.7;
      }
    }
  }

  &__sec {
    @include media-breakpoint-down(sm) {
      padding: 63px 19px 0;
    }

    @include media-breakpoint-up(md) {
      width: 960px;
      padding-top: 79px;
      margin: 0 auto;
    }
  }

  &__child-sec {
    margin-bottom: 50px;

    @include media-breakpoint-up(md) {
      margin-bottom: 80px;
    }

    .c-fill-title {
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 78px;
      }
    }
  }

  &__link-group {
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }

    .c-more-link {
      font-size: 1.4rem;

      @include media-breakpoint-up(md) {
        width: 272px;
        font-size: 1.5rem;
      }

      &:first-of-type {
        @include media-breakpoint-down(sm) {
          margin-bottom: 20px;
        }

        @include media-breakpoint-up(md) {
          margin-right: 50px;
        }
      }
    }
  }
}
