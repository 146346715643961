.c-translation-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;

  @include media-breakpoint-down(sm) {
    display: block;
    border-bottom: solid 1px $border-gray3;

    &__thead {
      display: none;
    }

    &__tbody {
      width: 100%;
      display: block;
    }
  }

  &__tr {
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &__th,
  &__td {
    border: solid 1px $border-gray3;

    @include media-breakpoint-down(sm) {
      width: 100%;
      display: block;
      border-bottom: none;
    }

    @include media-breakpoint-up(md) {
      border: solid 1px $border-gray3;
    }
  }

  &__th {
    background-color: $bg-light-gray;
    font-weight: normal;
    line-height: 1.6;

    @include media-breakpoint-up(md) {
      padding: 14px 0;

      &:nth-of-type(1) {
        width: 500px;
      }

      &:nth-of-type(2) {
        width: 248px;
      }
    }

    &__inner {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__td {
    background-color: white;
    font-size: 1.6rem;
    line-height: 1.6;

    @include media-breakpoint-down(sm) {

      &:not(:first-child) {
        padding: 15px 19px;
      }

      &:first-child {

        .c-translation-table__ttl {
          padding: 16px 0;
        }
      }
    }

    @include media-breakpoint-up(md) {
      padding: 15px 19px;
    }
  }

  &__ttl {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  &__sp-th-top {
    background-color: $bg-light-gray;
    text-align: center;
    border-bottom: solid 1px $border-gray3;
    padding: 14px 0;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__sp-th {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
