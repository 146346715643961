.c-tab-nav {
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: $border-gray;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__list {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 0 19px;
    }

    @include media-breakpoint-up(md) {
      width: 960px;
      margin: 0 auto;
    }
  }

  &__item {
    height: 88px;
    position: relative;

    @include media-breakpoint-up(md) {
      height: 116px;
    }
  }

  &__link {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $tricolor-blue-color;
    font-size: 1.2rem;
    line-height: 1.3;
    color: white !important;
    white-space: nowrap;
    position: relative;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      height: 72px;
      font-size: 2rem;
    }

    &:not(.-current) {
      opacity: 0.5;
      transition: opacity 0.3s;
    }

    &:hover {
      opacity: 1;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
      z-index: 1;
    }

    &::before {
      height: 8px;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      border-left: solid 5px transparent;
      border-right: solid 5px transparent;
      border-top: solid 8px $tricolor-blue-color;

      @include media-breakpoint-up(md) {
        height: 15px;
        bottom: -15px;
        border-left: solid 13px transparent;
        border-right: solid 13px transparent;
        border-top: solid 13px $tricolor-blue-color;
      }
    }

    &::after {
      width: 100%;
      height: 2px;
      bottom: -28px;
      left: 0;
      background-color: $tricolor-blue-color;

      @include media-breakpoint-up(md) {
        bottom: -44px;
      }
    }

    &.-current::before,
    &.-current::after {
      visibility: visible;
      opacity: 1;
    }
  }

  &.-col3 &__item {
    width: calc((100% - 10px) / 3);

    @include media-breakpoint-up(md) {
      width: 310px;
    }
  }

  &.-col4 &__item {
    width: calc((100% - 15px) / 4);

    @include media-breakpoint-up(md) {
      width: 228px;
    }
  }
}
