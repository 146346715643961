.c-lower-kv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  margin-bottom: 11px;

  @include media-breakpoint-down(sm) {
    padding-top: 37.6%;
  }

  @include media-breakpoint-up(md) {
    min-width: 1044px;
    height: 350px;
    margin-bottom: 25px;
  }

  &::before,
  &::after {
    width: calc(100% / 3);
    height: 100%;
    display: block;
    content: "";
    position: absolute;
    opacity: 0.8;
    top: 0;
  }

  &::before {
    background-color: $tricolor-blue-color;
    left: 0;
  }

  &::after {
    background-color: $tricolor-red-color;
    right: 0;
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    color: white;

    @include media-breakpoint-down(sm) {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @include media-breakpoint-up(md) {
      position: relative;
    }

    &__icon { }

    &__fr-img {
      flex-shrink: 0;
      margin-bottom: 5px;
    }

    &__txt {
      font-size: 1.5rem;
      white-space: nowrap;

      @include media-breakpoint-up(md) {
        font-size: 2.6rem;
      }
    }
  }

  &__ja-title {
    font-size: 4.2vw;
    font-weight: bold;

    @include media-breakpoint-up(md) {
      font-size: 5rem;
    }
  }

  &.-premier {
    background-image: url("/assets/img/premier/kv.jpg");
  }

  &.-premier &__title {
    margin-top: 11px;

    @include media-breakpoint-up(md) {
      margin-top: 31px;
    }

    &__fr-img {
      width: 69%;
      margin-right: 10px;
      margin-bottom: 5px;

      @include media-breakpoint-up(md) {
        width: 590px;
        margin-bottom: 12px;
      }
    }
  }

  &.-visa {
    background-image: url("/assets/img/visa/kv.jpg");
  }

  &.-visa &__title {
    margin-top: -2%;

    @include media-breakpoint-up(md) {
      margin-top: -21px;
    }

    &__icon {
      font-size: 4rem;
      margin-bottom: 5px;

      @include media-breakpoint-up(md) {
        font-size: 6.4rem;
        margin-bottom: 20px;
      }
    }

    &__fr-img {
      width: 72%;
      margin-bottom: 2px;

      @include media-breakpoint-up(md) {
        width: 620px;
        margin-bottom: 5px;
      }
    }
  }

  &.-ecole {
    background-image: url("/assets/img/ecole/kv.jpg");
  }

  &.-ecole &__title {
    margin-top: -2%;

    @include media-breakpoint-up(md) {
      margin-top: -23px;
    }

    &__icon {
      font-size: 4rem;
      margin-bottom: 1.9%;

      @include media-breakpoint-up(md) {
        font-size: 7.1rem;
        margin-bottom: 13px;
      }
    }

    &__fr-img {
      width: 19.5%;
      margin-bottom: 1.8%;

      @include media-breakpoint-up(md) {
        width: 168px;
        margin-bottom: 16px;
      }
    }
  }

  &.-translation {
    background-image: url("/assets/img/translation/kv.jpg");
  }

  &.-translation &__title {
    margin-top: -3%;

    @include media-breakpoint-up(md) {
      margin-top: -22px;
    }

    &__icon {
      font-size: 3.7rem;
      margin-bottom: 2%;

      @include media-breakpoint-up(md) {
        font-size: 6.4rem;
        margin-bottom: 18px;
      }
    }

    &__fr-img {
      width: 38%;
      margin-bottom: 1.6%;

      @include media-breakpoint-up(md) {
        width: 327px;
        margin-bottom: 14px;
      }
    }
  }

  &.-qa {
    background-image: url("/assets/img/qa/kv.jpg");
  }

  &.-qa &__title {
    margin-top: 2%;

    @include media-breakpoint-up(md) {
      margin-top: 33px;
    }

    &__fr-img {
      width: 19.5%;
      margin-bottom: 1%;

      @include media-breakpoint-up(md) {
        width: 178px;
        margin-bottom: 12px;
      }
    }
  }

  &.-premier-other {
    background-image: url("/assets/img/premier/other-kv.jpg");
  }

  &.-premier-other &__title {
    @include media-breakpoint-up(md) {
      margin-top: -13px;
    }

    &__fr-img {
      width: 19.5%;
      margin-bottom: 1%;

      @include media-breakpoint-up(md) {
        width: 178px;
        margin-bottom: 12px;
      }
    }
  }
}
