.c-fill-title {
  font-size: 1.6rem;
  color: white;
  padding: 3px 12px;

  @include media-breakpoint-up(md) {
    font-size: 2.4rem;
    color: white;
    padding: 7px 20px;
  }

  &.-blue {
    background-color: $tricolor-blue-color;
  }

  &.-light-blue {
    font-weight: bold;
    color: $tricolor-blue-color;
    background-color: #ebecf4;

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }
}
