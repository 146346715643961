.l-footer-sub-nav {

  &__list {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
  }

  &__item {
    margin-right: 24px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 5px;
    }

    @include media-breakpoint-up(md) {
      margin-right: 24px;
    }
  }

  &__link {
    font-size: 1.4rem;
    color: white;
    white-space: nowrap;
    padding: 3px 0;

    &:hover {
      color: white;
    }
  }

  &__item:first-of-type &__link {
    padding-left: 0;
  }

  &__icon {
    font-size: 1.6rem;
    margin-left: 2px;
  }
}
