.c-underline-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: $tricolor-blue-color;
  border-bottom: solid 2px $tricolor-blue-color;
  padding: 2px 0 1px 1px;

  @include media-breakpoint-up(md) {
    font-size: 2.4rem;
    padding: 5px 0 6px 2px;
  }
}
