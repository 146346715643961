.c-sec-visual {
  position: relative;

  &__img {}

  &__title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &__txt {
      display: block;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1;
      color: white;

      @include media-breakpoint-up(md) {
        font-size: 2.4rem;
      }
    }

    &.-pour-etudier {
      right: 20px;
      margin-top: 6px;

      @include media-breakpoint-up(md) {
        right: 46px;
        margin-top: 6px;
      }

      .c-sec-visual__title__img {
        width: 241px;
        margin-bottom: 8px;

        @include media-breakpoint-up(md) {
          width: 484px;
          margin-bottom: 10px;
        }
      }

      .c-sec-visual__title__txt {
        @include media-breakpoint-down(sm) {
          text-align: right;
        }
      }
    }

    &.-visiteur {
      left: 20px;
      margin-top: 5px;

      @include media-breakpoint-up(md) {
        left: 48px;
        margin-top: 11px;
      }

      .c-sec-visual__title__img {
        width: 162px;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
          width: 326px;
          margin-bottom: 18px;
        }
      }
    }

    &.-vacances {
      left: 20px;
      margin-top: 4px;

      @include media-breakpoint-up(md) {
        left: 50px;
        margin-top: 9px;
      }

      .c-sec-visual__title__img {
        width: 280px;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
          width: 564px;
          margin-bottom: 19px;
        }
      }
    }

    &.-scientifique {
      left: 20px;
      margin-top: 15px;

      @include media-breakpoint-up(md) {
        left: 50px;
        margin-top: 31px;
      }

      .c-sec-visual__title__img {
        width: 296px;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
          width: 596px;
          margin-bottom: 19px;
        }
      }
    }

    &.-passeport {
      right: 20px;
      margin-top: 4px;

      @include media-breakpoint-up(md) {
        right: 69px;
        margin-top: 8px;
      }

      .c-sec-visual__title__img {
        width: 281px;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
          width: 566px;
          margin-bottom: 11px;
        }
      }
    }

    &.-scolarise-monaco {
      left: 20px;
      margin-top: 5px;

      @include media-breakpoint-up(md) {
        left: 50px;
        margin-top: 10px;
      }

      .c-sec-visual__title__img {
        width: 251px;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
          width: 505px;
          margin-bottom: 14px;
        }
      }

      .c-sec-visual__title__txt {
        color: black;
      }
    }
  }
}
