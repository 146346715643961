.c-website-link {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  white-space: nowrap;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
  }

  &::after {
    content: "\e900";
    /* stylelint-disable-next-line */
    font-family: Premier;
    font-size: 1rem;
    line-height: 1;
    margin: 3px 2px 0 6px;
  }

  &__icon {
    font-size: 1.5rem;
    margin-right: 3px;

    @include media-breakpoint-up(md) {
      font-size: 1.9rem;
    }
  }
}
