.p-ecole-list {

  &__header {
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      margin-bottom: 88px;
    }
  }

  &__sec {
    background-color: $bg-light-gray;
    padding-top: 63px;

    @include media-breakpoint-up(md) {
      padding-top: 80px;
    }
  }

  &__map-container {

    &__inner {
      @include media-breakpoint-down(sm) {
        padding: 0 19px 10px;
      }

      @include media-breakpoint-up(md) {
        width: 960px;
        padding-bottom: 84px;
        margin: 0 auto;
      }
    }
  }

  &__map {
    width: 80%;
    margin: 0 auto 42px;

    @include media-breakpoint-up(md) {
      width: 520px;
      margin: 0 auto 72px;
      transform: translateX(-22px);
    }

    &__img { }
  }

  &__list-container {
    background-color: white;

    &__inner {
      @include media-breakpoint-down(sm) {
        padding: 32px 19px 0;
      }

      @include media-breakpoint-up(md) {
        width: 960px;
        padding-top: 101px;
        margin: 0 auto;
      }
    }
  }

  &__school-sec {
    margin-bottom: 31px;

    @include media-breakpoint-up(md) {
      margin-bottom: 31px;
    }

    .c-fill-title {
      margin-bottom: 13px;

      @include media-breakpoint-up(md) {
        margin-bottom: 31px;
      }
    }

    .c-school-list {

      &:not(:last-of-type) {
        margin-bottom: 24px;

        @include media-breakpoint-up(md) {
          margin-bottom: 24px;
        }
      }
    }
  }

  &__city-group {
    display: flex;
    flex-wrap: wrap;
  }
}
