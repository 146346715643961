/* =============================================
 * Gnav
 * ============================================= */

.l-gnav {
  position: absolute;
  right: -2px;
  top: 74px;
  transition: all 0.5s;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  &__list {
    display: flex;
    justify-content: center;
  }

  &__item {}

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 1.3rem;
    font-weight: bold;
    white-space: nowrap;
    color: white;
    padding: 0 6.4px;
    position: relative;
    transition: color 0.5s;
    opacity: 1 !important;

    &::after {
      content: "";
      width: calc(100% - 13px);
      height: 2px;
      background-color: white;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 5px);
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
    }

    &:hover {
      color: white;

      &::after {
        visibility: visible;
        opacity: 1;
        transform: translate(-50%, 2px);
      }
    }

    .l-header.-fixed & {
      color: black;

      &::after {
        background-color: black;
      }
    }
  }

  .l-header.-fixed & {
    right: 248px;
    top: 19px;
  }

  /* stylelint-disable-next-line */
  body.-lower &__link {
    color: black;

    /* stylelint-disable-next-line */
    &::after {
      background-color: black;
    }
  }
}
